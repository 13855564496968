import React, { useState, useEffect } from 'react';
import { IoMdTrash } from 'react-icons/io';
import { FaRegEdit } from 'react-icons/fa';
import Searchicon from '../Assets/tableicon/Searchicon.png';
import WastageCreate from '../components/WastageCreate';
import WastageEdit from '../components/WastageEdit';
import WastageDelete from '../components/WastageDelete';

const Wastage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState([]);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedWastage, setSelectedWastage] = useState(null);
  const [warehouses, setWarehouses] = useState([]); // State to hold warehouse data
  const [selectedWarehouse, setSelectedWarehouse] = useState(''); // State for selected warehouse
  const [formValues, setFormValues] = useState({
    productName: '',
    warehouseName: '',
    quantity: '',
    unitSymbol: '',
  });

  // Fetch warehouse data
  const fetchWarehouses = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found in local storage');
        return;
      }
      const response = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const responseData = await response.json();
      setWarehouses(responseData.data); // Set warehouse data in state
    } catch (error) {
      console.error('Error fetching warehouses:', error);
    }
  };
  

  useEffect(() => {
    fetchWarehouses(); // Fetch warehouse data on component mount
  }, []);
  
  useEffect(() => {
    fetchData(); // Fetch wastage data when component mounts, selectedWarehouse changes, or currentPage changes
  }, [selectedWarehouse, currentPage]);
  
  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found in local storage');
        return;
      }
      const response = await fetch(`https://pos.farm2bag.com/api/v1/wastages`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const responseData = await response.json();
      setData(responseData.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

 // Handle delete wastage item
const handleDelete = async (wastageId, productId) => {
  console.log(wastageId,productId);
  try {
    const token = localStorage.getItem('token');
    await fetch(`https://pos.farm2bag.com/api/v1/wastages/${wastageId}/${productId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const updatedData = data.map((item) => {
      if (item.id === wastageId) {
        return {
          ...item,
          items: item.items.filter((product) => product.productId !== productId),
        };
      }
      return item;
    }).filter((item) => item.items.length > 0); // Remove wastage entry if no products left
    setData(updatedData);
    setShowDeletePopup(false);
    
//delete last product in the page means it will go to pre page
    const lastPage = Math.ceil(updatedData.length / itemsPerPage);
    if (currentPage > lastPage) {
        setCurrentPage(lastPage);
    }
    // 
    
  } catch (error) {
    console.error('Error deleting product from wastage:', error);
  }
};




  // Filter data based on searchQuery and selectedWarehouse
  // Filter data based on searchQuery and selectedWarehouse
const filteredData = data.filter(
  (item) =>
    item.items.some((product) =>
      product.productName.toLowerCase().includes(searchQuery.toLowerCase())
    ) && (!selectedWarehouse || item.warehouseName === selectedWarehouse)
);

// Flatten the filtered data to handle pagination correctly
const flattenedData = filteredData.flatMap((item) =>
  item.items.map((product) => ({
    ...product,
    warehouseName: item.warehouseName,
    wastageId: item.id,
  }))
);


  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = flattenedData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle pagination
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Open edit popup
  const openEditPopup = (wastageId, product) => {
    setSelectedWastage({ wastageId, product });
    setShowEditPopup(true);
  };

  // Open delete popup
  const openDeletePopup = (wastageId, product) => {
    setSelectedWastage({ wastageId, product });
    setShowDeletePopup(true);
  };

  return (
    <div className="bg-gray-100 ml-64 mt-20 p-12 h-screen">
      <div className="container mx-auto">
        <div className="mb-10">
          <div className="flex">
            <h1 className="font-extrabold text-3xl text-left text-gray-700">
              Wastage
            </h1>
          </div>
          <div className="mt-2 border-t border-gray-300"></div>
        </div>
        <div className="flex items-center mb-5">
          <div className="ml-0">
            <form>
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="border border-gray-300 px-4 py-2 pl-10 rounded-lg focus:outline-none focus:border-blue-500"
                />
                <img
                  src={Searchicon}
                  alt="search"
                  className="absolute left-3 top-3 w-4 h-4"
                />
              </div>
            </form>
          </div>
          <div className="ml-4">
          <label htmlFor="warehouseSelect">Warehouse:</label>
<select
  id="warehouseSelect"
  value={selectedWarehouse}
  onChange={(e) => {
    setSelectedWarehouse(e.target.value);
    setCurrentPage(1); // Reset to the first page when warehouse changes
  }}
  className="border border-gray-300 rounded-lg px-2 py-1 ml-2"
>
  <option value="">Select Warehouse</option>
  {warehouses.map((warehouse) => (
    <option key={warehouse.id} value={warehouse.name}>
      {warehouse.name}
    </option>
  ))}
</select>

          </div>
          <div className="flex ml-auto mr-0 items-center space-x-4">
            <button
              className="bg-green-500 text-white px-4 py-2 rounded-lg"
              onClick={() => setShowCreatePopup(true)}
            >
              Create Wastage
            </button>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead className="bg-blue-500">
              <tr>
                <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Product Name
                </th>
                <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Warehouse
                </th>
                <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Quantity
                </th>
                
                <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Unit Symbol
                </th>
                <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {currentItems.map((product, index) => (
                <tr key={index} className="hover:bg-green-200">
                  <td className="py-3 px-4 text-left">
                    {product.productName}
                  </td>
                  <td className="py-3 px-4 text-left">
                    {product.warehouseName}
                  </td>
                  <td className="py-3 px-4 text-left">
                    {product.quantity}
                  </td>
                  <td className="py-3 px-4 text-left">
                    {product.unitSymbol}
                  </td>
                  <td className="px-4 py-2 text-left space-x-2">
                    <button onClick={() => openEditPopup(product.wastageId, product)}>
                      <FaRegEdit className="text-blue-500 hover:text-blue-700" size={25} />
                    </button>
                    <button onClick={() => openDeletePopup(product.wastageId, product)}>
                      <IoMdTrash className="text-red-500 hover:text-red-700" size={25} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between mt-4">
          <div>
            <label htmlFor="itemsPerPage" className="mr-2">
              Items per page:
            </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(Number(e.target.value))}
              className="border border-gray-300 rounded-lg px-2 py-1"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </select>
          </div>
          <div>
            {flattenedData.length > itemsPerPage && (
              <ul className="flex justify-end space-x-2">
                {Array.from({ length: Math.ceil(flattenedData.length / itemsPerPage) }).map((_, i) => (
                  <li key={i}>
                    <button
                      onClick={() => paginate(i + 1)}
                      className={`px-3 py-1 rounded-lg ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>

      {showCreatePopup && (
        <WastageCreate        
          setShowCreatePopup={setShowCreatePopup}
          formValues={formValues}
          setFormValues={setFormValues}
          fetchData={fetchData}
        />
      )}

      {showEditPopup && (
        <WastageEdit
          selectedWastage={selectedWastage}
          setShowEditPopup={setShowEditPopup}
          fetchData={fetchData}
        />
      )}

      {showDeletePopup && (
        <WastageDelete
          selectedWastage={selectedWastage}
          setShowDeletePopup={setShowDeletePopup}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default Wastage;
