import React from 'react'

import { useState } from "react";

// Modal Component
const StockUpdateModal = ({
    selectedWarehouseId,
    showModal,
    setShowModal,
    product,
    handleUpdateStock,
  }) => {
    const [newQuantity, setNewQuantity] = useState(0);
  
    const handleSubmit = (e) => {
      e.preventDefault();
      handleUpdateStock(product.productId, selectedWarehouseId,newQuantity);
      setShowModal(false); // Close modal after updating stock
    };
  
    return (
      showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-lg font-semibold mb-4">Update Stock Quantity</h2>
            <form onSubmit={handleSubmit}>
              <label className="block mb-2 font-medium text-gray-700">
                
                {product.name} - Current Quantity: {product.quantity}
              </label>
              <input
                type="number"
                value={newQuantity}
                onChange={(e) => setNewQuantity(e.target.value)}
                className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter new quantity"
              />
              <div className="mt-4 flex justify-end">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="bg-gray-300 px-4 py-2 rounded-lg mr-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      )
    );
  };

export default StockUpdateModal
