import React, { useState, useEffect } from 'react';
import { toast} from 'react-toastify';
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import filter from "../Assets/tableicon/filter.png";
import Searchicon from "../Assets/tableicon/Searchicon.png";
import Eye from "../Assets/tableicon/Eye.svg";
import AdjustmentCreate from "../components/AdjustmentCreate";
import AdjustmentEdit from "../components/AdjustmentEdit";
import AdjustmentDelete from "../components/AdjustmentDelete";

const Adjustment = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [dataCategories, setdataCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [data, setData] = useState([]);
    const [isCreatePopupOpen, setCreatePopupOpen] = useState(false);
    const [isEditPopupOpen, setEditPopupOpen] = useState(false);
    const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
    const [selecteddata, setSelecteddata] = useState(null);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [popupData, setPopupData] = useState(null);
    const [sortBy, setSortBy] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(''); // State for selected warehouse
    const [warehouseId, setWarehouseId] = useState(''); // State to store selected warehouse ID


    useEffect(() => {
        fetchdataCategories();
    }, []);

    useEffect(() => {
        if (dataCategories.length > 0) {
            // Automatically select the first warehouse when dataCategories is loaded
            const firstWarehouseId = dataCategories[0]?.id || '';
            setSelectedWarehouse(firstWarehouseId);
            setWarehouseId(firstWarehouseId);
        }
    }, [dataCategories]);

    useEffect(() => {
        fetchData(warehouseId);
    }, [warehouseId]);

    useEffect(() => {
        fetchData(warehouseId);
    }, [currentPage, itemsPerPage, warehouseId]);


    const fetchData = async (warehouseId) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }
    
            let url = 'https://pos.farm2bag.com/api/v1/adjustments';
    
            if (warehouseId) {
                url += `/byWarehouseId/${warehouseId}`;
            }
    
    
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    
    
            if (!response.ok) {
                // Handle specific status codes here
                if (response.status === 404) {
                    console.log('No adjustments found for the provided warehouseId.'); // Log specific message for 404
                    setData([]);
                    toast.info('No adjustments found for the selected warehouse');
                    return;
                }
                throw new Error('Failed to fetch adjustments');
            }
    
            const responseData = await response.json();
    
    
            // Check the responseData status and handle accordingly
            if (responseData.status === 404 && responseData.data === null) {
                console.log('No adjustments found for the selected warehouse'); // Log specific message for 404 with null data
                setData([]);
                toast.info('No adjustments found for the selected warehouse');
            } else {
                setData(responseData.data || []); // Handle the case where data might be undefined
            }
    
            // Adjust the current page if necessary
            const totalPages = Math.ceil((responseData.data?.length || 0) / itemsPerPage);
            if (currentPage > totalPages) {
                setCurrentPage(totalPages);
            }
    
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    
    
    const fetchdataCategories = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }
            const response = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch warehouses');
            }
            const responseData = await response.json();
            setdataCategories(responseData.data);

        } catch (error) {
            console.error('Error fetching warehouses:', error);
            toast.error('Failed to fetch warehouses');
            setdataCategories([]); // Set empty array or handle error state as per your app logic
        }
    };


    const handleEye = async (expense) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }
            const response = await fetch(`https://pos.farm2bag.com/api/v1/adjustments/getByAllId/${expense.id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch adjustment details');
            }
            const responseData = await response.json();
            setPopupData(responseData.data);
            setIsPopupVisible(true);
        } catch (error) {
            console.error('Error fetching adjustment details:', error);
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data
        .filter(item =>
            (item.dataCategory && item.dataCategory.dataCategoryName && item.dataCategory.dataCategoryName.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.warehouse && item.warehouse.name && item.warehouse.name.toLowerCase().includes(searchQuery.toLowerCase()))
        )
        .slice(indexOfFirstItem, indexOfLastItem);

    const handleEdit = (expense) => {
        setSelecteddata(expense);
        setEditPopupOpen(true);
    };

    const handleDelete = (expense) => {
        setSelecteddata(expense);
        setDeletePopupOpen(true);
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleItemsPerPageChange = (e) => {
        setCurrentPage(1);
        setItemsPerPage(parseInt(e.target.value));
    };

    const handleSearch = (e) => {
        const query = e.target.value.trim(); // Trim whitespace

        // Update searchQuery state with the input value
        setSearchQuery(query);

        // Filter data based on the search query (case-insensitive)
        const filteredItems = data.filter(item =>
            (item.warehouse?.name && item.warehouse?.name.toLowerCase().includes(query.toLowerCase())) ||
            (item.product && item.product.length.toString().includes(query))
        );

        // Sort filtered items so that exact matches or matches where query appears earlier come first
        const sortedItems = filteredItems.sort((a, b) => {
            const nameA = a.warehouse?.name.toLowerCase() || '';
            const nameB = b.warehouse?.name.toLowerCase() || '';

            // Get indices of the query in nameA and nameB
            const indexA = nameA.indexOf(query.toLowerCase());
            const indexB = nameB.indexOf(query.toLowerCase());

            // Prioritize exact matches or matches where query appears earlier
            if (indexA === 0 && indexB !== 0) return -1; // query is at the beginning of nameA
            if (indexB === 0 && indexA !== 0) return 1;  // query is at the beginning of nameB
            if (indexA < indexB) return -1;              // query appears earlier in nameA
            if (indexA > indexB) return 1;               // query appears earlier in nameB
            return 0;
        });

        setData(sortedItems); // Update state with sorted items
    };


    const handleSort = (key) => {
        // Check if already sorted by the same key and determine the next sort order
        const nextSortOrder = sortBy === key ? (sortBy === `${key}_desc` ? null : `${key}_desc`) : key;

        let sortedData;

        if (nextSortOrder) {
            sortedData = [...data].sort((a, b) => {
                let aValue = a[key];
                let bValue = b[key];

                // Handle nested properties like warehouse.name
                if (key.includes('.')) {
                    const keys = key.split('.');
                    aValue = keys.reduce((obj, k) => (obj && obj[k] !== undefined ? obj[k] : null), a);
                    bValue = keys.reduce((obj, k) => (obj && obj[k] !== undefined ? obj[k] : null), b);
                }

                // Convert values to lowercase if they are strings for case-insensitive sorting
                if (typeof aValue === 'string') aValue = aValue.toLowerCase();
                if (typeof bValue === 'string') bValue = bValue.toLowerCase();

                if (nextSortOrder === `${key}_desc`) {
                    // Sort descending
                    if (aValue > bValue) return -1;
                    if (aValue < bValue) return 1;
                } else {
                    // Sort ascending
                    if (aValue < bValue) return -1;
                    if (aValue > bValue) return 1;
                }
                return 0;
            });
        } else {
            // If nextSortOrder is null, reset to original order (unsorted)
            sortedData = [...data];
        }

        setData(sortedData); // Update state with sorted data
        setSortBy(nextSortOrder); // Update sortBy state to reflect current sort order
    };

    const handleClosePopup = () => {
        setIsPopupVisible(false);
        setPopupData(null); // Reset popup data when closing the popup
    };

    const handleWarehouseChange = (e) => {
        const warehouseId = e.target.value;
        setSelectedWarehouse(warehouseId);
        setWarehouseId(warehouseId); // Save the selected warehouse ID
    };

    return (
        <div className='bg-gray-100 ml-64 mt-20 p-12 h-screen'>
            <div className="container mx-auto">
                <div className="mb-10">
                    <h1 className="font-extrabold text-3xl text-left text-gray-500">Adjustment</h1>
                    <div className="mt-2 border-t border-gray-500"></div>
                </div>
                <div className="flex items-center mb-5">
                    <div className="items-center flex">
                        <form>
                            <div className="relative">
                                <input type="text" placeholder='search warehouse ' value={searchQuery} onChange={handleSearch} className="border border-gray-200 px-4 py-2 pl-10 rounded-lg focus:outline-none focus:border-blue-500" />
                                <img src={Searchicon} alt="search" className="absolute left-3 top-3 w-4 h-4" />
                            </div>
                        </form>
                        <div className="ml-4">
                            <select className="form-control p-2 rounded-lg" onChange={handleWarehouseChange} value={selectedWarehouse}>
                                <option value="">All Warehouses</option>
                                {dataCategories.map(warehouse => (
                                    <option key={warehouse.id} value={warehouse.id}>{warehouse.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="flex ml-auto mr-0 items-center space-x-4">
                        <img src={filter} alt="filter" className="w-12 h-10 rounded-lg cursor-pointer" onClick={() => handleSort('warehouse.name')} />
                        <button onClick={() => setCreatePopupOpen(true)} style={{ backgroundColor: 'rgba(66, 255, 97, 1)' }} className="w-48 h-10 font-medium text-sm rounded-lg">Add Adjustment</button>
                    </div>
                </div>
                <div className="rounded-lg overflow-hidden">
                    <table className="table-auto w-full border-collapse border-gray-500 text-center">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="px-4 py-2 text-left pl-16">S.No</th>
                                <th className="px-4 py-2 text-left">Warehouse Name</th>
                                <th className="px-4 py-2 text-left">Product Count</th>
                                <th className="px-4 py-2 text-left">Date</th>
                                <th className="px-4 py-2">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {data.length === 0 ? (
                                <tr>
                                    <td className="border px-4 py-2 text-center" colSpan={5}>
                                        Wait loading...
                                    </td>
                                </tr>
                            ) : currentItems.length === 0 ? (
                                <tr>
                                    <td className="border px-4 py-2 text-center" colSpan={5}>
                                        No data in this warehouse
                                    </td>
                                </tr>
                            ) : (
                                currentItems.map((expense, index) => (
                                    <tr key={expense.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                                        <td className="border px-4 py-2">{indexOfFirstItem + index + 1}</td>
                                        <td className="border px-4 py-2 text-left">{expense.warehouse?.name || 'N/A'}</td>
                                        <td className="border px-4 py-2 text-left">{expense.products ? expense.products.length : 'N/A'}</td>
                                        <td className="border px-4 py-2 text-left">{expense.date}</td>
                                        <td className="border px-4 py-2 flex justify-center">
                                            <button className="flex items-center p-2 text-white rounded focus:outline-none" onClick={() => handleEye(expense)}>
                                                <img src={Eye} className="w-6 h-6 mr-2" alt="Eye Icon" />
                                            </button>
                                            <button className="mr-2 text-blue-500" onClick={() => handleEdit(expense)}>
                                                <FaRegEdit size={22} />
                                            </button>
                                            <button className="text-red-500" onClick={() => handleDelete(expense)}>
                                                <IoMdTrash size={30} />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>

                <div className="mt-4 flex justify-between">
                    <div>
                        <label htmlFor="itemsPerPage" className="mr-2">Items per page:</label>
                        <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange} className="border border-gray-300 rounded-lg px-2 py-1">
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                        </select>
                    </div>
                    <div className="flex space-x-2">
                        {Array.from({ length: Math.ceil(data.length / itemsPerPage) }, (_, index) => (
                            <button
                                key={index}
                                onClick={() => paginate(index + 1)}
                                className={`px-3 py-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </div>

                {isPopupVisible && popupData && (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-70 z-50">
                        <div className="absolute inset-0 opacity-50" onClick={handleClosePopup}></div>
                        <div className="relative rounded-lg p-6 w-11/12 md:w-2/3 lg:w-1/2">
                            <h2 className="text-xl font-bold mb-4 py-2 bg-white">Adjustment Details</h2>
                            <div className="mb-4">
                                <table className="w-full border-collapse">
                                    <thead>
                                        <tr className="bg-blue-600 text-white">
                                            <th className="border-b p-2 text-center">DATE</th>
                                            <th className="border-b p-2 text-center">WAREHOUSE</th>
                                            <th className="border-b p-2 text-center">PHONE</th>
                                            <th className="border-b p-2 text-center">CITY</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-white ">
                                            <td className="border-b p-2 text-center">{popupData.date}</td>
                                            <td className="border-b p-2 text-center">{popupData.warehouse?.name || 'N/A'}</td>
                                            <td className="border-b p-2 text-center">{popupData.warehouse?.phone || 'N/A'}</td>
                                            <td className="border-b p-2 text-center">{popupData.warehouse?.city || 'N/A'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div>
                                    <table className="w-full border-collapse mt-4">
                                        <thead>
                                            <tr className="bg-green-600 text-white">
                                                <th className="border-b p-2 text-left">PRODUCT</th>
                                                <th className="border-b p-2 text-left">CODE PRODUCT</th>
                                                <th className="border-b p-2 text-left">QUANTITY</th>
                                                <th className="border-b p-2 text-left">METHOD TYPE</th>
                                                <th className="border-b p-2 text-left">DESCRIPTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {popupData.product?.map((product, index) => (
                                                <tr key={index} className={`hover:bg-yellow-100 ${index % 2 === 0 ? "bg-white" : "bg-gray-200"}`}>
                                                    <td className="border-b p-2 text-left pl-5">{product.productDetails?.[0]?.name || 'N/A'}</td>
                                                    <td className="border-b p-2 text-left pl-5">{product.productsCode || 'N/A'}</td>
                                                    <td className="border-b p-2 text-left pl-5">{product.quantity}</td>
                                                    <td className="border-b p-2 text-left pl-5" style={{ color: product.methodType === 1 ? 'green' : 'red' }}>
                                                        {product.methodType === 1 ? 'Addition' : 'Subtraction'}
                                                    </td>
                                                    <td className="border-b p-2 text-left pl-5">{product.productDetails?.[0]?.description || 'N/A'}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <button
                                className="mt-4 px-4 py-2 bg-red-500 text-white rounded transform transition-transform duration-200 hover:scale-125 hover:bg-red-600" onClick={handleClosePopup}>
                                Close
                            </button>

                        </div>
                    </div>
                )}

            </div>
            
            <AdjustmentCreate isOpen={isCreatePopupOpen} onClose={() => setCreatePopupOpen(false)} fetchData={fetchData} dataCategories={dataCategories} products={products} warehouseId={warehouseId} />
            <AdjustmentEdit isOpen={isEditPopupOpen} onClose={() => setEditPopupOpen(false)} selecteddata={selecteddata} fetchData={fetchData} dataCategories={dataCategories} products={products} warehouseId={warehouseId} />
            <AdjustmentDelete isOpen={isDeletePopupOpen} onClose={() => setDeletePopupOpen(false)} selecteddata={selecteddata} fetchData={fetchData} warehouseId={warehouseId} />

        </div>
    );
};

export default Adjustment;
