import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import SearchIcon from "../Assets/tableicon/Searchicon.png";
import { IoMdTrash } from "react-icons/io";

const AdjustmentCreate = ({ isOpen, onClose, fetchData, dataCategories, warehouseId }) => {
    const [formData, setFormData] = useState({
        date: '',
        warehouseId: '',
        product: [],
    });

    const [productSearchQuery, setProductSearchQuery] = useState('');
    const [productSuggestions, setProductSuggestions] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [warehouseStock, setWarehouseStock] = useState([]);

    useEffect(() => {
        if (formData.warehouseId) {
            fetchWarehouseStock(formData.warehouseId);
        }
    }, [formData.warehouseId]);

    const fetchWarehouseStock = async (warehouseId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://pos.farm2bag.com/api/v1/stocks/warehouse/${warehouseId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch warehouse stock');
            }
            const data = await response.json();
            setWarehouseStock(data.data.products); // Store products for search functionality
        } catch (error) {
            console.error('Error fetching warehouse stock:', error);
            toast.error('Error fetching warehouse stock. Please try again.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSearchInputChange = (e) => {
        const query = e.target.value;
        setProductSearchQuery(query);

        if (query === '') {
            setProductSuggestions([]);
        } else {
            const suggestions = warehouseStock.filter(product =>
                product.name.toLowerCase().includes(query.toLowerCase())
            );
            setProductSuggestions(suggestions);
        }
    };

    const clearInput = () => {
        setProductSuggestions([])
        setProductSearchQuery('');
    }



    const handleProductSelect = (product) => {
        // Check if the product is already selected
        const isProductAlreadySelected = selectedProducts.some(selectedProduct => selectedProduct.productId === product.productId);

        // If the product is already selected, do not add it again
        if (isProductAlreadySelected) {
            toast.warning('Product is already selected.');
            return;
        }

        // Otherwise, add the product to the selected products
        setSelectedProducts(prevState => [
            ...prevState,
            { ...product, quantity: 1, methodType: 1 } // Add default values for quantity and methodType
        ]);

        // Update the formData with the selected product
        setFormData(prevState => ({
            ...prevState,
            product: [...prevState.product, product.productId]
        }));

        // Clear the search query and suggestions
        setProductSearchQuery('');
        setProductSuggestions([]);
    };

    const handleProductDelete = (productId) => {
        setSelectedProducts(prevState => prevState.filter(product => product.productId !== productId));
        setFormData(prevState => ({
            ...prevState,
            product: prevState.product.filter(id => id !== productId)
        }));
    };

    const handleQuantityIncrement = (productId, e) => {
        e.preventDefault(); // Prevent default form submission behavior
        setSelectedProducts(prevState =>
            prevState.map(product =>
                product.productId === productId ? { ...product, quantity: product.quantity + 1 } : product
            )
        );
    };

    const handleQuantityDecrement = (productId, e) => {
        e.preventDefault(); // Prevent default form submission behavior
        setSelectedProducts(prevState =>
            prevState.map(product =>
                product.productId === productId && product.quantity > 1 ? { ...product, quantity: product.quantity - 1 } : product
            )
        );
    };

    const handleMethodTypeChange = (productId, value) => {
        setSelectedProducts(prevState =>
            prevState.map(product =>
                product.productId === productId ? { ...product, methodType: value } : product
            )
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Validate form data
            if (!formData.date || !formData.warehouseId) {
                toast.error('Please fill in all required fields.');
                return;
            }

            if (selectedProducts.length === 0) {
                toast.error('Please select at least one product.');
                return;
            }

            const token = localStorage.getItem('token');


            // Prepare payload for API
            const payload = {
                date: formData.date,
                warehouseId: formData.warehouseId,
                product: selectedProducts.map(product => ({
                    productId: product.productId,
                    productsCode: product.productsCode,
                    quantity: product.quantity,
                    methodType: product.methodType || 1, // Default to 1 if methodType is not set
                    unitId: product.unitId
                }))
            };

            // Perform API call (replace with your API endpoint)
            const response = await fetch('https://pos.farm2bag.com/api/v1/adjustments', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Include the authorization token in the headers
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                throw new Error('Failed to create adjustment');
            }

            // Reset form and selected products on successful submission
            setFormData({
                date: '',
                warehouseId: '',
                product: [],
            });
            setSelectedProducts([]);

            toast.success('Adjustment created successfully', {
                autoClose: 1000 // Close the toast after 1 second (1000 milliseconds)
            });

            fetchData(warehouseId);

            setTimeout(() => {
                onClose();
            }, 2000);

        } catch (error) {
            console.error('Error creating adjustment:', error);
            toast.error('Error creating adjustment. Please try again.');
        }
    };

    return isOpen ? (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-70 z-50">
            <div className="relative rounded-lg p-6 w-9/12 bg-white">
                <h2 className="text-xl font-bold mb-4 py-2">Create Adjustment</h2>
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-wrap -mx-2">
                        <div className="w-full md:w-1/2 px-2 mb-4">
                            <label htmlFor="date" className="block text-gray-700 font-medium mb-2">Date</label>
                            <input type="date" id="date" name="date" value={formData.date} onChange={handleInputChange} className="border border-gray-300 rounded-md p-2 w-full" />
                        </div>
                        <div className="w-full md:w-1/2 px-2 mb-4">
                            <label htmlFor="warehouseId" className="block text-gray-700 font-medium mb-2">Warehouse</label>
                            <select id="warehouseId" name="warehouseId" value={formData.warehouseId} onChange={handleInputChange} className="border border-gray-300 rounded-md p-2 w-full">
                                <option value="">Select Warehouse</option>
                                {dataCategories.map(category => (
                                    <option key={category.id} value={category.id}>
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="w-full px-2 mb-4 relative">
                            <input
                                type="text"
                                placeholder="Search products"
                                value={productSearchQuery}
                                onChange={handleSearchInputChange}
                                className="border border-gray-200 px-4 py-2 pl-10 rounded-lg focus:outline-none focus:border-blue-500 w-full"
                            />
                            <img src={SearchIcon} alt="search" className="absolute left-3 top-3 w-4 h-4" />
                            {productSuggestions.length > 0 && (
                                <div className="absolute z-10 mt-1 bg-white border border-gray-200 rounded-lg shadow-lg w-full h-72 overflow-y-auto">
                                    <div className="sticky top-0 bg-red-500 p-2 border-b ">
                                        <button onClick={clearInput} className="text-gray-800 w-full">Close</button>
                                    </div>
                                    <ul className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
                                        {productSuggestions.map(product => (
                                            <li
                                                key={product.productId}
                                                className="px-4 py-2 cursor-pointer bg-yellow-400 hover:bg-green-200 border border-gray-200 rounded-md"
                                                onClick={() => handleProductSelect(product)}
                                            >
                                                {product.name} <br /><span className='font-semibold text-purple-500'> Stocks : {product.quantity.toFixed(2)}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>

                    </div>
                    {/* Selected products section */}

                    {selectedProducts.length > 0 && (
                        <div className="mt-4 overflow-y-auto max-h-[400px]">
                            <h3 className="text-lg font-semibold mb-2">Selected Products</h3>
                            <div className="overflow-y-auto max-h-[300px]"> {/* Added a wrapper with overflow-y: auto */}
                                <table className="w-full border-collapse border-gray-500 text-left">
                                    <thead>
                                        <tr className="bg-gray-200">
                                            <th className="px-4 py-2">Product Code</th>
                                            <th className="px-4 py-2">Name</th>
                                            <th className="px-4 py-2">Unit</th>
                                            <th className="px-4 py-2">Quantity</th>
                                            <th className="px-4 py-2">Method Type</th>
                                            <th className="px-4 py-2">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedProducts.map(product => (
                                            <tr key={product.productId} className="bg-white hover:bg-gray-100">
                                                <td className="border px-4 py-2">{product.productsCode}</td>
                                                <td className="border px-4 py-2">{product.name}</td>
                                                <td className="border px-4 py-2">{product.unitSymbol}</td>
                                                <td className="border px-4 py-2">
                                                    <button onClick={(e) => handleQuantityDecrement(product.productId, e)} className="px-2 bg-red-400 text-white">-</button>
                                                    <span className="px-2">{product.quantity}</span>
                                                    <button onClick={(e) => handleQuantityIncrement(product.productId, e)} className="px-2 bg-green-400 text-white">+</button>
                                                </td>
                                                <td className="border px-4 py-2">
                                                    <label className="flex items-center">
                                                        <input type="radio" name={`methodType-${product.productId}`} value="-1" checked={product.methodType === -1} onChange={() => handleMethodTypeChange(product.productId, -1)} className="form-radio h-4 w-4 text-blue-600" />
                                                        <span className="ml-2">Reduction</span>
                                                    </label>
                                                    <label className="flex items-center ml-4">
                                                        <input type="radio" name={`methodType-${product.productId}`} value="1" checked={product.methodType === 1} onChange={() => handleMethodTypeChange(product.productId, 1)} className="form-radio h-4 w-4 text-blue-600" />
                                                        <span className="ml-2">Addition</span>
                                                    </label>
                                                </td>
                                                <td className="border px-4 py-2">
                                                    <button type="button" onClick={() => handleProductDelete(product.productId)} className="text-red-500 hover:text-red-700">
                                                        <IoMdTrash />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}


                    {/* Form actions */}
                    <div className="mt-4">
                        <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-lg mr-2">Save</button>
                        <button type="button" onClick={() => { onClose(); setSelectedProducts([]); setFormData({})}} className="px-4 py-2 bg-red-500 text-white rounded-lg">Cancel</button>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </div>
    ) : null;
};

export default AdjustmentCreate;