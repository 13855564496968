import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';

const OrderSummaryOverview = () => {
  const chartRef = useRef(null);
  const [selectedMonthRange, setSelectedMonthRange] = useState(['Jan', 'Dec']);
  const [selectedYear, setSelectedYear] = useState('2024');

  const divStyle = {
    boxShadow: "none",
  };

  useEffect(() => {
    let myChart = chartRef.current;

    if (myChart) {
      myChart.destroy();
    }

    const ctx = document.getElementById('myBarChart').getContext('2d');
    myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ["Jan", "Feb", "Mar", "Apr", "May"],
        datasets: [{
          data: [55, 49, 44, 24, 45],
          backgroundColor: [
            'rgba(255, 99, 132, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(255, 205, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(54, 162, 235, 0.6)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(255, 205, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(54, 162, 235, 1)',
          ],
          borderWidth: 1,
          barPercentage: 0.5,
          categoryPercentage: 0.8,
        }],
      },
      options: {
        maintainAspectRatio: false, // Change to false to allow custom dimensions
        scales: {
          y: {
            type: 'linear',
            beginAtZero: true,
            stepSize: 10,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          }
        }
      },
    });

    chartRef.current = myChart;

    return () => {
      if (myChart) {
        myChart.destroy();
      }
    };
  }, [selectedMonthRange, selectedYear]);

  const handleMonthChange = (index, value) => {
    const newMonthRange = [...selectedMonthRange];
    newMonthRange[index] = value;
    setSelectedMonthRange(newMonthRange);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  return (
    <div className="bg-white p-4 pt-3 rounded-lg shadow-md" style={divStyle}>
      <div className="flex justify-between">
        <h1 className="text-xl font-semibold">Order Summary</h1>
        <div className="pb-5">
          <label>
            <select value={selectedMonthRange[0]} onChange={(e) => handleMonthChange(0, e.target.value)}>
              <option value="Jan">Jan</option>
              <option value="Feb">Feb</option>
              <option value="Mar">Mar</option>
              <option value="Apr">Apr</option>
              <option value="May">May</option>
              <option value="Jun">Jun</option>
              <option value="Jul">Jul</option>
              <option value="Aug">Aug</option>
              <option value="Sep">Sep</option>
              <option value="Oct">Oct</option>
              <option value="Nov">Nov</option>
              <option value="Dec">Dec</option>
            </select>
          </label>
          <span> - </span>
          <label>
            <select value={selectedMonthRange[1]} onChange={(e) => handleMonthChange(1, e.target.value)}>
              <option value="Jan">Jan</option>
              <option value="Feb">Feb</option>
              <option value="Mar">Mar</option>
              <option value="Apr">Apr</option>
              <option value="May">May</option>
              <option value="Jun">Jun</option>
              <option value="Jul">Jul</option>
              <option value="Aug">Aug</option>
              <option value="Sep">Sep</option>
              <option value="Oct">Oct</option>
              <option value="Nov">Nov</option>
              <option value="Dec">Dec</option>
            </select>
          </label>
          <span></span>
          <select value={selectedYear} onChange={handleYearChange}>
            <option value="2020">2020</option>
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
          </select>
        </div>
      </div>
      <div className="w-full h-80" >
        <canvas id="myBarChart"></canvas>
      </div>
    </div>
  );
};

export default OrderSummaryOverview;