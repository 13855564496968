

import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Utility functions for date parsing and formatting
const parseDate = (dateString) => {
  if (!dateString) return null;
  const [day, month, year] = dateString.split('/');
  return new Date(`${year}-${month}-${day}`);
};

const formatDate = (date) => {
  if (!date) return '';
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const StockEdit = ({ isVisible, onClose, product, warehouseId, onProductUpdated }) => {
  const [newPrice, setNewPrice] = useState(product?.price || "");
  const [newQuantity, setNewQuantity] = useState(product?.quantity || "");
  const [priceError, setPriceError] = useState("");
  const [quantityError, setQuantityError] = useState("");
  const [expDate, setExpDate] = useState(parseDate(product?.exp_Date));
  const [mafDate, setMafDate] = useState(parseDate(product?.maf_Date));
  const [unitOptions, setUnitOptions] = useState([]);
  const [selectedUnitId, setSelectedUnitId] = useState(product?.unitId || "");

  // Fetch unit options when the component mounts
  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("Authentication token is missing");
          return;
        }

        const response = await fetch('https://pos.farm2bag.com/api/v1/measurements/master', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          if (data.status === 200) {
            setUnitOptions(data.data);
          } else {
            console.error("Failed to fetch units");
          }
        } else {
          console.error("Error fetching units");
        }
      } catch (error) {
        console.error("Error fetching units:", error);
      }
    };

    fetchUnits();
  }, []);

  // Reset fields if product changes
  useEffect(() => {
    if (product) {
      setNewPrice(product.price || "");
      setNewQuantity(product.quantity || "");
      setExpDate(parseDate(product.exp_Date));
      setMafDate(parseDate(product.maf_Date));
      setSelectedUnitId(product.unitId || "");
    }
  }, [product]);

  // Update product details
  const handleUpdateProduct = async () => {
    let isValid = true;

    if (newPrice === "" || newPrice < 0) {
      setPriceError(newPrice < 0 ? "Negative value is not allowed" : "Price is required");
      isValid = false;
    } else {
      setPriceError("");
    }

    if (newQuantity === "" || newQuantity < 0) {
      setQuantityError(newQuantity < 0 ? "Negative value is not allowed" : "Quantity is required");
      isValid = false;
    } else {
      setQuantityError("");
    }

    if (!isValid) return;

    try {
      const token = localStorage.getItem("token");
      const role = localStorage.getItem('role');

      if (!token) {
        setPriceError("Authentication token is missing");
        return;
      }

      const url = new URL(`https://pos.farm2bag.com/api/v1/stocks/${warehouseId}/${product.productId}`);

      // Add parameters to the URL
      const params = new URLSearchParams({
        price: newPrice.toString(),
        quantity: newQuantity.toString(),
        exp_Date: expDate ? formatDate(expDate) : "",
        maf_Date: mafDate ? formatDate(mafDate) : "",
        unitId: selectedUnitId,
        modifiedBy: role
      });

      url.search = params.toString();

      const response = await fetch(url.toString(), {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        onClose();
        onProductUpdated();
      } else {
        const data = await response.json();
        setPriceError(data.message || "Failed to update product");
      }
    } catch (error) {
      console.error('Error updating product:', error);
      setPriceError("An unexpected error occurred");
    }
  };


  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold mb-4">Update Product</h2>
        <div className="grid grid-cols-2 gap-6">

          <div className="mb-4">
            <label className="block text-gray-700">New Price</label>
            <input
              type="number"
              value={newPrice}
              onChange={(e) => {
                const value = e.target.value;
                if (value >= 0) {
                  setNewPrice(value);
                  setPriceError("");
                } else {
                  setPriceError("Negative value is not allowed");
                }
              }}
              min="0"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
              placeholder="Enter new price"
            />
            {priceError && <div className="text-red-500">{priceError}</div>}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Quantity</label>
            <input
              type="number"
              value={newQuantity}
              onChange={(e) => {
                const value = e.target.value;
                if (value >= 0) {
                  setNewQuantity(value);
                  setQuantityError("");
                } else {
                  setQuantityError("Negative value is not allowed");
                }
              }}
              min="1"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
              placeholder="Enter quantity"
            />
            {quantityError && <div className="text-red-500">{quantityError}</div>}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Manufacturing Date</label>
            <DatePicker
              selected={mafDate}
              onChange={setMafDate}
              dateFormat="dd/MM/yyyy"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Expiration Date</label>
            <DatePicker
              selected={expDate}
              onChange={setExpDate}
              dateFormat="dd/MM/yyyy"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="mb-4 col-span-2">
            <label className="block text-gray-700">Unit</label>
            <select
              value={selectedUnitId}
              onChange={(e) => setSelectedUnitId(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
            >
              <option value="" disabled>Select unit</option>
              {unitOptions.map((unit) => (
                <option key={unit.id} value={unit.id}>{unit.unitSymbol}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex justify-between mt-6">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-500 text-white rounded-lg"
          >
            Cancel
          </button>
          <button
            onClick={handleUpdateProduct}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg"
          >
            Update Product
          </button>
        </div>
      </div>

    </div>
  );
};

export default StockEdit;
