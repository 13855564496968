import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { IoMdTrash } from 'react-icons/io';
import { Oval } from 'react-loader-spinner';

const TransferEditPopup = ({ transferId, onClose }) => {
    const [stockProducts, setStockProducts] = useState([]);
    const [selectedProductsList, setSelectedProductsList] = useState([]);
    const [updatedTransferItems, setUpdatedTransferItems] = useState([]);
    const [transferDate, setTransferDate] = useState('');
    const [transportCharge, setTransportCharge] = useState(0);
    const [packingCharge, setPackingCharge] = useState(0);
    const [paymentMode, setPaymentMode] = useState('cash');
    const [status, setStatus] = useState('pending');
    const [newItems, setNewItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [transfer, setTransfer] = useState({}); // Store transfer data here
    const [fromWarehouse, setFromWarehouse] = useState('');
    const [toWarehouse, setToWarehouse] = useState('');
    const [totalAmount, setTotalAmount] = useState('');
    const [loading, setLoading] = useState(true);

    // Get the current date in yyyy-mm-dd format
    const today = new Date().toISOString().substring(0, 10);



    // Step 1: Fetch Transfers
    const fetchTransfers = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://pos.farm2bag.com/api/v1/transfers/${transferId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch transfer');
            }

            const data = await response.json();
            const transferData = data.data;

            // Set the state values based on the response
            setFromWarehouse(transferData.fromWarehouseName);
            setToWarehouse(transferData.toWarehouseName);
            setTransferDate(transferData.transferDate.split('/').reverse().join('-')); // Format the date to YYYY-MM-DD
            setTransportCharge(transferData.transportCharge);
            setPackingCharge(transferData.packingCharge);
            setPaymentMode(transferData.paymentMode.toLowerCase());
            setStatus(transferData.status.toLowerCase());
            setTotalAmount(transferData.totalAmount);

            // Extract product details and set the selected products list
            const products = transferData.transferItems.flatMap(item =>
                item.products.map(product => ({
                    productId: product.productId,
                    name: product.productName,
                    quantity: product.quantity,
                    unit: product.unit,
                    price: product.price
                }))
            );
            setSelectedProductsList(products);

            // Set full transfer data if needed for later use
            setTransfer(transferData);

            return transferData; // Return the data to use in the next step
        } catch (error) {
            console.error('Error fetching transfer:', error.message);
            toast.error('Error fetching transfer');
        }
        finally {
            setLoading(false); // Hide loader after data is fetched
        }
    };
    useEffect(() => {
        fetchTransfers();
    }, []);

    // Step 2: Fetch Stock Products
    const fetchStockProducts = async (fromWarehouseId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`https://pos.farm2bag.com/api/v1/stocks/warehouse/${fromWarehouseId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                setStockProducts(response.data.data.products);
            } else {
                toast.error('Failed to fetch stock products');
            }
        } catch (error) {
            console.error('Error fetching stock products:', error.message);
            toast.error('Error fetching stock products');
        }
    };

    // Combine fetch logic in useEffect
    useEffect(() => {
        const initialize = async () => {
            const transferData = await fetchTransfers(); // Fetch transfer first
            if (transferData) {
                fetchStockProducts(transferData.fromWarehouseId); // Fetch stock products based on fromWarehouseId
            }
        };

        initialize();
    }, [transferId]);

    const getProductStockQuantity = (productId) => {
        const product = stockProducts.find(p => p.productId === productId);
        return product ? product.quantity : 0;
    };

    const handleProductToggle = (productId) => {
        const selectedProduct = stockProducts.find(product => product.productId === productId);
        if (selectedProduct) {
            const isSelected = selectedProductsList.some(prod => prod.productId === productId);
            if (isSelected) {
                handleProductDeselect(productId);
            } else {
                handleProductSelect(selectedProduct);
            }
        }
    };

    const handleProductSelect = (selectedProduct) => {
        setSelectedProductsList(prevSelected => [
            ...prevSelected,
            { ...selectedProduct, quantity: 1, unit: selectedProduct.unitSymbol, price: selectedProduct.price }
        ]);
        setNewItems(prevItems => [
            ...prevItems,
            { productId: selectedProduct.productId, quantity: 1, unit: selectedProduct.unitSymbol, price: selectedProduct.price }
        ]);
    };

    const handleProductDeselect = (productId) => {
        setSelectedProductsList(prevSelected =>
            prevSelected.filter(product => product.productId !== productId)
        );

        setUpdatedTransferItems(prevItems =>
            prevItems.map(item => ({
                ...item,
                products: item.products.filter(product => product.productId !== productId)
            }))
        );
        setNewItems(prevItems =>
            prevItems.filter(item => item.productId !== productId)
        );
    };

    const handleIncrement = (productId) => {
        setSelectedProductsList(prevSelected =>
            prevSelected.map(product => {
                if (product.productId === productId) {
                    const availableStock = getProductStockQuantity(productId);
                    if (product.quantity < availableStock) {
                        return { ...product, quantity: product.quantity + 1 };
                    } else {
                        toast.warn('Quantity exceeds available stock');
                        return product;
                    }
                }
                return product;
            })
        );
    };

    const handleDecrement = (productId) => {
        setSelectedProductsList(prevSelected =>
            prevSelected.map(product =>
                product.productId === productId
                    ? { ...product, quantity: Math.max(product.quantity - 1, 1) }
                    : product
            )
        );
    };


    const handleTransferDateChange = (event) => {
        setTransferDate(event.target.value);
    };

    const handleTransportChargeChange = (event) => {
        setTransportCharge(Number(event.target.value));
    };

    const handlePackingChargeChange = (event) => {
        setPackingCharge(Number(event.target.value));
    };

    const handlePaymentModeChange = (event) => {
        setPaymentMode(event.target.value);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    // Create a map of existing products for quick lookup and update
    const existingProductMap = new Map();
    updatedTransferItems.forEach(item => {
        item.products.forEach(product => {
            existingProductMap.set(product.productId, {
                ...product,
                quantity: product.quantity // keep existing quantity for update
            });
        });
    });

    // Update existing products and add new products from selectedProductsList
    selectedProductsList.forEach(product => {
        if (existingProductMap.has(product.productId)) {
            // Update the existing product's quantity
            const existingProduct = existingProductMap.get(product.productId);
            existingProductMap.set(product.productId, {
                ...existingProduct,
                quantity: existingProduct.quantity + product.quantity // update quantity
            });
        } else {
            // Add new product
            existingProductMap.set(product.productId, {
                productId: product.productId,
                productName: product.name,
                quantity: product.quantity,
                unit: product.unit,
                price: product.price
            });
        }
    });

    // Convert the map to the array structure required
    const mergedItems = Array.from(existingProductMap.values()).map(product => ({
        products: [product]
    }));

    // Function to calculate total product amount
    const calculateTotalProductAmount = () => {
        return mergedItems.reduce((total, item) => {
            return total + item.products.reduce((itemTotal, product) => {
                return itemTotal + (product.quantity * product.price);
            }, 0);
        }, 0);
    };

    // Calculate total product amount
    const totalProductAmount = calculateTotalProductAmount();

    // Calculate total amount with charges
    const totalAmountWithCharges = totalProductAmount + transportCharge + packingCharge;

    // Log results for debugging
    console.log('Merged Items:', mergedItems);
    console.log('Total Product Amount:', totalProductAmount);
    console.log('Total Amount With Charges:', totalAmountWithCharges);


    const handleSave = async () => {
        try {
            const token = localStorage.getItem('token');

            // Create a map to track products by their ID
            const productMap = new Map();

            // Add existing products to the map
            updatedTransferItems.forEach(item => {
                item.products.forEach(product => {
                    if (productMap.has(product.productId)) {
                        // Update the existing product quantity
                        const existingProduct = productMap.get(product.productId);
                        productMap.set(product.productId, {
                            ...existingProduct,
                            quantity: existingProduct.quantity + product.quantity // Add new quantity
                        });
                    } else {
                        // Add new product
                        productMap.set(product.productId, {
                            productId: product.productId,
                            productName: product.productName,
                            quantity: product.quantity,
                            unit: product.unit,
                            price: product.price
                        });
                    }
                });
            });

            // Update the map with new items or add them if not present
            selectedProductsList.forEach(product => {
                if (productMap.has(product.productId)) {
                    // Update the existing product quantity
                    const existingProduct = productMap.get(product.productId);
                    productMap.set(product.productId, {
                        ...existingProduct,
                        quantity: existingProduct.quantity + product.quantity // Add new quantity
                    });
                } else {
                    // Add new product
                    productMap.set(product.productId, {
                        productId: product.productId,
                        productName: product.name,
                        quantity: product.quantity,
                        unit: product.unit,
                        price: product.price
                    });
                }
            });

            // Convert the map to the array format for the API payload
            const transferItems = [
                {
                    products: Array.from(productMap.values()), // Get all products as an array
                    modifiedBy: '[ADMIN]', // Replace with actual user or admin ID
                }
            ];

            // Format the date to dd/mm/yyyy
            const formatDate = (dateStr) => {
                const [year, month, day] = dateStr.split('-');
                return `${day}/${month}/${year}`;
            };
            const formattedTransferDate = formatDate(transferDate);

            // Construct the payload
            const payload = {
                fromWarehouseId: transfer.fromWarehouseId,
                toWarehouseId: transfer.toWarehouseId,
                transferDate: formattedTransferDate,
                status: status,
                totalAmount: totalAmountWithCharges,
                paymentMode: paymentMode,
                transportCharge: transportCharge,
                packingCharge: packingCharge,
                transferItems: transferItems,
                createdAt: new Date().toISOString(), // Current date-time for creation
                modifiedAt: new Date().toISOString(), // Current date-time for modification
                createdBy: '[ADMIN]' // Replace with actual user or admin ID
            };

            // Send the PUT request to update the transfer
            const response = await fetch(`https://pos.farm2bag.com/api/v1/transfers/updateWithItems/${transfer.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });

            // Debugging: Check response status and body
            console.log('Response status:', response.status);
            const responseBody = await response.json();
            console.log('Response body:', responseBody);

            if (!response.ok) {
                throw new Error(responseBody.message || 'Error updating transfer');
            }

            // Display success toast notification
            toast.success('Transfer updated successfully', {
                position: "top-right",
                autoClose: 2000, // Automatically close after 2 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                style: {
                    fontSize: '16px',
                    padding: '10px 20px',
                    borderRadius: '4px',
                    marginTop: '100px' // Adjusting the margin to offset the toast
                }
            });

            // Close the popup after the toast disappears
            setTimeout(() => {
                onClose();
            }, 2000);
        } catch (error) {
            console.error('Error saving transfer:', error.message);
            toast.error(`Error saving transfer: ${error.message}`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2000,
                style: {
                    fontSize: '16px',
                    padding: '10px 20px',
                    borderRadius: '4px',
                    marginTop: '100px'
                }
            });
        }
    };



    console.log("selectedProductsList", selectedProductsList);

    return (


        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center mt-12">
            {loading ? (
                <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
                    <Oval
                        height={80}
                        width={80}
                        color="#3498db"
                        wrapperStyle={{}}
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#f3f3f3"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            ) : (
                <div className="bg-gray-200 w-4/5 h-5/6 ml-64 overflow-y-auto rounded-lg relative px-12">
                    <h2 className="text-2xl font-semibold mb-6 mt-6">Edit Transfer</h2>
                    <div className="grid grid-cols-2 gap-4">
                        <div className='bg-yellow-200 rounded-2xl'>
                            <label className="block text-lg font-medium text-gray-700">From Warehouse </label>
                            <p className="mt-1 text-lg font-semibold">{fromWarehouse}</p>
                        </div>
                        <div className='bg-yellow-200 rounded-2xl'>
                            <label className="block text-lg font-medium text-gray-700">To Warehouse </label>
                            <p className="mt-1 text-lg font-semibold">{toWarehouse}</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 mt-10">
                        <div>
                            <label className="block text-lg font-medium text-gray-700">Total Amount</label>
                            <input
                                type="number"
                                className="mt-1 block w-96 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                value={totalAmountWithCharges}
                                readOnly
                            />
                        </div>
                        <div>
                            <label className="block text-lg font-medium text-gray-700">Transport Charge</label>
                            <input
                                type="number"
                                className="mt-1 block w-96 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                value={transportCharge}
                                min={0}
                                onChange={handleTransportChargeChange}
                            />
                        </div>
                        <div>
                            <label className="block text-lg font-medium text-gray-700">Packing Charge</label>
                            <input
                                type="number"
                                className="mt-1 block w-96 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                value={packingCharge}
                                min={0}
                                onChange={handlePackingChargeChange}
                            />
                        </div>
                        <div>
                            <label className="block text-lg font-medium text-gray-700">Payment Mode</label>
                            <select
                                value={paymentMode}
                                onChange={handlePaymentModeChange}
                                className="mt-1 block w-96 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                <option value="cash">Cash</option>
                                <option value="card">Card</option>
                                <option value="digital">Digital</option>
                            </select>
                        </div>
                        <div>
                            <label className="block text-lg font-medium text-gray-700">Status</label>
                            <select
                                value={status}
                                onChange={handleStatusChange}
                                className="mt-1 block w-96 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                <option value="paid">Paid</option>
                                <option value="pending">Pending</option>
                            </select>
                        </div>
                        <div>
                            <label className="block text-lg font-medium text-gray-700">Transfer Date</label>
                            <input
                                type="date"
                                className="mt-1 block w-96 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                value={transferDate}
                                min={today} // Set the minimum date to today
                                onChange={handleTransferDateChange}
                            />
                        </div>
                    </div>

                    <div className="mt-6  border rounded">
                        <div className='border rounded bg-green-300'>
                            <h3 className="text-lg font-semibold mb-4">Available Products</h3>
                            <div className="mb-4">
                                <input
                                    type="text"
                                    placeholder="Search products..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="w-96 px-3 py-2 uppercase border rounded"
                                />
                            </div>
                            <div className="grid grid-cols-3 gap-4  p-4 h-52 overflow-y-scroll">
                                {stockProducts
                                    .filter(product =>
                                        (product.name && product.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                        (product.productsCode && product.productsCode.toLowerCase().includes(searchTerm.toLowerCase()))
                                    )
                                    .map((product) => (
                                        <div key={product.productId} className="flex items-center">
                                            <input
                                                type="checkbox"
                                                checked={selectedProductsList.some(
                                                    (selected) => selected.productId === product.productId
                                                )}
                                                onChange={() => handleProductToggle(product.productId)}
                                                className="mr-2"
                                            />
                                            <label className='font-semibold'>
                                                {product.name} ({product.productsCode})
                                            </label>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <div className="mt-6 bg-white border rounded ">
                            <h3 className="text-lg font-semibold mb-4">Selected Products</h3>
                            <table className="w-full border rounded ">
                                <thead>
                                    <tr className="border-b">
                                        <th className="p-2 text-center">Product Name</th>
                                        <th className="p-2 text-left">Quantity</th>
                                        <th className="p-2 text-center">Unit</th>
                                        <th className="p-2 text-center">Price</th>
                                        <th className="p-2 text-center">Subtotal</th>
                                        <th className="p-2 text-left">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedProductsList.map((product) => {
                                        const subtotal = product.quantity * product.price;
                                        return (
                                            <tr key={product.productId} className="border-b">
                                                <td className="p-2 text-center">{product.name}</td>
                                                <td className="p-2 flex items-center">
                                                    <button
                                                        className="px-2 py-1 bg-gray-200 rounded"
                                                        onClick={() => handleDecrement(product.productId)}
                                                    >
                                                        -
                                                    </button>
                                                    <span className="px-2">{product.quantity}</span>
                                                    <button
                                                        className="px-2 py-1 bg-gray-200 rounded"
                                                        onClick={() => handleIncrement(product.productId)}
                                                    >
                                                        +
                                                    </button>
                                                </td>
                                                <td className="p-2">{product.unit}</td>
                                                <td className="p-2">₹{product.price.toFixed(2)}</td>
                                                <td className="p-2">₹{subtotal.toFixed(2)}</td>
                                                <td className="p-2">
                                                    <IoMdTrash
                                                        onClick={() => handleProductDeselect(product.productId)}
                                                        className="text-red-600 cursor-pointer w-6 h-6"
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr className="border-t font-semibold">
                                        <td colSpan="4" className="p-2 text-right">Total Amount:</td>
                                        <td className="p-2">
                                            ₹{selectedProductsList.reduce((total, product) => {
                                                return total + (product.quantity * product.price);
                                            }, 0).toFixed(2)}
                                        </td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>


                    <div className="mt-6 mb-4">
                        <h3 className="text-lg font-semibold mb-4">Total Amount: ₹{totalAmountWithCharges.toFixed(2)}</h3>
                        <button
                            onClick={handleSave}
                            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-green-700"
                        >
                            Save Changes
                        </button>
                        <button
                            className="px-4 py-2 bg-gray-400 text-white rounded hover:bg-red-700 ml-12" onClick={onClose}>Cancel</button>
                    </div>
                </div>
            )}
            <ToastContainer />
        </div>


    );
};

export default TransferEditPopup;

