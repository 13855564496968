import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateUser = ({ onCancel, onSuccess }) => {
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState(null);
  const [userName, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formErrors, setFormErrors] = useState({
    userName: '',
    email: '',
    password: '',
    role: '',
  });

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          console.error("No token found in local storage");
          setError("No token found in local storage");
          return;
        }

        const response = await fetch('https://pos.farm2bag.com/api/v1/roles', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setRoles(data.data);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        setError(error.message);
      }
    };

    fetchRoles();
  }, []);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'username') {
      setUsername(value);
    } else if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    } else if (name === 'role') {
      setSelectedRole(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    const formValid =
      userName &&
      email &&
      selectedRole &&
      password.length >= 8;

    if (!formValid) {
      setFormErrors({
        userName: userName ? '' : 'Username is required',
        email: email ? '' : 'Email is required',
        password: password ? (password.length < 8 ? 'Password must be at least 8 characters long' : '') : 'Password is required',
        role: selectedRole ? '' : 'Role selection is required',
      });
      return;
    }

    try {
      const token = localStorage.getItem("token");

      const response = await fetch('https://pos.farm2bag.com/api/v1/users', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userName,
          email,
          password,
          roleId: selectedRole
        }),
      });

      if (!response.ok) {
        if (response.status === 409) { // Assuming 409 is the status code for duplicate email
          throw new Error('A user with the same email already exists');
        }
        throw new Error('Network response was not ok');
      }

      // Clear form and show success message
      setUsername('');
      setEmail('');
      setPassword('');
      setSelectedRole('');
      setFormErrors({ userName: '', email: '', password: '', role: '' });

      onSuccess(); // Close the popup
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      if (error.message === 'A user with the same email already exists') {
        toast.error('A user with the same email already exists');
      } else {
        setError(error.message);
      }
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Create New User</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4 flex flex-col">
          <label className="block text-gray-700">User Name</label>
          <input
            type="text"
            name="username"
            value={userName}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          />
          {formErrors.userName && <p className="text-red-500 mt-1">{formErrors.userName}</p>}
        </div>
        <div className="mb-4 flex flex-col">
          <label className="block text-gray-700">Email</label>
          <input
            type="email"
            name="email"
            value={email}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          />
          {formErrors.email && <p className="text-red-500 mt-1">{formErrors.email}</p>}
        </div>
        <div className="mb-4 flex flex-col">
          <label className="block text-gray-700">Password</label>
          <div className="relative">
            <input
              type={passwordVisible ? 'text' : 'password'}
              name="password"
              value={password}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-700"
            >
              {passwordVisible ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M13.875 9.875a3.875 3.875 0 11-7.75 0 3.875 3.875 0 017.75 0z" />
                  <path fillRule="evenodd" d="M10 1.5c4.96 0 9 6 9 8.375s-4.04 8.375-9 8.375S1 13.29 1 9.875 5.04 1.5 10 1.5zm0 2c-3.52 0-7 4.155-7 6.375 0 2.22 3.48 6.375 7 6.375s7-4.155 7-6.375c0-2.22-3.48-6.375-7-6.375z" clipRule="evenodd" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M3.207 4.793a1 1 0 011.414 0L10 10.172l5.379-5.38a1 1 0 011.414 1.414l-6.793 6.793a1 1 0 01-1.414 0l-6.793-6.793a1 1 0 010-1.414z" clipRule="evenodd" />
                  <path fillRule="evenodd" d="M10 1.5c4.96 0 9 6 9 8.375s-4.04 8.375-9 8.375S1 13.29 1 9.875 5.04 1.5 10 1.5zm0 2c-3.52 0-7 4.155-7 6.375 0 2.22 3.48 6.375 7 6.375s7-4.155 7-6.375c0-2.22-3.48-6.375-7-6.375z" clipRule="evenodd" />
                </svg>
              )}
            </button>
          </div>
          {formErrors.password && <p className="text-red-500 mt-1">{formErrors.password}</p>}
        </div>
        <div className="mb-4 flex flex-col">
          <label className="block text-gray-700">Role</label>
          <select
            name="role"
            value={selectedRole}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          >
            <option value="">Select Role</option>
            {roles.map(role => (
              <option key={role.roleId} value={role.roleId}>
                {role.roleName}
              </option>
            ))}
          </select>
          {formErrors.role && <p className="text-red-500 mt-1">{formErrors.role}</p>}
        </div>
        <div className="flex justify-between">
          <button
            type="button"
            className="bg-gray-500 text-white px-4 py-2 rounded-lg"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-lg"
          >
            Create User
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default CreateUser;
