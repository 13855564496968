import React from 'react';
import Modal from 'react-modal';
import f2b_logo from "../Assets/Billingpage/invoice-logo-f2b.jpg";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import f2bLink from "../Assets/Billingpage/f2bLink.png";


const InvoiceBill = ({ isOpen, onClose, billData, editsClose }) => {
    if (!isOpen) return null;

    const { warehouse, invoice, date, createdAt, discount, shippingCharges, productsTotal, customer, orderItems, paymentMode, status, gst } = billData;

    const downloadPDF = () => {
        const invoiceElement = document.getElementById('invoice-content');
        const closeButton = document.getElementById('close-button');
        const downloadButton = document.getElementById('download-button');

        // Hide buttons before generating the PDF
        closeButton.style.display = 'none';
        downloadButton.style.display = 'none';

        html2canvas(invoiceElement, {
            scale: 8, // Increase the scale to improve the resolution
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/webp', 0.95); // Use WebP with high quality
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'px',
                format: [canvas.width * 2, canvas.height * 2],
                compression: 'EXTREME', // Optimize the PDF rendering
            });

            const width = pdf.internal.pageSize.getWidth();
            const height = pdf.internal.pageSize.getHeight();

            pdf.addImage(imgData, 'WEBP', 0, 0, width, height);
            pdf.save('invoice.pdf');

            // Show buttons again after generating the PDF
            closeButton.style.display = 'block';
            downloadButton.style.display = 'block';
        });
    };

    const printInvoice = () => {
        const invoiceElement = document.getElementById('invoice-content');
        const printWindow = window.open('', '_blank');
        printWindow.document.open();
        printWindow.document.write(`
            <html>
                <head>
                    <title>Invoice</title>
                    <style>
                        @media print {
                            body {
                                font-size: 12px;
                                margin: 0;
                            }
                            .invoice {
                                width: 100%;
                                height: auto;
                            }
                        }
                    </style>
                </head>
                <body>
                    <div class="invoice">
                        ${invoiceElement.innerHTML}
                    </div>
                </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
    };


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Invoice"
            className="flex items-center justify-center"
            overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center">
            <div id="invoice-content" className="bg-white p-3 rounded-lg shadow-lg w-96 max-h-screen overflow-y-auto">
                <div className="text-center mb-4">
                    <img src={f2b_logo} alt="Company Logo" className="mx-auto h-12" />
                    <h2 className="text-2xl font-extrabold mt-2 text-black">{warehouse}</h2>
                    <p className="text-sm text-black font-semibold"> Farm2bag, 5/396 , Rajeev Gandhi Salai, OMR Thoraipakkam , Chennai, Tamil Nadu 600097</p>
                    <p className='text-sm text-black font-semibold'>PH-095000 37221</p>
                </div>
                <h2 className="text-xl font-bold mb-4 text-center">Invoice</h2>
                <div className="mb-4">
                    <table className="min-w-full bg-white border-none">
                        <tbody>
                            <tr className="border-b">
                                <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">Invoice Number</th>
                                <td className="px-4 py-1 text-black font-semibold">{invoice || 'N/A'}</td>
                            </tr>
                            <tr className="border-b">
                                <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">Date</th>
                                <td className=" py-1 text-black font-semibold">{date}▮{new Date(createdAt).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}</td>
                            </tr>
                            <tr className="border-b">
                                <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">Customer Name</th>
                                <td className="px-4 py-1 text-black font-semibold">{customer || 'N/A'}</td>
                            </tr>
                            <tr className="border-b">
                                <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">Products Total</th>
                                <td className="px-4 py-1 text-black font-semibold">₹ {productsTotal || 'N/A'}</td>
                            </tr>
                            <tr className="border-b">
                                <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">GST(%)</th>
                                <td className="px-4 py-1 text-black font-semibold"> {gst || 'N/A'} %</td>
                            </tr>
                            <tr className="border-b">
                                <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">Discount(%)</th>
                                <td className="px-4 py-1 text-black font-semibold"> {discount || 'N/A'} %</td>
                            </tr>
                            <tr className="border-b">
                                <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">Shipping Charges</th>
                                <td className="px-4 py-1 text-black font-semibold">₹ {shippingCharges || 'N/A'}</td>
                            </tr>


                            <tr className="border-b">
                                <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">Payment Mode:</th>
                                <td className="px-4 py-1 text-black font-semibold">{paymentMode || 'N/A'} / {status || 'N/A'}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <h3 className="text-lg font-semibold mb-2 text-center">Purchase Details</h3>
                <table className="min-w-full bg-white border border-gray-200">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="px-1 py-1 text-left text-sm font-bold text-black uppercase">Product</th>
                            <th className="px-1 py-1 text-center text-sm font-bold text-black uppercase">Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderItems.map((item, index) => (
                            <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                                <td className="px-1 py-1 text-wrap text-sm font-medium text-black  overflow-hidden" style={{ maxWidth: "160px" }}>{item.name || 'N/A'} <br /> ({item.quantity || 0} {item.unit || 'N/A'} x ₹ {item.price || 0})</td>
                                <td className=" py-1 whitespace-nowrap text-sm text-black font-semibold text-center">₹ {(item.price * item.quantity).toFixed(2)}</td>
                            </tr>
                        ))}
                        <tr className="bg-gray-100">
                            <td colSpan="1" className="px-4 py-1 text-right text-black text-nowrap font-bold">Total</td>
                            <td className=" py-1 pr-2 text-black text-nowrap font-bold">
                                ₹ {orderItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2)}
                            </td>
                        </tr>

                        <tr className="border-b">
                            <td colSpan="1" className="pl-10 py-1 text-center text-black text-nowrap font-black">Grand Total : ₹ {(
                                    orderItems.reduce((total, item) => total + item.price * item.quantity, 0)
                                    * (1 - (discount / 100 || 0))
                                    * (1 + (gst / 100 || 0)) // GST calculation added here
                                    + (shippingCharges || 0)
                                ).toFixed(2)}</td>
                        </tr>

                    </tbody>
                </table>

                <div className='text-center'>
                    <p className='text-lg font-extrabold'>Thank You For<span> Shopping With Us.</span><br /> Please vist again.</p>
                    <img src={f2bLink} alt="Company Logo" className="h-20 w-20 block mt-2 mx-auto" />
                </div>

                <div className='flex justify-between'>
                    <button
                        id="close-button"
                        onClick={() => {
                            onClose();
                            editsClose();
                        }} className="mt-6 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none">
                        Close
                    </button>
                    <button
                        id="download-button"
                        onClick={downloadPDF}
                        className="mt-6 bg-green-500 text-white h-10 p-2 rounded-lg hover:bg-green-600 focus:outline-none">
                        Download PDF
                    </button>
                    <button
                        id="print-button"
                        onClick={printInvoice}
                        className="mt-6 bg-orange-500 text-white h-10 p-2 rounded-lg hover:bg-orange-600 focus:outline-none">
                        Print
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default InvoiceBill;