import React from 'react';
import Modal from 'react-modal';
import { IoMdTrash } from 'react-icons/io';
import Searchicon from "../Assets/tableicon/Searchicon.png";

const PurchaseForm = ({
    isOpen,
    onClose,
    categories,
    vendors,
    selectedCategory,
    selectedVendor,
    handleCategoryChange,
    handleVendorChange,
    searchQuery,
    setSearchQuery,
    products,
    selectedItemsByCategory,
    handleCheckboxChange,
    itemDetails,
    calculateTotalAmount,
    calculateTotalAmountWithTax,
    handleDetailChange,
    handleRemoveRow,
    measurements,
    isPopupOpen,
    setIsPopupOpen,
    newOrder,
    setNewOrder,
    handleCreateOrder,
    grandTotalAmount,
    grandTotalAmountWithTax,
    filteredProducts,
    setFilteredProducts,
    setSelectedVendor,
    setSelectedCategory,
    setSelectedItemsByCategory,
    setItemDetails,
    warehouses,
    selectedWarehouseId,
    setSelectedWarehouseId

}) => {

    const handleSearch = (e) => {
        const query = e.target.value ? e.target.value.toLowerCase().trim() : '';
        setSearchQuery(query);

        const filteredData = products.filter(product =>
            (product.name && product.name.toLowerCase().includes(query)) ||
            (product.productsCode && product.productsCode.toLowerCase().includes(query))
        );

        setFilteredProducts(filteredData);
    };


    const validateOrder = () => {
        for (const [categoryId, items] of Object.entries(selectedItemsByCategory)) {
            for (const item of items) {
                const key = `${categoryId}-${items.indexOf(item)}`;
                const details = itemDetails[key] || {};

                // Check if any required field is missing
                if (!details.unitType || !details.unitCost || !details.quantity) {
                    return false;
                }

                // Check if values are valid numbers and not zero
                if (
                    isNaN(details.unitCost) || details.unitCost <= 0 || isNaN(details.quantity) || details.quantity <= 0
                ) {
                    return false;
                }
            }
        }
        return true;
    };

    const handleCancel = () => {
        setSelectedVendor(null);
        setNewOrder('');
        setSelectedCategory('');
        setSelectedItemsByCategory({});
        setItemDetails({});
        onClose(); // Close modal/form
        setIsPopupOpen(false);
    };


    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} className="modal-content bg-gray-300 ml-64 mt-20 h-full">
            <div className='justify-evenly '>
                <div className='flex items-center mb-5 bg-yellow-400'>
                    <div className="flex p-4">
                        <select id="category-select" value={selectedCategory} onChange={handleCategoryChange} className="block w-56 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent">
                            <option value="" className='text-slate-600'>Choose Category</option>
                            {categories.map(category => (
                                <option key={category.id} value={category.id}>{category.categoryName}</option>
                            ))}
                        </select>
                    </div>

                    <div className="flex p-4">
                        <select
                            id="warehouse-select"
                            value={selectedWarehouseId || ''}  // Make sure the value is controlled
                            onChange={(e) => setSelectedWarehouseId(e.target.value)}
                            className="block w-56 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        >
                            <option value="" className='text-slate-600'>Choose Warehouse</option>
                            {warehouses.map((warehouse) => (
                                <option key={warehouse.id} value={warehouse.id}>{warehouse.name}</option>
                            ))}
                        </select>
                    </div>


                    <div className="flex p-4">
                        <select id="vendor-select" value={selectedVendor} onChange={handleVendorChange} className="block w-56 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent">
                            <option value="" className='text-slate-600 hidden'>Choose Vendor</option>
                            {vendors.map(vendor => (
                                <option key={vendor.id} value={vendor.id}>{vendor.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="ml-0">
                        <form>
                            <div className="relative">
                                <input type="text" id="table-search" value={searchQuery} onChange={handleSearch} className="block w-56 p-2 pl-10 text-sm border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent" placeholder="Search for items" />
                                <img src={Searchicon} alt="search" className="absolute left-3 top-3 w-4 h-4" />
                            </div>
                        </form>
                    </div>
                    <div className="flex space-x-10 ml-5">
                        <button
                            onClick={() => setIsPopupOpen(true)}
                            className={`text-gray-700 px-10 py-2 rounded-lg border hover:border-fuchsia-500 ${validateOrder() && selectedVendor ? '' : 'opacity-50 cursor-not-allowed'}`}
                            disabled={!validateOrder() || !selectedVendor}
                            style={{ backgroundColor: "#26eb0c" }}
                        >
                            Create
                        </button>
                        <button onClick={handleCancel} className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600">Cancel</button>
                    </div>
                </div>

                <div className='w-full h-64 overflow-auto bg-white p-4'>
                    <div className="grid grid-cols-4 gap-4">
                        {filteredProducts.map((product) => (
                            <div key={product.id} className={`flex items-center border border-gray-200 rounded-lg p-2 ${selectedItemsByCategory[selectedCategory]?.includes(product.name) ? 'bg-green-300' : ''}`}>
                                <input
                                    type="checkbox"
                                    value={product.name}
                                    checked={selectedItemsByCategory[selectedCategory]?.includes(product.name)}
                                    onChange={() => handleCheckboxChange(product.name)}
                                />
                                <span className="ml-4 overflow-hidden">{product.name}({product.productsCode})</span>
                            </div>
                        ))}
                    </div>
                </div>


                <div className='flex justify-center p-2'>
                    <div className='w-full rounded-lg'>
                        <div className="overflow-y-auto h-72">
                            <table className="w-full bg-white border-gray-200 rounded-lg">
                                <thead>
                                    <tr>
                                        <th className="sticky top-0 px-4 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 text-nowrap">Product Name</th>
                                        <th className="sticky top-0 px-4 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 text-nowrap">Unit Type</th>
                                        <th className="sticky top-0 px-4 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 text-nowrap">Per Unit (₹)</th>
                                        <th className="sticky top-0 px-4 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 text-nowrap">Order Quantity</th>
                                        <th className="sticky top-0 px-4 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 text-nowrap">GST (%)</th>
                                        <th className="sticky top-0 px-4 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 text-nowrap">Total Amount(₹)</th>
                                        <th className="sticky top-0 px-4 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 text-nowrap">Total Amount With Tax (₹)</th>
                                        <th className="sticky top-0 px-4 py-2 border-b-2 border-gray-200 bg-gray-100 text-center text-sm font-semibold text-gray-600 rounded-tr-lg text-nowrap">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(selectedItemsByCategory).map(([categoryId, items]) => (
                                        items.map((item, index) => {
                                            const key = `${categoryId}-${index}`;
                                            const details = itemDetails[key] || {};
                                            const totalAmount = calculateTotalAmount(details.unitCost, details.quantity);
                                            const totalAmountWithTax = calculateTotalAmountWithTax(totalAmount, details.gst);

                                            return (
                                                <tr key={index}>
                                                    <td className="px-4 py-4 border-b border-gray-200 bg-white text-sm">{item}</td>


                                                    <td className="px-4 py-4 border-b border-gray-200 bg-white text-sm">
                                                        <select
                                                            value={details.unitType || ''}
                                                            onChange={(e) => handleDetailChange(categoryId, index, 'unitType', e.target.value)}
                                                            className={`p-2 w-full border-2 ${details.unitType ? 'border-[#07fa02]' : 'border-red-300'} rounded`}
                                                        >
                                                            <option value="" disabled className='hidden'>Unit</option>
                                                            {measurements.map((symbol) => (
                                                                <option key={symbol} value={symbol}>
                                                                    {symbol}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td className="px-4 py-4 border-b border-gray-200 bg-white text-sm">
                                                        <input
                                                            type="number"
                                                            step="0.01"
                                                            className={`p-2 w-full border-2 ${details.unitCost ? 'border-[#07fa02]' : 'border-red-300'} rounded`}
                                                            value={details.unitCost || '0'}
                                                            onChange={(e) => handleDetailChange(categoryId, index, 'unitCost', e.target.value)}
                                                        />
                                                    </td>
                                                    <td className="px-2 py-4 border-b border-gray-200 bg-white text-sm">
                                                        <input
                                                            type="number"
                                                            step="0.01"
                                                            className={`p-2 w-2/3 border-2 ${details.quantity ? 'border-[#07fa02]' : 'border-red-300'} rounded`}
                                                            value={details.quantity || '0'}
                                                            onChange={(e) => handleDetailChange(categoryId, index, 'quantity', e.target.value)}
                                                        />
                                                    </td>
                                                    <td className="px-2 py-4 border-b border-gray-200 bg-white text-sm">
                                                        <input
                                                            type="number"
                                                            step="0.01"
                                                            className={`p-2 w-2/3 border-2 ${details.gst ? 'border-[#07fa02]' : 'border-red-300'} rounded`}
                                                            value={details.gst || '0'}
                                                            onChange={(e) => handleDetailChange(categoryId, index, 'gst', e.target.value)}
                                                        />
                                                    </td>
                                                    <td className="px-4 py-4 border-b border-gray-200 bg-white text-sm">{totalAmount.toFixed(2)}</td>
                                                    <td className="px-4 py-4 border-b border-gray-200 bg-white text-sm">{totalAmountWithTax.toFixed(2)}</td>
                                                    <td className="px-4 py-4 border-b border-gray-200 bg-white text-sm">
                                                        <button onClick={() => handleRemoveRow(categoryId, index)} className="text-blue-500 hover:text-blue-700">
                                                            <IoMdTrash size={30} style={{ color: "red" }} className="inline-block mr-1" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ))}
                                </tbody>
                                <tfoot >
                                    <tr>
                                        <td colSpan="5" className="px-4 py-4 border-b border-gray-200 bg-white text-sm font-semibold bg-yellow-400">Grand Total</td>
                                        <td className="px-4 py-4 border-b border-gray-200 bg-yellow-400 text-sm font-semibold">{parseFloat(grandTotalAmount).toFixed(2)}</td>
                                        <td className="px-4 py-4 border-b border-gray-200 bg-yellow-400 text-sm font-semibold">{parseFloat(grandTotalAmountWithTax).toFixed(2) || 0}</td>
                                        <td className="px-4 py-4 border-b border-gray-200 bg-yellow-400 text-sm"></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>

                {isPopupOpen && (
                    <Modal
                        isOpen={isPopupOpen} onRequestClose={() => setIsPopupOpen(false)} className="modal-content flex items-center justify-center h-screen bg-gray-300 p-4">
                        <div className="flex flex-col w-3/5 h-3/5 bg-white rounded-md p-4  items-center justify-center">
                            <h2 className="text-xl text-center font-bold mb-10">Confrom Order</h2>

                            <label htmlFor="Date">Delivery Date</label>
                            <input type="date" value={newOrder} onChange={(e) => setNewOrder(e.target.value)} className="p-2 mb-10 border w-1/3 border-gray-300 rounded" />

                            <div className="flex space-x-4">
                                <button onClick={handleCreateOrder} className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600">Create</button>
                                <button onClick={() => setIsPopupOpen(false)} className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600">Cancel</button>
                            </div>
                        </div>
                    </Modal>
                )}

            </div>
        </Modal>
    );
};

export default PurchaseForm;