import React, { useState, useEffect } from 'react';
import axios from 'axios';

const WastageEdit = ({ selectedWastage, setShowEditPopup, fetchData }) => {
  const initialProduct = selectedWastage && selectedWastage.product ? selectedWastage.product : null;

  const [product, setProduct] = useState(initialProduct);
  const [quantity, setQuantity] = useState(initialProduct ? initialProduct.quantity : '');
  const [quantityError, setQuantityError] = useState('');
  const [apiError, setApiError] = useState('');

  useEffect(() => {
    if (selectedWastage && selectedWastage.product) {
      setProduct(selectedWastage.product);
      setQuantity(selectedWastage.product.quantity);
    }
  }, [selectedWastage]);

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setQuantity(value);
      setQuantityError('');
    } else {
      setQuantityError('Negative value should not be entered');
    }
  };

  const handleEdit = async () => {
    if (!quantity || quantity < 0) {
      setQuantityError(quantity < 0 ? 'Negative value should not be entered' : 'Quantity field is empty');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const role = localStorage.getItem('role');
      const updatedProduct = { 
        productId: product.productId, 
        quantity, 
        unitId: product.unitId 
      };
  
      const response = await axios.put(
        `https://pos.farm2bag.com/api/v1/wastages/${selectedWastage.wastageId}`,
        { 
          modifiedBy: role,
          items: [updatedProduct] 
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      console.log('Response:', response.data);
      setShowEditPopup(false);
      fetchData();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setApiError(error.response.data.message);
      } else {
        console.error('Error updating wastage:', error);
      }
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-semibold mb-4">Edit Wastage Quantity</h2>
        {apiError && <div className="text-red-500 mb-4">{apiError}</div>}
        {product && (
          <div className="mb-4">
            <label className="block text-gray-700">Quantity</label>
            <input
              type="number"
              value={quantity}
              onChange={handleQuantityChange}
              className="border border-gray-300 px-4 py-2 rounded-lg w-full focus:outline-none focus:border-blue-500"
            />
            {quantityError && <div className="text-red-500">{quantityError}</div>}
          </div>
        )}
        <div className="flex justify-end space-x-4">
          <button
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg"
            onClick={() => setShowEditPopup(false)}
          >
            Cancel
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-lg"
            onClick={handleEdit}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default WastageEdit;
