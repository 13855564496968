import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import f2b_logo from "../Assets/login/f2bimage.png"; // Adjust path to your logo

const TransferInvoicePopup = ({ isOpen, onClose, transferData, FirstPop }) => {
    const contentRef = useRef(null);
    const handleDownloadPDF = () => {
        if (!contentRef.current) return;

        html2canvas(contentRef.current, { scrollY: -window.scrollY, backgroundColor: '#ffffff' }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'pt', 'a4');
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            // Add logo and address section to PDF
            const marginLeft = 40; // Left margin for logo and address
            pdf.addImage(f2b_logo, 'PNG', marginLeft, 40, 80, 40); // Left aligned logo
            pdf.setFont('Helvetica', 'bold');
            pdf.setFontSize(12);
            pdf.text('Farm2bag', marginLeft, 100); // Left aligned company name
            pdf.setFontSize(10);
            pdf.text('5/396, Rajeev Gandhi Salai, OMR Thoraipakkam, Chennai, Tamil Nadu 600097', marginLeft, 115, { maxWidth: 230 }); // Left aligned address with maximum width
            pdf.text('PH-095000 37221', marginLeft, 140); // Left aligned phone number

            // Add transfer details section as a table
            pdf.setFont('Helvetica', 'normal');
            pdf.setFontSize(14);
            pdf.text('Transfer Details', marginLeft, 160); // Left aligned section heading
            pdf.autoTable({
                startY: 180,
                head: [['Detail', 'Value']],
                body: [
                    ['Invoice', transferData.invoice],
                    ['From Warehouse', transferData.fromWarehouseName],
                    ['To Warehouse', transferData.toWarehouseName],
                    ['Transfer Date',transferData.transferDate],
                    ['Status', transferData.status],
                    ['Total Product Amount', transferData.totalProductAmount.toFixed(2)], // Display total product amount here
                    ['Payment Mode', transferData.paymentMode],
                    ['Transport Charge', transferData.transportCharge],
                    ['Packing Charge', transferData.packingCharge],
                    ['Total Amount', transferData.totalAmount.toFixed(2)],
                ],
                margin: { top: 10, left: marginLeft, bottom: 10, right: 40 },
                styles: { fontSize: 10, halign: 'left' },
                theme: 'grid',
            });

            // Add transfer items table
            const startY = pdf.autoTable.previous.finalY + 20; // Adjust start y-coordinate for table
            const tableHeaders = ['Product Name', 'Quantity', 'Unit', 'Price', 'Subtotal'];
            const tableData = transferData.products.map((product) => [
                product.name,
                product.quantity,
                product.unit,
                product.price.toFixed(2),
                (product.quantity * product.price).toFixed(2), // Calculate and display subtotal
            ]);
            pdf.autoTable({
                startY,
                head: [tableHeaders],
                body: tableData,
                margin: { top: 10, left: marginLeft, bottom: 10, right: 40 },
                styles: { fontSize: 10, halign: 'left' },
                theme: 'grid',
                columnStyles: { 0: { cellWidth: 'auto' } },
            });

            pdf.save('transfer-invoice.pdf');
        }).catch((error) => {
            console.error('Error generating PDF:', error);
        });
    };

    const closeAllPop = () =>{
        onClose();
        FirstPop();
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeAllPop}
            contentLabel="Transfer Invoice"
            className="flex items-center justify-center mt-16"
            overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center"
        >
            <div ref={contentRef} className="overflow-y-auto max-h-96 bg-white p-6 pb-10 rounded-lg shadow-lg w-full  ">
                <div className="text-center mb-4">
                    <img src={f2b_logo} alt="Company Logo" className="mx-auto h-12" />
                    <h2 className="text-2xl font-bold mt-2">Transfer Details</h2>
                </div>
                <div className="mb-4">
                    <table className="min-w-full bg-white border-none">
                        <tbody>
                        <tr className="border-b">
                                <th className="px-4 py-1 text-left text-gray-600 bg-gray-100">Invoice:</th>
                                <td className="px-4 py-1 text-gray-800">{transferData.invoice}</td>
                            </tr>
                            <tr className="border-b">
                                <th className="px-4 py-1 text-left text-gray-600 bg-gray-100">From Warehouse:</th>
                                <td className="px-4 py-1 text-gray-800">{transferData.fromWarehouseName}</td>
                            </tr>
                            <tr className="border-b">
                                <th className="px-4 py-1 text-left text-gray-600 bg-gray-100">To Warehouse:</th>
                                <td className="px-4 py-1 text-gray-800">{transferData.toWarehouseName}</td>
                            </tr>
                            <tr className="border-b">
                                <th className="px-4 py-1 text-left text-gray-600 bg-gray-100">Transfer Date:</th>
                                <td className="px-4 py-1 text-gray-800">{transferData.transferDate}</td>
                            </tr>
                            <tr className="border-b">
                                <th className="px-4 py-1 text-left text-gray-600 bg-gray-100">Total Product Amount:</th>
                                <td className="px-4 py-1 text-gray-800">₹ {transferData.totalProductAmount.toFixed(2)}</td>
                            </tr>
                            <tr className="border-b">
                                <th className="px-4 py-1 text-left text-gray-600 bg-gray-100">Transport Charge:</th>
                                <td className="px-4 py-1 text-gray-800">₹ {transferData.transportCharge}</td>
                            </tr>
                            <tr className="border-b">
                                <th className="px-4 py-1 text-left text-gray-600 bg-gray-100">Packing Charge:</th>
                                <td className="px-4 py-1 text-gray-800">₹ {transferData.packingCharge}</td>
                            </tr>
                            <tr className="border-b">
                                <th className="px-4 py-1 text-left text-gray-600 bg-gray-100">Total Amount:</th>
                                <td className="px-4 py-1 text-gray-800">₹ {transferData.totalAmount.toFixed(2)}</td>
                            </tr>
                            <tr className="border-b">
                                <th className="px-4 py-1 text-left text-gray-600 bg-gray-100">Payment Mode:</th>
                                <td className="px-4 py-1 text-gray-800">{transferData.paymentMode}</td>
                            </tr>
                            <tr className="border-b">
                                <th className="px-4 py-1 text-left text-gray-600 bg-gray-100">Status:</th>
                                <td className="px-4 py-1 text-gray-800">{transferData.status}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <h3 className="text-lg font-semibold mb-2 text-center">Transfer Items</h3>
                <table className="min-w-full bg-white border border-gray-200">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="px-4 py-1 text-left text-sm font-medium text-gray-700">Product Name</th>
                            <th className="px-4 py-1 text-left text-sm font-medium text-gray-700">Quantity</th>
                            <th className="px-4 py-1 text-left text-sm font-medium text-gray-700">Unit</th>
                            <th className="px-4 py-1 text-left text-sm font-medium text-gray-700">Price</th>
                            <th className="px-4 py-1 text-left text-sm font-medium text-gray-700">Subtotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transferData.products.map((product, index) => (
                            <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                                <td className="px-4 py-1 whitespace-nowrap text-sm font-medium text-gray-900">{product.name}</td>
                                <td className="px-4 py-1 whitespace-nowrap text-sm text-gray-500">{product.quantity}</td>
                                <td className="px-4 py-1 whitespace-nowrap text-sm text-gray-500">{product.unit}</td>
                                <td className="px-4 py-1 whitespace-nowrap text-sm text-gray-500">₹ {product.price.toFixed(2)}</td>
                                <td className="px-4 py-1 whitespace-nowrap text-sm text-gray-500">₹ {(product.quantity * product.price).toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="mt-4 flex justify-end">
                    <button onClick={handleDownloadPDF} className="ml-4 bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 focus:outline-none">
                        Download PDF
                    </button>
                </div>
            </div>
        </Modal>
    );
};

TransferInvoicePopup.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    transferData: PropTypes.shape({
        invoice: PropTypes.string.isRequired,
        fromWarehouseName: PropTypes.string.isRequired,
        toWarehouseName: PropTypes.string.isRequired,
        transferDate: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        totalProductAmount: PropTypes.number.isRequired,
        paymentMode: PropTypes.string.isRequired,
        transportCharge: PropTypes.number.isRequired,
        packingCharge: PropTypes.number.isRequired,
        totalAmount: PropTypes.number.isRequired,
        products: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            quantity: PropTypes.number.isRequired,
            unit: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,
        })).isRequired,
    }).isRequired,
};

export default TransferInvoicePopup;
