import React, { useState, useEffect } from 'react';
import SearchInput from 'react-search-input';
import filter from "../Assets/tableicon/filter.png";
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";


const QualityChecking = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch data from API
    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('token'); // Get token from local storage
            const headers = new Headers({
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            });

            try {
                const response = await fetch('https://pos.farm2bag.com/api/v1/qualityChecking/all', { headers });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result.data || []); // Access the data field and default to an empty array
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    // Render loading, error, or table
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className='bg-gray-100 ml-64 mt-20 p-12 h-screen'>
            <div className="container mx-auto">
                <div className="mb-10">
                    <h1 className="font-extrabold text-3xl text-left text-gray-500">Quality Checking</h1>
                    <div className="mt-2 border-t border-gray-500"></div>
                </div>
                <div className="flex items-center mb-5">
                    <div className="ml-0">
                        <form>
                            <div className="relative">
                                <SearchInput placeholder="🔍 Invoice Number"
                                    style={{ padding: '6px', border: '1px solid #ccc', borderRadius: '5px', width: '120%', fontSize: '1rem', outline: 'none', fontWeight: 600 }} />
                            </div>
                        </form>
                    </div>
                    <div className="flex ml-auto mr-0 items-center space-x-4">
                        <img src={filter} alt="filter" className="w-12 h-10 rounded-lg cursor-pointer" />
                        <button style={{ backgroundColor: 'rgba(66, 255, 97, 1)' }} className="w-48 h-10 font-medium text-sm rounded-lg">Create Quality Checking</button>
                    </div>
                </div>

                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-300">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 border-b">Invoice No</th>
                                <th className="py-2 px-4 border-b">Purchase Code</th>
                                <th className="py-2 px-4 border-b">Invoice Date</th>
                                <th className="py-2 px-4 border-b">Product Count</th>
                                <th className="py-2 px-4 border-b">Created At</th>
                                <th className="py-2 px-4 border-b">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(item => (
                                <tr key={item.id}>
                                    <td className="py-2 px-4 border-b">{item.invoiceNo}</td>
                                    <td className="py-2 px-4 border-b">{item.purchaseCode}</td>
                                    <td className="py-2 px-4 border-b">{item.invoiceDate}</td>
                                    <td className="py-2 px-4 border-b">{item.productCount}</td>
                                    <td className="py-2 px-4 border-b">{item.createdAt}</td>
                                    <td className="py-2 px-4 border-b">                                        
                                        <button className="mr-2 font-bold  rounded">
                                        <FaRegEdit size={22} style={{ color: "blue" }} className="inline-block mr-1" />
                                    </button>

                                        <button className=" text-white font-bold rounded">
                                            <IoMdTrash size={30} style={{ color: "red" }} className="inline-block mr-1" />
                                        </button></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default QualityChecking;
