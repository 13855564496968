import React, { useState, useEffect } from "react";

const RolePopup = ({
    isPopupOpen,
    onClose,
    onSubmit,
    popupType,
    roleName,
    setRoleName,
    roleNameError,
    selectedRole,
    selectedModelId,
}) => {
    const [apiData, setApiData] = useState([]);
    const [selectedModels, setSelectedModels] = useState(new Set());

    useEffect(() => {
        if (isPopupOpen) {
            const fetchData = async () => {
                try {
                    const token = localStorage.getItem("token"); // Retrieve token from localStorage
                    const response = await fetch("https://pos.farm2bag.com/api/v1/models/status", {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${token}`, // Include token in header
                        },
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    const data = await response.json();
                    if (data.status === 200) {
                        setApiData(data.data);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };

            fetchData();
        }
    }, [isPopupOpen]); // Run effect when isPopupOpen changes

    useEffect(() => {
        if (popupType === "edit" && Array.isArray(selectedModelId)) {
            // Initialize selectedModels with IDs from selectedRole
            setSelectedModels(new Set(selectedModelId));
        } else {
            setSelectedModels(new Set()); // Clear selection if not editing
        }
    }, [selectedModelId, popupType]); // Run effect when selectedRole or popupType changes

    const handleCheckboxChange = (id) => {
        setSelectedModels((prevSelected) => {
            const updatedSelection = new Set(prevSelected);
            if (updatedSelection.has(id)) {
                updatedSelection.delete(id);
            } else {
                updatedSelection.add(id);
            }
            return updatedSelection;
        });
    };

    const handleClose = () => {
        setSelectedModels(new Set()); // Clear selected models
        setRoleName(""); // Reset role name (optional)
        onClose(); // Call the provided onClose handler
    };

    if (!isPopupOpen) return null;

    let title = "";
    let submitText = "";
    let content = null;

    switch (popupType) {
        case "create":
            title = "Create New Role";
            submitText = "Create";
            content = (
                <>
                    <input
                        type="text"
                        value={roleName}
                        onChange={(e) => setRoleName(e.target.value)}
                        placeholder="Enter Role name"
                        className="border border-gray-200 px-4 py-2 rounded-lg mb-2 w-4/5"
                    />
                    {roleNameError && (
                        <p className="text-red-500 text-sm">{roleNameError}</p>
                    )}
                    <div className="mt-4 max-h-60 overflow-y-auto grid grid-cols-3 gap-4">
                        {apiData.map((model) => (
                            <div key={model.id} className="flex items-center">
                                <input
                                    type="checkbox"
                                    id={`model-${model.id}`}
                                    checked={selectedModels.has(model.id)}
                                    onChange={() => handleCheckboxChange(model.id)}
                                    className="mr-2 w-5 h-5 accent-[#00ff00] cursor-grabbing"
                                />
                                <label htmlFor={`model-${model.id}`} className="font-semibold">{model.pageName}</label>
                            </div>
                        ))}
                    </div>
                </>
            );
            break;

        case "edit":
            title = "Edit Role";
            submitText = "Update";
            content = (
                <>
                    <input
                        type="text"
                        value={roleName}
                        onChange={(e) => setRoleName(e.target.value)}
                        placeholder="Enter new Role name"
                        className="border border-gray-200 px-4 py-2 rounded-lg mb-4 w-4/5"
                    />
                    <div className="mt-4 max-h-60 overflow-y-auto grid grid-cols-3 gap-4">
                        {apiData.map((model) => (
                            <div key={model.id} className="flex items-center">
                                <input
                                    type="checkbox"
                                    id={`model-${model.id}`}
                                    checked={selectedModels.has(model.id)}
                                    onChange={() => handleCheckboxChange(model.id)}
                                    className="mr-2 w-5 h-5 accent-[#00ff00] cursor-grabbing"
                                />
                                <label htmlFor={`model-${model.id}`} className="font-semibold">{model.pageName}</label>
                            </div>
                        ))}
                    </div>
                </>
            );
            break;

        case "delete":
            title = "Delete Role";
            submitText = "Delete";
            content = (
                <>
                    <p>Are you sure you want to delete the Role " <span className="text-red-500">{selectedRole?.RoleName}</span> "?</p>
                </>
            );
            break;

        default:
            return null;
    }

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex justify-center items-center">
            <div className="bg-yellow-400 p-6 rounded-lg">
                <h2 className="text-xl font-bold mb-4">{title}</h2>
                <div className="mb-4 border-t border-gray-500"></div>
                {content}
                <button
                    onClick={handleClose}
                    className="bg-red-500 text-white px-4 py-2 rounded-lg ml-2 float-left ml-12 mt-4 hover:bg-red-500"
                >
                    Cancel
                </button>
                <button
                    onClick={() => onSubmit(Array.from(selectedModels))}
                    className="bg-green-500 text-white px-4 py-2 rounded-lg float-right mr-11 mt-4 hover:bg-green-600"
                >
                    {submitText}
                </button>
            </div>
        </div>
    );
};

export default RolePopup;
