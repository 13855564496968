import React, { useEffect, useState } from "react";
import StockUpdateModal from "./StockUpdateModal";
import { toast, ToastContainer } from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';

const ProductOverview = () => {
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouseBestSelling, setSelectedWarehouseBestSelling] =
    useState(null);
  const [selectedWarehouseStockAlerts, setSelectedWarehouseStockAlerts] =
    useState(null);
  const[selectedWarehouseId, setSelectedWarehouseId] = useState(null);
  const [lowQuantityProducts, setLowQuantityProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [highestBilledProducts, setHighestBilledProducts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const [currentPageBestSelling, setCurrentPageBestSelling] = useState(1);
  const recordsPerPage = 5;
  const [showModal, setShowModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [currentPageStockAlerts, setCurrentPageStockAlerts] = useState(1);
  useEffect(() => {
    fetchWarehouses();
  }, []);

  useEffect(() => {
    fetchBillingAndBillingItems();
  }, [warehouses, selectedWarehouseBestSelling]);

  useEffect(() => {
    if (warehouses.length === 0) return;
    fetchStockData();
  }, [selectedWarehouseStockAlerts, warehouses]);

  // Warehouse API
  const fetchWarehouses = async () => {
    try {
      const token = localStorage.getItem("token");
      const warehouseResponse = await fetch(
        "https://pos.farm2bag.com/api/v1/warehouse", //Fetching warehouse data
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!warehouseResponse.ok) {
        throw new Error(`HTTP error! status: ${warehouseResponse.status}`);
      }
      const warehouseData = await warehouseResponse.json();
      if (warehouseData && Array.isArray(warehouseData.data)) {
        setWarehouses(warehouseData.data);
      } else {
        throw new Error("Unexpected warehouse data format");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  // Billing and BillingItem API
  const fetchBillingAndBillingItems = async () => {
    try {
      const token = localStorage.getItem("token");

      const billingResponse = await fetch(
        "https://pos.farm2bag.com/api/v1/billing", // Fetching billing data first
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!billingResponse.ok) {
        throw new Error(`HTTP error! status: ${billingResponse.status}`);
      }
      const billingData = await billingResponse.json();

      const billingItemsResponse = await fetch(
        "https://pos.farm2bag.com/api/v1/billingItems", // Fetching billing items data
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!billingItemsResponse.ok) {
        throw new Error(`HTTP error! status: ${billingItemsResponse.status}`);
      }
      const billingItemsData = await billingItemsResponse.json();
      if (billingData && Array.isArray(billingData.data)) {
        const billingWarehouse = billingData.data.reduce((acc, item) => {
          acc[item.id] = warehouses.find(
            (e) => item.warehouseId === e.id
          )?.name;
          return acc;
        }, {});

        const billingWarehouseId = billingData.data.reduce((acc, item) => {
          acc[item.id] = warehouses.find((e) => item.warehouseId === e.id)?.id;
          return acc;
        }, {});

        let filteredBillingItems = billingItemsData.data
          .map((item) =>
            item.product
              .filter((product) => product.productStatus === "Purchase")
              .map((product) => ({
                ...product,
                warehouse: billingWarehouse[item.billingId],
                warehouseId: billingWarehouseId[item.billingId],
              }))
          )
          .flat();

        if (selectedWarehouseBestSelling) {
          filteredBillingItems = filteredBillingItems.filter(
            (item) => item.warehouseId === selectedWarehouseBestSelling
          );
        }

        filteredBillingItems = filteredBillingItems.reduce((acc, product) => {
          if (!acc[product.productId]) {
            acc[product.productId] = { ...product };
            // console.log("Billed Product", filteredBillingItems);
          } else {
            acc[product.productId].quantity += product.quantity;
            acc[product.productId].totalPrice += product.totalPrice;
          }
          return acc;
        }, {});

        filteredBillingItems = Object.values(filteredBillingItems).map(
          (product) => ({
            ...product,
            price: product.totalPrice / product.quantity,
          })
        );

        const sortedBillingItems = Object.values(filteredBillingItems);
        sortedBillingItems.sort((a, b) => {
          if (b.totalPrice === a.totalPrice) {
            return b.totalPrice - a.totalPrice;
          }
          return b.quantity - a.quantity;
        });
        setHighestBilledProducts(sortedBillingItems);
      } else {
        throw new Error("Unexpected billing items data format");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const fetchStockData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found");
      }

      // Stock API
      const stockAlert = selectedWarehouseStockAlerts
        ? `?warehouseId=${selectedWarehouseStockAlerts}`
        : "";
      const response = await fetch(
        `https://pos.farm2bag.com/api/v1/stocks${stockAlert}`, // Fetching Stock Data
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const res = await response.json();
      if (res && Array.isArray(res.data)) {
        let updatedStockData = res.data.map((item) => ({
          ...item,
          products: item.products.map((product) => ({
            ...product,
            warehouse: warehouses.find(
              (stockWarehouse) => stockWarehouse.id === item.wareHouseId
            )?.name,
            wareHouseId: item.wareHouseId,
          })),
        }));

        let allProducts = updatedStockData.map((item) => item.products).flat();
        setFilteredProducts(allProducts);
        setLowQuantityProducts(
          allProducts.filter((product) => product.quantity <= 10)
        );
      } else {
        throw new Error("Data format is not an array");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // warehouse Filter for The Best Selling Product
  const handleWarehouseBestSelling = (e) => {
    const warehouseIdFilter =
      e.target.value === "All Warehouse" ? null : e.target.value;
    setSelectedWarehouseBestSelling(warehouseIdFilter);
    setCurrentPageBestSelling(1);
  };

  const handleWarehouseStockAlerts = (e) => {
    const warehouseId =
      e.target.value === "All Warehouse" ? null : e.target.value;
  
    // Set the state for selected warehouse stock alerts
    setSelectedWarehouseStockAlerts(warehouseId);
  
    // Set the state for selected warehouse ID
    setSelectedWarehouseId(warehouseId);
  
    // Reset pagination to the first page
    setCurrentPageStockAlerts(1);
  };
  
  // Optionally, you can use useEffect to track changes to the state:
  useEffect(() => {
    console.log("Updated Selected Warehouse Stock Alerts:", selectedWarehouseStockAlerts);
  }, [selectedWarehouseStockAlerts]);
  
  useEffect(() => {
    console.log("Updated Selected Warehouse ID:", selectedWarehouseId);
  }, [selectedWarehouseId]);
  

  useEffect(() => {
    if (selectedWarehouseStockAlerts === null) {
      setLowQuantityProducts(
        filteredProducts.filter((product) => product.quantity <= 10)
      );
    } else {
      setLowQuantityProducts(
        filteredProducts.filter(
          (product) =>
            product.quantity <= 10 &&
            product.wareHouseId === selectedWarehouseStockAlerts
        )
      );
    }
  }, [selectedWarehouseStockAlerts, filteredProducts]);

  useEffect(() => {
    if (selectedWarehouseBestSelling === null) {
      setHighestBilledProducts((prev) => [...prev]);
    } else {
      setHighestBilledProducts((prev) =>
        prev.filter(
          (product) => product.wareHouseId === selectedWarehouseBestSelling
        )
      );
    }
  }, [selectedWarehouseBestSelling]);

  const sortedLowQuantityProducts = lowQuantityProducts
    .slice()
    .sort((a, b) => a.quantity - b.quantity);

  const indexOfLastBestSelling = currentPageBestSelling * recordsPerPage;
  const indexOfFirstBestSelling = indexOfLastBestSelling - recordsPerPage;
  const currentBestSellingProducts = highestBilledProducts.slice(
    indexOfFirstBestSelling,
    indexOfLastBestSelling
  );

  const indexOfLastStockAlerts = currentPageStockAlerts * recordsPerPage;
  const indexOfFirstStockAlerts = indexOfLastStockAlerts - recordsPerPage;
  const currentStockAlerts = sortedLowQuantityProducts.slice(
    indexOfFirstStockAlerts,
    indexOfLastStockAlerts
  );

  const paginateBestSelling = (pageNumber) =>
    setCurrentPageBestSelling(pageNumber);
  const paginateStockAlerts = (pageNumber) =>
    setCurrentPageStockAlerts(pageNumber);
  const handleUpdateStock = async (productId, selectedWarehouseId, newQuantity) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found, please login again.");
      }
  
      // Log values before API call
      console.log("Product ID:", productId);
      console.log("Selected Warehouse ID:", selectedWarehouseId);
      console.log("New Quantity:", newQuantity);
  
      // Ensure newQuantity is a valid number
      const quantity = parseFloat(newQuantity);
      if (isNaN(quantity) || quantity <= 0) {
        throw new Error("Invalid quantity value");
      }
  
      // Check for null warehouseId
      if (!selectedWarehouseId) {
        toast.error("Warehouse Not Selected.");
        return;
      }
  
      const response = await fetch(
        `https://pos.farm2bag.com/api/v1/stocks/updateStock?warehouseId=${selectedWarehouseId}&productId=${productId}&newQuantity=${quantity}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.ok) {
        const data = await response.json();
        console.log("Stock updated successfully", data);
  
        fetchStockData();
        // Show success toast
        toast.success("Stock updated successfully.");
      } else {
        const errorData = await response.json();
        console.error("Failed to update stock:", errorData.message || response.statusText);
  
        // Show error toast
        toast.error(`Failed to update stock: ${errorData.message || response.statusText}`);
      }
    } catch (error) {
      console.error("Error updating stock:", error.message);
  
      // Show error toast
      toast.error(`Error updating stock: ${error.message}`);
    }
  };
  
  

  const openModal = (product) => {
    setCurrentProduct(product);
    console.log(currentProduct);
    setShowModal(true);
  };
  const totalPagesBestSelling = Math.ceil(
    highestBilledProducts.length / recordsPerPage
  );
  const totalPagesStockAlerts = Math.ceil(
    sortedLowQuantityProducts.length / recordsPerPage
  );

  return (
    <div className="flex flex-col md:flex-row gap-4 p-0 ">
      {/* Best Selling Products Section */}
      <div className="flex-1 bg-white shadow-lg  rounded-lg p-4  ">
        <div className="flex justify-between items-center mb-4  ">
          <h1 className="text-xl font-semibold">Best Selling Products</h1>
          <select
            className="ml-2 p-1 border w-40 rounded-lg bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="All Warehouse"
            aria-label="Select Warehouse"
            onChange={handleWarehouseBestSelling}
          >
            <option>All Warehouse</option>
            {warehouses.map((warehouse) => (
              <option key={warehouse.id} value={warehouse.id}>
                {warehouse.name}
              </option>
            ))}
          </select>
        </div>
        <div className="overflow-x-auto">
          <table className=" leading-normal text-left w-full">
            <thead>
              <tr>
                <th className="px-10 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-380 uppercase tracking-wider">
                  ID
                </th>
              
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-380 uppercase tracking-wider">
                  PRODUCT NAME
                </th>
                
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-380 uppercase tracking-wider">
                  QUANTITY
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-380 uppercase tracking-wider">
                  UNIT
                </th>
               
              </tr>
            </thead>
            <tbody>
              {currentBestSellingProducts.map((product, index) => (
                <tr key={product.id}>
                  <td className="px-10 py-3 border-b border-gray-200 bg-white text-sm">
                    {index + 1}
                  </td>
                 
                  <td className="px-5 py-3 border-b border-gray-200 bg-white text-sm">
                    {product.productName}
                  </td>
                 
                  <td className="px-10 py-3 border-b border-gray-200 bg-white text-sm">
                    {product.quantity}
                  </td>
                  <td className="px-5 py-3 border-b border-gray-200 bg-white text-sm">
                    {product.unit}
                  </td>
                 
                </tr>
              ))}
            </tbody>
          </table>
          {currentBestSellingProducts.length === 0 && (
            <div className="text-center py-4 text-gray-700">
              No Best-Selling Products found for the Specified Warehouse
            </div>
          )}
          <Pagination
            currentPage={currentPageBestSelling}
            totalPages={totalPagesBestSelling}
            paginate={paginateBestSelling}
          />
         
        </div>
      </div>

      {/* Stock Alerts Section */}
      <div className="w-full md:w-1/3 bg-white shadow-lg rounded-lg p-3">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl font-semibold">Stock Alerts</h1>
          <select
            className="ml-2 p-1 border w-40 rounded-lg bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="All Warehouse"
            aria-label="Select Warehouse"
            onChange={handleWarehouseStockAlerts}
          >
            <option>All Warehouse</option>
            {warehouses.map((warehouse) => (
              <option key={warehouse.id} value={warehouse.id}>
                {warehouse.name}
              </option>
            ))}
          </select>
        </div>
        <h2 className="mt-4 mb-3 text-left text-slate-500">
          Low Quantity Stock
        </h2>
        {currentStockAlerts.length === 0 && (
          <div className="text-center py-4 text-gray-700">
            No Low Quantity found for the Specified Warehouse
          </div>
        )}
        <ul>
          {currentStockAlerts.map((product) => (
            <li
              key={product.id}
              className="mb-2 p-2 bg-gray-100 rounded-lg justify-between items-center text-left flex"
            >
              <span className="text-sm font-medium">{product.name}</span>
              <br />
              <span className="text-sm">{` count: ${product.quantity} ${product.unitSymbol}`}</span>
              <button
              className="ml-4 bg-green-500 text-white px-2 py-1 rounded-lg"
              onClick={() => openModal(product)}
            >
            +
            </button>
            </li>
          ))}
        </ul>
        <Pagination
          currentPage={currentPageStockAlerts}
          totalPages={totalPagesStockAlerts}
          paginate={paginateStockAlerts}
        />
         {currentProduct && (
        <StockUpdateModal
        selectedWarehouseId={selectedWarehouseId}
          showModal={showModal}
          setShowModal={setShowModal}
          product={currentProduct}
          handleUpdateStock={handleUpdateStock}
        />
      )}
      </div>
      <ToastContainer />
    </div>
  );
};

const Pagination = ({ currentPage, totalPages, paginate }) => {
  return (
    <nav className="flex justify-center mt-2">
      <ul className="flex items-center">
        
        <li>
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-3 py-1 border rounded-lg mx-1 border-none ${currentPage===1 ? 'bg-gray-200 text-gray-500':'bg-green-500 text-white'}`}
          >
            Previous
          </button>
        </li>
        <li className="mx-3">
          <span className="px-3 py-1">
            {currentPage} of {totalPages}            
          </span>
        </li>
        <li>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`px-3 py-1 border rounded-lg mx-1 border-none ${currentPage===totalPages? 'bg-gray-200 text-gray-500':'bg-green-500 text-white'}`}
          >
            Next
          </button>
        </li>

      </ul>
    </nav>
  );
};

export default ProductOverview;
