import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import SearchIcon from "../Assets/tableicon/Searchicon.png";
import { IoMdTrash } from "react-icons/io";

const AdjustmentEdit = ({ isOpen, onClose, fetchData, selecteddata, dataCategories, products, warehouseId }) => {
    const [formData, setFormData] = useState({
        date: '',
        warehouseId: '',
        productDetails: [],
    });
    const [productSearchQuery, setProductSearchQuery] = useState('');
    const [productSuggestions, setProductSuggestions] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [warehouseStock, setWarehouseStock] = useState([]);

    useEffect(() => {
        if (isOpen && selecteddata) {
            const { date, warehouse, products } = selecteddata;
            setFormData({
                date: date || '',
                warehouseId: warehouse?.id || '',
                productDetails: products || [],
            });
            setSelectedProducts(products || []);
            if (warehouse?.id) {
                fetchWarehouseStock(warehouse.id);
            }
        }
    }, [isOpen, selecteddata]);

    const fetchWarehouseStock = async (warehouseId) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found in local storage');
            }

            const response = await fetch(`https://pos.farm2bag.com/api/v1/stocks/warehouse/${warehouseId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch warehouse stock data');
            }

            const data = await response.json();
            const products = data.data.products || []; // Ensure this matches the structure returned by your API
            setWarehouseStock(products);
        } catch (error) {
            console.error('Error fetching warehouse stock:', error);
            toast.error('Error fetching warehouse stock. Please try again.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'warehouseId') {
            fetchWarehouseStock(value);
        }
    };


    const handleSearchInputChange = (e) => {
        const query = e.target.value;
        setProductSearchQuery(query);

        if (query === '') {
            setProductSuggestions([]);
        } else {
            // Check if warehouseStock and warehouseStock.products are defined
            if (warehouseStock && Array.isArray(warehouseStock)) {
                const suggestions = warehouseStock.filter(product =>
                    product.name && product.name.toLowerCase().includes(query.toLowerCase())
                );
                setProductSuggestions(suggestions);
            } else {
                // Handle case where warehouseStock or its products are not yet available
                console.warn('Warehouse stock data or products list is not available yet.');
                // You may choose to set an empty array or show a message to the user
                setProductSuggestions([]);
            }
        }
    };




    const handleProductSelect = (product) => {
        const alreadySelected = selectedProducts.some(selectedProduct => selectedProduct.productId === product.productId);
        if (!alreadySelected) {
            setSelectedProducts(prevState => [...prevState, { ...product, quantity: 1, methodType: 1 }]);
            setFormData(prevState => ({
                ...prevState,
                productDetails: [...prevState.productDetails, { productId: product.productId }]
            }));
        }
    };

    const handleProductDelete = (productId) => {
        setSelectedProducts(prevState => prevState.filter(product => product.productId !== productId));
        setFormData(prevState => ({
            ...prevState,
            productDetails: prevState.productDetails.filter(detail => detail.productId !== productId)
        }));
    };

    const handleQuantityIncrement = (productId) => {
        setSelectedProducts(prevState =>
            prevState.map(product =>
                product.productId === productId ? { ...product, quantity: product.quantity + 1 } : product
            )
        );
    };

    const handleQuantityDecrement = (productId) => {
        setSelectedProducts(prevState =>
            prevState.map(product =>
                product.productId === productId && product.quantity > 1 ? { ...product, quantity: product.quantity - 1 } : product
            )
        );
    };

    const handleMethodTypeChange = (productId, value) => {
        setSelectedProducts(prevState =>
            prevState.map(product =>
                product.productId === productId ? { ...product, methodType: value } : product
            )
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
    
            if (!token) {
                console.error('No token found in local storage');
                return;
            }
    
            if (!formData.date || !formData.warehouseId || formData.productDetails.length === 0) {
                console.error('Missing required fields');
                return;
            }
    
            for (const product of selectedProducts) {
                if (!product.productId || !product.quantity || !product.methodType) {
                    toast.error('Product information is incomplete');
                    return;
                }
            }
    
            const payload = {
                date: formData.date,
                warehouseId: formData.warehouseId,
                product: selectedProducts.map(product => ({
                    productId: product.productId,
                    productsCode: product.productsCode,
                    quantity: product.quantity,
                    methodType: product.methodType || 1, // Default to 1 if methodType is not set
                    unitId: product.unitId
                }))
            };
    
            const response = await fetch(`https://pos.farm2bag.com/api/v1/adjustments/${selecteddata.id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });
    
            if (!response.ok) {
                throw new Error('Failed to update adjustment');
            }

            fetchData(warehouseId);

            toast.success('Adjustment updated successfully', { autoClose: 1000 });
            
            setSelectedProducts([]);

            setFormData({ date: '', warehouseId: '', productDetails: [] });

            setTimeout(() => {
                onClose();
            }, 2000);
    
        } catch (error) {
            console.error('Error updating adjustment:', error);
            toast.error('Error updating adjustment. Please try again.');
        }
    };
    

    return isOpen && selecteddata ? (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-70 z-50">
            <div className="relative rounded-lg p-6 w-11/12 md:w-2/3 lg:w-1/2 bg-white">
                <h2 className="text-xl font-bold mb-4 py-2">Edit Adjustment</h2>
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-wrap -mx-2">
                        <div className="w-full md:w-1/2 px-2 mb-4">
                            <label htmlFor="date" className="block text-gray-700 font-medium mb-2">Date</label>
                            <input type="date" id="date" name="date" value={formData.date} onChange={handleInputChange} className="border border-gray-300 rounded-lg px-3 py-2 mt-1 focus:outline-none focus:border-blue-500" required />
                        </div>
                        <div className="w-full md:w-1/2 px-2 mb-4">
                            <label htmlFor="warehouseId" className="block text-gray-700 font-medium mb-2">Warehouse</label>
                            <select id="warehouseId" name="warehouseId" value={formData.warehouseId} onChange={handleInputChange} className="border border-gray-300 rounded-md p-2 w-full">
                                <option value="">Select Warehouse</option>
                                {dataCategories.map(category => (
                                    <option key={category.id} value={category.id}>
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="w-full px-2 mb-4 relative">
                            <input
                                type="text"
                                placeholder="Search products"
                                value={productSearchQuery}
                                onChange={handleSearchInputChange}
                                className="border border-gray-200 px-4 py-2 pl-10 rounded-lg focus:outline-none focus:border-blue-500 w-full"
                            />
                            <img src={SearchIcon} alt="search" className="absolute left-5 top-3 w-4 h-4" />
                            {productSuggestions.length > 0 && (
                                <ul className="absolute z-10 mt-1 bg-white border border-gray-200 rounded-lg shadow-lg w-96 left-40">
                                    {productSuggestions.map(product => (
                                        <li
                                            key={product.productId}
                                            className="px-4 py-2 cursor-pointer hover:bg-gray-100 bg-green-200"
                                            onClick={() => {
                                                handleProductSelect(product);
                                                setProductSearchQuery('');
                                                setProductSuggestions([]);
                                            }}
                                        >
                                            {product.name} {/* Ensure you are displaying the correct property */}
                                        </li>
                                    ))}
                                </ul>
                            )}

                        </div>

                    </div>

                    {selectedProducts.length > 0 && (
                        <div className="mt-4">
                            <h3 className="text-lg font-semibold mb-2">Selected Product Details</h3>
                            <table className="min-w-full bg-white border border-gray-300">
                                <thead>
                                    <tr>
                                        <th className="px-4 py-2 text-left">Product Code</th>
                                        <th className="px-4 py-2 text-left">Name</th>
                                        <th className="px-4 py-2 text-left">Unit</th>
                                        <th className="px-4 py-2 text-left">Quantity</th>
                                        <th className="px-4 py-2 text-left">Method Type</th>
                                        <th className="px-4 py-2 text-left">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedProducts.map(product => (
                                        <tr key={product.productId} className="bg-white hover:bg-green-200">
                                            <td className="border px-4 py-2">
                                                {product.productsCode}
                                            </td>
                                            <td className="border px-4 py-2">{product.name}</td>
                                            <td className="border px-4 py-2">{product.unitSymbol}</td>
                                            <td className="border px-4 py-2">
                                                <button type="button" onClick={() => handleQuantityDecrement(product.productId)} className="px-2 bg-red-400 text-white">-</button>
                                                <span className="px-2">{product.quantity}</span>
                                                <button type="button" onClick={() => handleQuantityIncrement(product.productId)} className="px-2 bg-green-400 text-white">+</button>
                                            </td>

                                            <td className="border px-4 py-2">
                                                <label className="flex items-center">
                                                    <input type="radio" name={`methodType-${product.productId}`} value="-1" checked={product.methodType === -1} onChange={() => handleMethodTypeChange(product.productId, -1)} className="form-radio h-4 w-4 text-blue-600" />
                                                    <span className="ml-2">Reduction</span>
                                                </label>
                                                <label className="flex items-center ml-4">
                                                    <input type="radio" name={`methodType-${product.productId}`} value="1" checked={product.methodType === 1} onChange={() => handleMethodTypeChange(product.productId, 1)} className="form-radio h-4 w-4 text-blue-600" />
                                                    <span className="ml-2">Addition</span>
                                                </label>
                                            </td>
                                            <td className="border px-4 py-2">
                                                <button onClick={() => handleProductDelete(product.productId)} className="text-red-500 hover:text-red-700">
                                                    <IoMdTrash />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                    <div className="mt-4 flex justify-end">
                        <button type="submit" onClick={handleSubmit} className="px-4 py-2 bg-blue-500 text-white rounded-lg mr-2">Save Changes</button>
                        <button type="button" onClick={onClose} className="px-4 py-2 bg-red-500 text-white rounded-lg">Cancel</button>
                    </div>
                </form>
            </div>
            <ToastContainer />

        </div>
    ) : null;
};

export default AdjustmentEdit;
