import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TransferInvoicePopup from './TransferInvoicePopup';

const PaymentPopup = ({ selectedProducts, fromWarehouse, toWarehouse, onClose, invoiceData, transfer, createPop }) => {
    const [paymentMode, setPaymentMode] = useState('Cash');
    const [transportCharge, setTransportCharge] = useState(0);
    const [packingCharge, setPackingCharge] = useState(0);
    const [transferDate, setTransferDate] = useState(new Date().toISOString().substring(0, 10));
    const [status, setStatus] = useState('Pending');
    const [transferData, setTransferData] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
     const [showInvoicePopup, setShowInvoicePopup] = useState(false); // New state for managing the invoice popup


    // Get the current date in yyyy-mm-dd format
    const today = new Date().toISOString().substring(0, 10);

    const calculateTotalProductAmount = () => {
        if (!selectedProducts || !Array.isArray(selectedProducts) || selectedProducts.length === 0) {
            return 0;
        }

        return selectedProducts.reduce((total, product) => {
            const price = parseFloat(product.price) || 0;
            const quantity = parseFloat(product.quantity) || 0;
            return total + (price * quantity);
        }, 0);
    };
    const calculateTotalAmount = () => {
        const totalProductAmount = calculateTotalProductAmount();
        const validTransportCharge = parseFloat(transportCharge) || 0;
        const validPackingCharge = parseFloat(packingCharge) || 0;

        const total = totalProductAmount + validTransportCharge + validPackingCharge;
        return isNaN(total) ? 0 : total;
    };


    const handleConfirmTransfer = async () => {
        // Prevent double-click by checking if the process is already running
        if (isProcessing) return;
    
        // Set processing state to true to disable the button
        setIsProcessing(true);
    
        // Ensure selectedProducts is defined and contains items
        if (!selectedProducts || selectedProducts.length === 0) {
            console.error('Selected products array is empty or undefined:', selectedProducts);
            setIsProcessing(false); // Re-enable the button
            return;
        }
    
        const token = localStorage.getItem('token');
        const role = localStorage.getItem('role');
        const formattedTransferDate = transferDate.split('-').reverse().join('/');
        const newTransferData = {
            fromWarehouseId: fromWarehouse,
            toWarehouseId: toWarehouse,
            transferDate: formattedTransferDate,
            status: status,
            totalAmount: calculateTotalAmount(),
            paymentMode,
            transportCharge: parseFloat(transportCharge),
            packingCharge: parseFloat(packingCharge),
            createdAt: new Date().toISOString(),
            modifiedAt: new Date().toISOString(),
            createdBy: role,
            modifiedBy: role,
            transferItems: [
                {
                    products: selectedProducts.map(product => ({
                        productId: product.productId,
                        quantity: product.quantity,
                        unit: product.unitSymbol, // Assuming unitSymbol corresponds to "unit" in your example
                        price: product.price,
                    })),
                    createdBy: role,
                    modifiedBy: role,
                }
            ]
        };
    
        try {
            const response = await fetch('https://pos.farm2bag.com/api/v1/transfers/createWithItems', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newTransferData),
            });
    
            if (response.ok) {
                const result = await response.json();
                console.log("Response is OK, triggering toast");
                transfer();
                toast.success('Transfer created successfully!', {
                    position: "top-right",
                    autoClose:300,
                    style: {
                        fontSize: '16px',
                        padding: '10px 20px',
                        borderRadius: '4px', 
                        marginTop: '100px'
                    },
                    onClose: () => {
                        setTimeout(() => {
                            setTransferData({
                                ...newTransferData,
                                ...invoiceData,
                                invoice: result.data.invoice,
                                transportCharge,    
                                packingCharge,
                                totalProductAmount: calculateTotalProductAmount(),
                            });
            
                            // Delay opening the popup to ensure the toast is visible
                            setTimeout(() => {
                                setShowInvoicePopup(true);
                                setIsProcessing(false); // Re-enable the button after the process is complete
                            }, 100); // Adjust delay as needed
                        });
                    }
                });            
            }
            else {
                const result = await response.json();
                toast.error(`Error creating transfer: ${result.message}`);
                setIsProcessing(false); // Re-enable the button if the request fails
            }
        } catch (error) {
            toast.error(`Error creating transfer: ${error.message}`);
            setIsProcessing(false); // Re-enable the button if an error occurs
        }
    };
    
    

    const handleCloseCreatePopup = () => {
        createPop();
    }
    return (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-md w-96">
                <h2 className="text-xl font-semibold mb-4">Confirm Transfer</h2>
                <div className="mb-4">
                    <label className="text-green-700 font-semibold text-m mb-2">Total Product Amount:</label>
                    <span className='text-m font-semibold'> ₹{calculateTotalProductAmount()}</span>
                </div>
                <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                        <label className="block text-gray-700 font-medium mb-2">Transfer Date:</label>
                        <input
                            type="date"
                            value={transferDate}
                            min={today} // Set the minimum date to today
                            onChange={(e) => setTransferDate(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 font-medium mb-2">Payment Mode:</label>
                        <select
                            value={paymentMode}
                            onChange={(e) => setPaymentMode(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-md"
                        >
                            <option value="Cash">Cash</option>
                            <option value="Credit">Credit</option>
                            <option value="Debit">Debit</option>
                        </select>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                        <label className="block text-gray-700 font-medium mb-2">Transport Charge:</label>
                        <input
                            type="number"
                            value={transportCharge}
                            min="0"
                            onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                if (value > 0) {
                                    setTransportCharge(value);
                                } else {
                                    setTransportCharge(''); // Reset to empty if the value is not valid
                                }
                            }}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e') {
                                    e.preventDefault();
                                }
                            }}
                            className="w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 font-medium mb-2">Packing Charge:</label>
                        <input
                            type="number"
                            value={packingCharge}
                            min="0"
                            onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                if (value > 0) {
                                    setPackingCharge(value);
                                } else {
                                    setPackingCharge(''); // Reset to empty if the value is not valid
                                }
                            }}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e') {
                                    e.preventDefault();
                                }
                            }}
                            className="w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-2">Status:</label>
                    <select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="Pending">Pending</option>
                        <option value="Paid">Paid</option>
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-2">Total Amount:</label>
                    <div className="w-full p-2 border border-gray-300 rounded-md">
                        ₹{calculateTotalAmount()}
                    </div>
                </div>
                <div className="flex justify-end">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 mr-2 bg-red-500 text-white rounded-md"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleConfirmTransfer}
                        className="px-4 py-2 bg-green-500 text-white rounded-md"
                    >
                        Confirm Transfer
                    </button>
                </div>
            </div>
            {transferData && (
                <TransferInvoicePopup
                    isOpen={true}
                    onClose={onClose}
                    transferData={transferData}
                    FirstPop={handleCloseCreatePopup}
                />
            )}
            <ToastContainer />
        </div>
    );
};

export default PaymentPopup;
