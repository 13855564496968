import React from 'react';
import { toast } from 'react-toastify';

const DeleteExpensePopup = ({ expense, onClose, onDelete }) => {

    const handleDeleteExpense = async () => {
        try {
            const token = localStorage.getItem('token');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const id = expense.id;

            const response = await fetch(`https://pos.farm2bag.com/api/v1/expense/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                throw new Error('Failed to delete expense');
            }

            console.log('Expense deleted successfully');
            onClose();
            onDelete();
            toast.success('Expense deleted successfully');

        } catch (error) {
            console.error('Error deleting expense:', error);
            toast.error('Error deleting expense');
        }
    };

    return (
        <div className="fixed inset-0 flex items-center bg-gray-500 bg-opacity-50 justify-center z-50">
            <div className="bg-white rounded-lg p-6 shadow-lg w-full md:w-3/4 lg:w-1/2">
                <h2 className="text-xl font-bold mb-4">Delete Expense</h2>
                <p>Are you sure you want to delete this expense <span className='text-red-600 font-semibold'>{expense.expenseCategory.expenseCategoryName} </span>?</p>
                <div className="flex justify-between mt-4">
                    <button type="button" onClick={onClose} className="mr-4 px-4 py-2 bg-gray-500 text-white rounded-lg float-left">Cancel</button>
                    <button type="button" onClick={handleDeleteExpense} className="px-4 py-2 bg-red-500 text-white rounded-lg">Delete</button>
                </div>
            </div>
        </div>
    );
};

export default DeleteExpensePopup;
