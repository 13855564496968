import { useRef, useEffect } from 'react';

const Dropdown = ({ products, onSelect, highlightedIndex }) => {
  const dropdownRef = useRef(null);
  const itemRefs = useRef([]);

  useEffect(() => {
    if (highlightedIndex >= 0 && itemRefs.current[highlightedIndex]) {
      const item = itemRefs.current[highlightedIndex];
      const dropdown = dropdownRef.current;

      // Scroll the dropdown if the highlighted item is out of view
      const itemTop = item.offsetTop;
      const itemBottom = itemTop + item.clientHeight;
      const dropdownScrollTop = dropdown.scrollTop;
      const dropdownHeight = dropdown.clientHeight;

      if (itemTop < dropdownScrollTop) {
        dropdown.scrollTop = itemTop;
      } else if (itemBottom > dropdownScrollTop + dropdownHeight) {
        dropdown.scrollTop = itemBottom - dropdownHeight;
      }
    }
  }, [highlightedIndex]);

  return (

    <div
      ref={dropdownRef}
      className="absolute mt-2 bg-white border border-gray-200 rounded-lg shadow-lg max-h-60 overflow-auto" style={{width:"450px"}}>
      {products.length === 0 ? (
        <div className="p-2 text-gray-600">No results</div>
      ) : (
        products.map((product, index) => (
          <div
            key={product.productsCode}
            ref={el => (itemRefs.current[index] = el)}
            className={`p-2 hover:bg-green-500 cursor-pointer ${index === highlightedIndex ? 'bg-green-500' : index % 2 === 0 ? 'bg-blue-100' : 'bg-yellow-100'
              }`}
            onClick={() => onSelect(product)}
          >
            <div className="text-left font-semibold mr-8">{product.name}     <span style={{fontSize:"15px"}} className='text-gray-600'>( {product.productsCode} )</span> </div>
          </div>
        ))
      )}
    </div>

  );
};

export default Dropdown;
