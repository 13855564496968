import React from 'react';
import PropTypes from 'prop-types';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import f2b_logo from "../Assets/login/f2bimage.png"; // Adjust path to your logo

const TransferViewPopup = ({ transfer, onClose }) => {

    // Calculate total product amount
    const totalProductAmount = transfer.transferItems.reduce((total, item) => {
        return total + item.products.reduce((subtotal, product) => {
            return subtotal + (product.quantity * product.price);
        }, 0);
    }, 0);

    const handleDownloadPDF = () => {
        const input = document.getElementById('transfer-invoice');
    
        // Preload the logo image
        const logoImg = new Image();
        logoImg.src = f2b_logo;
        logoImg.onload = () => {
            html2canvas(input, { scale: 2 }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'pt', 'a4');
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    
                // Add logo and address section to PDF
                const marginLeft = 40; // Left margin for logo and address
                pdf.addImage(logoImg, 'PNG', marginLeft, 40, 80, 40); // Left aligned logo
                pdf.setFont('Helvetica', 'bold');
                pdf.setFontSize(12);
                pdf.text('Farm2bag', marginLeft, 100); // Left aligned company name
                pdf.setFontSize(10);
                pdf.text('5/396, Rajeev Gandhi Salai, OMR Thoraipakkam, Chennai, Tamil Nadu 600097', marginLeft, 115, { maxWidth: 230 }); // Left aligned address with maximum width
                pdf.text('PH-095000 37221', marginLeft, 140); // Left aligned phone number
    
                // Add transfer details section as a table
                pdf.setFont('Helvetica', 'normal');
                pdf.setFontSize(14);
                pdf.text('Transfer Details', marginLeft, 160); // Left aligned section heading
                pdf.autoTable({
                    startY: 180,
                    head: [['Detail', 'Value']],
                    body: [
                        ['Invoice', transfer.invoice],
                        ['From Warehouse', transfer.fromWarehouseName],
                        ['To Warehouse', transfer.toWarehouseName],
                        ['Transfer Date', transfer.transferDate],
                        ['Status', transfer.status],
                        ['Total Product Amount', totalProductAmount.toFixed(2)], // Display total product amount here
                        ['Payment Mode', transfer.paymentMode],
                        ['Transport Charge', transfer.transportCharge],
                        ['Packing Charge', transfer.packingCharge],
                        ['Total Amount', transfer.totalAmount],
                    ],
                    margin: { top: 10, left: marginLeft, bottom: 10, right: 40 },
                    styles: { fontSize: 10, halign: 'left' },
                    theme: 'grid',
                });
    
                // Add transfer items table
                const startY = pdf.autoTable.previous.finalY + 20; // Adjust start y-coordinate for table
                const tableHeaders = ['Product Name', 'Quantity', 'Unit', 'Price'];
                const tableData = transfer.transferItems.flatMap((item) =>
                    item.products.map((product) => [
                        product.productName,
                        product.quantity,
                        product.unit,
                        product.price,
                    ])
                );
                pdf.autoTable({
                    startY,
                    head: [tableHeaders],
                    body: tableData,
                    margin: { top: 10, left: marginLeft, bottom: 10, right: 40 },
                    styles: { fontSize: 10, halign: 'left' },
                    theme: 'grid',
                    columnStyles: { 0: { cellWidth: 'auto' } },
                });
    
                pdf.save('transfer-invoice.pdf');
            }).catch((error) => {
                console.error('Error generating PDF:', error);
            });
        };
    };
    
    
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg relative w-2/5 h-4/5 overflow-auto">
                <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </button>
                <div className="text-center mb-4">
                    <img src={f2b_logo} alt="Company Logo" className="mx-auto h-12" />
                    <h2 className="text-2xl font-bold mt-2">{transfer.warehouse}</h2>
                    <p className="text-sm text-gray-600">Farm2bag, 5/396, Rajeev Gandhi Salai, OMR Thoraipakkam, Chennai, Tamil Nadu 600097</p>
                    <p className="text-sm text-gray-800">PH-095000 37221</p>
                </div>
                <div id="transfer-invoice" className="p-4">
                    <div className="mt-4">
                        <h3 className="text-xl font-semibold mb-3">Transfer Details</h3>
                        <table className="border-collapse w-full">
                            <tbody>
                                {[
                                    ['Invoice', transfer.invoice],
                                    ['From Warehouse', transfer.fromWarehouseName],
                                    ['To Warehouse', transfer.toWarehouseName],
                                    ['Transfer Date', transfer.transferDate],
                                    ['Status', transfer.status],
                                    ['Total Product Amount', totalProductAmount.toFixed(2)], // Display total product amount here
                                    ['Payment Mode', transfer.paymentMode],
                                    ['Transport Charge', transfer.transportCharge],
                                    ['Packing Charge', transfer.packingCharge],
                                    ['Total Amount', transfer.totalAmount],
                                ].map((item, index) => (
                                    <tr key={index} className="border-b border-gray-200">
                                        <td className="border border-gray-300 px-4 py-2 font-semibold">{item[0]}</td>
                                        <td className="border border-gray-300 px-4 py-2">{item[1]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-4">
                        <h3 className="text-xl font-semibold mb-3">Transfer Items</h3>
                        <table className="border-collapse w-full">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="border border-gray-300 px-4 py-2">Product Name</th>
                                    <th className="border border-gray-300 px-4 py-2">Quantity</th>
                                    <th className="border border-gray-300 px-4 py-2">Unit</th>
                                    <th className="border border-gray-300 px-4 py-2">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transfer.transferItems.flatMap((item) =>
                                    item.products.map((product, index) => (
                                        <tr key={index} className="border-b border-gray-200">
                                            <td className="border border-gray-300 px-4 py-2">{product.productName}</td>
                                            <td className="border border-gray-300 px-4 py-2">{product.quantity}</td>
                                            <td className="border border-gray-300 px-4 py-2">{product.unit}</td>
                                            <td className="border border-gray-300 px-4 py-2">{product.price}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mt-4 flex justify-end">
                    <button onClick={handleDownloadPDF} className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:outline-none">Download PDF</button>
                </div>
            </div>
        </div>
    );
};

TransferViewPopup.propTypes = {
    transfer: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default TransferViewPopup;
