import React, { useEffect, useState, useCallback, useRef } from "react";
import debounce from 'lodash.debounce'; // You can install lodash.debounce via npm
import Addcustomer from "../Assets/Billingpage/Addcustomer.png";
import Box1 from "../Assets/Billingpage/menu.png";
import Box2 from "../Assets/Billingpage/house.png";
import Box3 from "../Assets/Billingpage/arrows.png";
import Box4 from "../Assets/Billingpage/calculator.png";
import Box5 from "../Assets/Billingpage/performance.png";
import { FaRegEdit } from "react-icons/fa";
import { IoMdTrash } from "react-icons/io";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import InvoicePopup from "../components/InvoicePopup";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Calculator from "../components/Calculator"; // Update the path to the actual path of your Calculator component
import "../scss/Billing.scss";
import { Oval } from 'react-loader-spinner';
import Dropdown from "../components/Dropdown";
import CategoryDropdown from "../components/CategoryDropdown";
const SCAN_COMPLETE_TIMEOUT = 100; // Time in milliseconds to wait before considering input as complete

const Billing = () => {
  const [warehouses, setWarehouses] = useState([]);
  const [selectedCustomerName, setSelectedCustomerName] = useState("");
  const [selectedWarehouseName, setSelectedWarehouseName] = useState("");
  const [customers, setCustomers] = useState([]);
  const [searchQuery1, setSearchQuery1] = useState("");
  const [invoiceData, setInvoiceData] = useState(null);
  const [isInvoicePopupOpen, setIsInvoicePopupOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [newCustomerName, setNewCustomerName] = useState("");
  const [nameError, setNameError] = useState("");
  const [newPinCode, setNewPinCode] = useState("");
  const [pinError, setPinError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isEditPopupOpen, setEditPopupOpen] = useState(false);
  const [updateStock, setUpdateStock] = useState("");
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [isPaymentPopupOpen, setPaymentPopupOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [products, setProducts] = useState([]);
  const [loadingProductsLocal, setLoadingProductsLocal] = useState(false);
  const [errorProducts, setErrorProducts] = useState(null);
  const [receivedAmount, setReceivedAmount] = useState("");
  const [paymentMode, setPaymentMode] = useState("card");
  const [paymentStatus, setPaymentStatus] = useState("Paid");
  const [brands, setBrands] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isHoldPopupRefOpen, setIsHoldPopupRefOpen] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState("");
  const [holdProducts, setHoldProducts] = useState([]);
  const [isHoldPopUpOpen, setHoldPopUpScreen] = useState(false);
  const [holdData, setHoldData] = useState([]);
  const [holdCount, setHoldCount] = useState(0);

  //  calc
  const [showCalculator, setShowCalculator] = useState(false);

  // Register-------------------------------------
  const [data, setData] = useState(null);
  const [cashInHand, setCashInHand] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showRegisterDetailsModal, setShowRegisterDetailsModal] =
    useState(false);
  const [showCloseRegisterModal, setShowCloseRegisterModal] = useState(false);
  const [registerDetails, setRegisterDetails] = useState(null);
  const [inHandCash, setInHandCash] = useState("");
  const [note, setNote] = useState("");

  const [showCashInHandPopup, setShowCashInHandPopup] = useState(false);

  // const [isMyPopupOpen, setIsMyPopupOpen] = useState(true);
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });
  const [invalidRows, setInvalidRows] = useState(new Set());

  const [isBox5PopupOpen, setIsBox5PopupOpen] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  const [customerNumber, setCustomerNumber] = useState("");



  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);


  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const [discount, setDiscount] = useState();
  const [deliveryCharge, setDeliveryCharge] = useState();

  const [selectedCount, setSelectedCount] = useState(0);

  const [loading, setLoading] = useState(false);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [categoryDropdownVisible, setCategoryDropdownVisible] = useState(false);

  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const [showDropdown, setShowDropdown] = useState(true); // Dropdown visibility state
  const [activeIndex, setActiveIndex] = useState(-1); // To keep track of the highlighted index
  const inputTimeoutRef = useRef(null);

  const [gst, setGst] = useState();

  useEffect(() => {
    setSelectedCount(selectedProducts.length);
  }, [selectedProducts]);


  useEffect(() => {
    if (showCloseRegisterModal) {
      setCashInHand(0);
    }
  }, [showCloseRegisterModal]);


  useEffect(() => {
    fetchHoldData();
  }, []);
  const handleBox1Click = () => {
    setHoldPopUpScreen(true);
  };

  // Fetch warehouses
  useEffect(() => {
    const fetchWarehouses = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch("https://pos.farm2bag.com/api/v1/warehouse", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setWarehouses(result.data);

        // Set the first warehouse as the default selected value and trigger change
        if (result.data && result.data.length > 0) {
          const firstWarehouseId = result.data[0].id;
          setSelectedWarehouse(firstWarehouseId);
          setSelectedWarehouseName(result.data[0].name);

          // Call handleWarehouseChange with a simulated event object
          handleWarehouseChange({ target: { value: firstWarehouseId } });
        }
      } catch (error) {
        console.error("Error fetching warehouses:", error);
      }
    };



    const fetchCustomers = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch("https://pos.farm2bag.com/api/v1/customers", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setCustomers(result.data);
      } catch (error) {
        console.error("Error fetching customers:", error);
      }
    };

    fetchWarehouses();
    fetchCustomers();
  }, []);

  // Fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch("https://pos.farm2bag.com/api/v1/categories", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setCategories(result.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  // Fetch brands
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch("https://pos.farm2bag.com/api/v1/brand", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setBrands(result.data);
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    fetchBrands();
  }, []);

  // Handle product selection
  const handleProductSelect = (product) => {
    if (product.totalQuantity < 0.01) {
      toast.warning("This product is Not in Stock", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: "#d8fc0d",
          color: "black",
        },
      });
      return;
    }

    const existingProductIndex = selectedProducts.findIndex(
      (p) => p.id === product.id
    );
    if (existingProductIndex !== -1) {
      const updatedProducts = [...selectedProducts];
      updatedProducts[existingProductIndex].subtotal =
        updatedProducts[existingProductIndex].quantity *
        updatedProducts[existingProductIndex].price;
      setSelectedProducts(updatedProducts);
    } else {
      const selectedProduct = {
        ...product,
        quantity: 1,
        subtotal: product.price,
      };
      setSelectedProducts([...selectedProducts, selectedProduct]);

    }

  };

  const fetchStock = async () => {
    if (!selectedWarehouse) return;

    try {
      setLoadingProductsLocal(true);
      const token = localStorage.getItem("token");
      const response = await fetch(`https://pos.farm2bag.com/api/v1/stocks`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      const warehouseStock = result.data.find(stock => stock.wareHouseId === selectedWarehouse);

      if (!warehouseStock) {
        throw new Error("Warehouse stock not found");
      }

      const mergedProducts = warehouseStock.products.map(product => ({
        id: product.productId,
        name: product.name,
        productsCode: product.productsCode,
        image: product.image,
        categoryId: product.productCategoryId,
        brandId: product.brandId,
        totalQuantity: product.quantity,
        quantity: product.quantity,
        unit: product.unitSymbol,
        unitId: product.unitId,
        price: product.price,
        subtotal: product.quantity * product.price,
      }));

      setProducts(mergedProducts);
    } catch (error) {
      console.error("Error fetching stock:", error);
      // Optionally display an error message to the user
    } finally {
      setLoadingProductsLocal(false);
    }
  };

  useEffect(() => {
    fetchStock();
  }, [selectedWarehouse, invoiceData]);



  const handleKeyDown = (e) => {
    if (dropdownVisible && filteredProducts.length > 0) {
      if (e.key === 'ArrowDown') {
        // Move down in the dropdown
        setHighlightedIndex(prevIndex =>
          prevIndex < filteredProducts.length - 1 ? prevIndex + 1 : 0
        );
      } else if (e.key === 'ArrowUp') {
        // Move up in the dropdown
        setHighlightedIndex(prevIndex =>
          prevIndex > 0 ? prevIndex - 1 : filteredProducts.length - 1
        );
      } else if (e.key === 'Enter') {
        // Select the highlighted item
        if (highlightedIndex >= 0) {
          const selectedProduct = filteredProducts[highlightedIndex];
          handleSelectProductFromDropdown(selectedProduct);
        }
      }
    }
  };




  const handleSearchInputChange = (e) => {
    const query = e.target.value;

    // Clear the previous timeout if it exists
    if (inputTimeoutRef.current) {
      clearTimeout(inputTimeoutRef.current);
    }

    // Set the new search query
    setSearchQuery(query);

    // Set a timeout to process the input after the user has stopped typing
    inputTimeoutRef.current = setTimeout(() => {
      // Check if the input matches a product code exactly
      const matchingProduct = products.find(product => product.productsCode === query);

      if (matchingProduct) {
        handleExactMatch(matchingProduct);
        setDropdownVisible(false);
      } else if (query) {
        // If not an exact match, show partial matches in the dropdown
        const lowercasedQuery = query.toLowerCase();
        const filtered = products.filter(product =>
          product.name?.toLowerCase().includes(lowercasedQuery) ||
          product.productsCode?.toLowerCase().includes(lowercasedQuery)
        );
        setFilteredProducts(filtered);
        setDropdownVisible(filtered.length > 0);
        setHighlightedIndex(-1);
        setShowDropdown(false);


      } else {
        setFilteredProducts([]);
        setDropdownVisible(false);
        setShowDropdown(false);

      }
    }, SCAN_COMPLETE_TIMEOUT);
  };
  const handleExactMatch = (matchingProduct) => {
    if (matchingProduct.totalQuantity > 0) {
      const existingProductIndex = selectedProducts.findIndex(p => p.productsCode === matchingProduct.productsCode);

      if (existingProductIndex !== -1) {
        setSelectedProducts(prevSelected => {
          const updatedProducts = [...prevSelected];
          const currentQuantity = updatedProducts[existingProductIndex].quantity;

          if (currentQuantity < matchingProduct.totalQuantity) {
            updatedProducts[existingProductIndex].quantity += 1;
          } else {
            toast.error('Cannot increase quantity beyond available stock');
          }

          return updatedProducts;
        });
      } else {
        const productWithDefaultQuantity = { ...matchingProduct, quantity: 1 };
        setSelectedProducts(prevSelected => [...prevSelected, productWithDefaultQuantity]);
      }

      setSearchQuery('');
      setDropdownVisible(false);
    } else {
      toast.error('Stock quantity not available');
    }
  };

  const handleSelectProductFromDropdown = (product) => {
    setSearchQuery(product.productsCode); // Set input field to the selected product code
    setDropdownVisible(false); // Hide the dropdown
    // setCategoryDropdownVisible(false);
    handleSearchInputChange({ target: { value: product.productsCode } }); // Simulate a search input change
  };



  const handleSearchChange = useCallback(debounce((query) => {
    const lowercasedQuery = query.toLowerCase();
    const filtered = products.filter(product =>
    (product.name?.toLowerCase().includes(lowercasedQuery) ||
      product.productsCode?.toLowerCase().includes(lowercasedQuery))
    );
    setFilteredProducts(filtered);
    setOffset(0); // Reset offset when search query changes
    setHasMore(true); // Reset hasMore when search query changes
  }, 300), [products]);

  // Effect for handling search query change
  useEffect(() => {
    handleSearchChange(searchQuery);
  }, [searchQuery, handleSearchChange]);


  useEffect(() => {
    const query = searchQuery.toLowerCase();
    const filtered = products.filter(product => {
      return (
        (selectedCategoryId === "" || product.categoryId === selectedCategoryId) &&
        (product.name?.toLowerCase().includes(query) ||
          product.productsCode?.toLowerCase().includes(query))
      );
    });
    setFilteredProducts(filtered);
  }, [searchQuery, selectedCategoryId, products]);

  const handleCategorySelect = (categoryId) => {
    if (categoryId === "") {
      // Hide the dropdown if no category is selected
      setCategoryDropdownVisible(false);
      setSelectedCategoryId(categoryId);
      setFilteredProducts([]);
    } else {
      setSelectedCategoryId(categoryId);
      setCategoryDropdownVisible(true);
      setOffset(0); // Reset offset when category changes
      setHasMore(true); // Reset hasMore when category changes
    }
  };

  const handleArrowKeyNavigation = (direction) => {
    setCategoryDropdownVisible(true);
    setHighlightedIndex(prevIndex => {
      const newIndex = direction === 'down' ? prevIndex + 1 : prevIndex - 1;
      if (newIndex < 0) return 0;
      if (newIndex >= filteredProducts.length) return filteredProducts.length - 1;
      return newIndex;
    });
  };

  const dropDownClose = () => {
    setCategoryDropdownVisible(false);
  }

  const handleEditClick = (product) => {
    setSelectedProductId(product.id);
    setUpdateStock(product.price);
    setEditPopupOpen(true);
  };

  const fetchPinCode = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDrxIN5pCE5TTrjdLWMq7VlMCxsj8FGD6k`
      );
      const data = await response.json();

      if (data.results && data.results.length > 0) {
        const addressComponents = data.results[0].address_components;

        // Log the address components for debugging
        console.log("Address Components:", addressComponents);

        const pinCodeComponent = addressComponents.find((component) =>
          component.types.includes("postal_code")
        );

        if (pinCodeComponent) {
          return pinCodeComponent.long_name;
        } else {
          console.warn("Postal code not found in address components");
          // Handle the case where postal code is not available
          return ""; // Or set a default value or prompt the user for input
        }
      } else {
        console.warn("No results found for the given coordinates");
        return ""; // Or handle this case appropriately in your application
      }
    } catch (error) {
      console.error("Error fetching pin code:", error);
      return ""; // Handle the error case
    }
  };


  const handleCreateCustomer = async (pinCode) => {
    const pincodeToUse = pinCode.trim() === "" ? "0" : pinCode.trim();

    try {
      const token = localStorage.getItem("token");
      const role = localStorage.getItem("role");

      if (!token) {
        console.error("No token found in local storage");
        return;
      }

      const response = await fetch("https://pos.farm2bag.com/api/v1/customers", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: newCustomerName,
          pincode: pincodeToUse,
          contactNumber: phoneNumber,
          createdBy: role,
        }),
      });
      setPhoneNumber(phoneNumber);
      if (!response.ok) {
        throw new Error("Failed to create customer");
      }

      const data = await response.json();

      if (data.status === 201) {
        setIsPopupOpen(false);
        setNewCustomerName("");
        setPhoneNumber();
        setSearchQuery1(data.data.name);
        setCustomers((prevCustomers) => [...prevCustomers, data.data]);
        setSelectedCustomer(data.data.id);
        setShowDropdown(false);
        toast.success("Customer created successfully!");
      } else {
        throw new Error(data.message || "Failed to create customer");
      }
    } catch (error) {
      console.error("Error creating customer:", error);
      toast.error("Error creating customer");
    }
  };
  const handleGetLocation = async (type) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        const pinCode = await fetchPinCode(latitude, longitude);
        console.log("PinCode", pinCode);

        if (type === "new") {
          setNewPinCode(pinCode); // You can keep this if you still need to store the pin code in state
          handleCreateCustomer(pinCode); // Pass the pin code to the customer creation function
        }
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };



  const handleUpdateStock = async () => {
    try {
      if (updateStock === "" || updateStock === null) {
        toast.error("Please Enter The Price Value", { autoClose: 1500 });
        return;
      }

      if (updateStock < 0) {
        toast.error("Do not enter the negative value", { autoClose: 1000 });
        return;
      }

      const warehouseId = selectedWarehouse;
      const productId = selectedProductId;

      const token = localStorage.getItem("token");

      // Making PUT request to update the product price
      const response = await fetch(
        `https://pos.farm2bag.com/api/v1/stocks/price/${warehouseId}/${productId}?price=${updateStock}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({}),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Parsing the response JSON to get updated product details
      const updatedProduct = await response.json();

      toast.success("Price💰 Updated successfully");

      // Update products state
      setProducts((prevProducts) => {
        const newProducts = prevProducts.map((product) =>
          product.id === selectedProductId
            ? {
              ...product,
              price: updateStock,
              subtotal: product.quantity * updateStock,
            }
            : product
        );

        return newProducts;
      });

      // Update selectedProducts state
      setSelectedProducts((prevSelectedProducts) => {
        const newSelectedProducts = prevSelectedProducts.map((product) =>
          product.id === selectedProductId
            ? {
              ...product,
              price: updateStock,
              subtotal: product.quantity * updateStock,
            }
            : product
        );

        return newSelectedProducts;
      });

      setEditPopupOpen(false);
    } catch (error) {
      console.error("Error updating stock:", error);
    }
  };

  // const calculateOverallSubTotal = () => {
  //   return selectedProducts.reduce((sum, product) => sum + product.price * product.quantity, 0);
  // };

  // const overallSubTotal = calculateOverallSubTotal();
  // const parsedDiscount = (parseFloat(discount) || 0) / 100;

  // const parsedDeliveryCharge = parseFloat(deliveryCharge) || 0;

  // const finalTotal = overallSubTotal - overallSubTotal * parsedDiscount + parsedDeliveryCharge;

  // const changeReturn = Math.max(
  //   (receivedAmount !== "" ? Number(receivedAmount) : 0) - finalTotal,
  //   0
  // );

  const calculateOverallSubTotal = () => {
    return selectedProducts.reduce((sum, product) => sum + product.price * product.quantity, 0);
  };

  const overallSubTotal = calculateOverallSubTotal();
  const parsedDiscount = (parseFloat(discount) || 0) / 100;
  const parsedGst = (parseFloat(gst) || 0) / 100;
  const parsedDeliveryCharge = parseFloat(deliveryCharge) || 0;

  // Calculate subtotal after applying discount
  const subtotalAfterDiscount = overallSubTotal - overallSubTotal * parsedDiscount;

  // Calculate GST based on the discounted subtotal
  const gstAmount = subtotalAfterDiscount * parsedGst;

  // Calculate final total by adding delivery charge to the subtotal and GST
  const finalTotal = subtotalAfterDiscount + gstAmount + parsedDeliveryCharge;

  // Calculate the change to return
  const changeReturn = Math.max(
    (receivedAmount !== "" ? Number(receivedAmount) : 0) - finalTotal,
    0
  );


  const handlePayBillClick = async () => {
    if (!selectedCustomer.trim()) {
      toast.error("Please select a customer");
      return;
    }

    // Set loading to true when the API call starts
    setLoading(true);

    const role = localStorage.getItem("role");

    const ProductStatus = "Purchase";

    // Extract product names
    const productNames = selectedProducts.map((product) => product.name);

    const billingData = {
      customerId: selectedCustomer,
      warehouseId: selectedWarehouse,
      gst: gst,
      shippingCharges: deliveryCharge,
      discount: discount,
      totalPrice: finalTotal.toFixed(2),
      paymentMode,
      status: paymentStatus,
      createdBy: role,
      billingItems: [
        {
          createdBy: role,
          product: selectedProducts.map((product) => ({
            productId: product.id,
            productName: product.name,
            unit: product.unit,
            unitId: product.unitId,
            quantity: product.quantity,
            totalPrice: parseFloat((product.price * product.quantity).toFixed(2)),
            productStatus: ProductStatus,
          })),
        },
      ],
      productNames: productNames,
    };

    try {
      const token = localStorage.getItem("token");

      // API call for billing
      const billingResponse = await fetch(
        "https://pos.farm2bag.com/api/v1/billing",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(billingData),
        }
      );

      if (!billingResponse.ok) {
        throw new Error(`HTTP error! status: ${billingResponse.status}`);
      }

      const billingResult = await billingResponse.json();

      const invoiceData = {
        warehouseName: selectedWarehouseName,
        customerName: selectedCustomerName || searchQuery1,
        contactNumber: customerNumber || phoneNumber,
        productDetails: selectedProducts,
        overallSubTotal: overallSubTotal,
        deliveryCharge: deliveryCharge,
        discount: discount,
        totalPrice: finalTotal,
        gst: gst,
        gstAmount: gstAmount,
        paymentMode: paymentMode,
        responseData: billingResult,
      };

      setInvoiceData(invoiceData);
      setIsInvoicePopupOpen(true);
      setPaymentPopupOpen(false);
      toast.success("Bill success");

      // Clear selected products and received amount
      setSelectedProducts([]);
      setReceivedAmount("");

      setSearchQuery1("");
      setSelectedCustomer("");
      setSelectedCustomerName("");
      setPaymentMode("card");
      setDeliveryCharge("");
      setDiscount("");
      setGst("");

      // New API call for recording sale

      // Ensure selectedWarehouse is not null
      if (!selectedWarehouse) {
        console.error("Warehouse is not selected");
        return;
      }

      // Only post the saleResponse if billingData.status is "Paid"
      if (billingData.status === "Paid") {
        const saleResponse = await axios.post(
          `https://pos.farm2bag.com/api/v1/registers/record-sale?paymentMode=${paymentMode}&saleAmount=${finalTotal}&warehouseId=${selectedWarehouse}`,
          {}, // Empty object since data is passed as query parameters
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setRegisterDetails(saleResponse.data);
        // setShowRegisterDetailsModal(true);
      }
    } catch (error) {
      console.error("Error during billing or sale recording:", error);
      toast.error("Error during billing or sale recording");
    } finally {
      // Reset loading to false when the API call is done
      setLoading(false);
    }
  };


  const handleQuantityChange = (index, value) => {
    setSelectedProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      const actualIndex = prevProducts.length - 1 - index;

      // Allow zero as the first character
      const newValue = parseFloat(value);
      const isStartingWithZero = value.startsWith("0") && value.length > 1 && !value.startsWith("0.");

      if (isNaN(newValue) || newValue < 0.001 || isStartingWithZero) {
        // Set error message if the value is below 0.1 or starts with zero but isn't a valid decimal like 0.1
        setErrorMessage({
          name: updatedProducts[actualIndex].name,
          message: "Quantity must be at least 0.001",
        });
        updatedProducts[actualIndex] = {
          ...updatedProducts[actualIndex],
          quantity: value, // Allow user to see what they're typing
          subtotal: 0,
        };
        setInvalidRows((prevInvalidRows) => {
          const newInvalidRows = new Set(prevInvalidRows);
          newInvalidRows.add(actualIndex);
          return newInvalidRows;
        });
      } else if (newValue >= 0.001 && newValue <= updatedProducts[actualIndex].totalQuantity) {
        // Update product with valid quantity
        updatedProducts[actualIndex] = {
          ...updatedProducts[actualIndex],
          quantity: newValue,
          subtotal: newValue * updatedProducts[actualIndex].price,
        };
        setErrorMessage({ name: "", message: "" });
        setInvalidRows((prevInvalidRows) => {
          const newInvalidRows = new Set(prevInvalidRows);
          newInvalidRows.delete(actualIndex);
          return newInvalidRows;
        });
      } else {
        // Set error message if quantity is out of range
        setErrorMessage({
          name: updatedProducts[actualIndex].name,
          message: "Stocks not available",
        });
      }

      return updatedProducts;
    });
  };


  const incrementQuantity = (index) => {
    setSelectedProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      const actualIndex = prevProducts.length - 1 - index;

      const currentProduct = updatedProducts[actualIndex];
      const maxQuantity = parseFloat(currentProduct.totalQuantity); // Ensure it's a float
      const currentQuantity = parseFloat(currentProduct.quantity); // Ensure it's a float

      console.log("Current Product:", currentProduct);
      console.log(`Quantity: ${currentQuantity}, Total Quantity: ${maxQuantity}`);

      if (currentQuantity < maxQuantity) {
        updatedProducts[actualIndex] = {
          ...currentProduct,
          quantity: Math.min(currentQuantity + 1, maxQuantity), // Ensure quantity does not exceed maxQuantity
        };
        setErrorMessage({ name: "", message: "" });
        setInvalidRows((prevInvalidRows) => {
          const newInvalidRows = new Set(prevInvalidRows);
          newInvalidRows.delete(actualIndex);
          return newInvalidRows;
        });
      } else {
        setErrorMessage({
          name: currentProduct.name,
          message: "Stocks not available",
        });
        console.log(
          `Cannot increment: Current Quantity: ${currentQuantity}, Total Quantity: ${maxQuantity}`
        );
      }
      return updatedProducts;
    });
  };


  const decrementQuantity = (index) => {
    setSelectedProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      const actualIndex = prevProducts.length - 1 - index;
      const currentQuantity = updatedProducts[actualIndex].quantity;

      if (currentQuantity === 1) {
        toast.info("Minimum quantity value reached", {
          autoClose: 1200,
        });
        return updatedProducts; // Return early without updating state
      }

      updatedProducts[actualIndex] = {
        ...updatedProducts[actualIndex],
        quantity: Math.max(currentQuantity - 1, 1),
      };
      setErrorMessage({ name: "", message: "" });
      setInvalidRows((prevInvalidRows) => {
        const newInvalidRows = new Set(prevInvalidRows);
        newInvalidRows.delete(actualIndex);
        return newInvalidRows;
      });
      return updatedProducts;
    });
  };


  // New function to validate quantities
  const validateAndSetQuantities = () => {
    let isValid = true;
    setSelectedProducts((prevProducts) => {
      const updatedProducts = prevProducts.map((product, index) => {
        if (!product.quantity || product.quantity === "") {
          isValid = false;
          setInvalidRows((prevInvalidRows) => {
            const newInvalidRows = new Set(prevInvalidRows);
            newInvalidRows.add(index);
            return newInvalidRows;
          });
          return {
            ...product,
            quantity: "",
            subtotal: 0,
          };
        }
        return product;
      });
      return updatedProducts;
    });

    if (isValid) {
      setPaymentPopupOpen(true);
    }
  };

  const handleDelete = (productToDelete) => {
    const newProducts = selectedProducts.filter(
      (product) => product.id !== productToDelete.id
    );
    setSelectedProducts(newProducts);
  };

  const handleReset = () => {
    // Reset the selectedProducts state to an empty array
    setSelectedProducts([]);
    setSearchQuery1("");
    setSelectedCustomerName("");
    setInvalidRows(new Set());
    setDeliveryCharge("");
    setDiscount("");
  };
  const toggleFullScreen = () => {
    if (!isFullScreen) {
      // Enter fullscreen mode
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        /* Firefox */
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        /* IE/Edge */
        document.documentElement.msRequestFullscreen();
      }
    } else {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
    }
    // Toggle the fullscreen state
    setIsFullScreen(!isFullScreen);
  };
  const handleCancelHold = () => {
    // Close the hold popup
    setIsHoldPopupRefOpen(false);
    // Clear the reference number field
    setReferenceNumber("");
    setHoldPopUpScreen(false);
    // Clear the hold products
    setHoldProducts([]);
  };

  // Function to send hold data to the server
  const sendHoldData = async () => {
    try {
      if (!referenceNumber) {
        toast("Reference number cannot be empty❌❌");
        return; // Exit function if reference number is empty
      }
      const role = localStorage.getItem("role");
      const token = localStorage.getItem("token");

      const holdData = {
        referenceNo: referenceNumber,
        warehouseId: selectedWarehouse,
        products: selectedProducts.map((product) => ({
          productId: product.id,
          price: product.price,
          unit: product.unit,
          quantity: product.quantity,
          stockQuantity: product.totalQuantity,
          subtotal: product.quantity * product.price,
        })),
        createdBy: role,
      };

      // Check if reference number already exists
      const existingHold = holdDataExists(holdData.referenceNo);
      if (existingHold) {
        toast("Reference number already exists❌❌");
        return; // Exit function if hold with same reference number exists
      }

      const response = await fetch("https://pos.farm2bag.com/api/v1/holds", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(holdData),
      });

      if (response.ok) {
        const updatedHoldData = await fetchHoldData(selectedWarehouse);
        fetchHoldData();

        // Hold data saved successfully
        // Close the hold popup
        setIsHoldPopupRefOpen(false);
        // Clear the reference number field
        setReferenceNumber("");
        // Clear the hold products

        setHoldProducts([]);
        setSelectedProducts([]);
        setCashInHand(0);
        setHoldData(updatedHoldData);
        setHoldCount(updatedHoldData.length);

        toast("Data Holds Successfully");
      } else {
        // Handle error response
        const errorMessage = await response.text();
        console.error("Error saving hold data:", errorMessage);
      }
    } catch (error) {
      // Handle network or other errors
      console.error("Network error:", error.message);
    }
  };

  const holdDataExists = (referenceNo) => {
    // Assuming holdData is an array containing existing holds
    // Check if there's already a hold with the same reference number
    const existingHold = holdData.find(
      (hold) => hold.referenceNo === referenceNo
    );
    return existingHold;
  };

  const fetchHoldData = async (warehouseId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `https://pos.farm2bag.com/api/v1/holds/warehouse/${warehouseId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        if (responseData && responseData.data) {
          const dataLength = responseData.data.length;
          setHoldData(responseData.data);
          setHoldCount(dataLength > 0 ? dataLength : 0); // Set the count to zero if data length is 0
          return responseData.data;
        } else {
          console.error("No hold data found in response:", responseData);
          return [];
        }
      } else {
        console.error("Failed to fetch hold data:", response.statusText);
        return [];
      }
    } catch (error) {
      console.error("Error fetching hold data:", error);
      return [];
    }
  };


  const handleEditClickInHoldPopup = async (holdId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`https://pos.farm2bag.com/api/v1/holds/${holdId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData && responseData.data && responseData.data.data) {
          const productsWithDetails = responseData.data.data.products.map(product => ({
            id: product.productId,
            name: product.name,
            quantity: product.quantity,
            totalQuantity: product.stocksquantity, // Ensure this is set correctly
            price: product.price,
            unit: product.unitSymbol,
            totalCost: product.price * product.quantity, // Calculate total cost based on price and quantity
          }));
          console.log("Products in Hold:", productsWithDetails);
          setSelectedProducts(productsWithDetails);
        } else {
          console.error("No hold details found in response:", responseData);
        }
      } else {
        console.error("Failed to fetch hold details:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching hold details:", error);
    }
  };





  const handleRowClick = (holdId) => {
    handleEditClickInHoldPopup(holdId);
    setHoldPopUpScreen(false);
  };

  const handleHoldDelete = async (item) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `https://pos.farm2bag.com/api/v1/holds/${item.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseData = await response.json();
      const { message, status, data } = responseData;

      if (response.ok) {
        // Remove the deleted item from the state
        setHoldData((prevData) =>
          prevData.filter((holdItem) => holdItem.id !== item.id)
        );
        // Update hold count
        setHoldCount((prevCount) => prevCount - 1);
        toast("Hold Deleted Successfully");
      } else {
        console.error("Failed to delete hold data:", response.statusText);
      }
    } catch (error) {
      console.error("Delete Error:", error.message);
    }
  };

  // calculator----------------------------------

  const toggleCalculator = () => {
    setShowCalculator(!showCalculator);
  };

  const handleWarehouseChange = async (e) => {
    setFilteredProducts([]);
    setHoldData([]);
    setHoldCount(0);
    const selectedWarehouseId = e.target.value;
    const selectedWarehouse = warehouses.find(
      (warehouse) => warehouse.id === selectedWarehouseId
    );

    setSelectedWarehouse(selectedWarehouseId);
    setSelectedWarehouseName(selectedWarehouse?.name || "");

    // Call toggleDropdown with the selected warehouse ID
    await toggleDropdown(String(selectedWarehouseId));

    // Fetch hold data for the selected warehouse
    await fetchHoldData(selectedWarehouseId);
  };



  const openRegisterDetailsModal = () => {
    setShowRegisterDetailsModal(true);
  };

  const openCloseRegisterModal = () => {
    setShowRegisterDetailsModal(false);

    setShowCloseRegisterModal(true);
  };

  const closeModal = () => {
    setShowRegisterDetailsModal(false);
    setShowCloseRegisterModal(false);
  };

  const handleCloseRegister = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }

      // Ensure selectedWarehouse is not null
      if (!selectedWarehouse) {
        toast.error("Warehouse is not selected");
        return;
      }

      const url = `https://pos.farm2bag.com/api/v1/registers/close?warehouseId=${encodeURIComponent(
        selectedWarehouse
      )}&inHandCash=${encodeURIComponent(cashInHand)}&note=${encodeURIComponent(
        note
      )}`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        setRegisterDetails(null);
        toast.success("Register closed successfully!");
        setIsOpen(false);
        closeModal();
      } else {
        console.error("Error closing register:", response.statusText);
      }
    } catch (error) {
      console.error("Network error:", error.message);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleInputChanges = (e) => {
    const value = e.target.value;

    // Allow only digits and an empty value
    const sanitizedValue = value.replace(/[^0-9]/g, '');

    // Convert to a number and ensure it's non-negative
    const numericValue = parseFloat(sanitizedValue);

    // Update the state if the sanitized value is a valid number
    if (!isNaN(numericValue)) {
      setCashInHand(numericValue);
    } else if (sanitizedValue === '') {
      setCashInHand('');
    }
  };

  const handlePostRequest = async () => {
    try {
      const token = localStorage.getItem("token"); // Retrieve token from local storage
      const role = localStorage.getItem("role"); // Define the role

      // Ensure selectedWarehouse is not null
      if (!selectedWarehouse) {
        toast.error("Warehouse is not selected");
        return;
      }

      const response = await axios.post(
        "https://pos.farm2bag.com/api/v1/registers/start",
        {
          createdBy: role,
          amount: cashInHand,
          warehouseId: selectedWarehouse, // Include warehouseId in the request body
        }, // API URL
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the headers
            "Content-Type": "application/json",
          },
        }
      );

      setRegisterDetails(response.data);
      toast.success("Register Opened Successfully!");
      // setCashInHand(null);
      closePopup();
    } catch (error) {
      console.error("There was a problem with the axios request:", error);
    }
  };


  const toggleDropdown = async (warehouseId) => {
    try {
      const token = localStorage.getItem("token"); // Retrieve token from local storage or wherever it's stored

      // Ensure warehouseId is not null
      if (!warehouseId) {
        toast.error("Warehouse is not selected");
        return;
      }

      // Include the warehouseId as a query parameter
      const response = await axios.get(
        `https://pos.farm2bag.com/api/v1/registers/open-register-for-today?warehouseId=${warehouseId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the headers
            "Content-Type": "application/json",
          },
        }
      );

      // If a response is received with status 200
      if (response.status === 200) {
        const responseData = response.data;
        const inHandCash = responseData.data.inHandCash;
        setRegisterDetails(responseData.data);
        setCashInHand(inHandCash);
        console.log("check register", responseData.data);
        console.log("check setCashInHand", cashInHand);
        // setIsOpen(!isOpen); // Toggle dropdown
        setShowRegisterDetailsModal(true);
        // alert("Already in Open---Register");
      }
    } catch (error) {
      // If the error is a 404, set registerDetails to null and show the popup
      if (error.response && error.response.status === 404) {
        setRegisterDetails(null); // Set registerDetails to null if no data (404)
        setShowPopup(true); // Show popup if registerDetails is null
      } else {
        console.error("Error fetching open register:", error);
        // Handle other errors (e.g., show error message, retry logic)
      }
    }
  };

  const handlePaymentStatusChange = (event) => {
    setPaymentStatus(event.target.value);
  };

  // const closePops = () => {
  //   setIsMyPopupOpen(false);
  // };

  const handleBox5ButtonClick = () => {
    setIsBox5PopupOpen(true);
  };

  const handleBox5OkClick = () => {
    localStorage.setItem("activeNavItem", "Dashboard"); // Set activeNavItem to "Dashboard" in local storage
    navigate("/dashboard"); // Navigate to the dashboard page
  };
  const handleBox5CancelClick = () => {
    setIsBox5PopupOpen(false);
  };

  const filteredCustomers = customers.filter((customer) =>
    customer.contactNumber.includes(searchQuery1)
  );

  const handleKeyDownCustomer = (e) => {
    if (e.key === 'ArrowDown') {
      // Move down in the list
      setActiveIndex((prevIndex) =>
        prevIndex < filteredCustomers.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      // Move up in the list
      setActiveIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === 'Enter' && activeIndex >= 0) {
      // Select the currently highlighted item
      const selected = filteredCustomers[activeIndex];
      setSearchQuery1(selected.name);
      setSelectedCustomer(selected.id);
      setPhoneNumber(selected.contactNumber);
      setShowDropdown(false);
      setActiveIndex(-1);
    }
  };

  useEffect(() => {
    // Add event listener for keydown
    document.addEventListener('keydown', handleKeyDownCustomer);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDownCustomer);
    };
  }, [activeIndex, filteredCustomers]);



  return (
    <div className="bg-gray-100 pt-5 h-screen  ">
      <div className=" mx-auto h-screen w-full ">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center w-75 bg-gray-200 rounded-lg">
            <div className="flex items-center">
              <button className="flex items-center pl-1" onClick={() => setIsPopupOpen(true)}>
                <img
                  src={Addcustomer}
                  className="w-10 h-10 bg-green-500 rounded-lg"
                  alt="Addcustomer Icon"
                />
              </button>
            </div>
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="WALK - IN - CUSTOMER"
                className="border p-4 rounded-lg w-full font-semibold bg-gray-200 pr-10 focus:outline-none focus:border-blue-500"
                value={searchQuery1}
                onChange={(e) => {
                  setSearchQuery1(e.target.value);
                  setPhoneNumber(e.target.value);
                  setCustomerNumber(e.target.value);
                  setShowDropdown(true);
                  setActiveIndex(-1); // Reset active index when search input changes
                }}
              />

              {showDropdown && searchQuery1.length > 0 && (
                <div className="absolute z-10 mt-1 w-full font-semibold text-lg text-left bg-white rounded-lg shadow-lg">
                  {filteredCustomers.length > 0 ? (
                    filteredCustomers.map((customer, index) => (
                      <div
                        key={customer.id}
                        className={`p-2 cursor-pointer text-sm hover:bg-gray-300 ${activeIndex === index ? 'bg-gray-300' : ''}`}
                        onClick={() => {
                          setSearchQuery1(customer.name);
                          setSelectedCustomer(customer.id);
                          setPhoneNumber(customer.contactNumber);
                          setCustomerNumber(customer.contactNumber)
                          setShowDropdown(false);
                          setActiveIndex(-1); // Reset active index on selection
                        }}
                      >
                        {customer.contactNumber} ({customer.name})
                      </div>
                    ))
                  ) : (
                    <div className="p-2 text-sm hover:bg-gray-300 cursor-pointer">
                      <p className="text-gray-700">Customer not found. Add new customer:</p>
                      <input
                        type="text"
                        placeholder="Enter new customer name"
                        className="border p-2 rounded-lg w-full mt-2"
                        value={newCustomerName}
                        onChange={(e) => setNewCustomerName(e.target.value)}
                      />
                      <button
                        onClick={() => handleGetLocation("new")}
                        className="bg-gray-200 text-white px-4 py-2 rounded-lg mt-2 hover:bg-green-600"
                      >
                        Add Customer
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-3.5 space-x-3.5">
            <select
              className="border p-3 rounded-lg bg-gray-200"
              style={{ width: "360px" }} // Adjust the width as needed
              value={selectedWarehouse}
              onChange={(event) => {
                const selectedValue = event.target.value;
                setSelectedWarehouse(selectedValue); // Update state on change
                handleWarehouseChange(event);
                handleReset();
                setDiscount("");
                setDeliveryCharge("");
                setGst("");
              }}
            >
              <option value="" className="hidden">
                SELECT WAREHOUSE
              </option>
              {warehouses.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.id}>
                  {warehouse.name}
                </option>
              ))}
            </select>


            <div className="relative w-80">
              <input
                type="text"
                placeholder="Search by Code and Name"
                className="border border-gray-200 w-full bg-gray-200 px-10 py-3 rounded-lg focus:outline-none focus:border-blue-500 uppercase"
                value={searchQuery}
                onChange={handleSearchInputChange}
                onBlur={() => setTimeout(() => setDropdownVisible(false), 100)} // Hide dropdown on blur
                onKeyDown={handleKeyDown} // Handle arrow keys and Enter
              />

              {dropdownVisible && (
                <Dropdown
                  products={filteredProducts}
                  onSelect={handleSelectProductFromDropdown}
                  highlightedIndex={highlightedIndex} // Pass highlighted index to Dropdown
                />
              )}

            </div>
            <button
              className="bg-gray-200 p-1 rounded-lg relative"
              onClick={handleBox1Click}
            >
              <img src={Box1} alt="Box1 Icon" className="h-9 w-10" />
              {holdCount !== null && holdCount !== undefined && (
                <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white rounded-full h-6 w-6 flex items-center justify-center text-xs">
                  {holdCount}
                </span>
              )}

            </button>


            {/* --------------------------------------------------------Register---------------------------------------- */}
            <div className="relative inline-block text-left">
              {showPopup && (
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
                  <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm mx-auto">
                    <div className="flex justify-between items-center mb-4">
                      <h2 className="text-lg font-medium">POS Register</h2>
                      <button
                        className="text-gray-500 hover:text-gray-700 focus:outline-none"
                        onClick={closePopup}
                      >
                        ❌
                      </button>
                    </div>
                    <div className="mb-6">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Cash In Hand:
                      </label>
                      <input
                        type="number"
                        min={0}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={cashInHand}
                        onChange={handleInputChanges}
                      />
                    </div>
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded-md w-full hover:bg-blue-600 focus:outline-none focus:bg-blue-700"
                      onClick={handlePostRequest}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}

              <button
                className="bg-gray-200 p-2 rounded-lg relative"
                onClick={() => toggleDropdown(String(selectedWarehouse))}
              >
                <img src={Box2} alt="Box2 Icon" className="h-10 w-10" />
              </button>

              {showRegisterDetailsModal && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                  <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div
                      className="fixed inset-0 transition-opacity"
                      aria-hidden="true"
                    >
                      <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>

                    <span
                      className="hidden sm:inline-block sm:align-middle sm:h-screen"
                      aria-hidden="true"
                    >
                      &#8203;
                    </span>

                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                              Register Details - {new Date(registerDetails.startDate).toLocaleDateString('en-GB')}
                            </h3>

                            <button
                              className="text-gray-500 hover:text-gray-700 focus:outline-none absolute top-4 right-6"
                              onClick={closeModal}
                            >
                              ❌
                            </button>
                            <div className="mt-2">
                              <table className="min-w-full">
                                <thead>
                                  <tr>
                                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Payment Type
                                    </th>
                                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Amount
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                      Total Sales:
                                    </td>
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                      ₹ {registerDetails.totalSalesAmount}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                      Cash:
                                    </td>
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                      ₹ {registerDetails.cashReceived}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                      Card:
                                    </td>
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                      ₹ {registerDetails.cardReceived}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                      UPI:
                                    </td>
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                      ₹ {registerDetails.digitalReceived}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                      In Hand Cash:
                                    </td>
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                      ₹ {registerDetails.inHandCash}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                          onClick={openCloseRegisterModal}
                        >
                          Settlement
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {showCloseRegisterModal && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                  <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div
                      className="fixed inset-0 transition-opacity"
                      aria-hidden="true"
                    >
                      <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>

                    <span
                      className="hidden sm:inline-block sm:align-middle sm:h-screen"
                      aria-hidden="true"
                    >
                      &#8203;
                    </span>

                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                              Close Register
                            </h3>
                            <div className="mt-2">
                              <label className="block text-sm font-medium text-gray-700 mb-1">
                                Cash In Hand:
                              </label>
                              <input
                                type="number"
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                value={cashInHand}
                                onChange={handleInputChanges}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                          onClick={handleCloseRegister}
                        >
                          Close Register
                        </button>
                        <button
                          type="button"
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:mt-0 sm:text-sm sm:leading-5"
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <button
              className="bg-gray-200 p-2 rounded-lg"
              onClick={toggleFullScreen}
            >
              <img src={Box3} alt="Box3 Icon" className="h-9 w-10" />
            </button>

            {/* calculator */}
            <div>
              <button
                className="bg-gray-200 p-2 rounded-lg"
                onClick={toggleCalculator}
              >
                <img src={Box4} alt="Box4 Icon" className="h-10 w-10" />
              </button>

              {showCalculator && <Calculator toggleModal={toggleCalculator} />}
            </div>

            <button
              className="bg-gray-200 p-2 rounded-lg"
              onClick={handleBox5ButtonClick}
            >
              <img src={Box5} alt="Box5 Icon" className="h-9 w-10" />
            </button>
          </div>
        </div>

        <div className="grid grid-cols-2 min-h-screen bg-gray-100 gap-8 mb-4 ">
          <div className="col-span-1 bg-gray-200 p-4 rounded-lg shadow-lg">
            <div className="rounded-lg overflow-hidden">
              {errorMessage.message && (
                <div className="text-red-600 bg-yellow-300 px-2 py-1 rounded-lg mb-2">
                  {errorMessage.name} {errorMessage.message}
                </div>
              )}
              <div
                className="overflow-x-auto rounded-lg"
                style={{ maxHeight: "400px" }}
              >
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="w-1/5 py-3 px-6 text-left text-xs font-medium text-gray-500 tracking-wider overflow-hidden text-nowrap">
                        PRODUCT <span className="text-white text-sm ml-5 p-1 rounded-lg bg-purple-600 ">Count : {selectedCount} nos</span>
                      </th>
                      <th className="w-1/5 py-3 px-6 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        QTY
                      </th>
                      <th className="w-1/5 py-3 px-6 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        PRICE
                      </th>
                      <th className="w-1/5 py-3 px-6 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        SUB TOTAL
                      </th>
                      <th className="w-1/5 py-3 px-6 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...selectedProducts].reverse().map((product, index) => {
                      const actualIndex = selectedProducts.length - 1 - index;
                      const isInvalid = invalidRows.has(actualIndex);

                      return (
                        <tr
                          key={product.id}
                          className={
                            isInvalid ? "bg-green-100 animate-blink" : ""
                          }
                        >
                          <td className="py-4 px-6">
                            <div className="flex items-center">
                              <button
                                className="mr-2 text-blue-500 text-left"
                                onClick={() => handleEditClick(product)}
                              >
                                <FaRegEdit size={18} />
                              </button>
                              <span
                                className="font-medium text-sm truncate overflow-hidden whitespace-nowrap"
                                style={{ maxWidth: "180px" }} // Adjust max-width as needed
                                title={product.name} // Tooltip to show full name on hover
                              >
                                {product.name}{" "}
                              </span>
                              <span className="text-green-500">({product.unit})</span>
                            </div>
                          </td>

                          <td className="py-4 px-6">
                            <div className="flex items-center">
                              <button
                                className="font-extrabold focus:outline-none px-3 bg-red-400 rounded-xl"
                                onMouseDown={() => decrementQuantity(index)}
                              >
                                {" "}
                                -{" "}
                              </button>
                              <input
                                type="number"
                                className="mx-2 w-16 text-center border border-gray-300 rounded bg-yellow-400 font-bold"
                                value={product.quantity}
                                onChange={(e) => handleQuantityChange(index, e.target.value)}
                                step="0.001" // Allows up to three decimal places
                                onInput={(e) => {
                                  // Limit the number of decimal places to three
                                  const value = e.target.value;
                                  if (value.includes(".")) {
                                    const [integerPart, decimalPart] = value.split(".");
                                    if (decimalPart.length > 3) {
                                      e.target.value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                                    }
                                  }
                                }}
                              />
                              <button
                                className="font-bold focus:outline-none px-3 bg-green-500 rounded-xl"
                                onMouseDown={() => incrementQuantity(index)}
                              >
                                {" "}
                                +{" "}
                              </button>
                            </div>
                          </td>
                          <td className="py-4 px-6">₹{product.price}</td>
                          <td className="py-4 px-6">
                            ₹{(product.quantity * product.price).toFixed(2)}
                          </td>

                          <td className="py-4 px-6">
                            <button
                              onClick={() => handleDelete(product)}
                              className=" text-white font-bold rounded"
                            >
                              <IoMdTrash
                                size={30}
                                style={{ color: "red" }}
                                className="inline-block mr-1"
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="mt-2 border-t border-gray-500"></div>

            <div className="grid grid-cols-3 gap-4 px-2 mb-1 bg-yellow-400 py-2">

              {/* Discount Input */}
              <div className="flex items-center">
                <label className="font-semibold w-1/2 text-right pr-2">Discount (%):</label>
                <input
                  type="number"
                  className="border p-1 w-2/5 rounded-lg font-bold"
                  value={discount}
                  onChange={(e) => {
                    const value = e.target.value;
                    setDiscount(value === '' ? '' : Math.max(0, Math.min(100, value)));
                  }}
                  placeholder="Enter discount"
                  min="0"
                  max="100"
                />
              </div>

              {/* Shipping Charge Input */}
              <div className="flex items-center">
                <label className="font-semibold w-1/2 text-left  text-nowrap">Shipping Charge (₹):</label>
                <input
                  type="number"
                  className="border p-1 w-2/5 rounded-lg font-bold ml-10"
                  value={deliveryCharge}
                  onChange={(e) => {
                    const value = e.target.value;
                    setDeliveryCharge(value === '' ? '' : Math.max(0, value));
                  }}
                  placeholder="Enter shipping charge"
                  min="0"
                />
              </div>

              {/* Sub Total */}
              <div className="flex items-center">
                <label className="font-semibold w-1/2 text-right pr-2">Sub Total:</label>
                <span className="font-bold">₹ {overallSubTotal.toFixed(2)}</span>
              </div>

              {/* GST Input */}
              <div className="flex items-center">
                <label className="font-semibold w-1/2 text-right pr-2">GST (%):</label>
                <input
                  type="number"
                  className="border p-1 w-2/5 rounded-lg font-bold"
                  value={gst}
                  onChange={(e) => {
                    const value = e.target.value;
                    setGst(value === '' ? '' : Math.max(0, Math.min(100, value)));
                  }}
                  placeholder="Enter GST"
                  min="0"
                  max="100"
                />
              </div>

              {/* GST Amount */}
              <div className="flex items-center">
                <label className="font-semibold w-1/2 text-left pr-2">GST Amount:</label>
                <span className="font-bold">₹ {gstAmount.toFixed(2)}</span>
              </div>

              {/* Overall Total */}
              <div className="flex items-center">
                <label className="font-semibold w-1/2 text-right pr-2">Overall Total:</label>
                <span className="px-2 bg-green-400 rounded-lg font-bold">₹ {finalTotal.toFixed(2)}</span>
              </div>
            </div>

            <div className="p-4">
              <div className="flex justify-between items-center mt-4">
                <button
                  className="bg-gray-400 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                  onClick={() => setIsHoldPopupRefOpen(true)}
                >
                  Hold
                </button>
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded-lg"
                  onClick={handleReset}
                >
                  Reset
                </button>
                {Array.from(invalidRows).length === 0 && (
                  <button
                    className={`px-4 py-2 rounded-lg text-white ${selectedCustomer.trim() &&
                      selectedProducts.length &&
                      registerDetails
                      ? 'bg-green-500'
                      : 'bg-gray-300 cursor-not-allowed'
                      }`}
                    onClick={() => {
                      // Set discount and delivery charge to 0 if they are empty or null
                      if (!discount || discount === "") {
                        setDiscount(0);
                      }
                      // Set gst charge to 0 if they are empty or null
                      if (!gst || gst === "") {
                        setGst(0);
                      }
                      if (!deliveryCharge || deliveryCharge === "") {
                        setDeliveryCharge(0);
                      }

                      // Proceed with validation and other operations
                      validateAndSetQuantities();
                    }}
                    disabled={
                      !selectedCustomer.trim() ||
                      !selectedProducts.length ||
                      !registerDetails
                    }
                  >
                    Pay Now
                  </button>

                )}
              </div>
            </div>
          </div>

          <div>
            <div className="flex justify-between mb-4 pr-2">
              <select
                className="border p-4 rounded-lg bg-gray-200 w-[290px] h-14"
                onChange={(e) => handleCategorySelect(e.target.value)}
                disabled={loadingProductsLocal}
              >
                <option value="">All Categories</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.categoryName}
                  </option>
                ))}
              </select>

              {!categoryDropdownVisible && (
                <button onClick={() => setCategoryDropdownVisible(prevState => !prevState)} className="p-2 bg-gray-200 rounded-lg mr-10">
                  Categories Products ▼
                </button>
              )}


              {categoryDropdownVisible && (
                <CategoryDropdown
                  products={filteredProducts}
                  onSelect={handleSelectProductFromDropdown}
                  highlightedIndex={highlightedIndex}
                  onArrowKeyNavigation={handleArrowKeyNavigation}
                  dropDownClose={dropDownClose}
                />
              )}
            </div>

            <div className="max-h-[800px] overflow-y-scroll">
              <div className="grid grid-cols-4 gap-4">
                {Array.isArray(filteredProducts) && filteredProducts.length > 0 ? (
                  filteredProducts.map((product) => (
                    <div key={product.id}>
                      <div
                        className={`bg-white p-2 rounded-lg shadow-lg flex flex-col justify-between ${product.totalQuantity < 1 ? "cursor-not-allowed" : ""}`}
                        onClick={() => handleProductSelect(product)}
                      >
                        <div className="text-sm text-white p-1 rounded-lg text-center w-24 ml-0" style={{ backgroundColor: "#151716" }}>
                          ⚖️{`${product.quantity.toFixed(2)} ${product.unit}`}
                        </div>

                        <div>
                          <img src={product.image} alt={product.name} className="w-full h-24 object-cover rounded-lg mb-2" />
                          <div className="font-semibold overflow-hidden text-ellipsis whitespace-nowrap text-sm">{product.name}</div>
                          <div className="font-semibold hidden">{product.categoryId}</div>
                          <div className="hidden">{product.totalQuantity}</div>
                          <div className="text-sm text-gray-600">{`${product.productsCode}`}</div>
                        </div>
                        <div className="text-sm p-1 font-bold bg-yellow-300 text-black rounded-lg mt-2">
                          ₹ {`${product.price}`} {product.unit}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-span-4 flex justify-center items-center">
                    <p className="text-gray-500">No results found</p>
                  </div>
                )}
              </div>

              {loadingProductsLocal && (
                <div className="flex justify-center items-center w-full h-full">
                  <Oval
                    height={80}
                    width={80}
                    color="#3498db"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#f3f3f3"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
      {isBox5PopupOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4">Confirmation</h2>
            <p className="text-xl mb-8">
              Are you sure you want to move to the{" "}
              <span className="text-green-800 font-bold"> Dashboard</span>?
            </p>
            <div className=" mt-4">
              <button
                onClick={handleBox5OkClick}
                className="bg-green-500 text-white px-8 py-2 float-left rounded-lg font-bold"
              >
                Ok
              </button>
              <button
                onClick={handleBox5CancelClick}
                className="bg-red-500 text-white px-4 py-2 rounded-lg mr-2 float-right font-bold"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {isEditPopupOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <div className="bg-purple-200 p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4 bg-gray-300 p-2">
              Edit Stock Price
            </h2>
            <p className="text-lg mb-4 font-bold">
              {
                products.find((product) => product.id === selectedProductId)
                  ?.name
              }{" "} /
              <span className="font-semibold text-red-600 bg-yellow-400 rounded-lg">
                ({" "}
                {
                  products.find((product) => product.id === selectedProductId)
                    ?.unit
                }{" "}
                )
              </span>
            </p>
            <label htmlFor="text" className="font-bold text-xl">
              {" "}
              ₹{" "}
            </label>
            <input
              type="number"
              value={updateStock}
              onChange={(e) => setUpdateStock(e.target.value)}
              placeholder="Enter new price"
              className="border border-gray-200 px-4 py-2 rounded-lg mb-4 w-4/5"
            />
            <button
              onClick={() => setEditPopupOpen(false)}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg float-left mr-11 mt-10 hover:bg-red-600"
            >
              Cancel
            </button>
            <button
              onClick={handleUpdateStock}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg ml-2 float-right ml-11 mt-10 hover:bg-green-500"
            >
              Update
            </button>
          </div>
        </div>
      )}

      {isPaymentPopupOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg w-96">
            <h2 className="text-xl font-bold mb-4">Make Payment</h2>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div className="col-span-1">
                <label className="block mb-2">Paying Amount:</label>
                <input
                  type="number"
                  className="border p-2 w-full bg-green-300 font-bold"
                  value={finalTotal.toFixed(2)}
                  readOnly
                />
              </div>
              <div className="col-span-1">
                <label className="block mb-2">Payment Type:</label>
                <select
                  className="border p-2 w-full"
                  value={paymentMode}
                  onChange={(e) => setPaymentMode(e.target.value)}
                >
                  <option value="cash">Cash</option>
                  <option value="card">Card</option>
                  <option value="digital">UPI</option>
                </select>
              </div>

            </div>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div className="col-span-1">
                <label className="block mb-2">Received Amount:</label>
                <input
                  type="number"
                  className="border p-2 w-full"
                  value={receivedAmount}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === "" || Number(value) > 0) {
                      setReceivedAmount(value);
                    }
                  }}
                  placeholder="Enter amount"

                />
              </div>
              <div className="col-span-1">
                <label className="block mb-2">Change Return:</label>
                <input
                  type="number"
                  className="border p-2 w-full bg-green-300 font-bold"
                  value={changeReturn.toFixed(2)}
                  readOnly
                />
              </div>

            </div>

            <div className="mb-4">
              <label className="block mb-2">Payment Status:</label>
              <select
                className="border p-2 w-full"
                value={paymentStatus}
                onChange={handlePaymentStatusChange}
              >
                <option value="Paid">Paid</option>
                <option value="Pending">Pending</option>
                <option value="COD">COD</option>

              </select>
            </div>
            <div className="mb-4">
              <label className="block mb-2">Notes:</label>
              <textarea className="border p-2 w-full"></textarea>
            </div>
            <div className="flex justify-between">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-lg"
                onClick={() => setPaymentPopupOpen(false)}
              >
                Cancel
              </button>
              {/* <button
                className={`bg-blue-500 text-white px-4 py-2 rounded-lg mr-2 ${paymentMode === "cash" && !receivedAmount ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                onClick={handlePayBillClick}
                disabled={paymentMode === "cash" && !receivedAmount}
              >
                Pay Bill
              </button> */}

              <button
                className={`bg-blue-500 text-white px-4 py-2 rounded-lg mr-2 ${(paymentMode === "cash" && !receivedAmount) || loading
                  ? "opacity-50 cursor-not-allowed"
                  : ""
                  }`}
                onClick={handlePayBillClick}
                disabled={(paymentMode === "cash" && !receivedAmount) || loading}
              >
                {loading ? "Processing..." : "Pay Bill"}
              </button>
            </div>
          </div>
        </div>
      )}


      {isHoldPopupRefOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <div className="bg-gray-200 w-96 p-6 rounded-lg relative">
            <h2 className="text-xl font-bold mb-4">Hold Data</h2>
            <button
              className="absolute top-4 right-4 text-red-500 hover:text-gray-700"
              onClick={() => setIsHoldPopupRefOpen(false)}
            >
              ✕
            </button>
            <div className="mb-4">
              <label htmlFor="referenceNumber" className="block mb-2">
                Reference Number:
              </label>
              <input
                type="text"
                id="referenceNumber"
                value={referenceNumber}
                onChange={(e) => setReferenceNumber(e.target.value)}
                className="border p-2 w-full"
                placeholder="Enter reference number"
              />
            </div>
            {/* Display hold products */}
            {holdProducts.length > 0 && (
              <div>
                <h3 className="text-lg font-bold mb-2">Hold Products</h3>
                <ul>
                  {holdProducts.map((product, index) => (
                    <li key={index}>
                      {product.name} - {product.quantity}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="flex justify-end mt-4">
              <button
                onClick={sendHoldData}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg mr-2"
              >
                Save
              </button>
              <button
                onClick={handleCancelHold}
                className="bg-red-500 text-white px-4 py-2 rounded-lg"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {isHoldPopUpOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg w-2/5">
            <button
              className="text-gray-500 float-right hover:text-gray-700"
              onClick={() => setHoldPopUpScreen(false)}
            >
              ❌
            </button>
            <h2 className="text-xl font-bold mb-4">Hold Orders</h2>
            <div className="overflow-auto max-h-80">
              {holdData.length === 0 ? (
                <p className="text-center text-gray-500">No hold found</p>
              ) : (
                <table className="w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Ref No
                      </th>
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {holdData.map((item) => (
                      <tr key={item.id} onClick={() => handleRowClick(item.id)}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item.referenceNo}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {new Date(item.createdAt).toLocaleDateString("en-GB")}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditClickInHoldPopup(item.id);
                              setHoldPopUpScreen(false); // Close popup on edit button click
                            }}
                            className="mr-2 font-bold rounded"
                          >
                            <FaRegEdit
                              size={22}
                              className="inline-block mr-1"
                            />
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setHoldPopUpScreen(false);
                              handleHoldDelete(item);
                            }}
                            className="text-white font-bold rounded"
                          >
                            <IoMdTrash
                              size={30}
                              style={{ color: "red" }}
                              className="inline-block mr-1"
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
      <InvoicePopup
        isOpen={isInvoicePopupOpen}
        onClose={() => setIsInvoicePopupOpen(false)}
        invoiceData={invoiceData}
      />
    </div>
  );
};

export default Billing;
