import './App.css';
import React from 'react';
import Routers from './components/Routers';
import SalesReturn from './components/SalesReturn';


const App = () => {
  return (
     <div className="App">
      
        <Routers/>
        {/* <SalesReturn/> */}
    </div>

  );
};

export default App;