import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import filter from "../Assets/tableicon/filter.png";
import Searchicon from "../Assets/tableicon/Searchicon.png";

const ExpenseCategories = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [isCreatePopupOpen, setCreatePopupOpen] = useState(false);
    const [isEditPopupOpen, setEditPopupOpen] = useState(false);
    const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
    const [newexpenseCategoryName, setNewexpenseCategoryName] = useState('');
    const [updateexpenseCategoryName, setUpdateexpenseCategoryName] = useState('');
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [switchStatus, setSwitchStatus] = useState(false);
    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc'); // Initialize sort direction



    useEffect(() => {
        fetchData();
    }, [currentPage, itemsPerPage]);

    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const response = await fetch('https://pos.farm2bag.com/api/v1/expenseCategories', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const responseData = await response.json();
            const BrandData = responseData.data.map(Brand => ({
                expenseCategoryName: Brand.expenseCategoryName,
                id: Brand.id,
                status: Brand.status,
            }));
            setData(BrandData);
            // Adjust the current page if necessary
            const totalPages = Math.ceil(BrandData.length / itemsPerPage);
            if (currentPage > totalPages) {
                setCurrentPage(totalPages);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data
        .filter(item => {
            if (!searchQuery) return true; // Return true if searchQuery is null or undefined
            return item.expenseCategoryName.toLowerCase().includes(searchQuery.toLowerCase());
        })
        .slice(indexOfFirstItem, indexOfLastItem);

    const handleEdit = (brand) => {
        setSelectedBrand(brand);
        setUpdateexpenseCategoryName(brand.expenseCategoryName);
        setEditPopupOpen(true);
    };

    const handleDelete = (brand) => {
        setSelectedBrand(brand);
        setDeletePopupOpen(true);
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleItemsPerPageChange = (e) => {
        setCurrentPage(1);
        setItemsPerPage(parseInt(e.target.value));
    };

    const handleCreateBrand = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            // Check if newexpenseCategoryName is null or empty
            if (!newexpenseCategoryName.trim()) {
                // Notify the user that the brand name cannot be empty
                toast.error('Expense Category name cannot be empty');
                return;
            }

            const response = await fetch('https://pos.farm2bag.com/api/v1/expenseCategories', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    expenseCategoryName: newexpenseCategoryName,
                    createdBy: role,
                    image: "nothing"
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                toast.error(`Failed to create Expense Category: ${errorData.message || response.statusText}`);
                return; // Exit the function if the request was not successful
            }
            console.log('Expense Category created successfully');
            setCreatePopupOpen(false);
            setNewexpenseCategoryName('');

            // After successfully creating the Brand, you might want to refresh the data
            fetchData();
            toast.success('Expense Category created successfully');

        } catch (error) {
            console.error('Error creating Expense Category:', error);
        }
    };


    const handleUpdateBrand = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const id = selectedBrand.id;

            if (!updateexpenseCategoryName.trim()) {
                // Notify the user that the category name cannot be empty
                toast.error('Category name cannot be empty');
                return;
            }

            const response = await fetch(`https://pos.farm2bag.com/api/v1/expenseCategories/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    expenseCategoryName: updateexpenseCategoryName,
                    status: 1,
                    modifiedBy: role, // Ensure modifiedBy is included in the request body
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update Expense Category');
            }

            console.log('Expense Category updated successfully');
            setEditPopupOpen(false);
            setUpdateexpenseCategoryName('');

            // After successfully updating the Brand, you might want to refresh the data
            fetchData();

            toast.success('Expense Category Update successfully');

        } catch (error) {
            console.error('Error updating Expense Category:', error);
        }
    };

    const handleDeleteBrand = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const id = selectedBrand.id;

            const response = await fetch(`https://pos.farm2bag.com/api/v1/expenseCategories/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ modifiedBy: role })
            });

            if (!response.ok) {
                throw new Error('Failed to delete Expense Category');
            }

            console.log('Expense Category deleted successfully');
            setDeletePopupOpen(false);

            // After successfully deleting the Brand, you might want to refresh the data
            fetchData();

            toast.success('Expense Category Deleted successfully');

        } catch (error) {
            console.error('Error deleting Expense Category:', error);
        }
    };

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase().trim();
        setSearchQuery(query);
    
        // Filter the data based on the search query
        const filteredData = data.filter(item =>
            item.expenseCategoryName.toLowerCase().includes(query)
        );
    
        // Sort the filtered data so that exact matches or matches where the query appears earlier come first
        const sortedData = filteredData.sort((a, b) => {
            // Check if expenseCategoryName starts with the query
            const startsWithQueryA = a.expenseCategoryName.toLowerCase().startsWith(query);
            const startsWithQueryB = b.expenseCategoryName.toLowerCase().startsWith(query);
    
            // Prioritize exact matches or matches where query appears earlier
            if (startsWithQueryA && !startsWithQueryB) return -1;
            if (!startsWithQueryA && startsWithQueryB) return 1;
            return 0;
        });
    
        setData(sortedData);
    };
    
    // useEffect to reset data to initial state when search input is cleared
    useEffect(() => {
        if (searchQuery === '') {
            fetchData(); // Fetch initial data again
        }
    }, [searchQuery]);
        

    const handleFilterClick = () => {
        // Determine the next sort order
        const nextSortOrder = sortBy === 'expenseCategoryName' ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
    
        let sortedData;
    
        if (nextSortOrder) {
            sortedData = [...data].sort((a, b) => {
                const aValue = a.expenseCategoryName.toLowerCase();
                const bValue = b.expenseCategoryName.toLowerCase();
    
                if (nextSortOrder === 'desc') {
                    // Sort descending
                    if (aValue > bValue) return -1;
                    if (aValue < bValue) return 1;
                } else {
                    // Sort ascending
                    if (aValue < bValue) return -1;
                    if (aValue > bValue) return 1;
                }
                return 0;
            });
        } else {
            // If nextSortOrder is null, reset to original order (unsorted)
            sortedData = [...data];
        }
    
        setData(sortedData);
        setSortBy('expenseCategoryName');
        setSortDirection(nextSortOrder);
    };
    

    return (
        <div className='bg-gray-100 ml-64 mt-20 p-12 h-screen'>
            <div className="container mx-auto">
                <div className="mb-10">
                    <h1 className="font-extrabold text-3xl text-left text-gray-500">Expense Category</h1>
                    <div className="mt-2 border-t border-gray-500"></div>
                </div>
                <div className="flex items-center mb-5">
                    <div className="ml-0">
                        <form>
                            <div className="relative">
                                <input type="text" placeholder='search' value={searchQuery} onChange={handleSearch} className="border border-gray-200 px-4 py-2 pl-10 rounded-lg focus:outline-none focus:border-blue-500" />
                                <img src={Searchicon} alt="search" className="absolute left-3 top-3 w-4 h-4" />
                            </div>
                        </form>
                    </div>
                    <div className="flex ml-auto mr-0 items-center space-x-4">
                        <img src={filter} alt="filter" className="w-12 h-10 rounded-lg cursor-pointer" onClick={handleFilterClick} />
                        <button onClick={() => setCreatePopupOpen(true)} style={{ backgroundColor: 'rgba(66, 255, 97, 1)' }} className="w-48 h-10 font-medium text-sm rounded-lg">Create Expense Category</button>
                    </div>
                </div>

                <div className="rounded-lg overflow-hidden">
                    <table className="table-auto w-full border-collapse border-gray-500 text-center">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="px-4 py-2 text-left pl-16">S.No</th>
                                <th className="px-4 py-2 text-left pl-16">Expense Category</th>
                                <th className="px-4 py-2 text-left pl-16">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((item, index) => (
                                <tr key={item.id} className={(index % 2 === 0) ? 'bg-white' : 'bg-gray-200'}>
                                    <td className="px-4 py-2 border-gray-500 text-left pl-16">{indexOfFirstItem + index + 1}</td>
                                    <td className="px-4 py-2 border-gray-500 text-left pl-16">{item.expenseCategoryName}</td>
                                    <td className="px-4 py-2 border-gray-500 text-left pl-16">
                                        <button onClick={() => handleEdit(item)} className="mr-2 font-bold  rounded">
                                            <FaRegEdit size={22} style={{ color: "blue" }} className="inline-block mr-1" />
                                        </button>

                                        <button onClick={() => handleDelete(item)} className=" text-white font-bold rounded">
                                            <IoMdTrash size={30} style={{ color: "red" }} className="inline-block mr-1" />
                                        </button>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {currentItems.length === 0 && (
                    <div className="text-center mt-4 text-gray-500">
                        No result found.
                    </div>
                )}

                {isCreatePopupOpen && (
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-gray-200 w-2/6 h-2/6 p-6 rounded-lg ">
                            <h2 className="text-xl font-bold mb-4">Create New ExpenseCategory</h2>
                            <div className="mb-4 border-t border-gray-500"></div>
                            <input type="text" value={newexpenseCategoryName} onChange={(e) => setNewexpenseCategoryName(e.target.value)} placeholder="Enter Expense Category" className="border border-gray-200 px-4 py-2 rounded-lg mb-4 w-4/5" /> <br />
                            <button onClick={() => setCreatePopupOpen(false)} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg float-left ml-12 mt-10 hover:bg-red-500">Cancel</button>
                            <button onClick={handleCreateBrand} className="bg-blue-500 text-white px-4 py-2 rounded-lg float-right mr-11  mt-10 hover:bg-green-600">Create</button>
                        </div>
                    </div>
                )}

                {isEditPopupOpen && (
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-6 rounded-lg">
                            <h2 className="text-xl font-bold mb-4">Edit ExpenseCategory</h2>
                            <input type="text" value={updateexpenseCategoryName} onChange={(e) => setUpdateexpenseCategoryName(e.target.value)} placeholder="Enter New Expense Category" className="border border-gray-200 px-4 py-2 rounded-lg mb-4 w-4/5" />
                            <button onClick={() => setEditPopupOpen(false)} className=" text-white px-4 py-2 rounded-lg float-left ml-12 mt-10 bg-red-600">Cancel</button>
                            <button onClick={handleUpdateBrand} className=" text-black px-4 py-2 rounded-lg ml-2 float-right mr-12 mt-10 bg-green-500">Update</button>
                        </div>
                    </div>
                )}

                {isDeletePopupOpen && (
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-6 rounded-lg">
                            <h2 className="text-xl font-bold mb-4">Delete ExpenseCategory</h2>
                            <p>Are you sure you want to delete the ExpenseCategory <span className='text-red-500 font-bold'>{selectedBrand.expenseCategoryName}</span> ?</p>
                            <button onClick={() => setDeletePopupOpen(false)} className="bg-blue-500 text-white px-4 py-2 rounded-lg float-left mr-11 mt-10 bg-green-500">Cancel</button>
                            <button onClick={handleDeleteBrand} className="bg-gray-300 text-black px-4 py-2 rounded-lg ml-2 float-right ml-11 mt-10 bg-red-500">Delete</button>
                        </div>
                    </div>
                )}

                <div className="flex justify-between items-center mt-5 pb-10">
                    <div>
                        <label htmlFor="itemsPerPage" className="mr-2">Items per page:</label>
                        <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange} className="border border-gray-300 rounded-lg px-2 py-1">
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                        </select>
                    </div>
                    <div className="flex space-x-2">
                        {Array.from({ length: Math.ceil(data.length / itemsPerPage) }, (_, index) => (
                            <button
                                key={index}
                                onClick={() => paginate(index + 1)}
                                className={`px-3 py-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </div>

            </div>
            <ToastContainer />
        </div>
    );
};

export default ExpenseCategories;