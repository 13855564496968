import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import filter from "../Assets/tableicon/filter.png";
import Searchicon from "../Assets/tableicon/Searchicon.png";
import Switch from "react-switch";


const Units = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [isCreatePopupOpen, setCreatePopupOpen] = useState(false);
    const [isEditPopupOpen, setEditPopupOpen] = useState(false);
    const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
    const [newUnitsName, setNewUnitsName] = useState('');
    const [newUnitsSymbol, setNewUnitsSymbol] = useState('');
    const [updateUnitsName, setUpdateUnitsName] = useState('');
    const [updateUnitsSymbol, setUpdateUnitsSymbol] = useState('');
    const [selectedUnits, setSelectedUnits] = useState(null);
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [switchStatus, setSwitchStatus] = useState(false);
    const [sortBy, setSortBy] = useState(null); // Initial sorting state



    useEffect(() => {
        fetchData();
    }, [currentPage, itemsPerPage]);

    // Update fetchData to correctly set data and handle pagination
    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const response = await fetch('https://pos.farm2bag.com/api/v1/measurements/master', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const responseData = await response.json();
            setData(responseData.data);

            // Adjust the current page if necessary
            const totalPages = Math.ceil(responseData.data.length / itemsPerPage);
            if (currentPage > totalPages) {
                setCurrentPage(totalPages);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    // Calculate data for current page based on current page and items per page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const filteredData = data.filter(item => item.unitSymbol.toLowerCase().includes(searchQuery.toLowerCase()));
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const handleEdit = (Units) => {
        setSelectedUnits(Units);
        setUpdateUnitsName(Units.unitType);
        setUpdateUnitsSymbol(Units.unitSymbol);
        setEditPopupOpen(true);
    };

    const handleDelete = (Units) => {
        setSelectedUnits(Units);
        setDeletePopupOpen(true);
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleItemsPerPageChange = (e) => {
        setCurrentPage(1);
        setItemsPerPage(parseInt(e.target.value));
    };

    const handleCreateUnits = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            // Check if either newUnitsName or newUnitsSymbol is null
            if (!newUnitsName || !newUnitsSymbol) {
                // Notify the user that both fields are required
                toast.error('Both Units name and Symbol are required');
                return;
            }

            const response = await fetch('https://pos.farm2bag.com/api/v1/measurements', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    unitType: newUnitsName,
                    unitSymbol: newUnitsSymbol,
                    createdBy: role,
                    image: "nothing"
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                toast.error(`Failed to create Units: ${errorData.message || response.statusText}`);
                return; // Exit the function if the request was not successful
            }

            console.log('Units created successfully');
            setCreatePopupOpen(false);
            setNewUnitsName('');
            setNewUnitsSymbol('');

            // After successfully creating the Units, you might want to refresh the data
            fetchData();
            toast.success('Units created successfully');

        } catch (error) {
            console.error('Error creating Units:', error);
        }
    };



    const handleUpdateUnits = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            // Check if either updateUnitsName or updateUnitsSymbol is null
            if (!updateUnitsName || !updateUnitsSymbol) {
                // Notify the user that both fields are required
                toast.error('Both Units name and Symbol are required');
                return;
            }

            const id = selectedUnits.id;

            const response = await fetch(`https://pos.farm2bag.com/api/v1/measurements/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    unitType: updateUnitsName,
                    unitSymbol: updateUnitsSymbol,
                    status: 1,
                    modifiedBy: role,
                    image: "nothing"
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                toast.error(`Failed to Update Unit: ${errorData.message || response.statusText}`);
                return; 
            }

            console.log('Units updated successfully');
            setEditPopupOpen(false);
            setUpdateUnitsName('');
            setUpdateUnitsSymbol('');

            // After successfully updating the Units, you might want to refresh the data
            fetchData();

            toast.success('Units Update successfully');

        } catch (error) {
            console.error('Error updating Units:', error);
        }
    };


    //---------------------------------------------------------------------


    const toggleStatus = async (selectedUnit) => {

        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const id = selectedUnit.id;
            const newStatus = selectedUnit.status === 0 ? 1 : 0; // Toggle status value

            const response = await fetch(`https://pos.farm2bag.com/api/v1/measurements/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    
                    status: newStatus,
                    
                })
            });

            if (!response.ok) {
                throw new Error('Failed to toggle status');
            }

            console.log('Status toggled successfully');

            // Update the status value in the data array
            setData(data.map(item => item.id === id ? { ...item, status: newStatus } : item));
        } catch (error) {
            console.error('Error toggling status:', error);
        }
    };

    //----------------------------------------------------------------

    const handleDeleteUnits = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const id = selectedUnits.id;

            const response = await fetch(`https://pos.farm2bag.com/api/v1/measurements/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ modifiedBy: role })
            });

            if (!response.ok) {
                throw new Error('Failed to delete Units');
            }

            console.log('Units deleted successfully');
            setDeletePopupOpen(false);

            // After successfully deleting the Units, you might want to refresh the data
            fetchData();

            toast.success('Units Deleted successfully');

        } catch (error) {
            console.error('Error deleting Units:', error);
        }
    };

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase().trim();
        setSearchQuery(query);
    
        // Filter the data based on the search query
        const filteredData = data.filter(item =>
            item.unitSymbol.toLowerCase().includes(query) ||
            item.unitType.toLowerCase().includes(query)
        );
    
        // Sort the filtered data so that exact matches or matches where the query appears earlier come first
        const sortedData = filteredData.sort((a, b) => {
            // Check if unitSymbol starts with the query
            const startsWithQueryA = a.unitSymbol.toLowerCase().startsWith(query);
            const startsWithQueryB = b.unitSymbol.toLowerCase().startsWith(query);
    
            // Prioritize exact matches or matches where query appears earlier
            if (startsWithQueryA && !startsWithQueryB) return -1;
            if (!startsWithQueryA && startsWithQueryB) return 1;
            return 0;
        });
    
        setData(sortedData);
    };
    
    // Reset data to initial state when search input is cleared
    useEffect(() => {
        if (searchQuery === '') {
            fetchData(); // Fetch initial data again
        }
    }, [searchQuery]);
    

    const handleSort = (key) => {
        // Check if already sorted by the same key and determine the next sort order
        const nextSortOrder = sortBy === key ? (sortBy === `${key}_desc` ? null : `${key}_desc`) : key;
    
        let sortedData;
    
        if (nextSortOrder) {
            sortedData = [...data].sort((a, b) => {
                const aValue = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
                const bValue = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];
    
                if (nextSortOrder === `${key}_desc`) {
                    // Sort descending
                    if (aValue > bValue) return -1;
                    if (aValue < bValue) return 1;
                } else {
                    // Sort ascending
                    if (aValue < bValue) return -1;
                    if (aValue > bValue) return 1;
                }
                return 0;
            });
        } else {
            // If nextSortOrder is null, reset to original order (unsorted)
            sortedData = [...data];
        }
    
        setData(sortedData);
        setSortBy(nextSortOrder);
    };
    

    return (
        <div className='bg-gray-100 ml-64 mt-20 p-12 h-screen'>
            <div className="container mx-auto">
                <div className="mb-10">
                    <h1 className="font-extrabold text-3xl text-left text-gray-500">Units</h1>
                    <div className="mt-2 border-t border-gray-500"></div>
                </div>
                <div className="flex items-center mb-5">
                    <div className="ml-0">
                        <form>
                            <div className="relative">
                                <input type="text" placeholder='search symbol' value={searchQuery} onChange={handleSearch} className="border border-gray-200 px-4 py-2 pl-10 rounded-lg focus:outline-none focus:border-blue-500" />
                                <img src={Searchicon} alt="search" className="absolute left-3 top-3 w-4 h-4" />
                            </div>
                        </form>
                    </div>
                    <div className="flex ml-auto mr-0 items-center space-x-4">
                    <img src={filter} alt="filter" className="w-12 h-10 rounded-lg cursor-pointer" onClick={() => handleSort('unitType')} />
                        <button onClick={() => setCreatePopupOpen(true)} style={{ backgroundColor: 'rgba(66, 255, 97, 1)' }} className="w-48 h-10 font-medium text-sm rounded-lg">Create Product Units</button>
                    </div>
                </div>

                <div className="rounded-lg overflow-hidden">
                    <table className="table-auto w-full border-collapse border-gray-500 text-center">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="px-4 py-2 text-left pl-16">S.No</th>
                                <th className="px-4 py-2 text-left pl-16">UNIT NAME</th>
                                <th className="px-4 py-2 text-left pl-16">UNIT SYMBOL</th>
                                <th className="px-4 py-2 text-left pl-16">STATUS</th>
                                <th className="px-4 py-2 text-left pl-16">ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((item, index) => (
                                <tr key={item.id} className={(index % 2 === 0) ? 'bg-white' : 'bg-gray-200'}>
                                    <td className="px-4 py-2 border-gray-500 text-left pl-16">{indexOfFirstItem + index + 1}</td> {/* Serial number column */}
                                    <td className="px-4 py-2 border-gray-500 text-left pl-16">{item.unitType}</td>
                                    <td className="px-4 py-2 border-gray-500 text-left pl-16">{item.unitSymbol}</td>
                                    <td className="px-4 py-2 border-gray-500 text-left pl-16">
                                        <Switch
                                            onChange={() => toggleStatus(item)}
                                            checked={item.status === 1}
                                        />
                                    </td>
                                    <td className="px-4 py-2 border-gray-500 text-left pl-16">
                                        <button onClick={() => handleEdit(item)} className="mr-2 font-bold  rounded">
                                            <FaRegEdit size={22} style={{ color: "blue" }} className="inline-block mr-1" />
                                        </button>

                                        <button onClick={() => handleDelete(item)} className=" text-white font-bold rounded">
                                            <IoMdTrash size={30} style={{ color: "red" }} className="inline-block mr-1" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {currentItems.length === 0 && (
                    <div className="text-center mt-4 text-gray-500">
                        No result found.
                    </div>
                )}

                {isCreatePopupOpen && (
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-gray-200 w-2/6 h-2/5 p-6 rounded-lg ">
                            <h2 className="text-xl font-bold mb-4">Create New Units</h2>
                            <div className="mb-4 border-t border-gray-500"></div>
                            <input type="text" value={newUnitsName} onChange={(e) => setNewUnitsName(e.target.value)} placeholder="Enter Units name" className="border border-gray-200 px-4 py-2 rounded-lg mb-4 w-4/5" /> <br />
                            <input type="text" value={newUnitsSymbol} onChange={(e) => setNewUnitsSymbol(e.target.value)} placeholder="Enter Units Symbol" className="border border-gray-200 px-4 py-2 rounded-lg mb-4 w-4/5" /> <br />
                            <button onClick={handleCreateUnits} className="bg-blue-500 text-white px-4 py-2 rounded-lg float-right mr-11 mt-10 hover:bg-green-600">Create</button>
                            <button onClick={() => setCreatePopupOpen(false)} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg ml-2 float-left ml-11 mt-10 hover:bg-red-500">Cancel</button>
                        </div>
                    </div>
                )}

                {isEditPopupOpen && (
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-6 w-2/6 h-2/5 rounded-lg">
                            <h2 className="text-xl font-bold mb-4">Edit Units</h2>
                            <input type="text" value={updateUnitsName} onChange={(e) => setUpdateUnitsName(e.target.value)} placeholder="Enter new Units name" className="border border-gray-200 px-4 py-2 rounded-lg mb-4 w-4/5" />
                            <input type="text" value={updateUnitsSymbol} onChange={(e) => setUpdateUnitsSymbol(e.target.value)} placeholder="Enter new Units Symbol" className="border border-gray-200 px-4 py-2 rounded-lg mb-4 w-4/5" />
                            <button onClick={() => setEditPopupOpen(false)} className="bg-blue-500 text-white px-4 py-2 rounded-lg float-left mr-11 mt-10 hover:bg-red-600">Cancel</button>
                            <button onClick={handleUpdateUnits} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg ml-2 float-right ml-11 mt-10 hover:bg-green-500">Update</button>
                        </div>
                    </div>
                )}

                {isDeletePopupOpen && (
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-6 rounded-lg">
                            <h2 className="text-xl font-bold mb-4">Delete Units</h2>
                            <p>Are you sure you want to delete the Units "{selectedUnits.unitSymbol}"?</p>
                            <button onClick={() => setDeletePopupOpen(false)} className="bg-blue-500 text-white px-4 py-2 rounded-lg float-left mr-11 mt-10 hover:bg-red-600">Cancel</button>
                            <button onClick={handleDeleteUnits} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg ml-2 float-right ml-11 mt-10 hover:bg-green-500">Delete</button>
                        </div>
                    </div>
                )}

                <div className="flex justify-between items-center mt-5 pb-10">
                    <div>
                        <label htmlFor="itemsPerPage" className="mr-2">Items per page:</label>
                        <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange} className="border border-gray-300 rounded-lg px-2 py-1">
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                        </select>
                    </div>
                    <div className="flex space-x-2">
                        {Array.from({ length: Math.ceil(data.length / itemsPerPage) }, (_, index) => (
                            <button
                                key={index}
                                onClick={() => paginate(index + 1)}
                                className={`px-3 py-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </div>

            </div>
            <ToastContainer />
        </div>
    );
};

export default Units;
