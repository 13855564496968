import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';

const Calculator = ({ toggleModal }) => {
  const [input, setInput] = useState('');

  const handleButtonClick = (value) => {
    if (value === '=') {
      try {
        setInput(eval(input).toString());
      } catch (error) {
        setInput('Error');
      }
    } else if (value === 'AC') {
      setInput('');
    } else if (value === 'C') {
      setInput(input.slice(0, -1));
    } else {
      setInput(input + value);
    }
  };

  const handleKeyPress = (event) => {
    const { key } = event;
    if (key === 'Enter') {
      handleButtonClick('=');
    } else if (key === 'Backspace') {
      handleButtonClick('C');
    } else if (key === 'Escape') {
      toggleModal();
    } else if ('0123456789+-*/.'.includes(key)) {
      setInput(input + key);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [input]);

  const isOperator = (value) => ['+', '-', '*', '/', '.'].includes(value);

  return (
    <Draggable>
      <div className="absolute top-1/4 left-1/4 bg-white p-6 rounded-lg shadow-xl w-80 md:w-96">
        <div className="flex justify-between items-center mb-6">
          <input
            type="text"
            value={input}
            readOnly
            className="w-full p-3 text-right bg-gray-200 rounded text-2xl mt-4"
          />
          <button
            onClick={toggleModal}
            className="text-xl text-gray-500 hover:text-gray-700 absolute top-2 right-2"
          >
            ❌
          </button>
        </div>
        <div className="grid grid-cols-4 gap-4">
          {['7', '8', '9', '/'].map((value) => (
            <button
              key={value}
              onClick={() => handleButtonClick(value)}
              className={`p-4 rounded text-xl ${isOperator(value) ? 'bg-yellow-300 hover:bg-yellow-400' : 'bg-gray-100 hover:bg-gray-200'}`}
            >
              {value}
            </button>
          ))}
          {['4', '5', '6', '*'].map((value) => (
            <button
              key={value}
              onClick={() => handleButtonClick(value)}
              className={`p-4 rounded text-xl ${isOperator(value) ? 'bg-yellow-300 hover:bg-yellow-400' : 'bg-gray-100 hover:bg-gray-200'}`}
            >
              {value}
            </button>
          ))}
          {['1', '2', '3', '-'].map((value) => (
            <button
              key={value}
              onClick={() => handleButtonClick(value)}
              className={`p-4 rounded text-xl ${isOperator(value) ? 'bg-yellow-300 hover:bg-yellow-400' : 'bg-gray-100 hover:bg-gray-200'}`}
            >
              {value}
            </button>
          ))}
          {['.', '0', '=', '+'].map((value) => (
            <button
              key={value}
              onClick={() => handleButtonClick(value)}
              className={`p-4 rounded text-xl ${value === '=' ? 'bg-blue-400 text-white hover:bg-blue-500' : isOperator(value) ? 'bg-yellow-300 hover:bg-yellow-400' : 'bg-gray-100 hover:bg-gray-200'}`}
            >
              {value}
            </button>
          ))}
          <button
            onClick={() => handleButtonClick('C')}
            className="col-span-2 p-4 bg-red-400 rounded text-xl text-white hover:bg-red-500"
          >
            C
          </button>
          <button
            onClick={() => handleButtonClick('AC')}
            className="col-span-2 p-4 bg-red-400 rounded text-xl text-white hover:bg-red-500"
          >
            AC
          </button>
        </div>
      </div>
    </Draggable>
  );
};

export default Calculator;
