import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import Select from 'react-select';
import Searchicon from "../Assets/tableicon/Searchicon.png";
import { RotatingLines } from "react-loader-spinner";
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import StockCreate from "../components/StockCreate";
import StockEdit from "../components/StockEdit";
import StockDelete from "../components/StockDelete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const Stock = () => {
  const [storedPage, setStoredPage] = useState(1);
  const [originalProducts, setOriginalProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [stockData, setStockData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEmptyPopupVisible, setIsEmptyPopupVisible] = useState(false);
  const [importedProducts, setImportedProducts] = useState([]);

  const [isPriceUpdatePopupVisible, setIsPriceUpdatePopupVisible] =
    useState(false);
  const [selectedProductForUpdate, setSelectedProductForUpdate] =
    useState(null);

  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);
  const [selectedProductForDelete, setSelectedProductForDelete] = useState(null);

  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    fetchWarehouses();
  }, []);

  useEffect(() => {
    if (selectedWarehouse) {
      fetchStockData(selectedWarehouse);
    } else {
      setStockData([]);
    }
  }, [selectedWarehouse]);

  const fetchWarehouses = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found in local storage");
        return;
      }

      const response = await fetch("https://pos.farm2bag.com/api/v1/warehouse", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      setWarehouses(responseData.data);
    } catch (error) {
      console.error("Error fetching warehouses:", error);
    }
  };

  const handleStockCreated = () => {
    fetchStockData(selectedWarehouse);
  };

  const fetchStockData = async (warehouseId) => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found in local storage");
        return;
      }

      let url = "https://pos.farm2bag.com/api/v1/stocks";
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      const filteredStockData = responseData.data.filter(
        (stock) => stock.wareHouseId === warehouseId
      );
      setStockData(filteredStockData);
    } catch (error) {
      console.error("Error fetching stock data:", error);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value; // Normalize the query
    setSearchQuery(query);
  
    // If the search query is empty, reset products to the original list
    if (query === "") {
      setProducts(originalProducts);
      setCurrentPage(storedPage); // Set current page back to stored page after resetting products
      return;
    }
  
    // Filter the original products based on the search query in both name and productsCode
    const filteredProducts = originalProducts.filter((product) =>
      product.name.toLowerCase().includes(query) || // Match against name
      product.productsCode.toLowerCase().includes(query) // Match against productsCode
    );
  
    setProducts(filteredProducts);
    setCurrentPage(1); // Reset to the first page after searching
  };
  const filteredProducts = stockData
  .flatMap((stock) =>
    stock.products.filter((product) => {
      // Ensure searchQuery is not null or undefined and normalize it
      const query = (searchQuery || '').toLowerCase().trim();

      // Ensure product properties are not null or undefined before calling toLowerCase
      const productName = (product.name || '').toLowerCase();
      const productCode = (product.productsCode || '').toLowerCase();

      // Filter based on search query
      const matchesSearchQuery =
        query === "" ||
        productName.includes(query) ||
        productCode.includes(query);

      // Filter based on selected brand
      const matchesBrand =
        !selectedBrand || (product.brandName || '') === selectedBrand;

      // Filter based on selected category
      const matchesCategory =
        !selectedCategory || (product.categoryName || '') === selectedCategory;

      return matchesSearchQuery && matchesBrand && matchesCategory;
    })
  )
  .sort((a, b) => a.quantity - b.quantity);

  const handleCategoryChange = (selectedOption) => {
    // selectedOption is the chosen option object from react-select
    setSelectedCategory(selectedOption ? selectedOption.value : '');
    setCurrentPage(1); // Reset to the first page after changing category
  };

  const handleBrandChange = (selectedOption) => {
    // selectedOption is the chosen option object from react-select
    setSelectedBrand(selectedOption ? selectedOption.value : '');
    setCurrentPage(1); // Reset to the first page after changing brand
  };

  const clearFilter = () => {
    setSelectedCategory("");
    setSelectedBrand("");
  }


  const handleNewStockClick = () => {
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  const handleEditPriceClick = (product) => {
    setSelectedProductForUpdate(product);
    setIsPriceUpdatePopupVisible(true);
  };

  const handleClosePriceUpdatePopup = () => {
    setSelectedProductForUpdate(null);
    setIsPriceUpdatePopupVisible(false);
  };

  const handleDeleteClick = (product) => {
    setSelectedProductForDelete(product);
    setIsDeletePopupVisible(true);
  };

  const handleCloseDeletePopup = () => {
    setSelectedProductForDelete(null);
    setIsDeletePopupVisible(false);
  };

  // Excel sheet export
  const downloadExcel = () => {
    try {
      const selectedWarehouseObj = warehouses.find(
        (warehouse) => warehouse.id === selectedWarehouse
      );
      if (!selectedWarehouseObj) {
        console.error("Selected warehouse not found");
        return;
      }

      const formattedData = filteredProducts.map((product) => ({
        ProductName: product.name,
        Quantity: product.quantity,
        brandName: product.brandName,
        Price: product.price,
        Unit: product.unitSymbol,
        WareHouseName: selectedWarehouseObj.name,
        maf_Date: product.maf_Date,
        exp_Date: product.exp_Date,
        Track: product.track,
        "Update(Y/N)": "N",
        Category: product.categoryName,
        // ProductId: product.productId,
        // WarehouseId: selectedWarehouseObj.id,
      }));

      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "StockData");

      // Change the file extension to .xlsx for Excel format
      const filename = `StockData_${selectedWarehouseObj.name}.xlsx`;
      XLSX.writeFile(workbook, filename);

      toast.success("Excel file exported successfully!");
    } catch (error) {
      toast.error("Error exporting Excel file!");
    }
  };

  const handleFileImport = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true);
      const reader = new FileReader();
      reader.onload = async (e) => {
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, { type: 'binary' });

        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        console.log("Parsed Excel data:", jsonData);

        const createdBy = "ramas";

        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found in local storage");
          toast.error("No token found in local storage");
          setLoading(false);
          return;
        }

        try {
          const headerRow = jsonData[0];
          const dataRows = jsonData.slice(1);

          console.log("Header Row:", headerRow);
          console.log("Initial Data Rows Length:", dataRows.length);

          const filteredData = dataRows.filter((row) => {
            return (
              row[headerRow.indexOf("ProductName")] !== undefined &&
              row[headerRow.indexOf("Quantity")] !== undefined &&
              row[headerRow.indexOf("Price")] !== undefined &&
              row[headerRow.indexOf("Unit")] !== undefined &&
              row[headerRow.indexOf("WareHouseName")] !== undefined &&
              row[headerRow.indexOf("maf_Date")] !== undefined &&
              row[headerRow.indexOf("exp_Date")] !== undefined &&
              row[headerRow.indexOf("Track")] !== undefined &&
              row[headerRow.indexOf("brandName")] !== undefined // Ensure brandName is present
            );
          });

          console.log("Filtered Data Length:", filteredData.length);

          const payload = filteredData.map((row) => {
            const maf_Date = XLSX.SSF.format("m/d/yyyy", row[headerRow.indexOf("maf_Date")]);
            const exp_Date = XLSX.SSF.format("m/d/yyyy", row[headerRow.indexOf("exp_Date")]);

            // Convert quantity to a double (floating-point number), default to 0 if invalid
            const quantityValue = row[headerRow.indexOf("Quantity")];
            const quantity = parseFloat(quantityValue);
            if (isNaN(quantity)) {
              console.warn(`Invalid quantity value: ${quantityValue}`);
              return null; // Skip invalid rows
            }

            console.log("Processed Quantity Value:", quantity);

            return {
              wareHouseName: row[headerRow.indexOf("WareHouseName")],
              productName: row[headerRow.indexOf("ProductName")],
              quantity: quantity,
              price: parseFloat(row[headerRow.indexOf("Price")]),
              unitName: row[headerRow.indexOf("Unit")],
              exp_Date: exp_Date,
              maf_Date: maf_Date,
              track: row[headerRow.indexOf("Track")],
              brandName: row[headerRow.indexOf("brandName")] // Add brandName to payload
            };
          }).filter(row => row !== null); // Remove any null values from the payload

          console.log("Payload Data:", payload);

          const url = `https://pos.farm2bag.com/api/v1/stocks/import?createdBy=${createdBy}`;

          const response = await fetch(url, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const result = await response.json();
          console.log("API Response:", result);

          if (result.data && result.data.length > 0) {
            setImportedProducts(result.data);
            setIsEmptyPopupVisible(true); // Show the popup if there are imported products
          }

          setLoading(false);
          handleStockCreated();
          toast.success(result.message || "Excel data imported successfully!");
        } catch (error) {
          console.error("Error during import:", error);
          setLoading(false);
          toast.error("Error importing Excel data!");
        }
      };
      reader.readAsBinaryString(file);
    }
  };







  const handleCloseEmptyPopup = () => {
    setIsEmptyPopupVisible(false);
    setImportedProducts([]); // Clear imported products when closing the popup
    handleStockCreated();
  };

  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleItemsPerPageChange = (e) => {
    setCurrentPage(1);
    setItemsPerPage(parseInt(e.target.value));
  };

  // Calculate pagination indexes
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedProducts = filteredProducts.slice(startIndex, endIndex);

  useEffect(() => {
    // Check if the current page is empty after deletion
    if (paginatedProducts.length === 0 && currentPage > 1) {
      // Move to the previous page
      setCurrentPage(currentPage - 1);
    }
  }, [filteredProducts, currentPage]);

  return (
    <div className="bg-gray-100 ml-64 mt-14 p-12 h-screen">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="container mx-auto">
        <div className="mb-5">
          <div className="flex">
            <h1 className="font-extrabold text-3xl text-left text-gray-500 mt-8">
              Inventory
            </h1>

            <div className="w-8/12 ml-72 ">
              <p className="bg-yellow-200 mb-2 p-1">
                <span className="text-red-700 font-bold ">Note:</span> Please
                import the Excel sheet with the following column names:
                ProductName, Quantity, Price, Unit, WarehouseName,exp_Date(dd/mm/yyyy),maf_Date(dd/mm/yyyy).
              </p>
              <div className="flex ml-auto mr-0 items-center space-x-4 justify-end">
                <button
                  onClick={handleNewStockClick}
                  style={{ backgroundColor: "rgba(66, 255, 97, 1)" }}
                  className="w-32 h-10 font-medium text-sm rounded-lg"
                >
                  Create New Stock
                </button>
                <button
                  onClick={downloadExcel}
                  style={{ backgroundColor: "rgba(66, 255, 97, 1)" }}
                  className="w-28 h-10 font-medium text-sm rounded-lg"
                >
                  Export
                </button>
                <input
                  type="file"
                  accept=".csv"
                  onChange={handleFileImport}
                  className="w-28 h-10 font-medium text-sm rounded-lg"
                  style={{ display: "none" }}
                  id="fileUpload"
                />
                <label
                  htmlFor="fileUpload"
                  style={{ backgroundColor: "rgba(66, 255, 97, 1)" }}
                  className="w-28 h-10 font-medium text-sm rounded-lg text-center flex items-center justify-center cursor-pointer"
                >
                  Import
                </label>
              </div>

            </div>

          </div>

          <div className="mt-2 border-t border-gray-500"></div>
        </div>
        <div className="flex items-center mb-5">
          <div className="ml-0 mr-4">
            <form>
              <div className="relative">
                <select
                  value={selectedWarehouse}
                  onChange={(e) => setSelectedWarehouse(e.target.value)}
                  className="border border-gray-200 px-4 py-2 rounded-lg focus:outline-none focus:border-blue-500"
                >
                  <option value="">Select Warehouse</option>
                  {warehouses.map((warehouse) => (
                    <option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          </div>


          {selectedWarehouse && (
            <div className="ml-0">
              <form>
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search the stock"
                    value={searchQuery}
                    onChange={handleSearch}
                    className="border border-gray-200 px-4 py-2 pl-10 uppercase rounded-lg focus:outline-none focus:border-blue-500"
                  />
                  <img
                    src={Searchicon}
                    alt="search"
                    className="absolute left-3 top-3 w-4 h-4"
                  />
                </div>
              </form>
            </div>
          )}

          {selectedWarehouse && (
            <div className="flex ml-auto mr-0 items-center space-x-4">
              {/* Category Filter */}
              <div className="ml-0 mr-2">
                <form>
                  <div className="relative">
                    <Select
                      value={selectedCategory ? { value: selectedCategory, label: selectedCategory } : null}
                      onChange={handleCategoryChange}
                      options={[...new Set(stockData.flatMap(stock => stock.products.map(product => product.categoryName)))].map(category => ({
                        value: category,
                        label: category
                      }))}
                      placeholder="Select Category"
                      className="basic-single"
                      classNamePrefix="select"
                      styles={{
                        control: (base) => ({
                          ...base,
                          backgroundColor: "#07ff03",
                          borderRadius: "0.5rem",
                        }),
                        menu: (base) => ({
                          ...base,
                          borderRadius: "0.5rem",
                          backgroundColor: "#fcfc03",
                          textAlign: "left",
                        }),
                      }}

                    />
                  </div>
                </form>
              </div>

              {/* Brand Filter */}
              <div className="ml-0">
                <form>
                  <div className="relative mr-4">
                    <Select
                      value={selectedBrand ? { value: selectedBrand, label: selectedBrand } : null}
                      onChange={handleBrandChange}
                      options={[...new Set(stockData.flatMap(stock => stock.products.map(product => product.brandName)))].map(brand => ({
                        value: brand,
                        label: brand
                      }))}
                      placeholder="Select Brand"
                      className="basic-single"
                      classNamePrefix="select"
                      styles={{
                        control: (base) => ({
                          ...base,
                          backgroundColor: "#07ff03",
                          borderRadius: "0.5rem",
                        }),
                        menu: (base) => ({
                          ...base,
                          borderRadius: "0.5rem",
                          backgroundColor: "#fcfc03",
                          textAlign: "left",
                        }),
                      }}
                    />
                  </div>
                </form>
              </div>
              <button className="p-2 bg-orange-400 rounded-lg hover:bg-green-400" onClick={clearFilter}>Clear Filter</button>
            </div>
          )}
        </div>


        {!selectedWarehouse ? (
          <div className="text-center text-gray-500">
            Select the warehouse to view the warehouse products.
          </div>
        ) : (
          <div className="rounded-lg overflow-hidden">
            <table className="table-auto w-full border-collapse border-gray-500 text-center">
              <thead>
                <tr className="bg-gray-200">
                  <th className="px-4 py-2 text-center">Product Name</th>
                  <th className="px-4 py-2 text-center">Brand Name</th>
                  <th className="px-4 py-2 text-center">Category Name</th>
                  <th className="px-4 py-2 text-center">Quantity</th>
                  <th className="px-4 py-2 text-center">Price</th>
                  <th className="px-4 py-2 text-center">Unit</th>
                  <th className="px-4 py-2 text-center">Manufacture date</th>
                  <th className="px-4 py-2 text-center">Expiry Date</th>
                  <th className="px-4 py-2 text-center">Track</th>
                  <th className="px-4 py-2 text-center">Action</th>

                </tr>
              </thead>
              <tbody>
  {loading ? (
    <tr>
      <td colSpan="10" className="text-center py-4">
        <div className="flex flex-col justify-center items-center h-32 ml-52">
          <RotatingLines
            height="80"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            visible={true}
          />
          <h1 className="mt-4">Wait a few seconds for import products</h1>
        </div>
      </td>
    </tr>
  ) : paginatedProducts.length > 0 ? (
    paginatedProducts.map((product, index) => {
      const currentDate = new Date();
      const expDate = new Date(product.exp_Date);

      // Calculate the difference in days
      const diffInDays = Math.ceil(
        (expDate - currentDate) / (1000 * 60 * 60 * 24)
      );

      // Initialize background color variable
      let backgroundColor = "";

      // Determine background color based on the days difference
      if (diffInDays <= 7) {
        backgroundColor = "bg-red-500 text-white"; // Red background for products expiring within 7 days
      } else if (diffInDays > 7 && diffInDays <= 14) {
        backgroundColor = "bg-yellow-300"; // Yellow background for products expiring within 7-14 days
      }

      return (
        <tr
          key={`${product.productId}-${index}`}
          className={`${index % 2 === 0 ? "bg-white" : "bg-gray-200"}`}
        >
          <td className="px-4 py-2 border-gray-500">
            {product.name}({product.productsCode})
          </td>
          <td className="px-4 py-2 border-gray-500">{product.brandName}</td>
          <td className="px-4 py-2 border-gray-500">{product.categoryName}</td>
          <td className="px-4 py-2 border-gray-500">{product.quantity}</td>
          <td className="px-4 py-2 border-gray-500">{product.price}</td>
          <td className="px-4 py-2 border-gray-500">{product.unitSymbol}</td>
          <td className="px-4 py-2 border-gray-500">{product.maf_Date}</td>

          <td className="px-4 py-2 border-gray-500">
            <span className={`px-1 rounded ${backgroundColor}`}>
              {product.exp_Date}
            </span>
          </td>
          <td className="px-4 py-2 border-gray-500">{product.track}</td>
          <td className="px-4 py-2 border-gray-500">
            <FaRegEdit
              size={22}
              className="inline-block mr-1 mb-4"
              onClick={() => handleEditPriceClick(product)}
            />
            <IoMdTrash
              size={30}
              style={{ color: "red" }}
              className="inline-block mr-1 mb-4"
              onClick={() => handleDeleteClick(product)}
            />
          </td>
        </tr>
      );
    })
  ) : (
    <tr>
      <td colSpan="10" className="text-center py-8 pl-96">
        No Products present in the Inventory.
      </td>
    </tr>
  )}
</tbody>

            </table>
            <div className="flex justify-between items-center mt-10 pb-10">
              <div>
                <label htmlFor="itemsPerPage">Records per page: </label>
                <select
                  id="itemsPerPage"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  className="ml-2 border border-gray-200 px-2 py-1 rounded-lg focus:outline-none focus:border-blue-500"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
              </div>
              <div className="flex justify-between items-center mt-4">
                <button
                  onClick={prevPage}
                  className="px-4 py-2 mx-1 rounded bg-gray-200"
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <span className="mx-2">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={nextPage}
                  className="px-4 py-2 mx-1 rounded bg-gray-200"
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {isEmptyPopupVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 ml-28">
          <div className="bg-white p-4 rounded shadow-lg w-full mx-4 md:mx-auto max-w-sm h-auto">
            <p className="text-red-500">
              Check product name mismatch in the file and
            </p>
            <p className="text-red-500">product page if they are same means</p>
            <p className="text-red-500">
              , create them manually in the Inventory.
            </p>
            <div className="mt-2 h-48 overflow-y-auto">
              <table className="table-auto w-full mt-4">
                <thead>
                  <tr>
                    <th className="px-4 py-2 border">Product Name</th>
                  </tr>
                </thead>
                <tbody>
                  {importedProducts.map((product, index) => (
                    <tr key={index}>
                      <td className="px-4 py-2 border text-left">{product}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <button
              onClick={handleCloseEmptyPopup}
              className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}

      <StockCreate
        isVisible={isPopupVisible}
        onClose={handleClosePopup}
        onStockCreated={handleStockCreated}
      />
      <StockEdit
        isVisible={isPriceUpdatePopupVisible}
        onClose={handleClosePriceUpdatePopup}
        product={selectedProductForUpdate}
        warehouseId={selectedWarehouse}
        onProductUpdated={handleStockCreated}
      />
      <StockDelete
        isVisible={isDeletePopupVisible}
        onClose={handleCloseDeletePopup}
        product={selectedProductForDelete}
        warehouseId={selectedWarehouse}
        onDeleteConfirmed={handleStockCreated}
      />
    </div>
  );
};

export default Stock;
