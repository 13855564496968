import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import Searchicon from "../Assets/tableicon/Searchicon.png";
import 'react-toastify/dist/ReactToastify.css';

const Gst = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [isCreatePopupOpen, setCreatePopupOpen] = useState(false);
    const [isEditPopupOpen, setEditPopupOpen] = useState(false);
    const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
    const [newGstType, setNewGstType] = useState('');
    const [newGst, setNewGst] = useState('');
    const [newIgst, setNewIgst] = useState('');
    const [newCgst, setNewCgst] = useState('');
    const [newSgst, setNewSgst] = useState('');
    const [updateGstType, setUpdateGstType] = useState('');
    const [updateGst, setUpdateGst] = useState('');
    const [updateIgst, setUpdateIgst] = useState('');
    const [updateCgst, setUpdateCgst] = useState('');
    const [updateSgst, setUpdateSgst] = useState('');
    const [selectedGst, setSelectedGst] = useState(null);
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }
            const response = await fetch('https://pos.farm2bag.com/api/v1/gst', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const responseData = await response.json();
            setData(responseData.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data
        .filter(item => item.gstType && item.gstType.toLowerCase().includes(searchQuery.toLowerCase()))
        .slice(indexOfFirstItem, indexOfLastItem);

    const handleEdit = (gst) => {
        setSelectedGst(gst);
        setUpdateGstType(gst.gstType);
        setUpdateGst(gst.gst);
        setUpdateIgst(gst.igst);
        setUpdateCgst(gst.cgst);
        setUpdateSgst(gst.sgst);
        setEditPopupOpen(true);
    };

    const handleDelete = (gst) => {
        setSelectedGst(gst);
        setDeletePopupOpen(true);
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleItemsPerPageChange = (e) => {
        setCurrentPage(1);
        setItemsPerPage(parseInt(e.target.value));
    };

    const handleCreateGst = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            if (!newGstType || !newGst || !newIgst || !newCgst || !newSgst) {
                toast.error('Please fill in all fields');
                return;
            }

            const response = await fetch('https://pos.farm2bag.com/api/v1/gst', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    gstType: newGstType, gst: newGst, igst: newIgst, cgst: newCgst, sgst: newSgst, createdBy: role
                })
            });
            if (!response.ok) {
                throw new Error('Failed to create Gst');
            }
            console.log('Gst created successfully');
            setCreatePopupOpen(false);
            setNewGstType('');
            setNewGst('');
            setNewIgst('');
            setNewCgst('');
            setNewSgst('');
            fetchData();
            toast.success('Gst created successfully');
        } catch (error) {
            console.error('Error creating Gst:', error);
        }
    };

const handleUpdateGst = async () => {
    try {
        console.log('Starting GST update process...');
        
        const token = localStorage.getItem('token');
        const role = localStorage.getItem('role');
        
        console.log('Token:', token);
        console.log('Role:', role);

        if (!token) {
            console.error('No token found in local storage');
            return;
        }

        const id = selectedGst.id;
        console.log('Selected GST ID:', id);

        if (!updateGstType.trim() || !updateGst.trim() || !updateIgst.trim() || !updateCgst.trim() || !updateSgst.trim()) {
            toast.error('All fields are required');
            console.log('Validation failed: One or more fields are empty');
            return;
        }

        console.log('All fields are filled, proceeding with API call...');
        
        const response = await fetch(`https://pos.farm2bag.com/api/v1/gst/${id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                gstType: updateGstType,
                gst: updateGst,
                igst: updateIgst,
                cgst: updateCgst,
                sgst: updateSgst,
                modifiedBy: role
            })
        });

        console.log('API call made, awaiting response...');

        if (!response.ok) {
            throw new Error('Failed to update Gst');
        }

        console.log('Gst updated successfully');

        setEditPopupOpen(false);
        setUpdateGstType('');
        setUpdateGst('');
        setUpdateIgst('');
        setUpdateCgst('');
        setUpdateSgst('');
        
        console.log('State reset, fetching updated data...');
        fetchData();
        
        toast.success('Gst updated successfully');
    } catch (error) {
        console.error('Error updating Gst:', error);
    }
};



    const handleDeleteGst = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }
            const id = selectedGst.id;
            const response = await fetch(`https://pos.farm2bag.com/api/v1/gst/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ modifiedBy: role })
            });
            if (!response.ok) {
                throw new Error('Failed to delete Gst');
            }
            console.log('Gst deleted successfully');
            setDeletePopupOpen(false);
            fetchData();
            toast.success('Gst deleted successfully');
        } catch (error) {
            console.error('Error deleting Gst:', error);
        }
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
        <div className='bg-gray-100 ml-64 mt-20 p-12 h-screen'>
            <div className="container mx-auto">
                <div className="mb-10">
                    <h1 className="font-extrabold text-3xl text-left text-gray-500">Gst</h1>
                    <div className="mt-2 border-t border-gray-500"></div>
                </div>
                <div className="flex items-center justify-between mb-5">
                    <div className="relative">
                        <input type="text" placeholder='search' value={searchQuery} onChange={handleSearch} className="border border-gray-200 px-4 py-2 pl-10 rounded-lg focus:outline-none focus:border-blue-500" />
                        <img src={Searchicon} alt="search" className="absolute left-3 top-3 w-4 h-4" />
                    </div>
                    <button onClick={() => setCreatePopupOpen(true)} style={{ backgroundColor: 'rgba(66, 255, 97, 1)' }} className="w-48 h-10 font-medium text-sm rounded-lg"> Create Gst </button>
                </div>

                <div className="rounded-lg overflow-hidden">
                    <table className="min-w-full bg-white border-gray-500 text-center">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="border-b border-gray-200 py-2">Gst Type</th>
                                <th className="border-b border-gray-200 py-2">Gst</th>
                                <th className="border-b border-gray-200 py-2">IGST</th>
                                <th className="border-b border-gray-200 py-2">CGST</th>
                                <th className="border-b border-gray-200 py-2">SGST</th>
                                <th className="border-b border-gray-200 py-2">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((gst, index) => (
                                <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-200"}>
                                    <td className="border-b border-gray-200 py-2">{gst.gstType}</td>
                                    <td className="border-b border-gray-200 py-2">{gst.gst}</td>
                                    <td className="border-b border-gray-200 py-2">{gst.igst}</td>
                                    <td className="border-b border-gray-200 py-2">{gst.cgst}</td>
                                    <td className="border-b border-gray-200 py-2">{gst.sgst}</td>
                                    <td className="border-b border-gray-200 py-2">
                                        <button onClick={() => handleEdit(gst)} className="mr-2 font-bold rounded">
                                            <FaRegEdit size={22} className="inline-block mr-1" />
                                        </button>
                                        <button onClick={() => handleDelete(gst)} className="text-white font-bold rounded">
                                            <IoMdTrash size={30} style={{ color: "red" }} className="inline-block mr-1" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="mt-4 flex justify-between items-center">
                    <div>
                        <span className="mr-2">Items per page:</span>
                        <select className="border border-gray-400 rounded p-1" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                        </select>
                    </div>
                    <div>
                        {data.length > 0 && (
                            <nav className="relative z-0 inline-flex shadow-sm">
                                <div>
                                    <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 disabled:opacity-50"> Previous </button>
                                    {[...Array(Math.ceil(data.length / itemsPerPage))].map((_, index) => (
                                        <button key={index} onClick={() => paginate(index + 1)} className={`-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${currentPage === index + 1 ? 'text-gray-700 bg-gray-100' : 'text-gray-500 hover:bg-gray-50'} focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}>
                                            {index + 1}
                                        </button>
                                    ))}
                                    <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(data.length / itemsPerPage)} className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 disabled:opacity-50"> Next </button>
                                </div>
                            </nav>
                        )}
                    </div>
                </div>

                {isCreatePopupOpen && (
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="bg-white p-8 rounded-lg shadow-lg">
                            <h2 className="text-xl font-bold mb-4">Create GST</h2>
                            <input type="text" placeholder="Gst Type" value={newGstType} onChange={(e) => setNewGstType(e.target.value)} className="w-full mb-2 p-2 border rounded" />
                            <input type="text" placeholder="Gst" value={newGst} onChange={(e) => setNewGst(e.target.value)} className="w-full mb-2 p-2 border rounded" />
                            <input type="text" placeholder="IGST" value={newIgst} onChange={(e) => setNewIgst(e.target.value)} className="w-full mb-2 p-2 border rounded" />
                            <input type="text" placeholder="CGST" value={newCgst} onChange={(e) => setNewCgst(e.target.value)} className="w-full mb-2 p-2 border rounded" />
                            <input type="text" placeholder="SGST" value={newSgst} onChange={(e) => setNewSgst(e.target.value)} className="w-full mb-4 p-2 border rounded" />
                            <div className="flex justify-end">
                                <button onClick={() => setCreatePopupOpen(false)} className="mr-2 px-4 py-2 border rounded bg-gray-200 hover:bg-gray-300">Cancel</button>
                                <button onClick={handleCreateGst} className="px-4 py-2 border rounded bg-blue-500 text-white hover:bg-blue-600">Create</button>
                            </div>
                        </div>
                    </div>
                )}

                {isEditPopupOpen && (
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="bg-white p-8 rounded-lg shadow-lg">
                            <h2 className="text-xl font-bold mb-4">Edit GST</h2>
                            <input type="text" placeholder="Gst Type" value={updateGstType} onChange={(e) => setUpdateGstType(e.target.value)} className="w-full mb-2 p-2 border rounded" />
                            <input type="text" placeholder="Gst" value={updateGst} onChange={(e) => setUpdateGst(e.target.value)} className="w-full mb-2 p-2 border rounded" />
                            <input type="text" placeholder="IGST" value={updateIgst} onChange={(e) => setUpdateIgst(e.target.value)} className="w-full mb-2 p-2 border rounded" />
                            <input type="text" placeholder="CGST" value={updateCgst} onChange={(e) => setUpdateCgst(e.target.value)} className="w-full mb-2 p-2 border rounded" />
                            <input type="text" placeholder="SGST" value={updateSgst} onChange={(e) => setUpdateSgst(e.target.value)} className="w-full mb-4 p-2 border rounded" />
                            <div className="flex justify-end">
                                <button onClick={() => setEditPopupOpen(false)} className="mr-2 px-4 py-2 border rounded bg-gray-200 hover:bg-gray-300">Cancel</button>
                                <button onClick={handleUpdateGst} className="px-4 py-2 border rounded bg-blue-500 text-white hover:bg-blue-600">Update</button>
                            </div>
                        </div>
                    </div>
                )}

                {isDeletePopupOpen && (
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="bg-white p-8 rounded-lg shadow-lg">
                            <h2 className="text-xl font-bold mb-4">Delete GST</h2>
                            <p>Are you sure you want to delete this GST?</p>
                            <div className="flex justify-end mt-4">
                                <button onClick={() => setDeletePopupOpen(false)} className="mr-2 px-4 py-2 border rounded bg-gray-200 hover:bg-gray-300">Cancel</button>
                                <button onClick={handleDeleteGst} className="px-4 py-2 border rounded bg-red-500 text-white hover:bg-red-600">Delete</button>
                            </div>
                        </div>
                    </div>
                )}

                <ToastContainer />
            </div>
        </div>
    );
};

export default Gst;