import React, { useState, useEffect, useRef } from "react";
import filter from "../Assets/tableicon/filter.png";
import Searchicon from "../Assets/tableicon/Searchicon.png";
import StockReport from "../components/StockReport"; // StockReturn component
import SalesReturn from "../components/SalesReturn"; // SalesReturn component
import Eye from "../Assets/tableicon/Eye.svg";

import {
  parse,
  format,
  isSameDay,
  startOfWeek,
  subWeeks,
  endOfWeek,
  startOfMonth,
  subMonths,
  endOfMonth,
  endOfDay,
  isWithinInterval,
} from "date-fns";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { data } from "autoprefixer";
import Modal from "../components/Modal ";

const Report = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState("Select Date");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const [currentReport, setCurrentReport] = useState("Sales Return");
  const [warehouses, setWarehouses] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [stockData, setStockData] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [dataAvailable, setDataAvailable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");

  // State and handlers for custom date inputs
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);

  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("");
  const [selectedStocks, setSelectedStocks] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [units, setUnits] = useState([]);

  const dropdownRef = useRef(null);
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [salesReturnData, setSalesReturnData] = useState([]);

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch("https://pos.farm2bag.com/api/v1/warehouse", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data && Array.isArray(data.data)) {
          setWarehouses(data.data);
          setLoading(false);
        } else {
          throw new Error("Unexpected warehouse data format");
        }
      } catch (error) {
        console.error("Error fetching warehouses:", error);
      }
    };

    const fetchSalesData = async () => {
      try {
        const token = localStorage.getItem("token");
        setLoading(true);
        const response = await fetch(
          "https://pos.farm2bag.com/api/v1/billing/getallForSales",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (data && Array.isArray(data.data)) {
          const formattedData = data.data
            .map((item) => ({
              ...item,
              createdAt: new Date(item.billing.createdAt), // Convert createdAt string to Date object
            }))
            .sort((a, b) => b.createdAt - a.createdAt); // Sort in descending order by createdAt
          setSalesData(formattedData);
        } else {
          console.error("Unexpected stock data format:", data);
        }
      } catch (error) {
        console.error("Error fetching sales data:", error);
      } finally {
        setLoading(false);
      }
    };


    // const fetchSalesData = async () => {
    //   try {
    //     const token = localStorage.getItem("token");
    //     setLoading(true);
    //     const response = await fetch(
    //       "https://pos.farm2bag.com/api/v1/billing/getall",
    //       {
    //         headers: {
    //           Authorization: `Bearer ${token}`,
    //         },
    //       }
    //     );
    //     const data = await response.json();
    //     if (data && Array.isArray(data.data)) {
    //       const formattedData = data.data.map((item) => ({
    //         ...item,
    //         invoiceDate: new Date(item.billing.invoiceDate), // Convert invoiceDate string to Date object
    //       }));
    //       setSalesData(formattedData);
    //     } else {
    //       console.error("Unexpected stock data format:", stockData);
    //     }
    //   } catch (error) {
    //     console.error("Error fetching sales data:", error);
    //   } finally {
    //     setLoading(false);
    //   }
    // };
    fetchSalesData();
    fetchData();
  }, []);

  // Fuction to close the dropdown with click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  //To Get sales return data
  useEffect(() => {
    const fetchSalesReturnData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          "https://pos.farm2bag.com/api/v1/billing/getAll-return",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        console.log(data);
        if (data && Array.isArray(data.data)) {
          // Filter products with status "Removed"
          const filteredData = data.data
            .map((item) => {
              const removedProducts = item.billingItems.filter((billingItem) =>
                billingItem.product.some(
                  (product) => product.productStatus === "Removed"
                )
              );
              return { ...item, billingItems: removedProducts };
            })
            .filter((item) => item.billingItems.length > 0); // Keep only entries with removed products

          setSalesReturnData(filteredData); // Update the state
          console.log("Filtered Data:", filteredData);
        } else {
          console.error("Unexpected response format:", data);
        }
      } catch (error) {
        console.error("Error fetching sales return data:", error);
      }
    };

    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");

        // Fetch warehouses
        const warehouseResponse = await fetch(
          "https://pos.farm2bag.com/api/v1/warehouse",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const warehouseData = await warehouseResponse.json();
        if (warehouseData && Array.isArray(warehouseData.data)) {
          setWarehouses(warehouseData.data);
        } else {
          console.error("Unexpected warehouse data format:", warehouseData);
        }
      } catch (error) {
        console.error("Error fetching warehouses:", error);
      }
    };
    fetchData();
    fetchSalesReturnData();
  }, []);

  // fetching unit api when stock report popup filter
  useEffect(() => {
    const fetchUnits = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await fetch(
          "https://pos.farm2bag.com/api/v1/measurements",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (data.status === 200) {
          setUnits(data.data);
        } else {
          console.error("Failed to fetch units:", data.message);
        }
      } catch (error) {
        console.error("Error fetching units:", error);
      }
    };

    fetchUnits();
  }, []);

  // Function to parse dd/mm/yyyy formatted dates
  const parseInputDate = (dateString) => {
    return parse(dateString, "yyyy-MM-dd", new Date());
  };

  // Utility function to format dates 
  const formatDate = (dateString) => {
    if (!dateString) return "N/A"; // Handle null or undefined values 

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed 
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Handlers
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleDateRangeChange = (e) => {
    setSelectedDateRange(e.target.getAttribute("value"));
    setIsDropdownOpen(false);
  };

  const handleReset = () => {
    setSelectedDateRange("Select Date");
    setIsDropdownOpen(false);
    setSearchQuery("");
    setCustomStartDate("");
    setCustomEndDate("");
    setSelectedPaymentStatus("");
  };

  const handleWarehouseChange = (e) => {
    const warehouseId =
      e.target.value === "All Warehouse" ? null : e.target.value;
    setSelectedWarehouse(warehouseId);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };
  const handleCustomSalesStartDateChange = (event) => {
    const startDate = parseInputDate(event.target.value);
    setCustomStartDate(startDate);
  };

  const handleCustomSalesEndDateChange = (event) => {
    const endDate = parseInputDate(event.target.value);
    setCustomEndDate(endDate);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  // Function to handle payment status dropdown change
  const handlePaymentStatusChange = (e) => {
    setSelectedPaymentStatus(e.target.value);
  };

  // Function to handel Filter reset
  const handleReset2 = () => {
    setSelectedStatus("");
    setSelectedPaymentStatus("");
  };

  // Function to handel stock filter
  const handleStocksChange = (e) => {
    setSelectedStocks(e.target.value);
  };
  const handleUnitChange = (e) => {
    setSelectedUnit(e.target.value);
  };
  const handleReset3 = () => {
    setSelectedStocks("");
    setSelectedUnit("");
  };

  // Stock report PDF generation
  const handlestockReportData = (data) => {
    setStockData(data);
  };

  // Sales return PDF generation
  const handleSalesReturnData = (data) => {
    setSalesReturnData(data);
  };

  const renderTable = () => {
    let filteredData = selectedWarehouse
      ? salesData.filter((item) => item.warehouseId === selectedWarehouse)
      : salesData;

    if (searchQuery) {
      const normalizedQuery = searchQuery.toLowerCase().trim();
      filteredData = filteredData.filter(
        (item) =>
          item.billing.invoice.toLowerCase().includes(normalizedQuery) ||
          item.customer.toLowerCase().includes(normalizedQuery) ||
          item.warehouseName.toLowerCase().includes(normalizedQuery)
      );
    }

    if (selectedPaymentStatus) {
      filteredData = filteredData.filter(
        (item) =>
          item.billing &&
          item.billing.status &&
          item.billing.status.toLowerCase() ===
          selectedPaymentStatus.toLowerCase()
      );
    }

    // Apply date range filter
    if (selectedDateRange !== "Select Date") {
      const today = new Date();

      switch (selectedDateRange) {
        case "Today":
          filteredData = filteredData.filter((item) =>
            isSameDay(
              parse(item.billing.invoiceDate, "dd/MM/yyyy", new Date()),
              today
            )
          );
          break;
        case "This Week":
          const startOfWeekDate = startOfWeek(today);
          filteredData = filteredData.filter((item) =>
            isWithinInterval(
              parse(item.billing.invoiceDate, "dd/MM/yyyy", new Date()),
              {
                start: startOfWeekDate,
                end: today,
              }
            )
          );
          break;
        case "Last Week":
          const startOfLastWeekDate = startOfWeek(subWeeks(today, 1));
          const endOfLastWeekDate = endOfWeek(subWeeks(today, 1));
          filteredData = filteredData.filter((item) =>
            isWithinInterval(
              parse(item.billing.invoiceDate, "dd/MM/yyyy", new Date()),
              {
                start: startOfLastWeekDate,
                end: endOfLastWeekDate,
              }
            )
          );
          break;
        case "This Month":
          const startOfMonthDate = startOfMonth(today);
          filteredData = filteredData.filter((item) =>
            isWithinInterval(
              parse(item.billing.invoiceDate, "dd/MM/yyyy", new Date()),
              {
                start: startOfMonthDate,
                end: today,
              }
            )
          );
          break;
        case "Last Month":
          const startOfLastMonthDate = startOfMonth(subMonths(today, 1));
          const endOfLastMonthDate = endOfMonth(subMonths(today, 1));
          filteredData = filteredData.filter((item) =>
            isWithinInterval(
              parse(item.billing.invoiceDate, "dd/MM/yyyy", new Date()),
              {
                start: startOfLastMonthDate,
                end: endOfLastMonthDate,
              }
            )
          );
          break;
        case "Custom Date":
          if (customStartDate && customEndDate) {
            filteredData = filteredData.filter((item) =>
              isWithinInterval(
                parse(item.billing.invoiceDate, "dd/MM/yyyy", new Date()),
                {
                  start: customStartDate,
                  end: customEndDate,
                }
              )
            );
          }
          break;
        default:
          break;
      }
    }

    const totalItems = filteredData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const currentItems = filteredData.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    if (currentItems.length === 0) {
      return (
        <div className="text-center py-4 text-gray-700">
          No data available for the selected filters.
        </div>
      );
    }

    return (
      <div className="bg-gray-100">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-blue-500">
            <tr>
              <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">
                Invoice
              </th>
              <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">
                Invoice Date
              </th>
              <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">
                Customer
              </th>
              <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">
                Warehouse
              </th>
              <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">
                Payment Mode
              </th>
              <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">
                Grand Total
              </th>
              <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">
                Payment Status
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {currentItems.map((item) => (
              <tr key={item.billing.id} className="hover:bg-green-200">
                <td className="py-3 px-4 text-center text-red-800 ">
                  {item.billing.invoice}
                </td>
                <td className="py-3 px-4 text-center">
                  {item.billing.invoiceDate}{' '}
                  {new Date(item.billing.createdAt).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                  })}
                </td>

                <td className="py-3 px-4 text-center">{item.customer}</td>
                <td className="py-3 px-4 text-center">{item.warehouseName}</td>
                <td className="py-3 px-4 text-center">
                  {item.billing.paymentMode}
                </td>
                <td className="py-3 px-4 text-center">
                  ₹{item.billing.totalPrice}
                </td>
                <td className="py-3 px-4 text-center">{item.billing.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className=" bg-gray-100 flex justify-between items-center mt-12">
          <div>
            <label htmlFor="itemsPerPage">Records per page: </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              className="border border-gray-300 rounded-lg px-4 py-2 focus:outline-none ml-2"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </select>
          </div>
          <div className="bg-gray-100 flex justify-between items-center mt-4">
            <button
              onClick={() => handlePagination(currentPage - 1)}
              disabled={currentPage === 1}
              className={`px-3 py-2 border rounded-lg mx-1 border-none ${currentPage === 1
                  ? "bg-gray-200 text-gray-500"
                  : "bg-green-500 text-white"
                }`}
            >
              Previous
            </button>
            <span className="px-3 py-1">
              {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => handlePagination(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`px-3 py-2 border rounded-lg mx-1 border-none ${currentPage === totalPages
                  ? "bg-gray-200 text-gray-500"
                  : "bg-green-500 text-white"
                }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const SalesReturn = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProductDetails, setSelectedProductDetails] = useState(null);
    //  console.log(data);
    let filteredData = selectedWarehouse
      ? salesReturnData.filter((item) => item.warehouseId === selectedWarehouse)
      : salesReturnData;

    if (searchQuery) {
      const normalizedQuery = searchQuery.toLowerCase().trim();
      filteredData = filteredData.filter(
        (item) =>
          item.billing.invoice.toLowerCase().includes(normalizedQuery) ||
          item.customer.name.toLowerCase().includes(normalizedQuery) ||
          item.warehouseName.toLowerCase().includes(normalizedQuery)
      );
    }
    if (selectedStatus) {
      filteredData = filteredData.filter(
        (item) =>
          item.billing.status.toLowerCase() === selectedStatus.toLowerCase()
      );
    }
    // Apply payment status filter
    if (selectedPaymentStatus) {
      console.log("Selected Payment Status:", selectedPaymentStatus);
      filteredData = filteredData.filter(
        (item) =>
          item.billing &&
          item.billing.status &&
          item.billing.status.toLowerCase() ===
          selectedPaymentStatus.toLowerCase()
      );
      // console.log(filteredData);
    }
    // Apply date range filter
    if (filterStatus === "Removed") {
      console.loga("filterStatus");
      filteredData = filteredData.filter((item) =>
        item.billingItems.some(
          (billingItem) => billingItem.productStatus.toLowerCase() === "removed"
        )
      );
    }

    if (filterStatus === "all") {
      const today = new Date();
      const startOfToday = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      const endOfToday = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 1
      );

      filteredData = filteredData.filter((item) =>
        item.billingItems.some(
          (billingItem) =>
            billingItem.product[0].productStatus === "Removed" &&
            isWithinInterval(new Date(billingItem.createdAt), {
              start: startOfToday,
              end: endOfToday,
            })
        )
      );
    }

    if (customSalesStartDate && customSalesEndDate) {
      filteredData = filteredData.filter((item) =>
        isWithinInterval(new Date(item.billing.modifiedAt), {
          start: new Date(customSalesStartDate),
          end: endOfDay(new Date(customSalesEndDate)),
        })
      );
    }

    const totalItems = filteredData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const currentItems = filteredData.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
    const handleOpenModal = (item) => {
      setSelectedProductDetails(item);
      setIsModalOpen(true);
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
      setSelectedProductDetails(null);
    };

    if (currentItems.length === 0) {
      return (
        <div className="text-center py-4 text-gray-700">
          No data available for the selected filters.
        </div>
      );
    }

    return (
      <div className="bg-gray-100">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-blue-500">
            <tr>
              <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">
                Invoice
              </th>
              <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">
                Customer
              </th>
              <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">
                Warehouse
              </th>
              <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">
                Product Count
              </th>

              <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">
                Date
              </th>
              <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {currentItems.map((item) => (
              <tr key={item.billing.id} className="hover:bg-green-200">
                <td className="py-3 px-4 text-center text-red-800">
                  {item.billing.invoice}
                </td>
                <td className="px-4 py-2 text-gray-700">
                  {item.customer.name}
                </td>
                <td className="px-4 py-2 text-gray-700">
                  {item.warehouseName}
                </td>
                <td className="px-4 py-2 text-gray-700">
                  {item.billingItems.map((billingItem) => {
                    // Filter products with 'Removed' status and count them
                    const removedProductCount = billingItem.product.filter(
                      (product) => product.productStatus === "Removed"
                    ).length;

                    return (
                      <div key={billingItem.id} className="block">
                        {removedProductCount > 0 ? (
                          <span className="text-red-500">
                            {removedProductCount}{" "}
                          </span>
                        ) : (
                          <span className="text-green-500">
                            No Products Returned
                          </span>
                        )}
                      </div>
                    );
                  })}
                </td>

                {/* <td className="px-4 py-2 text-gray-700">
                  {item.billingItems.map((billingItem) => (
                    <span key={billingItem.id}>
                      {billingItem.product[0].productStatus === "Removed" ? (
                        <span className="text-red-500">Returned</span>
                      ) : (
                        <span className="text-green-500">Purchase</span>
                      )}
                    </span>
                  ))}
                </td> */}

                <td className="px-4 py-2 text-gray-700">
                  {formatDate(item.billingItems[0]?.modifiedAt)}
                </td>

                <td className="px-4 py-2 text-gray-700">
                  <button
                    className="text-blue-500 hover:underline"
                    onClick={() => handleOpenModal(item)}
                  >
                    <img src={Eye} className="w-6 h-6 mr-2" alt="Eye Icon" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className=" bg-gray-100 flex justify-between items-center mt-12">
          <div>
            <label htmlFor="itemsPerPage">Records per page: </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              className="border border-gray-300 rounded-lg px-4 py-2 focus:outline-none ml-2"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </select>
          </div>
          <div className="bg-gray-100 flex justify-between items-center mt-4">
            <button
              onClick={() => handlePagination(currentPage - 1)}
              disabled={currentPage === 1}
              className={`px-3 py-2 border rounded-lg mx-1 border-none ${currentPage === 1
                  ? "bg-gray-200 text-gray-500"
                  : "bg-green-500 text-white"
                }`}
            >
              Previous
            </button>
            <span className="px-3 py-1">
              {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => handlePagination(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`px-3 py-2 border rounded-lg mx-1 border-none ${currentPage === totalPages
                  ? "bg-gray-200 text-gray-500"
                  : "bg-green-500 text-white"
                }`}
            >
              Next
            </button>
          </div>
        </div>

        {/* Modal */}
        {isModalOpen && selectedProductDetails && (
          <Modal onClose={handleCloseModal}>
            <div className="p-6 max-w-4xl mx-auto bg-white rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold mb-6">Product Details</h2>

              {/* Details Card */}
              <div className="mb-6 p-4 border border-gray-200 rounded-lg bg-gray-50">
                <div className="grid grid-cols-2 gap-x-6">
                  <div className="mb-4">
                    <p className="font-semibold text-gray-700">Invoice:</p>
                    <p className="text-gray-600">{selectedProductDetails.billing.invoice}</p>
                  </div>
                  <div className="mb-4">
                    <p className="font-semibold text-gray-700">Customer:</p>
                    <p className="text-gray-600">{selectedProductDetails.customer.name}</p>
                  </div>
                  <div className="mb-4">
                    <p className="font-semibold text-gray-700">Warehouse:</p>
                    <p className="text-gray-600">{selectedProductDetails.warehouseName}</p>
                  </div>
                  <div className="mb-4">
                    <p className="font-semibold text-gray-700">Status:</p>
                    <p className="text-gray-600">{selectedProductDetails.billing.status}</p>
                  </div>
                  <div className="mb-4">
                    <p className="font-semibold text-gray-700">Total Price:</p>
                    <p className="text-gray-600">{selectedProductDetails.billing.totalPrice}</p>
                  </div>
                  <div className="mb-4">
                    <p className="font-semibold text-gray-700">Date:</p>
                    <p className="text-gray-600">
                      {selectedProductDetails.billing.invoiceDate}
                    </p>
                  </div>
                </div>
              </div>

              {/* Products Table */}
              <h3 className="text-xl font-semibold mb-4">Products</h3>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200 rounded-lg">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="px-4 py-2 text-left text-gray-600">Product Name</th>
                      <th className="px-4 py-2 text-left text-gray-600">Quantity</th>
                      <th className="px-4 py-2 text-left text-gray-600">Total Price</th>
                      <th className="px-4 py-2 text-left text-gray-600">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedProductDetails.billingItems.map((billingItem) => (
                      <React.Fragment key={billingItem.id}>
                        {billingItem.product.map((product) => (
                          <tr key={product.productId}>
                            <td className="px-4 py-2 border-b">{product.productName}</td>
                            <td className="px-4 py-2 border-b">{product.quantity}</td>
                            <td className="px-4 py-2 border-b">{product.totalPrice}</td>
                            <td className="px-4 py-2 border-b">
                              {product.productStatus}
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Modal>
        )}


      </div>
    );
  };

  // Handle items per page change
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  // Excel Export
  const exportToExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    let formattedData;
    let fileName;

    switch (currentReport) {
      case "Sales Report":
        formattedData = salesData.map((item) => ({
          Invoice: item.billing.invoice,
          Customer: item.customer,
          Warehouse: item.warehouseName,
          Status: item.billing.status === "Paid" ? "Received" : "Pending",
          "Grand Total": item.billing.totalPrice,
          Paid: item.billing.totalPrice,
          "Payment Status": item.billing.status,
          "Invoice Date": item.billing.invoiceDate,
        }));
        fileName = `Sales_Report_${new Date()
          .toISOString()
          .slice(0, 10)}${fileExtension}`;
        break;

      case "Sales Return":
        formattedData = salesReturnData.map((item) => ({
          Invoice: item.billing.invoice,
          Customer: item.customer.name,
          Date: new Date(item.billing.createdAt).toLocaleDateString("en-GB"),
          Product: item.billingItems[0].product[0].productName,
          Quantity: item.billingItems[0].product[0].quantity,
          Status: item.billingItems[0].product[0].productStatus,
          Unit: item.billingItems[0].product[0].unit,
          Remarks: item.billingItems[0].remarks,
        }));
        fileName = `Sales_Return_Report_${new Date()
          .toISOString()
          .slice(0, 10)}${fileExtension}`;
        break;

      case "Stock Report":
        formattedData = stockData.map((item) => ({
          Code: item.productsCode,
          Warehouse: item.warehouse,
          "Product Name": item.name,
          Unit: item.unitSymbol,
          Price: item.price,
          "Current Stock": item.quantity,
        }));
        fileName = `Stock_Report_${new Date()
          .toISOString()
          .slice(0, 10)}${fileExtension}`;
        break;

      default:
        return;
    }

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName);
  };

  // PDF Export
  const exportToPDF = () => {
    const unit = "pt";
    const size = "A4";
    const doc = new jsPDF("p", unit, size);

    let title;
    let headers = [];
    let data = [];

    switch (currentReport) {
      case "Sales Report":
        title = `Sales Report - ${new Date().toLocaleDateString()}`;
        headers = [
          [
            "Invoice",
            "Invoice Date",
            "Customer",
            "Warehouse",
            "Payment Mode",
            "Grand Total",
            "Payment Status",
          ],
        ];
        data = salesData.map((item) => [
          item.billing.invoice,
          item.billing.invoiceDate,
          item.customer,
          item.warehouseName,
          item.billing.paymentMode,
          item.billing.totalPrice.toString(),
          item.billing.status,
        ]);
        break;

      case "Sales Return":
        title = `Sales Return Report - ${new Date().toLocaleDateString()}`;
        headers = [
          [
            "Invoice",
            "Date",
            "Customer",
            "Product",
            "Quantity",
            "Unit",
            "Status",
            "Remarks",
          ],
        ];
        data = salesReturnData.map((item) => [
          item.billing.invoice,
          new Date(item.billing.createdAt).toLocaleDateString("en-GB"),
          item.customer.name,
          item.billingItems[0].product[0].productName,
          item.billingItems[0].product[0].quantity,
          item.billingItems[0].product[0].unit,

          item.billingItems[0].product[0].productStatus,
          item.billingItems[0].remarks,
        ]);
        break;

      case "Stock Report":
        title = `Stock Report - ${new Date().toLocaleDateString()}`;
        headers = [
          [
            "Code",
            "Warehouse",
            "Product Name",
            "Unit",
            "Price",
            "Current Stock",
          ],
        ];
        data = stockData.map((item) => [
          item.productsCode,
          item.warehouse,
          item.name,
          item.unitSymbol,
          item.price,
          item.quantity,
        ]);
        break;

      default:
        return;
    }

    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.text(title, 40, 60);

    doc.autoTable({
      head: headers,
      body: data,
      startY: 80,
      theme: "grid",
      headStyles: {
        fillColor: [52, 73, 94],
        textColor: 255,
        fontStyle: "bold",
        fontSize: 8,
      },
      styles: {
        fontSize: 6,
        cellPadding: 6,
        overflow: "linebreak",
      },
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 80 },
        2: { cellWidth: 80 },
        3: { cellWidth: 60 },
        4: { cellWidth: 60 },
        5: { cellWidth: 60 },
      },
      margin: { top: 100, left: 40, right: 40 },
      didDrawPage: function (data) {
        const totalPages = doc.internal.getNumberOfPages();
        if (data.pageNumber === totalPages) {
          const footer = `Generated on ${new Date().toLocaleString()}`;
          doc.setFontSize(10);
          doc.text(footer, 40, doc.internal.pageSize.height - 30);
        }
      },
    });

    doc.save(`report_${new Date().toISOString().slice(0, 10)}.pdf`);
  };

  const [currentReport, setCurrentReport] = useState("Sales Return");
  const [filterStatus, setFilterStatus] = useState("");
  const [showCustomDates, setShowCustomDates] = useState(false);
  const [customSalesStartDate, setCustomSalesStartDate] = useState("");
  const [customSalesEndDate, setCustomSalesEndDate] = useState("");

  const handleCustomStartDateChange = (e) => {
    setCustomSalesStartDate(e.target.value);
  };

  const handleCustomEndDateChange = (e) => {
    setCustomSalesEndDate(e.target.value);
  };

  return (
    <div className="bg-gray-100 ml-64 mt-20 p-12 min-h-screen">
      <div className="container mx-auto flex flex-col flex-1">
        <div className="mb-10">
          <h1 className="font-extrabold text-3xl text-left text-gray-500">
            Report
          </h1>
          <div className="mt-2 border-t border-gray-500"></div>
        </div>
        <div className="mb-4">
          <span className="font-semibold text-lg">Warehouse</span>
          <select
            className="ml-2 p-3 border mr-7 w-60 rounded-lg bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="All Warehouse"
            aria-label="Select Warehouse"
            onChange={handleWarehouseChange}
          >
            <option>All Warehouse</option>
            {warehouses.map((warehouse) => (
              <option key={warehouse.id} value={warehouse.id}>
                {warehouse.name}
              </option>
            ))}
          </select>
        </div>

        <div className="flex mb-4 items-center justify-between">
          <div className="relative">
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearch}
              className="border border-gray-200 px-4 py-2 pl-10 rounded-lg focus:outline-none focus:border-blue-500"
            />
            <img
              src={Searchicon}
              alt="search"
              className="absolute left-3 top-3 w-4 h-4"
            />
          </div>

          <div className="flex items-center">
            {(currentReport === "Sales Report" ||
              currentReport === "Stock Report") && (
                <button
                  className="text-white px-4 py-2 rounded-lg flex items-center"
                  onClick={togglePopup}
                >
                  <img
                    src={filter}
                    alt="Filter"
                    className="w-12 h-10 rounded-lg cursor-pointer"
                  />
                </button>
              )}
            {isPopupOpen && currentReport === "Sales Report" && (
              <div className="absolute mt-44 w-54  rounded-lg bg-gray-100 shadow-lg py-2 z-10 ml-2 ">
                <div className="px-4 py-2">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Payment Status
                  </label>
                  <select
                    className="border rounded-md w-full px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onChange={handlePaymentStatusChange}
                    value={selectedPaymentStatus}
                  >
                    <option value="">Select Payment Status</option>
                    <option value="Paid">Paid</option>
                    <option value="Pending">Pending</option>
                  </select>
                  <button
                    onClick={handleReset}
                    className="text-black-500 bg-blue-500 mt-2 px-6 py-1 rounded hover:scale-105"
                    style={{ backgroundColor: "rgba(66, 255, 97, 1)" }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            )}

            {/* Stock Report Filter */}
            {isPopupOpen && currentReport === "Stock Report" && (
              <div className="absolute z-10 p-3 mt-80 mb-6 ml-2 w-60 max-w-xs bg-gray-100 border rounded-lg shadow-lg">
                <div className="px-4 py-2">
                  <label className="block text-left text-gray-700 text-sm font-bold mb-2">
                    Stock Quantity
                  </label>
                  <select
                    className="border rounded-md w-full px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-200"
                    onChange={handleStocksChange}
                    value={selectedStocks}
                  >
                    <option value="">Select Quantity</option>
                    <option value="500">Less 500</option>
                    <option value="200">Less 200</option>
                    <option value="100">Less 100</option>
                    <option value="50">Less 50</option>
                  </select>
                </div>
                <div className="px-4 py-2">
                  <label className="block text-left text-gray-700 text-sm font-bold mb-2">
                    Unit
                  </label>
                  <select
                    className="border rounded-md w-full px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-200"
                    onChange={handleUnitChange}
                    value={selectedUnit}
                  >
                    <option value="">Select Unit</option>
                    {units.map((unit) => (
                      <option key={unit.id} value={unit.unitSymbol}>
                        {unit.unitSymbol}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="p-2 hover:bg-gray-100 cursor-pointer">
                  <button
                    onClick={handleReset3}
                    className="text-black-500 bg-blue-500 px-6 py-1 rounded hover:scale-105"
                    style={{ backgroundColor: "rgba(66, 255, 97, 1)" }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            )}
            {/* ); */}

            <button
              className="w-28 h-10 font-medium text-sm rounded-lg"
              onClick={exportToExcel}
              style={{ backgroundColor: "rgba(66, 255, 97, 1)" }}
            >
              Excel
            </button>
            <button
              className="w-28 h-10 font-medium text-sm rounded-lg ml-2"
              onClick={exportToPDF}
              style={{ backgroundColor: "rgb(255, 0, 0)" }}
            >
              PDF
            </button>

            <div className="relative ml-2">
              {currentReport === "Sales Report" && (
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="border border-gray-200 px-4 py-2 rounded-lg flex items-center justify-between focus:outline-none focus:ring-2 focus:ring-blue-500"
                  style={{ minWidth: "10rem" }}
                  aria-label="Select Date Range"
                >
                  <span>{selectedDateRange}</span>
                  <svg
                    className="w-4 h-4 ml-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
              )}
              {isDropdownOpen && (
                <div className="absolute z-10 mt-2 w-full max-w-xs bg-white border rounded-md shadow-lg">
                  <div
                    onClick={handleDateRangeChange}
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                    value="Today"
                  >
                    Today
                  </div>
                  <div
                    onClick={handleDateRangeChange}
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                    value="This Week"
                  >
                    This Week
                  </div>
                  <div
                    onClick={handleDateRangeChange}
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                    value="Last Week"
                  >
                    Last Week
                  </div>
                  <div
                    onClick={handleDateRangeChange}
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                    value="This Month"
                  >
                    This Month
                  </div>
                  <div
                    onClick={handleDateRangeChange}
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                    value="Last Month"
                  >
                    Last Month
                  </div>
                  <div
                    onClick={handleDateRangeChange}
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                    value="Custom Date"
                  >
                    Custom Date
                  </div>
                  <div className="p-2 hover:bg-gray-100 cursor-pointer">
                    <button
                      onClick={handleReset}
                      className="text-black-500 bg-blue-500 px-6 py-1 rounded hover:scale-105"
                      style={{ backgroundColor: "rgba(66, 255, 97, 1)" }}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              )}

              {currentReport === "Sales Return" && (
                <div className="flex items-center space-x-2">
                  <select
                    className="border border-gray-200 px-4 py-2 rounded-lg"
                    onChange={(e) => {
                      setFilterStatus(e.target.value);
                      setShowCustomDates(e.target.value === "custom");
                    }}
                  >
                    <option value="alldate">All Date</option>
                    <option value="all">Today Date</option>
                    <option value="custom">Custom Date</option>
                  </select>
                  {showCustomDates && (
                    <>
                      <input
                        type="date"
                        className="border border-gray-200 px-4 py-2 rounded-lg"
                        placeholder="Start Date"
                        value={customSalesStartDate}
                        onChange={handleCustomStartDateChange}
                      />
                      <input
                        type="date"
                        className="border border-gray-200 px-4 py-2 rounded-lg"
                        placeholder="End Date"
                        value={customSalesEndDate}
                        onChange={handleCustomEndDateChange}
                      />
                    </>
                  )}
                </div>
              )}
            </div>

            {selectedDateRange === "Custom Date" && (
              <div className="flex items-center space-x-4 mt-0">
                <input
                  type="date" // Use date type for native date picker
                  className="p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={
                    customStartDate ? format(customStartDate, "yyyy-MM-dd") : ""
                  }
                  onChange={handleCustomSalesStartDateChange}
                />
                <input
                  type="date" // Use date type for native date picker
                  className="p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={
                    customEndDate ? format(customEndDate, "yyyy-MM-dd") : ""
                  }
                  onChange={handleCustomSalesEndDateChange}
                />
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-start space-x-4 mb-4">
          <button
            onClick={() => {
              setCurrentReport("Sales Report");
              setIsPopupOpen(false);
            }}
            className={`px-4 py-2 rounded-md ${currentReport === "Sales Report"
                ? "bg-blue-500 text-white"
                : "bg-white text-gray-700"
              }`}
          >
            Sales Report
          </button>
          <button
            onClick={() => setCurrentReport("Sales Return")}
            className={`px-4 py-2 rounded-md ${currentReport === "Sales Return"
                ? "bg-blue-500 text-white"
                : "bg-white text-gray-700"
              }`}
          >
            Sales Return
          </button>

          <button
            onClick={() => {
              setCurrentReport("Stock Report");
              setIsPopupOpen(false); // Close the popup
            }}
            className={`px-4 py-2 rounded-md ${currentReport === "Stock Report"
                ? "bg-blue-500 text-white"
                : "bg-white text-gray-700"
              }`}
          >
            Stock Report
          </button>
        </div>
        <div>
          {loading ? (
            <div className="text-center py-4 text-gray-700">Loading...</div>
          ) : (
            <>
              {currentReport === "Sales Report" && renderTable()}
              {currentReport === "Sales Return" && (
                <SalesReturn salesReturnDataPdf={handleSalesReturnData} />
              )}
              {currentReport === "Stock Report" && (
                <StockReport
                  quantityFilter={selectedStocks}
                  unitFilter={selectedUnit}
                  stockSearch={searchQuery}
                  wareHouseFilter={selectedWarehouse}
                  stockDataPdf={handlestockReportData}
                  warehouses={warehouses}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Report;
