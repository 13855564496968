import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Eye from "../Assets/tableicon/Eye.svg";
import { IoMdTrash } from 'react-icons/io';
import { FaRegEdit } from 'react-icons/fa';
import CreateTransfer from '../components/CreateTransfer'; // Import your popup component
import TransferViewPopup from '../components/TransferViewPopup';
import TransferEditPopup from '../components/TransferEditPopup';
import { Oval } from 'react-loader-spinner';
import Searchicon from "../Assets/tableicon/Searchicon.png";

const Transfer = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedTransfer, setSelectedTransfer] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isCreatePopupOpen, setIsCreatePopupOpen] = useState(false); // State for the create transfer popup
    const [isViewPopupOpen, setIsViewPopupOpen] = useState(false);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [transferToDelete, setTransferToDelete] = useState(null);
    const [editPopupOpen, setEditPopupOpen] = useState(false); // State for edit popup

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }
            const response = await fetch('https://pos.farm2bag.com/api/v1/transfers/transferwithItems', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const responseData = await response.json();
            setData(responseData.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error('Failed to fetch transfers');
            setLoading(false);
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data
        .filter(item => item.invoice && item.invoice.toLowerCase().includes(searchQuery.toLowerCase())) // Updated to filter by invoice number
        .slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleItemsPerPageChange = (e) => {
        setCurrentPage(1);
        setItemsPerPage(parseInt(e.target.value));
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleCreateTransfer = () => {
        setIsCreatePopupOpen(true); // Open the create transfer popup
    };

    const handleCloseCreatePopup = () => {
        setIsCreatePopupOpen(false); // Close the create transfer popup
    };

    const handleView = (transfer) => {
        setSelectedTransfer(transfer);
        setIsViewPopupOpen(true);
    };
    const handleEdit = (transfer) => {
        setSelectedTransfer(transfer.id);
        setEditPopupOpen(true);
    };

    const handleCloseEditPopup = () => {
        setEditPopupOpen(false);
        setSelectedTransfer(null); // Clear selected transfer on close if necessary
    };


    const handleCloseViewPopup = () => {
        setIsViewPopupOpen(false);
    };
    const handleDelete = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }
            const response = await fetch(`https://pos.farm2bag.com/api/v1/transfers/${transferToDelete}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.ok) {
                toast.success('Transfer deleted successfully');
                // Refetch data after deletion
                fetchData();
            } else {
                toast.error('Failed to delete transfer');
            }
        } catch (error) {
            console.error('Error deleting transfer:', error);
            toast.error('Failed to delete transfer');
        }
        // Close confirmation dialog after delete attempt
        setDeleteConfirmationOpen(false);
        setTransferToDelete(null);
    };

    const openDeleteConfirmation = (transferId) => {
        setTransferToDelete(transferId);
        setDeleteConfirmationOpen(true);
    };

    const closeDeleteConfirmation = () => {
        setTransferToDelete(null);
        setDeleteConfirmationOpen(false);
    };

    console.log("selectedTransfer", selectedTransfer);
    
    const sortedItems = [...currentItems].sort((a, b) => b.invoice.localeCompare(a.invoice));

    return (
        <div className='bg-gray-100 ml-64 mt-20 p-12 h-screen'>
            <div className="container mx-auto">
                <div className="mb-10">
                    <h1 className="font-extrabold text-3xl text-left text-gray-500">Transfers</h1>
                    <div className="mt-2 border-t border-gray-500"></div>
                </div>
                <div className="flex items-center justify-between mb-5">
                    <div className="relative">
                        <input type="text" placeholder='Search invoice' value={searchQuery} onChange={handleSearch} className="border border-gray-300 px-4 py-2 pl-10 rounded-lg focus:outline-none focus:border-blue-500" />
                        <img src={Searchicon} alt="search" className="absolute left-3 top-3 w-4 h-4" />
                    </div>
                    <button onClick={handleCreateTransfer} className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:outline-none">Create Transfer</button>
                </div>

                <div className="rounded-lg overflow-hidden">
                    {loading ? (
                        <div className="flex justify-center items-center py-10">
                            <Oval
                                height={80}
                                width={80}
                                color="#3498db"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#f3f3f3"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />
                        </div>
                    ) : (
                        <table className="min-w-full bg-white border-gray-500 text-center">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="border-b border-gray-200 py-2">Invoice</th>
                                    <th className="border-b border-gray-200 py-2">Transfer Date</th>
                                    <th className="border-b border-gray-200 py-2">From Warehouse</th>
                                    <th className="border-b border-gray-200 py-2">To Warehouse</th>
                                    <th className="border-b border-gray-200 py-2">Status</th>
                                    <th className="border-b border-gray-200 py-2">Total Amount</th>
                                    <th className="border-b border-gray-200 py-2">Payment Mode</th>
                                    <th className="border-b border-gray-200 py-2">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedItems.map((transfer) => (
                                    <tr key={transfer.id} className="hover:bg-gray-100">
                                        <td className="border-b border-gray-200 py-2">{transfer.invoice}</td>
                                        <td className="border-b border-gray-200 py-2">{transfer.transferDate}</td>
                                        <td className="border-b border-gray-200 py-2">{transfer.fromWarehouseName}</td>
                                        <td className="border-b border-gray-200 py-2">{transfer.toWarehouseName}</td>
                                        <td className="border-b border-gray-200 py-2">{transfer.status}</td>
                                        <td className="border-b border-gray-200 py-2">{transfer.totalAmount}</td>
                                        <td className="border-b border-gray-200 py-2">{transfer.paymentMode}</td>
                                        <td className="px-4 py-2 border-b border-gray-200 pl-16 flex justify-center">
                                            <button className="flex items-center p-2 text-white rounded focus:outline-none" onClick={() => handleView(transfer)}>
                                                <img src={Eye} className="w-6 h-6 mr-2" alt="Eye" />
                                            </button>
                                            <button className="mr-2 font-bold rounded" onClick={() => handleEdit(transfer)}>
                                                <FaRegEdit size={22} className="inline-block mr-1 text-blue-500" />
                                            </button>
                                            <button className="border-none cursor-pointer" onClick={() => openDeleteConfirmation(transfer.id)}>
                                                <IoMdTrash size={30} className="text-red-500" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>

                <div className="flex items-center justify-between mt-5 mb-54">
                    <div className="space-x-2">
                        <label htmlFor="itemsPerPage" className="mr-2">Items per page:</label>
                        <select value={itemsPerPage} onChange={handleItemsPerPageChange} className="border border-gray-200 px-2 py-1 rounded-lg focus:outline-none">
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                        </select>
                    </div>
                    <div className="space-x-2">
                        <button onClick={() => paginate(currentPage - 1)} className={`px-3 py-1 rounded-lg ${currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white'}`} disabled={currentPage === 1}>Previous</button>
                        <button onClick={() => paginate(currentPage + 1)} className={`px-3 py-1 rounded-lg ${currentPage === Math.ceil(data.length / itemsPerPage) ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white'}`} disabled={currentPage === Math.ceil(data.length / itemsPerPage)}>Next</button>
                    </div>
                </div>
                {/* Delete Confirmation Modal */}
                {deleteConfirmationOpen && (
                    <div className="fixed top-0 left-24 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
                        <div className="relative w-auto max-w-md mx-auto my-6">
                            <div className="bg-white rounded-lg shadow-lg">
                                <div className="flex items-start justify-between p-5 border-b border-gray-300 rounded-t">
                                    <h3 className="text-lg font-semibold">Confirm Delete</h3>
                                    <button
                                        className="text-gray-500 hover:text-gray-700"
                                        onClick={closeDeleteConfirmation}
                                    >
                                        <span className="text-2xl">&times;</span>
                                    </button>
                                </div>
                                <div className="relative p-6">
                                    <p className="text-lg text-gray-700">Are you sure you want to delete this transfer?</p>
                                    <div className="mt-4 flex justify-end space-x-4">
                                        <button
                                            onClick={handleDelete}
                                            className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-700 focus:outline-none"
                                        >
                                            Delete
                                        </button>
                                        <button
                                            onClick={closeDeleteConfirmation}
                                            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-400 focus:outline-none"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/* Create Transfer Popup */}
                {isCreatePopupOpen && (
                    <div className="fixed overflow-y-auto max-h-screen top-0 left-0 w-full h-screen bg-gray-800 bg-opacity-80 flex justify-center items-center">
                        <CreateTransfer onClose={handleCloseCreatePopup} transfer={fetchData} />
                    </div>
                )}
                {/* Transfer View Popup */}
                {isViewPopupOpen && selectedTransfer && (
                    <TransferViewPopup transfer={selectedTransfer} onClose={handleCloseViewPopup} />
                )}
                {editPopupOpen && selectedTransfer && (
                    <TransferEditPopup
                        transferId={selectedTransfer}
                        onClose={handleCloseEditPopup}

                    />
                )}


            </div>
        </div>
    );
};

export default Transfer;
