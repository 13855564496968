import React, { useEffect, useState } from 'react';
import CreateExpensePopup from '../components/CreateExpensePopup';
import EditExpensePopup from '../components/EditExpensePopup';
import DeleteExpensePopup from '../components/DeleteExpensePopup';
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import filter from "../Assets/tableicon/filter.png";
import Searchicon from "../Assets/tableicon/Searchicon.png";

const Expense = () => {
    const [expenses, setExpenses] = useState([]);
    const [filteredExpenses, setFilteredExpenses] = useState([]);
    const [expenseCategories, setExpenseCategories] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState('');
    const [isCreatePopupOpen, setIsCreatePopupOpen] = useState(false);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [currentExpense, setCurrentExpense] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {
        fetchExpenseCategories();
        fetchWarehouses();
    }, []);

    useEffect(() => {
        if (selectedWarehouse) {
            fetchExpenses();
        }
    }, [selectedWarehouse]);

    const fetchExpenses = async () => {
        try {
            const token = localStorage.getItem('token');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const response = await fetch(`https://pos.farm2bag.com/api/v1/expense/warehouse/${selectedWarehouse}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const responseData = await response.json();
            if (responseData.data) {
                setExpenses(responseData.data);
                setFilteredExpenses(responseData.data);
            } else {
                setExpenses([]);
                setFilteredExpenses([]);
                console.warn(responseData.message);
            }
            console.log("Checking Message : ", responseData.message);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchExpenseCategories = async () => {
        try {
            const token = localStorage.getItem('token');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const response = await fetch('https://pos.farm2bag.com/api/v1/expenseCategories', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch expense categories');
            }

            const responseData = await response.json();
            setExpenseCategories(responseData.data);
        } catch (error) {
            console.error('Error fetching expense categories:', error);
            setExpenseCategories([]);
        }
    };

    const fetchWarehouses = async () => {
        try {
            const token = localStorage.getItem('token');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const response = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch warehouses');
            }

            const responseData = await response.json();
            setWarehouses(responseData.data);
            if (responseData.data.length > 0) {
                setSelectedWarehouse(responseData.data[0].id);
            }
        } catch (error) {
            console.error('Error fetching warehouses:', error);
            setWarehouses([]);
        }
    };

    const handleCreatePopupOpen = () => setIsCreatePopupOpen(true);
    const handleCreatePopupClose = () => setIsCreatePopupOpen(false);

    const handleEditPopupOpen = (expense) => {
        setCurrentExpense(expense);
        setIsEditPopupOpen(true);
    };
    const handleEditPopupClose = () => setIsEditPopupOpen(false);

    const handleDeletePopupOpen = (expense) => {
        setCurrentExpense(expense);
        setIsDeletePopupOpen(true);
    };
    const handleDeletePopupClose = () => setIsDeletePopupOpen(false);

    const handleCreateExpense = () => {
        fetchExpenses();
        handleCreatePopupClose();
    };

    const handleUpdateExpense = () => {
        fetchExpenses();
        handleEditPopupClose();
    };

    const handleDeleteExpense = () => {
        fetchExpenses();
        handleDeletePopupClose();
    };

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        if (query === '') {
            setFilteredExpenses(expenses);
        } else {
            setFilteredExpenses(
                expenses.filter((expense) =>
                    expense.expenseCategory.expenseCategoryName.toLowerCase().includes(query.toLowerCase())
                )
            );
        }
        setCurrentPage(1);
    };

    const handleWarehouseChange = (event) => {
        setSelectedWarehouse(event.target.value);
        setCurrentPage(1);
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1);  // Reset to the first page whenever items per page changes
    };

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentExpenses = filteredExpenses.slice(indexOfFirstItem, indexOfLastItem);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredExpenses.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className='bg-gray-100 ml-64 mt-20 p-12 h-screen'>
            <div className="container mx-auto">
                <div className="mb-10">
                    <h1 className="font-extrabold text-3xl text-left text-gray-500">Expense</h1>
                    <div className="mt-2 border-t border-gray-500"></div>
                </div>
                <div className="flex justify-between items-center mb-5">
                    <div className="flex items-center space-x-4">
                        <div className="relative">
                            <input type="text" placeholder='search' value={searchQuery} onChange={handleSearch} className="border border-gray-200 px-4 py-2 pl-10 rounded-lg focus:outline-none focus:border-blue-500" />
                            <img src={Searchicon} alt="search" className="absolute left-3 top-3 w-4 h-4" />
                        </div>
                        <select value={selectedWarehouse} onChange={handleWarehouseChange} style={{ backgroundColor: "#1afc05" }} className="border border-gray-300 px-4 py-2 rounded-lg focus:outline-none focus:border-blue-500">
                            {warehouses.map(warehouse => (
                                <option key={warehouse.id} value={warehouse.id}>
                                    {warehouse.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex ml-auto mr-0 items-center space-x-4 mr-2">
                        <img src={filter} alt="filter" className="w-12 h-10 rounded-lg cursor-pointer" />
                        <button onClick={handleCreatePopupOpen} style={{ backgroundColor: 'rgba(66, 255, 97, 1)' }} className="w-48 h-10 font-medium text-sm rounded-lg">Add Expense</button>
                    </div>
                    <select value={itemsPerPage} onChange={handleItemsPerPageChange} className="border border-gray-300 px-4 py-2 rounded-lg focus:outline-none focus:border-blue-500">
                            <option value={5}>5 per page</option>
                            <option value={10}>10 per page</option>
                            <option value={20}>20 per page</option>
                        </select>
                </div>

                {filteredExpenses.length > 0 ? (
                    <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                        <thead className="bg-blue-500">
                            <tr>
                                <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">Category</th>
                                <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">Warehouse</th>
                                <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">Date</th>
                                <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">Amount (₹)</th>
                                <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">Description</th>
                                <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {currentExpenses.map(expense => (
                                <tr key={expense.id} className="hover:bg-green-200">
                                    <td className="py-3 px-4 text-left">{expense.expenseCategory.expenseCategoryName}</td>
                                    <td className="py-3 px-4 text-left">{expense.warehouseDetails.name}</td>
                                    <td className="py-3 px-4 text-left">{expense.date}</td>
                                    {/* <td className="py-3 px-4 text-left">₹ {expense.amount}</td> */}
                                    <td className="py-3 px-4 text-left">₹&nbsp;{new Intl.NumberFormat({ style: 'currency', currency: 'INR' }).format(expense.amount)}</td>
                                    <td className="py-3 px-4 text-left">{expense.description}</td>
                                    <td className="border px-4 py-2 flex justify-center">
                                        <button onClick={() => handleEditPopupOpen(expense)} className="mr-2 text-blue-500"><FaRegEdit size={22} style={{ color: "blue" }} /></button>
                                        <button onClick={() => handleDeletePopupOpen(expense)} className="text-red-500"><IoMdTrash size={30} style={{ color: "red" }} /></button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="text-center mt-4">No expenses found.</p>
                )}

                {filteredExpenses.length > itemsPerPage && (
                    <div className="flex justify-center mt-4">
                        {pageNumbers.map(number => (
                            <button
                                key={number}
                                onClick={() => paginate(number)}
                                className={`px-3 py-1 rounded-lg mx-1 ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                            >
                                {number}
                            </button>
                        ))}
                    </div>
                )}
            </div>

            {isCreatePopupOpen && <CreateExpensePopup expenseCategories={expenseCategories} onClose={handleCreatePopupClose} onCreate={handleCreateExpense} />}
            {isEditPopupOpen && currentExpense && <EditExpensePopup expense={currentExpense} expenseCategories={expenseCategories} onClose={handleEditPopupClose} onUpdate={handleUpdateExpense} />}
            {isDeletePopupOpen && currentExpense && <DeleteExpensePopup expense={currentExpense} onClose={handleDeletePopupClose} onDelete={handleDeleteExpense} />}
        </div>
    );
};

export default Expense;

