import React from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

const PurchaseDelete = ({ isOpen, onClose, purchase, fetchData }) => {
    const handleDelete = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }
            const response = await fetch(`https://pos.farm2bag.com/api/v1/purchaseDetails/${purchase.id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) throw new Error('Failed to delete purchase');
            toast.success('Purchase deleted successfully');
            fetchData();  // Refresh the data after deletion
            onClose();
        } catch (error) {
            console.error('Error deleting purchase:', error);
            toast.error('Failed to delete purchase');
        }
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-70 z-50">
            <div className="relative rounded-lg p-6 w-11/12 md:w-2/3 lg:w-1/2 bg-gradient-to-r from-blue-500 to-green-500">
                <h2 className="text-2xl font-bold mb-2 text-center text-white">Delete Purchase</h2>
                <hr /><hr />
                {purchase ? (
                    <div className="flex flex-col gap-4">
                        <p className="text-center text-lg text-white">
                            Are you sure you want to delete the purchase order <strong className='text-red-600'>{purchase.purchaseOrder}</strong> from vendor <strong className='text-red-600'>{purchase.vendorName}</strong> ?
                        </p>
                        <div className="flex justify-between gap-2">
                            <button onClick={onClose} className="bg-green-500 text-gray-800 font-bold py-2 px-4 rounded">
                                <span className='text-white'>Cancel</span>
                            </button>
                            <button onClick={handleDelete} className="bg-red-700 text-white font-bold py-2 px-4 rounded">
                                <span className='text-white'>Yes, Delete</span>
                            </button>
                        </div>
                    </div>
                ) : (
                    <p className="text-white">Loading...</p>
                )}
            </div>
        </Modal>
    );
};

export default PurchaseDelete;