import React, { useState, useEffect } from 'react';
import SearchIcon from "../Assets/tableicon/Searchicon.png";
import FilterIcon from "../Assets/tableicon/filter.png";
import EyeIcon from "../Assets/tableicon/Eye.svg";
import { FaRegEdit } from 'react-icons/fa';
import { IoMdTrash } from 'react-icons/io';
import CreateVendorRate from '../components/CreateVendorRate';
import EditVendorrate from '../components/EditVendorRate';
import Switch from "react-switch";

const VendorRate = () => {
    const [vendorRates, setVendorRates] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [createPopupOpen, setCreatePopupOpen] = useState(false);
    const [editPopupOpen, setEditPopupOpen] = useState(false);
    const [selectedRateId, setSelectedRateId] = useState(null);
    const [productCodes, setProductCodes] = useState({});
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [popupData, setPopupData] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc'); // Added state for sort order
    const [selectedVendorId, setSelectedVendorId] = useState(null);
    const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
    const [selectedVendorName, setSelectedVendorName] = useState('');

    useEffect(() => {
        fetchVendors();
        fetchVendorRates();
        fetchVendorById();
    }, []);
    const handleDataUpdate = () => {
        fetchVendorRates(); // Fetch updated vendor rates after creation
        // You might need to fetch other relevant data as well depending on your application logic
    };

    const openDeletePopup = (rateId, vendorName) => {
        setSelectedRateId(rateId);
        setSelectedVendorName(vendorName);
        setDeletePopupOpen(true);
    };
    const fetchVendors = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const response = await fetch('https://pos.farm2bag.com/api/v1/vendors', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch vendors');
            }

            const responseData = await response.json();
            if (responseData.status === 200) {
                setVendors(responseData.data);
            } else {
                console.error('Error fetching vendors:', responseData.message);
            }

        } catch (error) {
            console.error('Error fetching vendors:', error);
        }
    };

    const fetchVendorRates = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const response = await fetch('https://pos.farm2bag.com/api/v1/vendorRates', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch vendor rates');
            }

            const responseData = await response.json();
            if (responseData.status === 200) {
                setVendorRates(responseData.data);
            } else {
                console.error('Error fetching vendor rates:', responseData.message);
            }

        } catch (error) {
            console.error('Error fetching vendor rates:', error);
        }
    };

    useEffect(() => {
        fetchVendors();
    }, []);

    const openEditPopup = async (rateId) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const response = await fetch(`https://pos.farm2bag.com/api/v1/vendorRates/${rateId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch vendor rate details');
            }

            const responseData = await response.json();
            if (responseData.status === 200) {
                const fetchedVendorId = responseData.data.vendorId;

                // Pass the vendor ID to the EditVendorRate component
                setEditPopupOpen(true);
                setSelectedRateId(rateId);
                setSelectedVendorId(fetchedVendorId); // Add this line to pass the vendor ID

                // Fetch vendor details by ID
                fetchVendorById(fetchedVendorId);
            } else {
                console.error('Error fetching vendor rate details:', responseData.message);
            }
        } catch (error) {
            console.error('Error fetching vendor rate details:', error);
        }
    };
    const fetchVendorById = async (vendorId) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const response = await fetch(`https://pos.farm2bag.com/api/v1/vendors/${vendorId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch vendor details');
            }

            const responseData = await response.json();
            // Handle the fetched vendor data as needed
            console.log('Fetched vendor data:', responseData);
        } catch (error) {
            console.error('Error fetching vendor details:', error);
        }
    };

    const handleEye = async (rateId) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const response = await fetch(`https://pos.farm2bag.com/api/v1/vendorRates/${rateId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch vendor rate details');
            }

            const responseData = await response.json();
            if (responseData.status === 200) {
                setPopupData(responseData.data); // Store fetched data in popupData state
                setSelectedRateId(rateId);
                setIsPopupVisible(true);
            } else {
                console.error('Error fetching vendor rate details:', responseData.message);
            }
        } catch (error) {
            console.error('Error fetching vendor rate details:', error);
        }
    };

    const calculateProductCount = (products) => {
        return products.length;
    };

    const filteredRates = vendorRates.filter(rate =>
        rate.vendorName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase().trim();
        setSearchQuery(query);
    
        // Filter the data based on the search query
        const filteredData = vendorRates.filter(rate =>
            rate.vendorName.toLowerCase().includes(query)
        );
    
        // Sort the filtered data so that exact matches or matches where the query appears earlier come first
        const sortedData = filteredData.sort((a, b) => {
            const startsWithQueryA = a.vendorName.toLowerCase().startsWith(query);
            const startsWithQueryB = b.vendorName.toLowerCase().startsWith(query);
    
            if (startsWithQueryA && !startsWithQueryB) return -1;
            if (!startsWithQueryA && startsWithQueryB) return 1;
            return 0;
        });
    
        setVendorRates(sortedData);
        setCurrentPage(1); // Reset to the first page after search
    };
    
    useEffect(() => {
        if (searchQuery === '') {
            fetchVendorRates(); // Fetch initial data again if search query is cleared
        }
    }, [searchQuery]);
    

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const toggleStatus = async (selectedRate) => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');

            if (!token) {
                console.error('No token found in local storage');
                return;
            } const id = selectedRate.id;
            const newStatus = selectedRate.status === 0 ? 1 : 0;

            const response = await fetch(`https://pos.farm2bag.com/api/v1/vendorRates/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    status: newStatus,
                    modifiedBy: role
                })
            });

            if (!response.ok) {
                throw new Error('Failed to toggle status');
            }

            console.log('Status toggled successfully');

            setVendorRates(prevRates => prevRates.map(rate => rate.id === id ? { ...rate, status: newStatus } : rate));
        } catch (error) {
            console.error('Error toggling status:', error);
        }
    };

    const handleDeleteRate = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }
    
            const response = await fetch(`https://pos.farm2bag.com/api/v1/vendorRates/${selectedRateId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
    
            if (!response.ok) {
                throw new Error('Failed to delete vendor rate');
            }
    
            console.log('Vendor rate deleted successfully');
    
            // Update state to remove the deleted rate from the list
            setVendorRates(prevRates => prevRates.filter(rate => rate.id !== selectedRateId));
    
            // Check if the current page is greater than the total number of pages after deletion
            const totalPagesAfterDeletion = Math.ceil((vendorRates.length - 1) / itemsPerPage);
            if (currentPage > totalPagesAfterDeletion) {
                // Decrement the current page to stay on the last page
                setCurrentPage(totalPagesAfterDeletion);
            }
    
            // Close the delete popup after deleting the rate
            setDeletePopupOpen(false);
        } catch (error) {
            console.error('Error deleting vendor rate:', error);
        }
    };
    
    const handleSort = () => {
        const sortedRates = [...vendorRates].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.vendorName.localeCompare(b.vendorName);
            } else {
                return b.vendorName.localeCompare(a.vendorName);
            }
        });
        setVendorRates(sortedRates);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const indexOfLastRate = currentPage * itemsPerPage;
    const indexOfFirstRate = indexOfLastRate - itemsPerPage;
    const currentRates = filteredRates.slice(indexOfFirstRate, indexOfLastRate);

    const currentRatesWithProductCount = currentRates.map(rate => ({
        ...rate,
        productCount: calculateProductCount(rate.products)
    }));

    return (
        <div className='bg-white-100 ml-64 mt-20 p-12 h-screen'>
            <div>
                <div className="container mx-auto">
                    <div className="mb-10">
                        <h1 className="font-extrabold text-3xl text-left text-gray-500">Vendor Rate</h1>
                        <div className="mt-2 border-t border-gray-500"></div>
                    </div>
                    <div className="flex items-center mb-5">
                        <div className="ml-0">
                            <form>
                                <div className="relative">
                                    <input type="text" placeholder='search' value={searchQuery} onChange={handleSearch} className="border border-gray-200 px-4 py-2 pl-10 rounded-lg focus:outline-none focus:border-blue-500" />
                                    <img src={SearchIcon} alt="search" className="absolute left-3 top-3 w-4 h-4" />
                                </div>
                            </form>
                        </div>
                        <div className="flex ml-auto mr-0 items-center space-x-4">
                            <img src={FilterIcon} alt="filter" className="w-12 h-10 rounded-lg cursor-pointer" onClick={handleSort} />
                            <button onClick={() => setCreatePopupOpen(true)} style={{ backgroundColor: 'rgba(66, 255, 97, 1)' }} className="w-48 h-10 font-medium text-sm rounded-lg">Add VendorRate</button>
                        </div>
                    </div>

                    <div className="rounded-lg overflow-hidden">
                        <table className="table-auto w-full border-collapse border-gray-500 text-center">
                            <thead>
                                <tr className="bg-gray-200 ">
                                    <th className='p-2'>Vendor Name</th>
                                    <th>Vendor Code</th>
                                    <th>Product Count</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentRatesWithProductCount.map((rate, index) => {
                                    const vendor = vendors.find(vendor => vendor.id === rate.vendorId);
                                    const vendorCode = vendor ? vendor.vendorCode : '';
                                    return (
                                        <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                                            <td>{rate.vendorName}</td>
                                            <td>{vendorCode}</td>
                                            <td>{rate.productCount}</td>
                                            <td>
                                                <Switch
                                                    onChange={() => toggleStatus(rate)}
                                                    checked={rate.status === 1}
                                                />
                                            </td>
                                            <td className="px-4 py-2 flex justify-center">
                                                <button className="flex items-center p-2 text-white rounded focus:outline-none" onClick={() => handleEye(rate.id)}>
                                                    <img src={EyeIcon} className="w-6 h-6 mr-2 mt-1" alt="Eye Icon" />
                                                </button>
                                                <button className="mr-2 text-blue-500" onClick={() => openEditPopup(rate.id)}>
                                                    <FaRegEdit size={22} />
                                                </button>
                                                <button className="text-red-500" onClick={() => openDeletePopup(rate.id)}> {/* Ensure onClick is set correctly */}
                                                    <IoMdTrash size={30} />
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>

                    <div className="mt-4 flex justify-between">
                        <div>
                            <label htmlFor="itemsPerPage" className="mr-2">Items per page:</label>
                            <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange} className="border border-gray-300 rounded-md p-2">
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                        <div className="flex">
                            {Array.from({ length: Math.ceil(filteredRates.length / itemsPerPage) }).map((_, index) => (
                                <button key={index} onClick={() => paginate(index + 1)} className="mx-1 px-4 py-2 border rounded-lg">{index + 1}</button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {isPopupVisible && popupData && selectedRateId && popupData.id === selectedRateId && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-70 z-50">
                    <div className="absolute inset-0 opacity-50" onClick={() => setIsPopupVisible(false)}></div>
                    <div className="relative rounded-lg p-6 w-11/12 md:w-2/3 lg:w-1/2 bg-white">
                        <h2 className="text-xl font-bold mb-4 py-2 bg-gray-200">Vendor Rate Details</h2>
                        <div className="mb-4">
                            <table className="w-full border-collapse mb-4">
                                <thead>
                                    <tr className="text-white">
                                        <td className="bg-blue-500 text-lg w-1/2 p-2 text-center">Vendor Name</td>
                                        <td className="bg-green-500 text-lg font-bold w-1/2 p-2 text-center">{popupData.vendorName}</td>
                                    </tr>
                                </thead>
                            </table>
                            <h3 className="text-lg font-bold mb-2">Products</h3>
                            <table className="w-full border-collapse">
                                <thead>
                                    <tr className="bg-blue-600 text-white">
                                        <th className="border-b p-2">Product Name</th>
                                        <th className="border-b p-2">Per KG Cost</th>
                                        <th className="border-b p-2">Per Quantity Cost</th>
                                        <th className="border-b p-2">Product Code</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {popupData.products.map((product, index) => (
                                        <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                                            <td>{product.productDetail[0].name || "-"}</td>
                                            <td>{product.perKgCost || "-"}</td>
                                            <td>{product.perQtyCost || "-"}</td>
                                            <td>{product.productDetail[0].productsCode || "-"}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <button
                            className="mt-4 px-4 py-2 bg-red-500 text-white rounded transform transition-transform duration-200 hover:scale-125 hover:bg-red-600" onClick={() => setIsPopupVisible(false)}>
                            Close
                        </button>
                    </div>
                </div>
            )}
            {isDeletePopupOpen && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg">
                        <h2 className="text-xl font-bold mb-4">Delete Vendor Rate</h2>
                        <p>Are you sure you want to delete the rate for vendor "{selectedVendorName}"?</p>
                        <button onClick={() => setDeletePopupOpen(false)} className="bg-blue-500 text-white px-4 py-2 rounded-lg float-right mr-11 mt-10 hover:bg-red-600">Cancel</button>
                        <button onClick={handleDeleteRate} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg ml-2 float-left ml-11 mt-10 hover:bg-green-500">Delete</button>
                    </div>
                </div>
            )}
{createPopupOpen && <CreateVendorRate closePopup={() => setCreatePopupOpen(false)} fetchData={handleDataUpdate} />}
            {editPopupOpen && <EditVendorrate id={selectedRateId} closePopup={() => setEditPopupOpen(false)} />}
        </div>
    );
};

export default VendorRate;











