import React, { useState } from 'react';
import { toast } from 'react-toastify';

const EditUser = ({ user, onCancel, onSuccess }) => {
  const [formData, setFormData] = useState({
    userName: user.userName,
    email: user.email,
    userCode: user.userCode,
    roleName: user.roleName,
    status: user.status,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found in local storage");
        return;
      }

      const response = await fetch(`https://pos.farm2bag.com/api/v1/users/${user.id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`Failed to update user: ${response.status}`);
      }

      toast.success("User updated successfully");
      onSuccess();
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error(error.message || "Error updating user");
    }
  };

  return (
    <div className="text-center">
      <h2 className="text-xl mb-4">Edit User</h2>
      <div className="mt-4">
        <label className="block">
          Username:
          <input
            type="text"
            name="userName"
            value={formData.userName}
            onChange={handleChange}
            className="border border-gray-200 px-4 py-2 rounded-lg"
          />
        </label>
        <label className="block mt-2">
          Email:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="border border-gray-200 px-4 py-2 rounded-lg"
          />
        </label>
       
        <button
          onClick={onCancel}
          className="bg-gray-500 text-white px-4 py-2 rounded-lg mt-4 mr-6"
        >
          Cancel
        </button>
      
        <button
          onClick={handleSave}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg mr-2 mt-4"
        >
          Update
        </button>
       
      </div>
    </div>
  );
};

export default EditUser;
