import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const VendorDetails = ({
  vendorId,
  onClose,
  VendorIdToDelete,
  onCloseDelete,
  refreshData,
  venderName,
  VendorForEdit,
  onCloseEdit,
  refreshDataWhileEdit,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [vendorDetails, setVendorDetails] = useState(null);
  const [isVisualPopupOpen, setCreatePopupOpen] = useState(true);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(true);
  const [isEditPopupOpen, setEditPopupOpen] = useState(true);
  const [editData, setEditData] = useState(VendorForEdit);
  const [ProductFromApi, setProductFromApi] = useState();

  

  useEffect(() => {
    const fetchVendorDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found in local storage");
          return;
        }
        const response = await fetch(
          `https://pos.farm2bag.com/api/v1/vendors/${vendorId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch vendor details");
        }

        const data = await response.json();

        setVendorDetails(data.data);
      } catch (error) {
        console.error("Error fetching vendor details:", error.message);
      }
    };

    fetchVendorDetails();
  }, [vendorId]);

  const closePopup = () => {
    setCreatePopupOpen(false);
    onClose();
  };

  const handleDeleteConfirmation = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found in local storage");
        return;
      }
      const response = await fetch(
        `https://pos.farm2bag.com/api/v1/vendors/${VendorIdToDelete}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete vendor");
      } else {
        setDeletePopupOpen(false);
        onCloseDelete();
        refreshData();
        toast.success("Vendor deleted successfully!");
      }
    } catch (error) {
      console.error("Error deleting vendor:", error.message);
      toast.error("Failed to delete vendor");
    }
  };

  const handleCancel = () => {
    setDeletePopupOpen(false);
    onCloseDelete();
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditData({
      ...editData,
      [name]: value,
    });
  };

  const handleEditSubmit = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found in local storage");
        return;
      }
      const response = await fetch(
        `https://pos.farm2bag.com/api/v1/vendors/${editData.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(editData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update vendor details");
      } else {
        refreshDataWhileEdit();
        setEditPopupOpen(false);
        toast.success("Vendor updated successfully!");
        onCloseEdit();
      }
    } catch (error) {
      console.error("Error updating vendor details:", error.message);
      toast.error("Failed to update vendor details");
    }
  };

  const handleCancelEdit = () => {
    setEditPopupOpen(false);
    onCloseEdit();
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found in local storage");
          return;
        }
        const response = await fetch("https://pos.farm2bag.com/api/v1/products", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch products data");
        }

        const data = await response.json();

        // console.log("Received data:", data);

        let products = [];

        if (Array.isArray(data)) {
          products = data.map((item) => ({
            productId: item.id,
            productName: item.name,
          }));
        } else if (typeof data === "object" && data.data) {
          products = data.data.map((item) => ({
            productId: item.id,
            productName: item.name,
          }));
        } else {
          throw new Error("Received data format not supported");
        }

        setProductFromApi(products);
      } catch (error) {
        console.error("Error fetching products data:", error.message);
      }
    };

    fetchData();
  }, []);
  
  

  return (
    <div>
      {isVisualPopupOpen && vendorDetails && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg relative w-2/3 max-w-lg">
            <h2 className="text-xl font-bold mb-4">Vendor Details</h2>
            <table className="table-auto w-full">
              <tbody>
                <tr>
                  <td className="border px-4 py-2">Vendor Type:</td>
                  <td className="border px-4 py-2">
                    {vendorDetails.vendorType}
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Vendor Name:</td>
                  <td className="border px-4 py-2">
                    {vendorDetails.vendorName}
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Vendor Code:</td>
                  <td className="border px-4 py-2">
                    {vendorDetails.vendorCode}
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Phone Number:</td>
                  <td className="border px-4 py-2">
                    {vendorDetails.phoneNumber}
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Email ID:</td>
                  <td className="border px-4 py-2">{vendorDetails.emailId}</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">GSTIN:</td>
                  <td className="border px-4 py-2">{vendorDetails.gstin}</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Address:</td>
                  <td className="border px-4 py-2">{vendorDetails.address}</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Locality:</td>
                  <td className="border px-4 py-2">{vendorDetails.locality}</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">District:</td>
                  <td className="border px-4 py-2">{vendorDetails.district}</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">State:</td>
                  <td className="border px-4 py-2">{vendorDetails.state}</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Country:</td>
                  <td className="border px-4 py-2">{vendorDetails.country}</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Products:</td>
                  <td className="border px-4 py-2">
                    <ul>
                      {vendorDetails.product.map((product) => (
                        <li key={product.productId}>{product.productName}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* Add more details as needed */}
            <button
              onClick={closePopup}
              className="absolute top-2 right-2 bg-red-500 text-white px-4 py-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {VendorIdToDelete && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg relative w-2/3 max-w-lg">
            <h2 className="text-xl font-bold mb-4">Delete Vendor</h2>
            <p>Are you sure you want to delete the vender {venderName}?</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={handleDeleteConfirmation}
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
              >
                Delete
              </button>

              <button
                onClick={handleCancel}
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {VendorIdToDelete && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg relative w-2/3 max-w-lg">
            <h2 className="text-xl font-bold mb-4">Delete Vendor</h2>
            <p>Are you sure you want to delete the vender {venderName}?</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={handleDeleteConfirmation}
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
              >
                Delete
              </button>

              <button
                onClick={handleCancel}
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
{editData && isEditPopupOpen && (
  <div className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center  bg-opacity-75">
  <div className="bg-gray-200 w-full max-w-2xl p-6 rounded-lg shadow-lg relative">
    <h2 className="text-xl font-bold mb-4">Edit Vendor</h2>
    <div className="mb-4 border-t border-gray-500"></div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 font-semibold mt-">
        <div>
          <label className="block mb-2 mr-44">
            Vendor Name:
            <input 
              type="text"
              name="vendorName"
              value={editData.vendorName}
              onChange={(e) => setEditData({ ...editData, vendorName: e.target.value })}
              className="mt-1 block w-full sm:w-64 border rounded p-2"
            />
          </label>
          <label className="block mb-2 mr-44 mt-4">
            Phone Number:
            <input
              type="text"
              name="phoneNumber"
              value={editData.phoneNumber}
              onChange={(e) => setEditData({ ...editData, phoneNumber: e.target.value })}
              className="mt-1 block w-full sm:w-64 border rounded p-2"/>
          </label>
          <label className="block mb-2 mr-56 mt-4">
            Email ID:
            <input
              type="text"
              name="emailId"
              value={editData.emailId}
              onChange={(e) => setEditData({ ...editData, emailId: e.target.value })}
              className="mt-1 block w-full sm:w-64 border rounded p-2"            />
          </label>
          <label className="block mb-2 mr-56 mt-4">
            Address:
            <input
              type="text"
              name="address"
              value={editData.address}
              onChange={(e) => setEditData({ ...editData, address: e.target.value })}
              className="mt-1 block w-full sm:w-64 border rounded p-2"            />
          </label>
          <label className="block mb-2 mr-56 mt-4">
            Locality:
            <input
              type="text"
              name="locality"
              value={editData.locality}
              onChange={(e) => setEditData({ ...editData, locality: e.target.value })}
              className="mt-1 block w-full sm:w-64 border rounded p-2"            />
          </label>
          <label className="block mb-2 mr-56 mt-4">
            District:
            <input
              type="text"
              name="district"
              value={editData.district}
              onChange={(e) => setEditData({ ...editData, district: e.target.value })}
              className="mt-1 block w-full sm:w-64 border rounded p-2"            />
          </label>
          <label className="block mb-2 mr-60 mt-4">
            State:
            <input
              type="text"
              name="state"
              value={editData.state}
              onChange={(e) => setEditData({ ...editData, state: e.target.value })}
              className="mt-1 block w-full sm:w-64 border rounded p-2"            />
          </label>
          <label className="block mb-2 mr-56 mt-4">
            Country:
            <input
              type="text"
              name="country"
              value={editData.country}
              onChange={(e) => setEditData({ ...editData, country: e.target.value })}
              className="mt-1 block w-full sm:w-64 border rounded p-2"            />
          </label>
        </div>
        <div>
          <label className="block mb-2 ">
            Add  or Delete Products:
            <div className="relative">
              <input
                type="text"
                readOnly
                onClick={() => setDropdownVisible(!dropdownVisible)}
                value="Click to select products"
                className="mt-1 block w-full border rounded p-2 cursor-pointer"
              />
               {dropdownVisible && (
                <div id="selectedProductsSection" className="absolute left-0 right-0 bg-white border rounded mt-1 max-h-40 overflow-y-auto z-10">
                  <div className="p-2">
                    {ProductFromApi &&
                      ProductFromApi.map((product) => {
                        const isChecked = editData.product.some((p) => p.productId === product.productId);
                        return (
                          <div key={product.productId} className="flex items-center mb-2">
                            <input
                              type="checkbox"
                              id={`product-${product.productId}`}
                              checked={isChecked}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setEditData({
                                    ...editData,
                                    product: [...editData.product, product],
                                  });
                                } else {
                                  setEditData({
                                    ...editData,
                                    product: editData.product.filter(
                                      (p) => p.productId !== product.productId
                                    ),
                                  });
                                }
                              }}
                              className="mr-2"
                            />
                            <label htmlFor={`product-${product.productId}`}>
                              {product.productName}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          </label>
          <div className="mt-44">
            <h3 className="text-lg font-bold mb-2">Selected Products:</h3>
            
            <div className="max-h-40 overflow-y-auto border rounded p-2">
              <ul>
                {editData.product.map((product, index) => (
                  <li key={index} className="mb-2">
                    <input
                      type="text"
                      name={`product-${index}`}
                      value={product.productName}
                      onChange={(e) => {
                        const updatedProducts = [...editData.product];
                        updatedProducts[index].productName = e.target.value;
                        setEditData({
                          ...editData,
                          product: updatedProducts,
                        });
                      }}
                      className="mt-1 block w-full border rounded p-2"
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end mb-12">
        <button onClick={handleEditSubmit} className="bg-blue-500 text-white px-4 py-2 rounded mr-2">
          Submit
        </button>
        <button onClick={handleCancelEdit} className="bg-gray-500 text-white px-4 py-2 rounded">
          Cancel
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
};

export default VendorDetails;
