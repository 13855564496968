import React, { useEffect, useState } from 'react';
import up from '../Assets/dashboardimage/chart.png';
import down from '../Assets/dashboardimage/down.png';
import increase from "../Assets/dashboardimage/up-arrow.png";
import moneyman from "../Assets/dashboardimage/money.png";

const OverviewOne = () => {
  const [totalSalesAmountToday, setTotalSalesAmountToday] = useState(null);
  const [totalSalesAmountYesterday, setTotalSalesAmountYesterday] = useState(null);
  const [salesPercentageChange, setSalesPercentageChange] = useState(null);
  const [trend, setTrend] = useState(null);
  const [totalBills, setTotalBills] = useState(0);
  const [todaysBills, setTodaysBills] = useState(0);
  const [averageSalesPerDay, setAverageSalesPerDay] = useState(null);

  useEffect(() => {
    const fetchTodaySalesAmount = async () => {
      const token = localStorage.getItem('token');
      const response = await fetch('https://pos.farm2bag.com/api/v1/registers/total-sales-amount-today', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      if (data.status === 200) {
        setTotalSalesAmountToday(data.data || 0);
      } else {
        setTotalSalesAmountToday(0);
        console.error('Error fetching today\'s sales amount:', data.message);
      }
    };

    fetchTodaySalesAmount();
  }, []);

  useEffect(() => {
    const fetchYesterdaySalesAmount = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch('https://pos.farm2bag.com/api/v1/registers/total-sales-amount-yesterday', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json();
        setTotalSalesAmountYesterday(data.data || 0);
      } catch (error) {
        console.error('Error fetching yesterday\'s data:', error);
        setTotalSalesAmountYesterday(null);
      }
    };

    fetchYesterdaySalesAmount();
  }, []);

  useEffect(() => {
    const fetchBillingData = async () => {
      const token = localStorage.getItem('token');
      const response = await fetch('https://pos.farm2bag.com/api/v1/billing', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      processBillingData(data);
    };
    const processBillingData = (billingData) => {
      if (billingData.status === 200 && Array.isArray(billingData.data)) {
        const totalBillsCount = billingData.data.length;
        setTotalBills(totalBillsCount);

        const today = new Date().toLocaleDateString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }).split('/').reverse().join('-');

        const todaysBillings = billingData.data.filter(billing => {
          const billingDate = billing.invoiceDate.split('/').reverse().join('-');
          return billingDate === today;
        });
        const todaysBillsCount = todaysBillings.length;
        setTodaysBills(todaysBillsCount);

        const totalSales = billingData.data.reduce((total, billing) => total + (billing.totalPrice || 0), 0);
        const daysCount = new Set(billingData.data.map(billing => billing.invoiceDate.split('/').reverse().join('-'))).size;
        const averageSales = daysCount ? (totalSales / daysCount).toFixed(2) : 0;
        setAverageSalesPerDay(averageSales);
      } else {
        console.error('Error: Unable to fetch billing data');
      }
    };



    fetchBillingData();
  }, []);

  useEffect(() => {
    if (totalSalesAmountToday !== null && totalSalesAmountYesterday !== null) {
      if (totalSalesAmountYesterday !== 0) {
        const percentageChange = ((totalSalesAmountToday - totalSalesAmountYesterday) / totalSalesAmountYesterday) * 100;
        const normalizedPercentage = Math.abs(percentageChange);
        setSalesPercentageChange((percentageChange >= 0 ? '+' : '-') + normalizedPercentage.toFixed(2));
        setTrend(percentageChange >= 0 ? 'up' : 'down');
      } else {
        setSalesPercentageChange('No sales ');
        setTrend(null);
      }
    }
  }, [totalSalesAmountToday, totalSalesAmountYesterday]);

  const data = [
    {
      title: "Today's Sales",
      amount: totalSalesAmountToday !== null ? `₹ ${totalSalesAmountToday.toFixed(2)}` : "Loading...",
      percentage: salesPercentageChange !== null ? `${salesPercentageChange}%` : "Calculating...",
      trend: trend || 'up'
    },
    { title: "Total Bills", count: totalBills !== null ? `${totalBills}` : "Loading...", percentage: "", trend: null },
    { title: "Today's Bills", count: todaysBills !== null ? `${todaysBills}` : "Loading...", percentage: "", trend: null },
    { title: "Average Sales Per Day", amount: averageSalesPerDay !== null ? `₹ ${averageSalesPerDay}` : "Loading...", percentage: "", trend: null }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      <div className="bg-white rounded-lg p-5 m-2 shadow-md transition duration-300 ease-in-out grid grid-cols-2 gap-4">
        <div className='shadow-md flex items-center'>
          <div className='flex flex-col items-center'>
            <h3 className="text-lg font-semibold text-center text-gray-500">{data[1].title}</h3>
            <p className="text-2xl font-bold text-center">{data[1].count}</p>
          </div>
          <img src={increase} alt="" className='h-10 w-10 ml-4' />
        </div>
        <div className='shadow-md flex items-center ml-4'>
          <div className='flex flex-col items-center'>
            <h3 className="text-lg font-semibold text-center text-gray-500">{data[2].title}</h3>
            <p className="text-2xl font-bold text-center">{data[2].count}</p>
          </div>
          <img src={increase} alt="" className='h-10 w-10 ml-4' />
        </div>
      </div>
      <div className="bg-white rounded-lg p-5 m-2 shadow-md transition duration-300 ease-in-out relative">
        <h3 className="text-lg font-semibold text-left text-gray-500">{data[0].title}</h3>
        {trend && <img src={trend === 'up' ? up : down} className="h-16 w-16 absolute ml-80 mt-7 top-0" alt="trend-icon" />}
        <p className="text-2xl font-bold text-left">{data[0].amount}</p>
        {data[0].percentage && (
          <p className={`${trend === 'up' ? 'text-green-500' : 'text-red-500'} font-semibold text-left`}>
            {data[0].percentage} from yesterday
          </p>
        )}
      </div>
      <div className="bg-white rounded-lg p-4 m-2 shadow-md transition duration-300 ease-in-out flex items-center">
        <div className="flex-grow mb-6">
          <h3 className="text-lg font-semibold text-left text-gray-500">{data[3].title}</h3>
          <p className="text-2xl font-bold text-left">{data[3].amount}</p>
        </div>
        <img src={moneyman} alt="" className='h-14 w-14 mr-12' />
      </div>
    </div>
  );
};

export default OverviewOne;
