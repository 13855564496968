
import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { IoMdTrash } from 'react-icons/io';

const EditVendorRate = ({ closePopup, id }) => {
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [productSearchQuery, setProductSearchQuery] = useState('');
    const [productSuggestions, setProductSuggestions] = useState([]);
    const [showProductSuggestions, setShowProductSuggestions] = useState(false);
    const productInputRef = useRef(null);

    useEffect(() => {
        const fetchVendorRate = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('No token found in local storage');
                }

                const response = await fetch(`https://pos.farm2bag.com/api/v1/vendorRates/${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch vendor rate details');
                }

                const responseData = await response.json();
                setSelectedVendor(responseData.data);
                setSelectedProducts(responseData.data.products.map(product => ({
                    productId: product.productId,
                    productName: product.productDetail[0].name,
                    perKgCost: product.perKgCost,
                    perQtyCost: product.perQtyCost,
                    productCode: product.productDetail[0].productsCode
                })));

                // Fetch vendor products for search suggestions
                fetchVendorProducts(responseData.data.vendorId);

            } catch (error) {
                console.error('Error fetching vendor rate details:', error);
            }
        };

        fetchVendorRate();
    }, [id]);

    const fetchVendorProducts = async (vendorId) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found in local storage');
            }
    
            const response = await fetch(`https://pos.farm2bag.com/api/v1/vendors/${vendorId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    
            if (!response.ok) {
                throw new Error('Failed to fetch vendor products');
            }
    
            const responseData = await response.json();
            const products = responseData.data.product || [];
    
            // Fetch product details for each product
            const productDetailsPromises = products.map(async (product) => {
                const productResponse = await fetch(`https://pos.farm2bag.com/api/v1/products/${product.productId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const productData = await productResponse.json();
                return {
                    ...product,
                    productCode: productData.data.productsCode // Assuming productCode is named productsCode in the response
                };
            });
    
            // Wait for all product details to be fetched
            const productDetails = await Promise.all(productDetailsPromises);
            setProductSuggestions(productDetails);
    
        } catch (error) {
            console.error('Error fetching vendor products:', error);
        }
    };
    
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                productInputRef.current &&
                !productInputRef.current.contains(event.target) &&
                !event.target.classList.contains('product-suggestion')
            ) {
                setShowProductSuggestions(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleUpdateData = async () => {
        try {
            if (!selectedVendor || !selectedVendor.vendorId) {
                throw new Error('No vendor selected or vendor ID not found');
            }

            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found in local storage');
            }

            const role = localStorage.getItem('role');
            if (!role) {
                throw new Error('No role found in local storage');
            }

            const payload = {
                vendorId: selectedVendor.vendorId,
                vendorName: selectedVendor.vendorName,
                createdBy: role.toUpperCase(),
                products: selectedProducts.map(product => ({
                    productId: product.productId,
                    productName: product.productName,
                    perKgCost: product.perKgCost,
                    perQtyCost: product.perQtyCost,
                    productCode: product.productCode
                }))
            };

            const response = await fetch(`https://pos.farm2bag.com/api/v1/vendorRates/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                const responseText = await response.text();
                console.error('Response Text:', responseText);
                throw new Error('Failed to update vendor rate');
            }

            toast.success('Vendor rate updated successfully');
            setTimeout(() => {
                closePopup();
            }, 3000);
        } catch (error) {
            console.error('Error updating vendor rate:', error);
        }
    };

    const handleProductSearchInputChange = (e) => {
        const query = e.target.value;
        setProductSearchQuery(query);
        setShowProductSuggestions(query !== '');

        if (productSuggestions && query !== '') {
            const filteredProducts = productSuggestions.filter(product =>
                product.productName && product.productName.toLowerCase().includes(query.toLowerCase())
            );
            setProductSuggestions(filteredProducts);
        } else {
            setProductSuggestions([]);
        }
    };

    const handleProductSelect = (product) => {
        // Check if the product already exists in selectedProducts array
        const productExists = selectedProducts.some(
            (existingProduct) => existingProduct.productId === product.productId
        );
    
        // If the product does not exist, add it to selectedProducts array
        if (!productExists) {
            const newSelectedProduct = {
                ...product,
                perKgCost: '',
                perQtyCost: ''
            };
    
            setSelectedProducts([...selectedProducts, newSelectedProduct]);
            setProductSearchQuery(product.productName || '');
            setProductSuggestions([]);
            setShowProductSuggestions(false);
        } else {
            // If the product already exists, display a message or handle it accordingly
            console.log('Product already selected');
            toast.error('This product is already added.');
            // You can display a toast message or handle it in any other way as needed
        }
    };
    
    const handleProductDelete = (index) => {
        setSelectedProducts(prevProducts => prevProducts.filter((_, i) => i !== index));
    };

    const handleProductCodeChange = (index, value) => {
        setSelectedProducts(prevProducts =>
            prevProducts.map((product, i) =>
                i === index ? { ...product, productCode: value } : product
            )
        );
    };

    const handlePerKgCostChange = (index, value) => {
        if (parseFloat(value) >= 0 || value === '') {
            setSelectedProducts(prevProducts =>
                prevProducts.map((product, i) =>
                    i === index ? { ...product, perKgCost: parseFloat(value) } : product
                )
            );
        }
    };

    const handlePerQtyCostChange = (index, value) => {
        if (parseFloat(value) >= 0 || value === '') {
            setSelectedProducts(prevProducts =>
                prevProducts.map((product, i) =>
                    i === index ? { ...product, perQtyCost: parseFloat(value) } : product
                )
            );
        }
    };

    return (
        <div className="fixed inset-0 flex justify-center bg-gray-800 bg-opacity-70 z-50">
            <div className='mt-10 p-12 h-4/5 bg-gray-200'>
                <button onClick={closePopup} className="text-white float-right p-2 bg-red-500 rounded-lg">✕</button>
                <div className="mb-10">
                    <ul className='flex'>
                        <li><h1 className="font-extrabold text-3xl text-left text-gray-500">Edit Vendor Rate</h1></li>
                    </ul>
                    <div className="mt-2 border-t border-gray-500"></div>
                </div>

                <div className='ml-50 mt-0' ref={productInputRef}>
                    <h1 className="font-extrabold text-2xl text-left text-gray-500 mt-14">Select Product</h1>
                    <input
                        type="text"
                        placeholder="Search products"
                        value={productSearchQuery}
                        onFocus={() => setShowProductSuggestions(true)}
                        onChange={handleProductSearchInputChange}
                        className="border border-gray-200 px-4 py-2 pl-10 rounded-lg float-left w-96 mt-2"
                    />
                    {showProductSuggestions && productSuggestions && productSuggestions.length > 0 && (
                        <ul className="absolute z-10 w-96 mt-12 bg-white border border-gray-200 rounded-lg shadow-lg">
                            {productSuggestions.map((product) => (
                                <li key={product.productId} className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                    onClick={() => handleProductSelect(product)}
                                >
                                    {product.productName}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                <div className="mt-20">
                    <h3 className="text-xl font-semibold mb-2">Selected Product Details</h3>
                    <table className="w-full border-collapse border-gray-500 text-left">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="px-4 py-2 text-center">Product Name</th>
                                <th className="px-4 py-2 text-center">Product Code</th>
                                <th className="px-4 py-2 text-center">Per KG Cost</th>
                                <th className="px-4 py-2 text-center">Per Quantity Cost</th>
                                <th className="px-4 py-2 text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedProducts.map((product, index) => (
                                <tr key={index} className="bg-white">
                                    <td className="border px-2 text-center py-2">{product.productName}</td>
                                    <td className="border px-2 text-center py-2">{product.productCode}</td> 
                                    <td className="border px-2 text-center py-2">
                                        <input
                                            type="number"
                                            value={product.perKgCost || ''}
                                            onChange={(e) => handlePerKgCostChange(index, e.target.value)}
                                            className="border border-gray-300 p-1"
                                        />
                                    </td>
                                    <td className="border px-2 text-center py-2">
                                        <input
                                            type="number"
                                            value={product.perQtyCost || ''}
                                            onChange={(e) => handlePerQtyCostChange(index, e.target.value)}
                                            className="border border-gray-300 p-1"
                                        />
                                    </td>
                                    <td className="border px-2 text-center py-2">
                                        <button onClick={() => handleProductDelete(index)} className="text-red-600 hover:text-red-800">
                                            <IoMdTrash />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="flex justify-between mt-4">
                    <button type="button" onClick={closePopup} className="px-4 py-2 bg-red-500 text-white rounded-lg ml-12">
                        Cancel
                    </button>
                    <button type="button" onClick={handleUpdateData} className="px-4 py-2 bg-green-500 text-white rounded-lg mr-12">
                        Save Changes
                    </button>
                </div>

                <ToastContainer />
            </div>
        </div>
    );
};

export default EditVendorRate;
