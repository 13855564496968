import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import PurchaseEditsApi from './PurchaseEditsApi';

const PurchaseEdit = ({ isOpen, onClose, purchase, fetchData }) => {
    const [purchaseDetails, setPurchaseDetails] = useState(null);
    const [status, setStatus] = useState('');
    const [vendors, setVendors] = useState([]);
    const [measurements, setMeasurements] = useState([]);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [editableProducts, setEditableProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [warehouses, setWarehouses] = useState([]);
    const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);


    useEffect(() => {
        setIsSaveEnabled(validateFields());
    }, [editableProducts]);

    // Fetch purchase details and initial data
    useEffect(() => {
        if (purchase?.id) {
            PurchaseEditsApi.fetchPurchaseById(purchase.id)
                .then((response) => {
                    setPurchaseDetails(response.data);
                    setSelectedWarehouseId(response.data.warehouseId);
                    setStatus(response.data.status);
                    setSelectedProducts(response.data.products.map(p => p.productId));
                    setEditableProducts(response.data.products);
                })
                .catch((error) => {
                    console.error('Failed to fetch purchase data:', error);
                });
        }
    }, [purchase?.id]);

    useEffect(() => {
        Promise.all([
            PurchaseEditsApi.fetchVendors(),
            PurchaseEditsApi.fetchMeasurements(),
            PurchaseEditsApi.fetchCategories(),
            PurchaseEditsApi.fetchProducts()
        ])
            .then(([vendorsResponse, measurementsResponse, categoriesResponse, productsResponse]) => {
                setVendors(vendorsResponse.data);
                setMeasurements(measurementsResponse.data);
                setCategories(categoriesResponse.data);
                setProducts(productsResponse.data);
            })
            .catch((error) => {
                console.error('Failed to fetch vendors, measurements, categories, or products:', error);
            });
    }, []);

    useEffect(() => {
        const { totalWithoutGst, totalWithGst } = calculateTotals();
        setPurchaseDetails(prevDetails => ({
            ...prevDetails,
            totalAmountWithoutGst: totalWithoutGst,
            totalAmountWithGst: totalWithGst
        }));
    }, [editableProducts]);


    const validateFields = () => {
        return editableProducts.every(product =>
            product.orderQuantity > 0 &&
            product.perUnitPrice > 0 &&
            product.gst >= 0
        );
    };

    const handleSave = () => {
        if (purchaseDetails) {
            const updatedProducts = editableProducts.map(product => ({
                ...product,
                productCode: product.productCode  // Ensure productCode is included
            }));

            PurchaseEditsApi.handleEditPurchase({
                ...purchaseDetails,
                products: updatedProducts
            }, status, fetchData, onClose);
        }
    };


    useEffect(() => {
        const fetchWarehouses = async () => {
            const token = localStorage.getItem('token');

            try {
                const response = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`  // Add the token to the Authorization header
                    }
                });

                const result = await response.json();
                if (response.ok) {
                    setWarehouses(result.data); // Set warehouse data
                } else {
                    console.error('Error fetching warehouse data:', result.message);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchWarehouses();
    }, []);



    const handleProductSelection = (productId) => {
        if (selectedProducts.includes(productId)) {
            // Deselecting product
            setSelectedProducts(prevSelected =>
                prevSelected.filter(id => id !== productId)
            );

            // Remove from editable products
            setEditableProducts(prevProducts =>
                prevProducts.filter(product => product.productId !== productId)
            );
        } else {
            // Selecting product
            setSelectedProducts(prevSelected =>
                [...prevSelected, productId]
            );

            // Add to editable products with default values
            const selectedProduct = products.find(product => product.id === productId);
            if (selectedProduct) {
                setEditableProducts(prevProducts => [
                    ...prevProducts,
                    {
                        productId: selectedProduct.id,
                        productName: selectedProduct.name,
                        productCode: selectedProduct.productsCode,
                        orderQuantity: 0,
                        unit: measurements.length > 0 ? measurements[0].unitSymbol : '',  // Set default unit
                        unitId: measurements.length > 0 ? measurements[0].id : '',        // Set default unitId
                        perUnitPrice: 0,
                        totalPriceWithoutGst: 0,
                        totalPriceWithGst: 0,
                        gst: 0
                    }
                ]);
            }
        }
    };


    const handleProductChange = (productId, field, value) => {
        setEditableProducts(prevProducts =>
            prevProducts.map(product => {
                if (product.productId === productId) {
                    let updatedProduct = { ...product };

                    if (field === 'unit') {
                        const selectedUnit = measurements.find(m => m.unitSymbol === value);
                        if (selectedUnit) {
                            updatedProduct.unit = selectedUnit.unitSymbol;
                            updatedProduct.unitId = selectedUnit.id;
                        }
                    } else {
                        updatedProduct[field] = value;
                    }

                    // Update calculated fields like total prices
                    updatedProduct.totalPriceWithoutGst = updatedProduct.orderQuantity * updatedProduct.perUnitPrice;
                    updatedProduct.totalPriceWithGst = updatedProduct.orderQuantity * updatedProduct.perUnitPrice * (1 + updatedProduct.gst / 100);

                    return updatedProduct;
                }
                return product;
            })
        );

        setIsSaveEnabled(validateFields());
    };



    const calculateTotals = () => {
        let totalWithoutGst = 0;
        let totalWithGst = 0;

        editableProducts.forEach(product => {
            totalWithoutGst += product.totalPriceWithoutGst || 0;
            totalWithGst += product.totalPriceWithGst || 0;
        });

        return { totalWithoutGst, totalWithGst };
    };


    const handleRemoveProduct = (productId) => {
        setEditableProducts(prevProducts => prevProducts.filter(product => product.productId !== productId));
        setSelectedProducts(prevSelected => prevSelected.filter(id => id !== productId));
    };

    const filteredProducts = products
        .filter(product =>
            (selectedCategory ? product.categoryId === selectedCategory : true) &&
            product.name.toLowerCase().includes(searchQuery.toLowerCase())
        );

    if (!purchaseDetails) {
        return null;
    }

    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-70 z-10 mt-14 ml-64">
            <div className='relative bg-white rounded-md shadow-lg max-w-7xl w-full h-[91vh] overflow-y-auto p-4'>

                <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2 p-4 rounded-lg shadow-lg bg-yellow-400">

                    <div className="flex flex-col md:flex-row items-start md:items-center">
                        <label className="font-semibold text-gray-700 md:w-1/2 pr-1">Purchase Order:</label>
                        <p className="bg-green-200 p-2 rounded md:w-1/2">{purchaseDetails.purchaseOrder}</p>
                    </div>

                    <div className="flex flex-col md:flex-row items-start md:items-center">
                        <label className="font-semibold text-gray-700 md:w-1/2 pr-1">Vendor Name:</label>
                        <select
                            value={purchaseDetails.vendorId}
                            onChange={(e) => setPurchaseDetails(prevDetails => ({ ...prevDetails, vendorId: e.target.value }))}
                            className="p-2 border border-gray-300 rounded md:w-1/2 bg-green-200"
                        >
                            <option value="">Select a Vendor</option>
                            {vendors.map(vendor => (
                                <option key={vendor.id} value={vendor.id}>
                                    {vendor.vendorName}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="flex flex-col md:flex-row items-start md:items-center">
                        <label className="font-semibold text-gray-700 md:w-1/2 pr-1 text-nowrap ml-5">Delivery Date:</label>
                        <input
                            type="date"
                            value={purchaseDetails.deliverydate}
                            onChange={(e) => setPurchaseDetails(prevDetails => ({
                                ...prevDetails,
                                deliverydate: e.target.value
                            }))}
                            className="p-2 border border-gray-300 bg-green-200 rounded w-36 ml-2"
                        />
                    </div>

                    <div className="flex flex-col md:flex-row items-start md:items-center">
                        <label className="font-semibold text-gray-700 md:w-1/2 pr-1 ml-16">Status:</label>
                        <select
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            className="p-2 border border-gray-300 bg-green-200 rounded w-36 "
                        >
                            <option value="Active">Active</option>
                            <option value="Complete">Complete</option>
                        </select>
                    </div>

                    <div className="flex flex-col md:flex-row  justify-end">
                        <button
                            onClick={handleSave}
                            disabled={!isSaveEnabled}
                            className={`bg-green-500 text-white font-semibold px-10 py-2 rounded ${!isSaveEnabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#35c704]'}`}
                        >
                            Save
                        </button>
                    </div>

                </div>



                <div className="mb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 p-4 rounded-lg shadow-lg bg-yellow-400">
                    <div className="flex flex-col md:flex-row items-start md:items-center">
                        <label className="font-semibold text-gray-700 md:w-1/2 pr-1">Category:</label>
                        <select
                            value={selectedCategory}
                            onChange={(e) => setSelectedCategory(e.target.value)}
                            className="p-2 border border-gray-300 rounded w-96 bg-white"
                        >
                            <option value="">Select a Category</option>
                            {categories.map(category => (
                                <option key={category.id} value={category.id}>
                                    {category.categoryName}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="flex flex-col md:flex-row items-start md:items-center">
                        <label className="font-semibold text-gray-700 md:w-1/2 pr-1">Search</label>
                        <input
                            type="text"
                            placeholder="Search products"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="px-2 py-2 border border-gray-300 rounded w-96 bg-white"
                        />
                    </div>

                    <div className="flex flex-col md:flex-row items-start md:items-center">
                        <label className="font-semibold text-gray-700 md:w-1/2 pr-1">Warehouse</label>

                        <select
                            id="warehouse-select"
                            value={selectedWarehouseId || ''}
                            onChange={(e) => setSelectedWarehouseId(e.target.value)}
                            className="block w-80 py-2 border border-gray-300 bg-green-200 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        >
                            <option value="" className='text-slate-600'>Choose Warehouse</option>
                            {warehouses.map((warehouse) => (
                                <option key={warehouse.id} value={warehouse.id}>{warehouse.name}</option>
                            ))}
                        </select>
                    </div>

                    <div className="flex flex-col md:flex-row  justify-end">
                        <button onClick={onClose} className="bg-orange-500 text-white px-10 py-2 rounded hover:bg-red-600">Close</button>
                    </div>

                </div>

                <div className="mb-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 h-60 overflow-y-auto border border-gray-300 p-2 rounded">
                        {filteredProducts.map(product => (
                            <div
                                key={product.id}
                                className={`flex items-center py-2 rounded ${selectedProducts.includes(product.id) ? 'bg-green-200' : 'bg-amber-100'}`}
                            >
                                <input
                                    type="checkbox"
                                    id={`product-${product.id}`}
                                    checked={selectedProducts.includes(product.id)}
                                    onChange={() => handleProductSelection(product.id)}
                                    className="mr-2"
                                />
                                <label htmlFor={`product-${product.id}`} className="text-sm">
                                    {product.name} ({product.productsCode})
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="overflow-x-auto">
                    <table className="w-full text-left border-collapse border border-gray-300">
                        <thead className="bg-blue-500">
                            <tr>
                                <th className="py-3 px-2 text-left text-xs font-medium text-white uppercase tracking-wider">Product Name</th>
                                <th className="py-3 px-2 text-left text-xs font-medium text-white uppercase tracking-wider">Order Quantity</th>
                                <th className="py-3 px-2 text-left text-xs font-medium text-white uppercase tracking-wider">Unit</th>
                                <th className="py-3 px-2 text-left text-xs font-medium text-white uppercase tracking-wider">Per Unit Price</th>
                                <th className="py-3 px-2 text-left text-xs font-medium text-white uppercase tracking-wider">GST (%)</th>
                                <th className="py-3 px-2 text-left text-xs font-medium text-white uppercase tracking-wider">Total Price (Without GST)</th>
                                <th className="py-3 px-2 text-left text-xs font-medium text-white uppercase tracking-wider">Total Price (With GST)</th>
                                <th className="py-3 px-2 text-left text-xs font-medium text-white uppercase tracking-wider">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {editableProducts.length > 0 ? (
                                editableProducts.map(product => (
                                    <tr key={product.productId}>
                                        <td className="border border-gray-300 p-2">{product.productName}</td>
                                        <td className="border border-gray-300 p-2 " style={{ maxWidth: "10px" }}>
                                            <input
                                                type="number"
                                                value={product.orderQuantity}
                                                onChange={(e) => handleProductChange(product.productId, 'orderQuantity', e.target.value)}
                                                className="p-1 border border-gray-300 rounded w-full"
                                                min="0"
                                            />
                                        </td>

                                        <td className="border border-gray-300 p-2">
                                            <select
                                                value={product.unit}  // Make sure this is bound to the product's unit value
                                                onChange={(e) => handleProductChange(product.productId, 'unit', e.target.value)}
                                                className="p-1 border border-gray-300 rounded w-full"
                                            >
                                                {measurements.map((measurement) => (
                                                    <option key={measurement.id} value={measurement.unitSymbol}>
                                                        {measurement.unitSymbol}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>

                                        <td className="border border-gray-300 p-2" style={{ maxWidth: "10px" }}>
                                            <input
                                                type="number"
                                                value={product.perUnitPrice}
                                                onChange={(e) => handleProductChange(product.productId, 'perUnitPrice', e.target.value)}
                                                className="p-1 border border-gray-300 rounded w-full"
                                                min="0"
                                            />
                                        </td>
                                        <td className="border border-gray-300 p-2" style={{ maxWidth: "16px" }}>
                                            <input
                                                type="number"
                                                value={product.gst}
                                                onChange={(e) => handleProductChange(product.productId, 'gst', e.target.value)}
                                                className="p-1 border border-gray-300 rounded w-full"
                                                min="0"
                                            />
                                        </td>
                                        <td className="border border-gray-300 p-2" >{product.totalPriceWithoutGst.toFixed(2)}</td>
                                        <td className="border border-gray-300 p-2" >{product.totalPriceWithGst.toFixed(2)}</td>
                                        <td className="border border-gray-300 p-2">
                                            <button
                                                onClick={() => handleRemoveProduct(product.productId)}
                                                className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
                                            >
                                                Remove
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8" className="border border-gray-300 p-2 text-center">No products available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="flex justify-end bg-yellow-400 p-2">
                        <div className='flex items-center justify-center mr-20'>
                            <label className="font-semibold text-gray-700  pr-1">Total Amount (Without GST):</label>
                            <p className="bg-gray-100 p-2 rounded ">{purchaseDetails.totalAmountWithoutGst.toFixed(2)}</p>

                        </div>
                        <div className='flex items-center justify-center mr-36'>
                            <label className="font-semibold text-gray-700  pr-1">Total Amount (With GST):</label>
                            <p className="bg-gray-100 p-2 rounded">{purchaseDetails.totalAmountWithGst.toFixed(2)}</p>

                        </div>
                    </div>

                </div>
            </div>
        </Modal>
    );
};

export default PurchaseEdit;