import React, { useEffect, useState } from 'react';
import CreateCapitalPopup from '../components/CreateCapital';
import EditCapitalPopup from '../components/EditCapitalPopup';
import DeleteCapitalPopup from '../components/DeleteCapitalPopup';
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import filter from "../Assets/tableicon/filter.png";
import Searchicon from "../Assets/tableicon/Searchicon.png";

const Capital = () => {
  const [capitalData, setCapitalData] = useState([]);
  const [filteredCapitalData, setFilteredCapitalData] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState('');
  const [isCreatePopupOpen, setIsCreatePopupOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [currentCapital, setCurrentCapital] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchCapitalData();
    fetchWarehouses();
  }, []);

  const fetchCapitalData = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found in local storage');
        return;
      }

      const response = await fetch('https://pos.farm2bag.com/api/v1/capital', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const responseData = await response.json();
      if (responseData.status === 200) {
        setCapitalData(responseData.data);
        setFilteredCapitalData(responseData.data);
      } else {
        setCapitalData([]);
        setFilteredCapitalData([]);
        console.warn(responseData.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchWarehouses = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found in local storage');
        return;
      }

      const response = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const responseData = await response.json();
      if (responseData.status === 200) {
        setWarehouses(responseData.data);
      } else {
        console.warn(responseData.message);
      }
    } catch (error) {
      console.error('Error fetching warehouses:', error);
    }
  };

  const handleCreateCapital = () => setIsCreatePopupOpen(true);
  const handleCreatePopupClose = () => setIsCreatePopupOpen(false);

  const handleEditPopupOpen = (capital) => {
    setCurrentCapital(capital);
    setIsEditPopupOpen(true);
  };

  const handleEditPopupClose = () => setIsEditPopupOpen(false);

  const handleDeletePopupOpen = (capital) => {
    setCurrentCapital(capital);
    setIsDeletePopupOpen(true);
  };
  const handleDeletePopupClose = () => setIsDeletePopupOpen(false);

  const handleCreateCapitalData = () => {
    fetchCapitalData();
    handleCreatePopupClose();
  };

  const handleUpdateCapitalData = () => {
    fetchCapitalData();
    handleEditPopupClose();
  };

  const handleDeleteCapitalData = () => {
    fetchCapitalData();
    handleDeletePopupClose();
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    filterCapitalData(query, selectedWarehouse);
    setCurrentPage(1);
  };

  const handleWarehouseFilter = (event) => {
    const selected = event.target.value;
    setSelectedWarehouse(selected);
    filterCapitalData(searchQuery, selected);
    setCurrentPage(1);
  };

  const filterCapitalData = (query, warehouse) => {
    let filteredData = capitalData;
    if (query) {
      filteredData = filteredData.filter((capital) =>
        capital.capitalTypeName.toLowerCase().includes(query.toLowerCase()) ||
        capital.description.toLowerCase().includes(query.toLowerCase())
      );
    }
    if (warehouse) {
      filteredData = filteredData.filter((capital) => capital.wareHouseName === warehouse);
    }
    setFilteredCapitalData(filteredData);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);  // Reset to the first page whenever items per page changes
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCapitalData = filteredCapitalData.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredCapitalData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className='bg-gray-100 ml-64 mt-20 p-12 h-screen'>
      <div className="container mx-auto">
        <div className="mb-10">
          <h1 className="font-extrabold text-3xl text-left text-gray-500">Capital</h1>
          <div className="mt-2 border-t border-gray-500"></div>
        </div>
        <div className="flex justify-between items-center mb-5">
          <div className="flex items-center space-x-4">
            <div className="relative">
              <input type="text" placeholder='search' value={searchQuery} onChange={handleSearch} className="border border-gray-200 px-4 py-2 pl-10 rounded-lg focus:outline-none focus:border-blue-500" />
              <img src={Searchicon} alt="search" className="absolute left-3 top-3 w-4 h-4" />
            </div>
            <select value={selectedWarehouse} onChange={handleWarehouseFilter} className="border border-gray-300 px-4 py-2 rounded-lg focus:outline-none focus:border-blue-500">
              <option value="">All Warehouses</option>
              {warehouses.map(warehouse => (
                <option key={warehouse.id} value={warehouse.name}>
                  {warehouse.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex ml-auto mr-0 items-center space-x-4 mr-2">
            <img
              src={filter}
              alt="filter"
              className="w-12 h-10 rounded-lg cursor-pointer"
              onClick={() => setFilteredCapitalData([...filteredCapitalData].sort((a, b) => a.capitalTypeName.localeCompare(b.capitalTypeName)))}
            />
            <button onClick={handleCreateCapital} style={{ backgroundColor: 'rgba(66, 255, 97, 1)' }} className="w-48 h-10 font-medium text-sm rounded-lg">Add Capital</button>
          </div>
          <select value={itemsPerPage} onChange={handleItemsPerPageChange} className="border border-gray-300 px-4 py-2 rounded-lg focus:outline-none focus:border-blue-500">
            <option value={5}>5 per page</option>
            <option value={10}>10 per page</option>
            <option value={20}>20 per page</option>
          </select>
        </div>

        {isLoading ? (
          <div className="text-center">Loading...</div>
        ) : (
          <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead className="bg-blue-500">
              <tr>
                <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">Date</th>
                <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">WareHouse</th>
                <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">Category</th>
                <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">Amount (₹)</th>
                <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">Description</th>
                <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentCapitalData.length > 0 ? (
                currentCapitalData.map((capital) => (
                  <tr key={capital.id} className="hover:bg-green-200">
                    <td className="py-3 px-4 text-left">
                      {new Date(capital.date).toLocaleDateString('en-GB')}
                    </td>
                    <td className="py-3 px-4 text-left">{capital.wareHouseName}</td>
                    <td className="py-3 px-4 text-left">{capital.capitalTypeName}</td>
                    <td className="py-3 px-4 text-left">₹&nbsp;{new Intl.NumberFormat({ style: 'currency', currency: 'INR' }).format(capital.amount)}</td>
                    <td className="py-3 px-4 text-left">{capital.description}</td>
                    <td className="border px-4 py-2 flex justify-center">
                      <button onClick={() => handleEditPopupOpen(capital)} className="mr-2 text-blue-500"><FaRegEdit size={22} style={{ color: "blue" }} /></button>
                      <button onClick={() => handleDeletePopupOpen(capital)} className="text-red-500"><IoMdTrash size={30} style={{ color: "red" }} /></button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="py-4 px-4 text-center text-sm text-gray-500">No results found</td>
                </tr>
              )}
            </tbody>
          </table>
        )}
        <div className="flex justify-between items-center mt-4">
          <div className="flex space-x-2">
            {pageNumbers.map(number => (
              <button key={number} onClick={() => paginate(number)} className={`py-2 px-4 rounded-lg ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
                {number}
              </button>
            ))}
          </div>
          <span className="text-gray-600">Page {currentPage} of {pageNumbers.length}</span>
        </div>

      </div>

      {/* Popups */}
      {isCreatePopupOpen && <CreateCapitalPopup onClose={handleCreatePopupClose} onCreate={handleCreateCapitalData} />}
      {isEditPopupOpen && <EditCapitalPopup onClose={handleEditPopupClose} onUpdate={handleUpdateCapitalData} capital={currentCapital} />}
      {isDeletePopupOpen && <DeleteCapitalPopup onClose={handleDeletePopupClose} onDelete={handleDeleteCapitalData} capital={currentCapital} />}
    </div>
  );
};

export default Capital;
