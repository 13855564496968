import React, {useState,useEffect} from 'react';
import { FaExclamationTriangle } from 'react-icons/fa'; // Importing the important icon
import Modal from 'react-modal'; // For the popup/modal

const ProductNotImport = ({ modalIsOpen, closeModal,errorMessages }) => {
  
   
    console.log(errorMessages);
  return (
    <div>
      {/* Popup Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Not import Products"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            maxWidth: '500px',
            width: '100%',
          },
        }}
      >
        <div className="flex items-center mb-4">
          <FaExclamationTriangle className="text-red-600 text-xl mr-2" />
          <h2 className="text-xl font-bold">Not imported Products</h2>
        </div>

        <ul className="list-disc list-inside text-red-600">
          {errorMessages.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </ul>
        <button
          onClick={closeModal}
          className="mt-4 bg-red-600 text-white py-2 px-4 rounded"
        >
          Close
        </button>
      </Modal>
    </div>
  );
};

export default ProductNotImport;
