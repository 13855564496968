import AWS from 'aws-sdk';

const S3_BUCKET = 'f2b-pos';
const REGION = 'ap-south-1';
const ACCESS_KEY = 'AKIAQSNAUQE5MBTDHWHK';
const SECRET_ACCESS_KEY = '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';

AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
    region: REGION,
});

const s3 = new AWS.S3();

const uploadImageToS3 = (file) => {
    return new Promise((resolve, reject) => {
        const params = {
            Bucket: S3_BUCKET,
            Key: file.name, // Specify the folder path for images
            Body: file,
            ContentType: file.type,
            ACL: 'public-read',
        };

        s3.upload(params, (err, data) => {
            if (err) {
                reject(err);
            } else {
                resolve(data.Location);
            }
        });
    });
};

const uploadPdfToS3 = (file) => {
    return new Promise((resolve, reject) => {
        const params = {
            Bucket: S3_BUCKET,
            Key: `bills/${file.name}`, // Specify the folder path for PDFs
            Body: file,
            ContentType: file.type,
            ACL: 'public-read',
        };

        s3.upload(params, (err, data) => {
            if (err) {
                reject(err);
            } else {
                resolve(data.Location);
            }
        });
    });
};

export { uploadImageToS3, uploadPdfToS3 };
