import React, { useEffect, useState } from "react";

const StockReport = ({
  quantityFilter,
  unitFilter,
  stockSearch,
  wareHouseFilter,
  stockDataPdf,
  warehouses,
}) => {
  const [stockData, setStockData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    let isMounted = true;

    const fetchStockData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found");
        }
        const response = await fetch("https://pos.farm2bag.com/api/v1/stocks", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const res = await response.json();
        if (res && Array.isArray(res.data)) {
          if (isMounted) {
            let updateStockData = res.data.map((item) => ({
              ...item,
              products: item.products.map((product) => ({
                ...product,
                warehouse: warehouses.find(
                  (stockWarehouse) => stockWarehouse.id == item.wareHouseId
                )?.name,
                wareHouseId: item.wareHouseId,
              })),
            }));
            setStockData(updateStockData);

            let allProducts = updateStockData
              .map((item) => item.products)
              .flat();

            if (wareHouseFilter) {
              allProducts = allProducts.filter(
                (item) => item.wareHouseId == wareHouseFilter
              );
            }

            if (quantityFilter) {
              allProducts = allProducts.filter(
                (item) => item.quantity <= quantityFilter
              );
            }
            if (unitFilter) {
              allProducts = allProducts.filter(
                (item) => item.unitSymbol === unitFilter
              );
            }
            setProducts(allProducts);
            stockDataPdf(allProducts); // Passing the data to the parent
          }
        } else {
          throw new Error("Data format is not an array");
        }
      } catch (error) {
        if (isMounted) {
          setError(error.message);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchStockData();

    return () => {
      isMounted = false;
    };
  }, [quantityFilter, unitFilter, wareHouseFilter]);

  useEffect(() => {
    let filtered = products;

    if (stockSearch) {
      filtered = filtered.filter(
        (item) =>
          item.productsCode.toLowerCase().includes(stockSearch.toLowerCase()) ||
          item.name.toLowerCase().includes(stockSearch.toLowerCase())
      );
    } else {
      filtered = products;
    }

    setFilteredProducts(filtered);
    setCurrentPage(1); // Reset to the first page whenever filters or search changes
  }, [stockSearch, products]);

  // Pagination Logic
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentPageItems = filteredProducts.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to the first page whenever items per page changes
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div>
      <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
        <thead className="bg-blue-500">
          <tr>
            <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">
              Code
            </th>
            <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">
              Warehouse
            </th>
            <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">
              Product Name
            </th>
            <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">
              Unit
            </th>
            <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">
              Price
            </th>
            <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">
              Current Stock
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {currentPageItems.map((item) => (
            <tr key={item.id} className="hover:bg-green-200">
              <td className="py-3 px-4 text-center w-1/12">
                {item.productsCode}
              </td>
              <td className="py-3 px-4 text-center w-1/12">{item.warehouse}</td>
              <td className="py-3 px-4 text-center w-1/12">{item.name}</td>
              <td className="py-3 px-4 text-center w-1/12">
                {item.unitSymbol}
              </td>
              <td className="py-3 px-4 text-center w-1/12">₹{item.price}</td>
              <td className="py-3 px-4 text-center w-1/12">{item.quantity}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between items-center mt-12">
        <div>
          <label htmlFor="itemsPerPage" className="mr-2">
            Records per page:
          </label>
          <select
            id="itemsPerPage"
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            className="border border-gray-300 rounded-lg px-4 py-2 focus:outline-none ml-2"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
          </select>
        </div>
        <div className="flex items-center">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className={`px-3 py-2 border rounded-lg mx-1 border-none ${
              currentPage === 1
                ? "bg-gray-200 text-gray-500"
                : "bg-green-500 text-white"
            }`}
          >
            Previous
          </button>
          <span className="px-3 py-1">
            {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className={`px-3 py-2 border rounded-lg mx-1 border-none ${
              currentPage === totalPages
                ? "bg-gray-200 text-gray-500"
                : "bg-green-500 text-white"
            }`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default StockReport;
