import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import filter from "../Assets/tableicon/filter.png";
import Searchicon from "../Assets/tableicon/Searchicon.png";
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import eye from "../Assets/tableicon/Eye.svg";
import VendorDetails from "../pages/VendorDetails";
import ReactPhoneInput from "react-phone-input-2";

const Vendor = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isCreatePopupOpen, setCreatePopupOpen] = useState(false);
  const [product, setProduct] = useState([]);
  const [productFromApi, setProductFromApi] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [isSortingActive, setIsSortingActive] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [VendorIdToDelete, setVendorIdToDelete] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [venderName, setVendorName] = useState(null);
  const [VendorForEdit, setVendorForEdit] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const [formValues, setFormValues] = useState({
    vendorName: "",
    vendorType: "",
    phoneNumber: "",
    emailId: "",
    gstin: "",
    address: "",
    locality: "",
    district: "",
    pinCode: "",
    state: "",
    country: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const refreshData = () => {
    fetchData();
  };

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found in local storage");
        return;
      }

      const response = await fetch("https://pos.farm2bag.com/api/v1/vendors", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const jsonData = await response.json();
      setData(jsonData.data); // Adjust to access the 'data' field in the response
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found in local storage");
          return;
        }
        const response = await fetch("https://pos.farm2bag.com/api/v1/products", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch products data");
        }

        const data = await response.json();

        // console.log("Received data:", data);

        let products = [];

        if (Array.isArray(data)) {
          products = data.map((item) => ({
            productId: item.id,
            productName: item.name,
          }));
        } else if (typeof data === "object" && data.data) {
          products = data.data.map((item) => ({
            productId: item.id,
            productName: item.name,
          }));
        } else {
          throw new Error("Received data format not supported");
        }

        setProductFromApi(products);
      } catch (error) {
        console.error("Error fetching products data:", error.message);
      }
    };

    fetchData();
  }, []);

  const postData = async (formData) => {
    console.log(formData);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found in local storage");
        return;
      }
      const response = await fetch("https://pos.farm2bag.com/api/v1/vendors", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        throw new Error("Failed to submit form data");
      }
      const data = await response.json();
      fetchData();

      setCreatePopupOpen(false);
      toast.success("Vendor created successfully!");
    } catch (error) {
      console.error("Error submitting form data:", error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateGSTIN = (gstin) => {
    const tamilnaduGstinPattern =
      /^33[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    return tamilnaduGstinPattern.test(gstin);
  };

  const validatePhoneNumber = (phoneNumber) => {
    if (!phoneNumber || phoneNumber.trim() === "") {
        return false; // Phone number is empty
    }
    return true; // Phone number is not empty
};


  const validatePincode = (pincode) => {
    return /^[0-9]{6}$/.test(pincode);
  };

  const validateEmail = (emailId) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(emailId);
  };

  const validateVendorName = (vendorName) => {
    const regex = /^[A-Za-z\s]+$/;
    return regex.test(vendorName);
  };
  
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleSort = () => {
    setIsSortingActive(true);
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const nameA = a.vendorName.toUpperCase();
      const nameB = b.vendorName.toUpperCase();
      if (nameA < nameB) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (nameA > nameB) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });
    setData(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };
// validation for the post api input fields
  const handleSubmit = (e) => {
    e.preventDefault();
    let validationErrors = {};

    if (!validateGSTIN(formValues.gstin)) {
      validationErrors.gstin = "Invalid GSTIN format.";
    }

    if(!validatePhoneNumber(formValues.phoneNumber)){
      validationErrors.phoneNumber="phone number need to be  excatly 10 ";
    }

    if (!validatePincode(formValues.pincode)) {
      validationErrors.pincode = "Pincode must be exactly 6 digits.";
    }

    if (!validateEmail(formValues.emailId)) {
      validationErrors.emailId = "Invalid email format.";
    }

    if (!validateVendorName(formValues.vendorName)) {
      validationErrors.vendorName = " It should not contain numbers or symbols.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      resetForm();
      postData({ ...formValues, product });
    }
  };

  // Product selection  and deletion for the input tag popup
  const handleProductSelection = (id) => {
    const isSelected = product.some((p) => p.productId === id);
    
    if (isSelected) {
      // If already selected, remove it from the product array
      const updatedProduct = product.filter((p) => p.productId !== id);
      setProduct(updatedProduct);
    } else {
      // If not selected, add it to the product array
      const selectedProduct = productFromApi.find(
        (product) => product.productId === id
      );
      if (selectedProduct) {
        setProduct((prevProducts) => [...prevProducts, selectedProduct]);
      }
    }
  };
  

  const handleProductDeletion = (id) => {
    const updatedProduct = product.filter((p) => p.productId !== id);
    setProduct(updatedProduct);
  };

  const handleCancel = () => {
    setCreatePopupOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setFormValues({
      vendorName: "",
      vendorType: "",
      phoneNumber: "",
      emailId: "",
      gstin: "",
      address: "",
      locality: "",
      district: "",
      pinCode: "",
      state: "",
      country: "",
    });
    setProduct([]);
  };

  const openPopup = () => {
    setCreatePopupOpen(true);
    setErrors({});
  };

  const handleVendorDetails = (vendorId) => {
    setSelectedVendorId(vendorId);
  };

  const handleVendorToDelete = (vendorId, vendorName) => {
    setVendorIdToDelete(vendorId);
    setVendorName(vendorName);
  };
  const handleVendorToEdit = (vendor) => {
    setVendorForEdit(vendor);
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleItemsPerPageChange = (e) => {
    setCurrentPage(1);
    setItemsPerPage(parseInt(e.target.value));
  };
  useEffect(() => {
   
    const totalPages = Math.ceil(data.length / itemsPerPage);
  
    
    if (currentPage > totalPages) {
      setCurrentPage(1);
    }
  }, [data]);

  return (
    <div>
      {/* common for all pages  and getall api*/} 
      <div className="bg-gray-100 ml-64 mt-20 p-10 h-screen">
        <div className="container mx-auto">
          <div className="mb-10">
            <div className="flex">
              <h1 className="font-extrabold text-3xl text-left   text-gray-500">
                Vendor
              </h1>
            </div>
            <div className="mt-2 border-t border-gray-500"></div>
          </div>
          <div className="flex items-center mb-5">
            <div className="ml-0">
              <form>
                <div className="relative">
                  <input
                    type="text"
                    placeholder="search  the vendername "
                    value={searchQuery}
                    onChange={handleSearch}
                    className="border border-gray-200 px-4 py-2 pl-10 rounded-lg focus:outline-none focus:border-blue-500"
                  />
                  <img
                    src={Searchicon}
                    alt="search"
                    className="absolute left-3 top-3 w-4 h-4"
                  />
                </div>
              </form>
            </div>

            <div className="flex ml-auto mr-0 items-center space-x-4">
              <img
                src={filter}
                alt="filter"
                onClick={handleSort}
                className="w-12 h-10 rounded-lg "
              />
              <button
                onClick={openPopup}
                style={{ backgroundColor: "rgba(66, 255, 97, 1)" }}
                className="w-48 h-10 font-medium text-sm rounded-lg"
              >
                Create New Vendor
              </button>
            </div>
          </div>

          {/* display the details of the vender */}
          <div className="bg-gray-100  flex justify-center items-center">
            <div className="container mx-auto">
              <div className="rounded-lg overflow-hidden">
                <table className="table-auto w-full border-collapse border-gray-500 text-center">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="px-4 py-2 text-left">Vendor Name</th>
                      <th className="px-4 py-2">Vendor Code</th>
                      <th className="px-4 py-2">Email ID</th>
                      <th className="px-4 py-2">Locality</th>
                      <th className="px-4 py-2">Actions</th>
                    </tr>
                  </thead>

                  {currentItems.length > 0 ? (
                    <tbody>
                      {currentItems
                        .filter((vendor) =>
                          vendor.vendorName
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())
                        )
                        .map((vendor, index) => (
                          <tr
                            key={vendor.id}
                            className={
                              index % 2 === 0 ? "bg-white" : "bg-gray-200"
                            }
                          >
                            <td className="px-4 py-2  pl-16 border-gray-500 text-left">
                              {vendor.vendorName}
                            </td>
                            <td className="px-4 py-2  pl-16 border-gray-500">
                              {vendor.vendorCode}
                            </td>
                            <td className="px-4 py-2  pl-16 border-gray-500">
                              {vendor.emailId}
                            </td>
                            <td className="px-4 py-2 pl-16 border-gray-500">
                              {vendor.locality}
                            </td>
                            <td className="px-4 py-2 pl-16 border-gray-500">
                              <button
                                className="mr-2 font-bold rounded"
                                onClick={() => handleVendorDetails(vendor.id)}
                              >
                                <img className="mt-2" src={eye} alt="eye" />
                              </button>
                              <FaRegEdit
                                size={22}
                                className="inline-block mr-1 mb-4"
                                onClick={() => handleVendorToEdit(vendor)}
                              />
                              <IoMdTrash
                                size={30}
                                style={{ color: "red" }}
                                className="inline-block mr-1 mb-4"
                                onClick={() =>
                                  handleVendorToDelete(
                                    vendor.id,
                                    vendor.vendorName
                                  )
                                }
                              />
                            </td>{" "}
                          </tr>
                        ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="5" className="text-center py-4">
                          No vendor
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
              <div className="flex justify-between items-center mt-10 pb-10">
                <div>
                  <label htmlFor="itemsPerPage">Records per page: </label>
                  <select
                    id="itemsPerPage"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </select>
                </div>
                <div className="flex justify-center">
                  {[
                    ...Array(
                      Math.ceil(data.length / itemsPerPage)
                    ).keys(),
                  ].map((number) => (
                    <button
                      key={number}
                      onClick={() => paginate(number + 1)}
                      className="mx-1 px-3 py-1 bg-gray-300 hover:bg-gray-400 rounded"
                    >
                      {number + 1}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            <ToastContainer />
          </div>
        </div>



        {/* post api */}
        {isCreatePopupOpen && (
          <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 ml-24 mt-10 flex justify-center items-center">
            <div className="bg-gray-200 w-2/3 h-3/4 p-6 rounded-lg overflow-y-auto">
              <h2 className="text-xl font-bold mb-4">Create New Vendor</h2>
              <div className="mb-4 border-t border-gray-500"></div>
              <form
                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
                onSubmit={handleSubmit}
              >
                {[
                  { label: "Vendor Name", name: "vendorName", type: "text" },
                  {
                    name: "vendorType",
                    type: "select",
                    options: ["Select Vendor Type", "Farmer", "Supplier"],
                  },
                  { label: "Email ID", name: "emailId" },
                  { label: "GSTIN", name: "gstin" },
                  { label: "Address", name: "address" },
                  { label: "Locality", name: "locality" },
                  { label: "District", name: "district" },
                  { label: "Pincode", name: "pincode", type: "number" },
                  { label: "State", name: "state" },
                  { label: "Country", name: "country" },
                ].map(({ label, name, type, options }) => (
                  <div key={name} className="relative col-span-1">
                    {type === "select" ? (
                      <select
                        name={name}
                        value={formValues[name]}
                        onChange={handleInputChange}
                        className={`mt-1 p-2 border-b border-gray-300 rounded w-full bg
                      rounded w-full bg-transparent focus:outline-none text-lg ${
                        formValues[name] ? "text-gray-900" : "text-gray-400"
                      }`}
                        required
                      >
                        {options.map((option, index) => (
                          <option
                            key={index}
                            value={
                              option === "Select Vendor Type" ? "" : option
                            }
                          >
                            {option}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type={type || "text"}
                        name={name}
                        value={formValues[name]}
                        onChange={handleInputChange}
                        className={`mt-1 p-2 border-b border-gray-300 rounded w-full bg-transparent focus:outline-none text-lg appearance-none ${
                          type === "number" ? "input-number-no-arrows" : ""
                        }`}
                        inputMode={type === "number" ? "numeric" : "text"}
                        required
                      />
                    )}
                    <label
                      className={`absolute left-2 transition-all duration-300 ${
                        formValues[name]
                          ? "-top-3 text-xs text-blue-500"
                          : "top-2 text-sm text-gray-400"
                      }`}
                    >
                      {label}
                    </label>
                    {errors[name] && (
                      <span className="text-red-500 text-sm">
                        {errors[name]}
                      </span>
                    )}
                  </div>
                ))}

                <div className="relative  my-4">
                  <ReactPhoneInput
                    defaultCountry="in"
                    value={formValues.phoneNumber}
                    onChange={(value) =>
                      handleInputChange({
                        target: { name: "phoneNumber", value },
                      })
                    }
                    countryCodeEditable={true}
                   
                    inputStyle={{
                      width: "80%",

                      border: "1px solid #ccc",
                      borderRadius: "0.25rem",
                      outline: "none",
                      padding: "0.5rem",
                      fontSize: "1rem",
                    }}
                  />

                  {errors.phoneNumber && (
                    <span className="text-red-500 text-sm">
                      {errors.phoneNumber}
                    </span>
                  )}
                </div>

                <div className="relative col-span-1">
                  <div className="relative">
                    <div
                      className="p-2 border-b border-gray-300 rounded cursor-pointer"
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                      Select Product
                    </div>
                    {isDropdownOpen && (
                      <div className="absolute z-10 top-full left-0 w-full max-h-60 overflow-y-auto bg-white border border-gray-300 rounded-b mt-1 shadow-lg">
                        {productFromApi.map((product) => (
                          <div
                            key={product.productId}
                            className="flex items-center p-2"
                          >
                            <input
                              type="checkbox"
                              id={`product_${product.productId}`}
                              value={product.productId}
                              onChange={(e) =>
                                handleProductSelection(e.target.value)
                              }
                              className="mr-2"
                            />
                            <label
                              htmlFor={`product_${product.productId}`}
                              className="text-lg text-gray-400"
                            >
                              {product.productName}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-span-3 flex justify-between">
                  
                  <button
                    type="button"
                    onClick={handleCancel}
                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg hover:bg-red-500"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Submit
                  </button>
                </div>
              </form>

              <hr className="my-6 border-t border-gray-500" />

              {product.length > 0 && (
                <div className="mx-52 mt-4 border border-gray-400 border-2 rounded p-4 w-2/4 shadow-2xl">
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th className="px-4 py-2">Product Name</th>
                        <th className="px-4 py-2">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {product.map((product) => (
                        <tr
                          key={product.productId}
                          className="border-t border-gray-400"
                        >
                          <td className="px-4 py-2">{product.productName}</td>
                          <td className="px-4 py-2">
                            <button
                              onClick={() =>
                                handleProductDeletion(product.productId)
                              }
                              className="bg-red-500 text-white px-2 py-1 rounded"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        )}

        {selectedVendor && (
          <VendorDetails
            vendor={selectedVendor}
            onClose={() => setSelectedVendor(null)}
          />
        )}
      </div>



      {selectedVendorId && (
        <VendorDetails
          vendorId={selectedVendorId}
          onClose={() => setSelectedVendorId(null)}
        />
      )}
      {VendorIdToDelete && (
        <VendorDetails
          VendorIdToDelete={VendorIdToDelete}
          onCloseDelete={() => setVendorIdToDelete(null)}
          refreshData={refreshData}
          venderName={venderName}
        />
      )}
      {VendorForEdit && (
        <VendorDetails
          VendorForEdit={VendorForEdit}
          onCloseEdit={() => setVendorForEdit(null)}
          refreshDataWhileEdit={refreshData}
        />
      )}
    </div>
  );
};

export default Vendor;
