import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import PurchaseForm from './PurchaseForm';
import PurchaseEditsApi from './PurchaseEditsApi';
Modal.setAppElement('#root');

const PurchaseCreate = ({ isOpen: isOpenProp, onClose, fetchData }) => {
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedVendor, setSelectedVendor] = useState('');
    const [categories, setCategories] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [products, setProducts] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [selectedItemsByCategory, setSelectedItemsByCategory] = useState({});
    const [itemDetails, setItemDetails] = useState({});
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [newOrder, setNewOrder] = useState('');
    const [productCodes, setProductCodes] = useState({});
    const [measurements, setMeasurements] = useState([]);
    const [productCodeMap, setProductCodeMap] = useState({});
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [grandTotalAmount, setGrandTotalAmount] = useState(0);
    const [grandTotalAmountWithTax, setGrandTotalAmountWithTax] = useState(0);
    const [warehouses, setWarehouses] = useState([]);
    const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);


    useEffect(() => {
        fetchCategories();
        fetchVendors();
        fetchMeasurements();
    }, []);

    useEffect(() => {
        if (searchQuery) {
            const lowercasedQuery = searchQuery.toLowerCase();
            setFilteredProducts(products.filter(product =>
                (product.name || '').toLowerCase().includes(lowercasedQuery) ||
                (product.productsCode || '').toLowerCase().includes(lowercasedQuery)
            ));
        } else {
            setFilteredProducts(products);
        }
    }, [searchQuery, products]);

    useEffect(() => {
        calculateGrandTotals();
    }, [selectedItemsByCategory, itemDetails]);


    useEffect(() => {
        const fetchWarehouses = async () => {
            const token = localStorage.getItem('token'); // Get the token from localStorage (or sessionStorage)

            try {
                const response = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`  // Add the token to the Authorization header
                    }
                });

                const result = await response.json();
                if (response.ok) {
                    setWarehouses(result.data); // Set warehouse data
                } else {
                    console.error('Error fetching warehouse data:', result.message);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchWarehouses();
    }, []);


    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const productData = await PurchaseEditsApi.fetchProducts();
                if (productData.status === 200) {
                    setProducts(productData.data);
                    // Create a map of productId to productsCode
                    const productCodeMap = productData.data.reduce((map, product) => {
                        map[product.id] = product.productsCode;
                        return map;
                    }, {});
                    setProductCodeMap(productCodeMap);
                } else {
                    console.error('Failed to fetch products');
                }
            } catch (error) {
                console.error('Failed to fetch products:', error);
            }
        };

        const fetchCategories = async () => {
            try {
                const categoryData = await PurchaseEditsApi.fetchCategories();
                if (categoryData.status === 200) {
                    setCategories(categoryData.data);
                } else {
                    console.error('Failed to fetch categories');
                }
            } catch (error) {
                console.error('Failed to fetch categories:', error);
            }
        };

        fetchProducts();
        fetchCategories();
    }, []);

    const fetchAllProducts = async () => {
        try {
            const productData = await PurchaseEditsApi.fetchProducts();
            if (productData.status === 200) {
                setProducts(productData.data);
                const productCodeMap = productData.data.reduce((map, product) => {
                    map[product.id] = product.productsCode;
                    return map;
                }, {});
                setProductCodeMap(productCodeMap);
            } else {
                console.error('Failed to fetch products');
            }
        } catch (error) {
            console.error('Failed to fetch products:', error);
        }
    };


    const fetchCategories = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://pos.farm2bag.com/api/v1/categories', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch categories');
            }

            const result = await response.json();

            if (result.status === 200 && Array.isArray(result.data)) {
                setCategories(result.data);
            } else {
                setCategories([]);
                console.error('Unexpected response format:', result);
                toast.error('Failed to fetch categories');
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
            toast.error('Failed to fetch categories');
        }
    };

    const fetchVendors = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://pos.farm2bag.com/api/v1/vendors', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch vendors');
            }

            const result = await response.json();

            if (result.status === 200 && Array.isArray(result.data)) {
                setVendors(result.data.map(vendor => ({
                    id: vendor.id,
                    name: vendor.vendorName,
                    address: vendor.address,
                    gstin: vendor.gstin,
                    vendorCode: vendor.vendorCode,
                    locality: vendor.locality,
                    district: vendor.district,
                    state: vendor.state,
                    country: vendor.country,
                    pinCode: vendor.pinCode
                })));
            } else {
                setVendors([]);
                console.error('Unexpected response format:', result);
                toast.error('Failed to fetch vendors');
            }
        } catch (error) {
            console.error('Error fetching vendors:', error);
            toast.error('Failed to fetch vendors');
        }
    };

    const fetchMeasurements = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://pos.farm2bag.com/api/v1/measurements', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch measurements');
            }

            const result = await response.json();

            if (result.status === 200 && Array.isArray(result.data)) {
                setMeasurements(result.data.map(item => item.unitSymbol));
            } else {
                setMeasurements([]);
                console.error('Unexpected response format:', result);
                toast.error('Failed to fetch measurements');
            }
        } catch (error) {
            console.error('Error fetching measurements:', error);
            toast.error('Failed to fetch measurements');
        }
    };



    const fetchCategoryProducts = async (categoryId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://pos.farm2bag.com/api/v1/categories/categoryProducts/${categoryId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch category products');
            }

            const result = await response.json();
            console.log('Fetched data:', result); // Log the fetched data

            if (result.status === 200 && result.data && result.data.products) {
                const products = result.data.products;
                // Extract product codes
                const productCodes = products.reduce((acc, product) => {
                    acc[product.name] = product.productsCode;
                    return acc;
                }, {});
                // Now you can do something with productCodes, like set it to a state variable or use it in your code
                setProducts(products);
                setProductCodes(productCodes); // Store productCodes in state
            } else {
                setProducts([]);
                setProductCodes({});
                console.error('Unexpected response format:', result);
                toast.error('Failed to fetch category products');
            }
        } catch (error) {
            console.error('Error fetching category products:', error);
            toast.error('Failed to fetch category products');
        }
    };


    const handleCreateOrder = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const selectedVendorDetails = vendors.find(vendor => vendor.id === selectedVendor);

            if (!selectedVendorDetails) {
                console.error('Selected vendor details not found');
                return;
            }

            // Create a map of product IDs to product codes
            const productIdToCodeMap = products.reduce((acc, product) => {
                acc[product.id] = product.productsCode;
                return acc;
            }, {});

            const purchaseOrderData = {
                vendorId: selectedVendorDetails.id,
                vendorName: selectedVendorDetails.name,
                vendorAddress: `${selectedVendorDetails.address}, ${selectedVendorDetails.locality}, ${selectedVendorDetails.district}, ${selectedVendorDetails.state}, ${selectedVendorDetails.country}, ${selectedVendorDetails.pinCode}`,
                gstNo: selectedVendorDetails.gstin,
                vendorCode: selectedVendorDetails.vendorCode,
                warehouseId: selectedWarehouseId,
                deliverydate: newOrder,
                products: Object.entries(selectedItemsByCategory).flatMap(([categoryId, items]) =>
                    items.map(item => {
                        const itemDetailsKey = `${categoryId}-${items.indexOf(item)}`;
                        const details = itemDetails[itemDetailsKey] || {};
                        const productId = products.find(product => product.name === item)?.id;
                        const productCode = productId ? productIdToCodeMap[productId] : '';

                        return {
                            productId: productId || '',
                            productName: item,
                            productCode: productCode, // Use the fetched product code
                            orderQuantity: details.quantity || 0,
                            gst: details.gst || 0,
                            unit: details.unitType || "kg",
                            perUnitPrice: details.unitCost || 0,
                            perUnitPriceWithGst: calculateTotalAmountWithTax(details.unitCost || 0, details.gst || 0),
                            totalPriceWithoutGst: calculateTotalAmount(details.unitCost || 0, details.quantity || 0),
                            totalPriceWithGst: calculateTotalAmountWithTax(calculateTotalAmount(details.unitCost || 0, details.quantity || 0), details.gst || 0),
                        };
                    })
                ),
                purchaseOrder: `PO-${Math.floor(1000 + Math.random() * 9000)}`,
                totalAmountWithGst: parseFloat(grandTotalAmountWithTax.toFixed(2)),
                totalAmountWithoutGst: parseFloat(grandTotalAmount.toFixed(2)),
                createdBy: role,
                status: "Active",
                isDeleted: false
            };

            const response = await fetch('https://pos.farm2bag.com/api/v1/purchaseDetails', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(purchaseOrderData),
            });

            if (!response.ok) {
                throw new Error('Failed to create purchase order');
            }

            toast.success('Purchase order created successfully');
            fetchData(); // Refresh data after successful creation
            setSelectedVendor(null);
            setNewOrder('');
            setSelectedCategory('');
            setSelectedItemsByCategory({});
            setItemDetails({});
            onClose(); // Close modal/form
            setIsPopupOpen(false);

        } catch (error) {
            console.error('Error creating purchase order:', error);
            toast.error('Failed to create purchase order');
        }
    };

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase().trim();
        setSearchQuery(query);

        const filteredData = products.filter(product =>
            product.name.toLowerCase().includes(query) ||
            product.productsCode.toLowerCase().includes(query)
        );

        setFilteredProducts(filteredData);
    };

    const handleCategoryChange = (event) => {
        const newCategory = event.target.value;
        setSelectedCategory(newCategory);

        if (newCategory === '') {
            fetchAllProducts();
        } else {
            fetchCategoryProducts(newCategory);
        }
    };

    const handleVendorChange = (event) => {
        setSelectedVendor(event.target.value);
    };

    const handleCheckboxChange = (productName) => {
        const selectedItemsForCategory = selectedItemsByCategory[selectedCategory] || [];
        const updatedSelectedItems = selectedItemsForCategory.includes(productName)
            ? selectedItemsForCategory.filter(item => item !== productName)
            : [...selectedItemsForCategory, productName];

        setSelectedItemsByCategory({
            ...selectedItemsByCategory,
            [selectedCategory]: updatedSelectedItems
        });
    };

    const handleRemoveRow = (categoryId, index) => {
        const updatedItemsByCategory = { ...selectedItemsByCategory };
        const items = updatedItemsByCategory[categoryId].filter((item, i) => i !== index);
        updatedItemsByCategory[categoryId] = items;
        setSelectedItemsByCategory(updatedItemsByCategory);

        const updatedItemDetails = { ...itemDetails };
        delete updatedItemDetails[`${categoryId}-${index}`];
        setItemDetails(updatedItemDetails);
    };

    const handleDetailChange = (categoryId, index, field, value) => {
        const key = `${categoryId}-${index}`;
        const details = itemDetails[key] || {};
        details[field] = field === 'unitCost' || field === 'quantity' || field === 'gst' ? parseFloat(value) || 0 : value;
        setItemDetails({
            ...itemDetails,
            [key]: details
        });
    };

    // Function to calculate grand totals
    const calculateGrandTotals = () => {
        let totalAmount = 0;
        let totalAmountWithTax = 0;

        // Loop through selected items by category to calculate totals
        Object.entries(selectedItemsByCategory).forEach(([categoryId, items]) => {
            items.forEach((item, index) => {
                const key = `${categoryId}-${index}`;
                const details = itemDetails[key] || {};

                const itemTotalAmount = calculateTotalAmount(details.unitCost, details.quantity);
                const itemTotalAmountWithTax = calculateTotalAmountWithTax(itemTotalAmount, details.gst);

                totalAmount += itemTotalAmount;
                totalAmountWithTax += itemTotalAmountWithTax;
            });
        });

        // Update grand totals in the state
        setGrandTotalAmount(totalAmount);
        setGrandTotalAmountWithTax(totalAmountWithTax);
    };



    const calculateTotalAmount = (unitCost, quantity) => unitCost * quantity;
    const calculateTotalAmountWithTax = (totalAmount, gst) => totalAmount + (totalAmount * (gst / 100));

    return (
        <PurchaseForm
            isOpen={isOpenProp}  // Use state variable here
            onClose={onClose}
            categories={categories}
            vendors={vendors}
            setSearchQuery={setSearchQuery}
            selectedCategory={selectedCategory}
            selectedVendor={selectedVendor}
            handleCategoryChange={handleCategoryChange}
            handleVendorChange={handleVendorChange}
            searchQuery={searchQuery}
            handleSearch={handleSearch}
            products={products}
            selectedItemsByCategory={selectedItemsByCategory}
            handleCheckboxChange={handleCheckboxChange}
            itemDetails={itemDetails}
            calculateTotalAmount={calculateTotalAmount}
            calculateTotalAmountWithTax={calculateTotalAmountWithTax}
            handleDetailChange={handleDetailChange}
            handleRemoveRow={handleRemoveRow}
            measurements={measurements}
            isPopupOpen={isPopupOpen}
            setIsPopupOpen={setIsPopupOpen}
            newOrder={newOrder}
            setNewOrder={setNewOrder}
            handleCreateOrder={handleCreateOrder}
            grandTotalAmount={grandTotalAmount}
            grandTotalAmountWithTax={grandTotalAmountWithTax}
            filteredProducts={filteredProducts}
            setFilteredProducts={setFilteredProducts}
            setSelectedVendor={setSelectedVendor}
            setSelectedCategory={setSelectedCategory}
            setSelectedItemsByCategory={setSelectedItemsByCategory}
            setItemDetails={setItemDetails}
            warehouses={warehouses}
            selectedWarehouseId={selectedWarehouseId}
            setSelectedWarehouseId={setSelectedWarehouseId}
        />
    );
};

export default PurchaseCreate;