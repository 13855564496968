import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import { IoMdTrash } from 'react-icons/io';

const CreateVendorrate = ({ fetchData, dataCategories, products, closePopup }) => {
    const [vendorSearchQuery, setVendorSearchQuery] = useState('');
    const [vendorSuggestions, setVendorSuggestions] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [showVendorSuggestions, setShowVendorSuggestions] = useState(false);
    const vendorInputRef = useRef(null);

    useEffect(() => {
        fetchVendors();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (vendorInputRef.current && !vendorInputRef.current.contains(event.target)) {
                setShowVendorSuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const fetchVendors = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const response = await fetch('https://pos.farm2bag.com/api/v1/vendors', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch vendors');
            }

            const responseData = await response.json();
            setVendorSuggestions(responseData.data);
        } catch (error) {
            console.error('Error fetching vendors:', error);
        }
    };

    const handleVendorSearchInputChange = (e) => {
        const query = e.target.value;
        setVendorSearchQuery(query);
        setShowVendorSuggestions(query !== '');

        if (query === '') {
            setVendorSuggestions([]);
        } else {
            const suggestions = vendorSuggestions.filter(vendor =>
                vendor.vendorName.toLowerCase().includes(query.toLowerCase())
            );
            setVendorSuggestions(suggestions);
        }
    };

    const fetchProductCode = async (productId) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found in local storage');
                return null;
            }
    
            const response = await fetch(`https://pos.farm2bag.com/api/v1/products/${productId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    
            if (!response.ok) {
                throw new Error('Failed to fetch product');
            }
    
            const productData = await response.json();
            return productData.data.productsCode || ''; // Assuming 'productsCode' is nested under 'data'
        } catch (error) {
            console.error('Error fetching product:', error);
            return '';
        }
    };
    

    const handleVendorSelect = async (vendor) => {
        setSelectedVendor(vendor);
        setVendorSearchQuery(vendor.vendorName);
        setVendorSuggestions([]);
        setShowVendorSuggestions(false);
    
        if (vendor.product && vendor.product.length > 0) {
            const vendorProducts = await Promise.all(vendor.product.map(async (product) => {
                const productsCode = await fetchProductCode(product.productId);
                return {
                    productId: product.productId,
                    productName: product.productName || product.productName,
                    perKgCost: '',
                    perQuantityCost: '',
                    productsCode // Add productsCode here
                };
            }));
            setSelectedProducts(vendorProducts);
        } else {
            setSelectedProducts([]);
        }
    };
    

    const handlePerKgCostChange = (productId, value) => {
        setSelectedProducts(selectedProducts.map(product =>
            product.productId === productId ? { ...product, perKgCost: value } : product
        ));
    };

    const handlePerQuantityCostChange = (productId, value) => {
        setSelectedProducts(selectedProducts.map(product =>
            product.productId === productId ? { ...product, perQuantityCost: value } : product
        ));
    };

    const handleProductDelete = (productId) => {
        setSelectedProducts(selectedProducts.filter(product => product.productId !== productId));
    };

    const handleCloseCreatePopup = () => {
        setVendorSearchQuery('');
        setVendorSuggestions([]);
        setSelectedVendor(null);
        setSelectedProducts([]);
    };

const handleCreatedata = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('No token found in local storage');
            return;
        }

        const role = localStorage.getItem('role');
        if (!role) {
            console.error('No role found in local storage');
            return;
        }

        const payload = {
            vendorId: selectedVendor.id,
            vendorName: selectedVendor.vendorName,
            createdBy: role.toUpperCase(),
            products: selectedProducts.map(product => ({
                productId: product.productId,
                perKgCost: parseFloat(product.perKgCost),
                perQtyCost: parseFloat(product.perQuantityCost)
            }))
        };

        console.log('Payload:', JSON.stringify(payload, null, 2));

        const response = await fetch('https://pos.farm2bag.com/api/v1/vendorRates', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(payload)
        });

        console.log('Response Status:', response.status);

        if (!response.ok) {
            const responseText = await response.text(); // Fetch response text for better error understanding
            console.error('Response Text:', responseText);
            throw new Error('Failed to create vendor rate');
        }
        
        const responseData = await response.json();
        console.log('Response Data:', JSON.stringify(responseData, null, 2));
        fetchData(); 
        toast.success('Vendor rates created successfully');
        closePopup();

        console.log('All vendor rates created successfully');

    } catch (error) {
        console.error('Error creating vendor rates:', error);
        toast.error('Failed to create vendor rates');
    }
};



    return (
        <div className="fixed inset-0 flex justify-center bg-gray-800 bg-opacity-70 z-50">
            <div className='mt-10 p-12 h-fit bg-gray-200 w-3/5'>
                <button onClick={closePopup} className="text-white float-right p-2 bg-red-500">✕</button>
                <div className="mb-10">
                    <ul className='flex'>
                        <li><h1 className="font-extrabold text-3xl text-left text-gray-500">Create Vendor Rate</h1></li>
                    </ul>
                    <div className="mt-2 border-t border-gray-500"></div>
                </div>
                <div className='flex'>
                    <div className='ml-12 mt-14' ref={vendorInputRef}>
                        <h1 className="font-extrabold text-2xl text-left text-gray-500">Select Vendor</h1>
                        <input
                            type="text"
                            placeholder="Search vendors"
                            value={vendorSearchQuery}
                            onFocus={() => {
                                setShowVendorSuggestions(true);
                                if (vendorSearchQuery === '') {
                                    fetchVendors();
                                }
                            }}
                            onChange={handleVendorSearchInputChange}
                            className="border border-gray-200 px-4 py-2 pl-10 rounded-lg float-left w-96 mt-2"
                        />
                        {showVendorSuggestions && vendorSuggestions.length > 0 && (
                            <ul className="absolute z-10 w-96 mt-12 bg-white border border-gray-200 rounded-lg shadow-lg">
                                {vendorSuggestions.map((vendor) => (
                                    <li key={vendor.id} className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                        onClick={() => handleVendorSelect(vendor)}
                                    >
                                        {vendor.vendorName}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>

                {selectedProducts.length > 0 && (
                    <div className="mt-4">
                        <h3 className="text-lg font-semibold mb-2">Selected Product Details</h3>
                        <table className="w-full border-collapse border-gray-500 text-left">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="px-4 py-2 text-center">Product Name</th>
                                    <th className="px-4 py-2 text-center">Product Code</th>
                                    <th className="px-4 py-2 text-center">Per KG</th>
                                    <th className="px-4 py-2 text-center">Per Quantity Cost</th>
                                    <th className="px-4 py-2 text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedProducts.map((product, index) => (
                                    <tr key={product.productId} className="bg-white">
                                        <td className="border px-2 text-center py-2">{product.productName}</td>
                                        <td className="border px-2 text-center py-2">{product.productsCode}</td> {/* Display productsCode */}
                                        <td className="border px-2 text-center py-2">
                                            <input
                                                type="number"
                                                value={product.perKgCost}
                                                onChange={(e) => handlePerKgCostChange(product.productId, e.target.value)}
                                                className="border border-gray-300 p-1"
                                            />
                                        </td>
                                        <td className="border px-2 text-center py-2">
                                            <input
                                                type="number"
                                                value={product.perQuantityCost}
                                                onChange={(e) => handlePerQuantityCostChange(product.productId, e.target.value)}
                                                className="border border-gray-300 p-1"
                                            />
                                        </td>
                                        <td className="border px-2 text-center py-2">
                                            <button onClick={() => handleProductDelete(product.productId)}>
                                                <IoMdTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                )}

                <div className="flex justify-between mt-4">
                    <button type="button" onClick={handleCloseCreatePopup} className="px-4 py-2 bg-red-500 text-white rounded-lg ml-12">
                        Cancel
                    </button>
                    <button type="button" onClick={handleCreatedata} className="px-4 py-2 bg-green-500 text-white rounded-lg mr-12">
                        Save
                    </button>
                </div>

                <ToastContainer />
            </div>
        </div>
    );
};

export default CreateVendorrate;


