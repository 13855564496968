import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse } from "date-fns";

const StockCreate = ({ isVisible, onClose, onStockCreated }) => {
  const [categories, setCategories] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [newProducts, setNewProducts] = useState([]);
  const [newProduct, setNewProduct] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [newUnit, setNewUnit] = useState("");
  const [newQuantity, setNewQuantity] = useState("");
  const [newTrack, setNewTrack] = useState("");
  const [units, setUnits] = useState([]);
  const [expireDate, setExpireDate] = useState(null);
  const [manufactureDate, setManufactureDate] = useState(null);
  const[brand,setBrands]=useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');

  const [warehouseError, setWarehouseError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [productError, setProductError] = useState("");
  const [newProductError, setNewProductError] = useState("");
  const [newPriceError, setNewPriceError] = useState("");
  const [newUnitError, setNewUnitError] = useState("");
  const [newQuantityError, setNewQuantityError] = useState("");
  const [formError, setFormError] = useState("");
  const [exp_DateError, setExpireDateError] = useState("")
  const [maf_DateError, setMafDateError] = useState("");
  const[brandError,setBrandError]=useState("");




  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          console.error("No token found in local storage");
          return;
        }

        const response = await fetch(
          "https://pos.farm2bag.com/api/v1/categories",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        if (response.ok) {
          setCategories(data.data);
        } else {
          console.error("Failed to fetch categories");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchWarehouses = async () => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          console.error("No token found in local storage");
          return;
        }

        const response = await fetch("https://pos.farm2bag.com/api/v1/warehouse", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();

        if (response.ok) {
          setWarehouses(data.data);
        } else {
          console.error("Failed to fetch warehouses");
        }
      } catch (error) {
        console.error("Error fetching warehouses:", error);
      }
    };

    const fetchUnits = async () => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          console.error("No token found in local storage");
          return;
        }

        const response = await fetch(
          "https://pos.farm2bag.com/api/v1/measurements",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        if (response.ok) {
          setUnits(data.data);
        } else {
          console.error("Failed to fetch units");
        }
      } catch (error) {
        console.error("Error fetching units:", error);
      }
    };
    const fetchBrands = async () => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          console.error("No token found in local storage");
          return;
        }

        const response = await fetch(
          "https://pos.farm2bag.com/api/v1/brand",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        if (response.ok) {
        setBrands(data.data);
        console.log(brand);
        } else {
          console.error("Failed to fetch Brands");
        }
      } catch (error) {
        console.error("Error fetching Brands:", error);
      }
    };

    if (isVisible) {
      fetchCategories();
      fetchWarehouses();
      fetchUnits();
      fetchBrands();
    }
  }, [isVisible]);

  const handleCategoryChange = async (categoryId) => {
    setSelectedCategory(categoryId);
    setCategoryError("");
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found in local storage");
        return;
      }
      const response = await fetch(
        `https://pos.farm2bag.com/api/v1/products/category/${categoryId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSelectedProducts(data.data);
      } else {
        console.error("Failed to fetch products for category");
      }
    } catch (error) {
      console.error("Error fetching products for category:", error);
    }
  };

  const handleAddProduct = () => {

    if (!newProduct) {
      setNewProductError("Please select a product.");
    } else {
      setNewProductError(""); // Reset error when product is selected
    }
    if (!newPrice) {
      setNewPriceError("Please enter the price.");
    } else {
      setNewPriceError(""); // Reset error when price is entered
    }
    if (!newUnit) {
      setNewUnitError("Please select a unit.");
    } else {
      setNewUnitError(""); // Reset error when unit is selected
    }
    if (!newQuantity) {
      setNewQuantityError("Please enter the quantity.");
    } else {
      setNewQuantityError(""); // Reset error when quantity is entered
    }
    if (!expireDate) {
      setExpireDateError("Please select the date");
    }
    else {
      setExpireDateError("");
    }
    if (!manufactureDate) {
      setMafDateError("Please select the date");
    }
    else {
      setMafDateError("");

    }
    if (!newTrack) {
      setNewTrack("Please select the place");
    }
    else {
      setNewTrack("");

    }
    if (!selectedBrand) {
      setBrandError("Please select a product.");
    } else {
      setBrandError(""); // Reset error when product is selected
    }



    if (!newProduct || !newPrice || !newUnit || !newQuantity || !expireDate || !manufactureDate || !newTrack || !selectedBrand) {
      return;
    }

    const selectedProduct = selectedProducts.find((p) => p.id === newProduct);
    const selectedUnit = units.find((unit) => unit.id === newUnit);

    if (!selectedProduct || !selectedUnit) {
      console.error("Selected product or unit not found.");
      return;
    }

    const product = {
      productId: newProduct,
      productName: selectedProduct.name,
      price: parseFloat(newPrice),
      unitId: newUnit,
      unitSymbol: selectedUnit.unitSymbol,
      quantity: parseFloat(newQuantity, 10),
      exp_Date: expireDate ? format(expireDate, "dd/MM/yyyy") : "N/A",
      maf_Date: manufactureDate
        ? format(manufactureDate, "dd/MM/yyyy")
        : "N/A",
      track: newTrack,
      brandId: selectedBrand,
      brandName: brand.find((b) => b.id === selectedBrand)?.brandName 

    };

    setNewProducts([...newProducts, product]);
    setNewProduct("");
    setNewPrice("");
    setNewUnit("");
    setNewQuantity("");
    setExpireDate("");
    setManufactureDate("");
    setNewTrack("");
    setFormError(""); // Reset form error when products are added
    setSelectedBrand("");
  };

  const handleCreateStock = async () => {
    if (!selectedWarehouse) {
      setWarehouseError("Please select a warehouse.");
    }
    if (!selectedCategory) {
      setCategoryError("Please select a category.");
    }
    if (newProducts.length === 0) {
      setFormError("Please add at least one product.");
    } else {
      setFormError("");
    }

    if (!selectedWarehouse || !selectedCategory || newProducts.length === 0) {
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const role = localStorage.getItem("role");

      if (!token) {
        console.error("No token found in local storage");
        return;
      }

      const response = await fetch("https://pos.farm2bag.com/api/v1/stocks", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          products: newProducts.map(({ unitSymbol, brandName,...rest }) => rest),
          createdBy: role,
          wareHouseId: selectedWarehouse,
        }),
      });

      if (response.ok) {
        resetForm();
        onClose();
        onStockCreated();
      } else {
        console.error("Failed to create stock");
      }
    } catch (error) {
      console.error("Error creating stock:", error);
    }
  };

  const resetForm = () => {
    setSelectedCategory("");
    setSelectedWarehouse("");
    setSelectedProducts([]);
    setNewProducts([]);
    setNewProduct("");
    setNewPrice("");
    setNewUnit("");
    setNewQuantity("");
    setManufactureDate("");
    setExpireDate("");
    setNewTrack("");
    setSelectedBrand("")


    // Reset all error states
    setWarehouseError("");
    setWarehouseError("");
    setCategoryError("");
    setProductError("");
    setNewProductError("");
    setNewPriceError("");
    setNewUnitError("");
    setNewQuantityError("");
    setFormError("");
    setMafDateError("");
    setExpireDateError("")
    setBrandError("");

  };

  const handleCancel = () => {
    resetForm();
    onClose();
  };

  const getWarehouseNameById = (id) => {
    const warehouse = warehouses.find((warehouse) => warehouse.id === id);
    return warehouse ? warehouse.name : "Unknown Warehouse";
  };

  if (!isVisible) return null;

  const handleWarehouseChange = (warehouseId) => {
    setSelectedWarehouse(warehouseId);
    setWarehouseError("");
  };
  const handleRemoveProduct = (index) => {
    const updatedProducts = [...newProducts];
    updatedProducts.splice(index, 1);
    setNewProducts(updatedProducts);
  };
  const handleExpireDateChange = (date) => {
    setExpireDate(date);
    setExpireDateError("")
  };
  const handleBrand=(brandId)=>{
    setSelectedBrand(brandId);
    setBrandError("");
  }

  const handleManufactureDateChange = (date) => {
    setManufactureDate(date);
    setMafDateError(""); // Clear the error message when the date is selected
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 mt-16 ml-40">
      <div className="bg-white p-3 rounded-lg shadow-lg  h-max   w-9/12  ">
        <h2 className="text-lg font-bold mb-2">Create New Stock Item</h2>
        <div className="mb-3">
          <div className="flex flex-wrap -mx-2">
            <div className="w-1/3 px-2">
              <select
                value={selectedWarehouse}
                onChange={(e) => handleWarehouseChange(e.target.value)}
                className="w-full px-1 py-1 border rounded-lg focus:outline-none focus:border-blue-500"
              >
                <option value="">Select Warehouse</option>
                {warehouses.map((warehouse) => (
                  <option key={warehouse.id} value={warehouse.id}>
                    {warehouse.name}
                  </option>
                ))}
              </select>
              {warehouseError && (
                <p className="text-red-500 text-xs">{warehouseError}</p>
              )}
            </div>
            <div className="w-1/3 px-2">
              <select
                value={selectedCategory}
                onChange={(e) => handleCategoryChange(e.target.value)}
                className="w-full px-1 py-1 border rounded-lg focus:outline-none focus:border-blue-500"
              >
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.categoryName}
                  </option>
                ))}
              </select>
              {categoryError && (
                <p className="text-red-500 text-xs">{categoryError}</p>
              )}
            </div>
            <div className="w-1/3 px-2">
              <select
                value={newProduct}
                onChange={(e) => {
                  setNewProduct(e.target.value);
                  setNewProductError(""); // Reset error message when product is selected
                }}
                className="w-full px-1 py-1 border rounded-lg focus:outline-none focus:border-blue-500"
              >
                <option value="">Select Product</option>
                {selectedProducts.map((product) => (
                  <option key={product.id} value={product.id}>
                    {product.name}
                  </option>
                ))}
              </select>
              {newProductError && (
                <p className="text-red-500 text-xs">{newProductError}</p>
              )}
            </div>
            <div className="w-1/3 px-2 mt-4">
              <input
                type="number"
                min="1"
                value={newPrice}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value >= 0) {
                    setNewPrice(value);
                    setNewPriceError(""); // Reset error message when valid value is entered
                  } else {
                    setNewPriceError("Negative value should not be entered");
                  }
                }}
                placeholder="Price"
                className="w-full px-1 py-1 border rounded-lg focus:outline-none focus:border-blue-500"
              />

              {newPriceError && (
                <p className="text-red-500 text-xs">{newPriceError}</p>
              )}
            </div>
            <div className="w-1/3 px-2 mt-4">
              <select
                value={newUnit}
                onChange={(e) => {
                  setNewUnit(e.target.value);
                  setNewUnitError(""); // Reset error message when unit is selected
                }}
                className="w-full px-1 py-1 border rounded-lg focus:outline-none focus:border-blue-500"
              >
                <option value="">Select Unit</option>
                {units.map((unit) => (
                  <option key={unit.id} value={unit.id}>
                    {unit.unitSymbol}
                  </option>
                ))}
              </select>
              {newUnitError && (
                <p className="text-red-500 text-xs">{newUnitError}</p>
              )}
            </div>
            <div className="w-1/3 px-2 mt-4">
              <input
                type="number"
                min="1"
                value={newQuantity}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value >= 0) {
                    setNewQuantity(value);
                    setNewQuantityError(""); // Reset error message when valid value is entered
                  } else {
                    setNewQuantityError("Negative value should not be entered");
                  }
                }}
                placeholder="Quantity"
                className="w-full px-1 py-1 border rounded-lg focus:outline-none focus:border-blue-500"
              />
              {newQuantityError && (
                <p className="text-red-500 text-xs">{newQuantityError}</p>
              )}
            </div>
            <div className="w-1/3 px-2 mt-4">
              <input
                type="text"
                value={newTrack}
                onChange={(e) => setNewTrack(e.target.value)}  // Add this line
                placeholder="Track"
                className="w-full px-1 py-1 border rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
            

              <div className="w-1/3 px-2 mt-4">
        <select
          value={selectedBrand}
          onChange={(e) => handleBrand(e.target.value)}
          className="w-full px-1 py-1 border rounded-lg focus:outline-none focus:border-blue-500"
        >
          <option value="">Select Brand</option>
          {brand.map((brand) => (
            <option key={brand.id} value={brand.id}>
              {brand.brandName}
            </option>
          ))}
        </select>
        {brandError && (
          <p className="text-red-500 text-xs">{brandError}</p>
        )}
      </div>

            <div className="mb-4 mt-2 m-2">
              <label
                htmlFor="expireDate"
                className="block text-gray-700 text-sm font-bold mb-2 text-left"
              >
                Manufacture Date

              </label>
              <DatePicker
                selected={manufactureDate}
                onChange={handleManufactureDateChange}
                dateFormat="dd/MM/yyyy"
                placeholderText="dd/mm/yyyy"
                className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
  {maf_DateError && (
                <p className="text-red-500 text-xs">{maf_DateError}</p>
              )}
            
            </div>
            <div className="mb-4 mt-2">
              <label
                htmlFor="manufactureDate"
                className="block text-gray-700 text-sm font-bold mb-2 text-left"
              >
                Expire Date
              </label>
              <DatePicker
                selected={expireDate}
                onChange={handleExpireDateChange}
                dateFormat="dd/MM/yyyy"
                placeholderText="dd/mm/yyyy"
                className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
  {exp_DateError && (
                <p className="text-red-500 text-xs">{exp_DateError}</p>
              )}
         
            
            </div>
          </div>

          <button
            onClick={handleAddProduct}
            className="bg-blue-500 text-white px-1 py-1 rounded-lg w-28 mt-2"
          >
            Add Product
          </button>
        </div>

        <div className="max-h-40 overflow-y-auto">
          <div className="mb-3  ">
            <h3 className="text-lg font-bold mb-1">Added Products</h3>
            <table className="min-w-full bg-white">
              <thead className="bg-green-400 ">
                <tr>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
                    Product Name
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
                    Brand Name
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
                    Price (Rs)
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
                    Quantity (Q)
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
                    Manufacture Date
                  </th>
                 
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
                    Expire Date
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
                    Track
                  </th>

                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {newProducts.map((product, index) => (
                  <tr key={index}>
                    <td className="py-2 px-4 border-b border-gray-200 text-left text-sm">
                      {product.productName}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 text-left text-sm">
                       {product.brandName}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 text-left text-sm">
                       {product.price}
                    </td>

                    <td className="py-2 px-4 border-b border-gray-200 text-left text-sm">
                      {product.quantity}
                      <span className="px-2 bg-yellow-300 rounded-md text-left">
                        {product.unitSymbol}
                      </span>{" "}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 text-left text-sm">
                      {product.maf_Date}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 text-left text-sm">
                      {product.exp_Date}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 text-left text-sm">
                      {product.track}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 text-left text-sm">
                      <button
                        onClick={() => handleRemoveProduct(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {formError && <p className="text-red-500 text-xs">{formError}</p>}
        <div className="flex justify-end mt-4">
          <button
            onClick={handleCancel}
            className="bg-gray-500 text-white px-1 py-1 rounded-lg mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handleCreateStock}
            className="bg-green-500 text-white px-1 py-1 rounded-lg"
          >
            Create Stock
          </button>
        </div>
      </div>
    </div>
  );
};

export default StockCreate;
